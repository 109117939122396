<template>
	<page-container no-column>
		<accesses-list
			v-model="activeAccesses"
			:items="accesses"
			@changeItems="onChangeItems"
			:loading="loadingData"
			ref="accesses-list"
		/>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import AccessesList from '@/components/accesses/AccessesList.vue';
import AccessService from '@/services/AccessService';

export default {
	name: 'FunctionalitiesForm',
	components: {
		PageContainer,
		AccessesList,
	},
	data() {
		return {
			accesses: [],
			activeAccesses: this.value,
			loadingData: false,
		};
	},
	props: {
		value: [Object, Array],
		project: Object,
	},
	watch: {
		// two-way Data Binding
		activeAccesses() {
			if (this.activeAccesses == this.value) return;

			this.$emit('input', this.activeAccesses);
		},
	},
	mounted() {
		this.loadAccesses();
	},
	methods: {
		async loadAccesses() {
			try {
				this.loadingData = true;

				const accessService = new AccessService();

				const response = await accessService.functionalities().index();

				this.accesses = response.data;

				this.$nextTick(() => {
					this.updateModel();
				});
			} catch (error) {
				this.$http.defaultCatchError(error);

				this.accesses = [];
			} finally {
				this.loadingData = false;
			}
		},
		onChangeItems(accesses) {
			this.accesses = accesses;
		},
		updateModel() {
			this.activeAccesses = this.value;

			this.$refs['accesses-list'].changeActiveAccessesByValue();
		},
	},
};
</script>

<style>

</style>
