<template>
	<v-select
		label="Tipo de usuário"
		v-model="model"
		:items="userTypes"
		:loading="loadingData"
		:rules="rules"
		item-text="title"
		item-value="id"
		return-object
		@change="onUserTypeChange"
	/>
</template>

<script>
import UserTypeService from '@/services/UserTypeService';

export default {
	name: 'UserTypeSelect',
	props: {
		value: Object,
		rules: Array,
	},
	data() {
		return {
			model: {},
			loadingData: false,
			userTypes: [],
		};
	},
	watch: {
		// Two Way data-binding
		value() {
			if (this.modelAndValueAreTheSame()) return;

			this.model = this.value;
		},
	},
	created() {
		this.loadUserTypes();
	},
	methods: {
		/**
		 * Event functions
		 */
		onUserTypeChange() {
			this.sendData();
		},
		/**
		 * Functions of CRUD
		 */
		async loadUserTypes() {
			try {
				this.loadingData = true;

				const userTypeService = new UserTypeService();

				const response = await userTypeService.index();

				this.userTypes = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		/**
		 * Helper Functions
		 */
		modelAndValueAreTheSame() {
			return this.model == this.value;
		},
		sendData() {
			// two way data binding
			this.$emit('input', this.model);
		},
	},
};
</script>

<style>

</style>
