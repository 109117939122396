<template>
	<page-container>
		<breadcrumbs :page-title="getRouteTitle()"/>

		<v-form v-model="valid" ref="form" @submit.prevent="() => {}">
			<form-structure :loading="loadingData">
				<template v-slot:content>
					<project-save-tabs v-model="project" :loading="loadingData"/>
				</template>

				<template v-slot:actions>
					<form-cancel-button/>

					<form-save-button
						:valid="valid"
						@click="save"
						:loading="loadingSubmit"
						:disabled="loadingData"
					/>

					<form-destroy-button
						v-if="!isANewProject()"
						:loading="loadingRemove"
						:disabled="loadingData"
						@remove="remove"
					/>
				</template>
			</form-structure>
		</v-form>
	</page-container>
</template>

<script>
import ProjectService from '@/services/ProjectService';

import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import FormDestroyButton from '@/components/defaults/buttons/FormDestroyButton.vue';
import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';

import ProjectSaveTabs from './ProjectSaveTabs.vue';

export default {
	name: 'ProjectSave',
	components: {
		FormSaveButton,
		Breadcrumbs,
		FormStructure,
		PageContainer,
		ProjectSaveTabs,
		FormDestroyButton,
		FormCancelButton,
	},
	data() {
		return {
			project: {},
			valid: false,
			loadingData: false,
			loadingSubmit: false,
			loadingRemove: false,
		};
	},
	created() {
		this.loadByRoute();
	},
	methods: {
		/**
		 * UX
		 */
		getRouteTitle() {
			if (this.idIsNewOrNotExists()) return 'Criação de Projeto';

			return 'Edição de Projeto';
		},
		/**
		 * Helper functions
		 */
		idIsNewOrNotExists(id) {
			return (!id || id == 'new');
		},
		getIdOfRoute() {
			const { id } = this.$route.params;
			return id;
		},
		isANewProject() {
			if (this.project.id) return false;

			const id = this.getIdOfRoute();

			return (this.idIsNewOrNotExists(id));
		},
		returnToPreviousRoute() {
			this.$router.push({ name: 'ProjectList' });
		},
		/**
		 * CRUD
		 */
		async loadByRoute() {
			try {
				const id = this.getIdOfRoute();

				if (this.isANewProject()) return;

				this.loadingData = true;

				const projectService = new ProjectService();

				const response = await projectService.show(id);

				this.project = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
				this.project = {};
			} finally {
				this.loadingData = false;

				this.serializeCompanies();
			}
		},

		async save() {
			try {
				const canSave = this.canSave();

				if (!canSave) return;

				this.loadingSubmit = true;

				const projectService = new ProjectService();

				if (this.isANewProject()) {
					await projectService.store(this.project);
				} else {
					await projectService.update(this.project);
				}

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingSubmit = false;
			}
		},

		canSave() {
			const valid = this.$refs.form.validate();

			if (!valid) return false;

			if (!this.project.companies || !this.project.companies.length) {
				const errorMessage = 'É necessário informar ao menos uma empresa';

				this.$http.defaultCatchError(null, errorMessage);

				return false;
			}

			return true;
		},

		async remove() {
			try {
				if (this.isANewProject()) return;

				this.loadingRemove = true;

				const projectService = new ProjectService();

				await projectService.destroy(this.project);

				this.project = {};
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingRemove = false;
			}
		},

		serializeCompanies() {
			if (!this.project || !this.project.companies) return;

			this.project.companies = this.project.companies.map((company, index) => {
				company.index = index + 1;

				return company;
			});
		},
	},
};
</script>

<style>

</style>
