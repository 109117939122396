<template>
	<grid-system-home/>
</template>

<script>
import GridSystemHome from './HomeView/GridSystemHome.vue';

export default {
	name: 'home',
	components: {
		GridSystemHome,
	},
	created() {
	},
};
</script>

<style>

</style>
