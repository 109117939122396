<template>
  <v-select
		:items="users"
		item-text="name"
		item-value="id"
		v-model="model"
		:label="label"
		return-object
		:loading="loading"
		:rules="rules"
		:disabled="isValidUser"
	>
  </v-select>
</template>

<script>
import UserService from '@/services/UserService';

export default {
	name: 'UserSelect',

	data() {
		return {
			loading: false,
			users: [],
			model: this.user,
		};
	},
	props: {
		rules: Array,
		substitute: [Number, Object],
		selector: [Number, Object],
		label: String,
		isValidUser: Boolean,
	},
	watch: {
		model() {
			this.$emit('input', this.model);
		},
		substitute: {
			deep: true,
			handler(value) {
				if (value) {
					this.model = value;
				}
			},
		},

	},
	created() {
		this.getUsers();
	},

	methods: {
		async getUsers() {
			try {
				this.loading = true;
				const userService = new UserService();

				const response = await userService.index();

				this.users = response.data;
				this.model = this.substitute;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
