<template>
	<div class="mt-n7 d-flex flex-column w-100">
		<v-tabs v-model="activeTab">
			<v-tab href="#fund">
				Informações
			</v-tab>
		</v-tabs>

		<v-tabs-items v-model="activeTab">
			<v-tab-item value="fund">
				<fund-form
					v-model="fund"
				/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import FundForm from './FundForm.vue';

export default {
	name: 'FundTabs',
	components: {
		FundForm,
	},
	props: {
		value: Object,
	},
	data() {
		return {
			activeTab: '',
			fund: this.value,
		};
	},
	watch: {
		fund: {
			deep: true,
			handler(value) {
				this.$emit('input', value);
			},
		},

		value() {
			if (this.fund == this.value) return;

			this.fund = this.value;
		},
	},
};
</script>

<style>

</style>
