<template>
	<login-layout
		@submit="verify"
		v-model="valid"
		ref="authForm"
	>
		<template v-slot:form>
			<v-col cols="12" class="text-center my-auto py-12" v-if="!validToken">
				<span
					class="
						text-subtitle-1
						font-weight-bold"
				>
					O código expirou, por favor peça para o administrador lhe enviar uma nova confirmação por email.
				</span>
			</v-col>

			<v-col cols="12" class="text-center" v-if="validToken">
				<span
					class="
						text-subtitle-1
						font-weight-normal
						"
				>
					Olá <b>{{ user.name }}</b>!
					Sua conta foi verificada. <br> Para prosseguir, por favor, crie uma senha para utilização da INTRANET.
				</span>
			</v-col>

			<v-col cols="12" v-if="validToken">
				<v-text-field
					label="Nova senha"
					v-model="user.new_password"
					type="password"
					:rules="passwordValidationRules"
					@input="revalidate"
					outlined
				/>
			</v-col>

			<v-col cols="12" v-if="validToken">
				<v-text-field
					label="Confirmar senha"
					v-model="user.confirm_password"
					type="password"
					:rules="passwordValidationRules"
					@input="revalidate"
					outlined
				/>
			</v-col>
		</template>

		<template v-slot:actions>
			<v-col class="pa-0 text-end ml-n1">
				<v-btn
					depressed
					color="primary"
					:loading="loggingIn"
					@click="verify"
					:disabled="!valid"
					block
				>
					registrar
				</v-btn>
			</v-col>
		</template>
	</login-layout>
</template>

<script>
import AuthService from '@/services/AuthService';
import LoginLayout from '../../components/auth/LoginLayout.vue';

export default {
	name: 'VerifyPassword',
	components: {
		LoginLayout,
	},
	data() {
		return {
			loggingIn: false,
			user: {},
			valid: false,
			validToken: false,
		};
	},
	computed: {
		passwordValidationRules() {
			let rules = [];

			const needToBeSet = (v) => !!v || 'Senha precisa ser definida';

			const newPasswordHasToBeTheSame = (v) => (v == this.user.new_password
				&& v == this.user.confirm_password)
				|| 'senhas não coincidem';

			rules = [...rules, ...[needToBeSet, newPasswordHasToBeTheSame]];

			return rules;
		},
	},
	created() {
		this.validateJWTToken();
	},
	methods: {
		/**
		 * JWT
		 *
		 * validateJWTToken
		 * verifica se o token passado pode ser utilizado para o envio do email
		 *
		 * @requires String verify_token - retornado pela rota como paramêtro
		 */
		validateJWTToken() {
			const { token } = this.$route.params;

			const decodedToken = this.$helpers.jwt.decode(token);

			const hasExpired = this.$helpers.jwt.hasExpired(decodedToken);

			const haveRequiredParams = this.validateTokenParams(decodedToken);

			this.validToken = !hasExpired && haveRequiredParams;

			this.loadUserDataByToken();
		},
		validateTokenParams(token) {
			if (!token.user_id.id) return false;

			return true;
		},
		/**
		 * CRUD
		 */
		async verify() {
			try {
				this.loggingIn = true;

				const authService = new AuthService();
				const response = await authService.verify().update(this.user, '/verify');

				this.saveLoginOnToken(response.token);

				this.showSuccessDialog();
				this.$router.push({ name: 'Home' });
			} catch (error) {
				console.log('catch error: ', error);
				this.$http.defaultCatchError(error);
			} finally {
				this.loggingIn = false;
			}
		},

		showSuccessDialog() {
			this.$swal({
				title: 'Senha alterada com sucesso',
				icon: 'success',
				showConfirmButton: true,
			});
		},

		loadUserDataByToken() {
			if (!this.validToken) return;

			const { token } = this.$route.params;

			const decodedToken = this.$helpers.jwt.decode(token);

			const {
				id,
				email,
				name,
				created_by,
			} = decodedToken.user_id;

			const userData = {
				id,
				email,
				name,
				created_by,
				verify_token: token,
			};

			this.user = {
				...this.user,
				...userData,
			};
		},
		/**
		 * HELPERS
		 */
		revalidate() {
			this.$refs.authForm.revalidate();
		},
		saveLoginOnToken(loggedUserToken) {
			sessionStorage.logged_user = JSON.stringify(loggedUserToken);
		},
	},
};
</script>
