<template>
  <div>
    <list-edit-button
    v-if="
      (['C', 'CG'].includes(item.type) && this.user.user_type.name === 'admin') ||
      (['approve', 'disapprove'].includes(item.status.trim()) && this.user.user_type.name === 'admin') ||
      (!item.status.trim() && !['C', 'CG'].includes(item.type))
    "
      @edit="edit"
    />

    <v-tooltip
      bottom
      v-if="
				this.user.user_type.name === 'admin' &&
        item.type === 'S' &&
        item.status.trim() !== 'approve' &&
        item.status.trim() !== 'disapprove'
      "
    >
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          @click="onClick(item, user.user, 'approve')"
          class="mr-2 cursor-pointer"
        >
          mdi-check-all
        </v-icon>
      </template>

      <span>Aprovar pedido</span>
    </v-tooltip>
    <v-tooltip
      bottom
      v-if="
				this.user.user_type.name === 'admin' &&
        item.type === 'S' &&
        item.status.trim() !== 'approve' &&
        item.status.trim() !== 'disapprove'
      "
    >
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          @click="onClick(item, user.user, 'disapprove')"
          class="cursor-pointer mr-2"
        >
          mdi-block-helper
        </v-icon>
      </template>

      <span>Reprovar pedido</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          @click="show(item)"
          class="cursor-pointer"
        >
          mdi-eye
        </v-icon>
      </template>

      <span>Visualizar</span>
    </v-tooltip>
  </div>
</template>
<script>
import VacationsService from '@/services/VacationsService';

import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';

export default {
	name: 'VacationsTableOptions',
	components: {
		ListEditButton,
	},

	props: {
		item: { type: Object, required: true },
		user: { type: Object, required: true },
	},

	methods: {
		async onClick(item, admin, type) {
			const { text, title, icon } = this.getTexts(type);

			const result = await this.$swal({
				text,
				title,
				icon,
				showConfirmButton: true,
				showCancelButton: true,
			});

			const { value } = result;

			if (value) {
				this.toApproveOrDisapprove(item, admin, type);
			}
		},

		async toApproveOrDisapprove(item, admin, type) {
			try {
				this.$emit('loading');

				const params = {
					id: item.vacations_id,
					observation: '',
					adminId: admin.id,
				};
				const vacationService = new VacationsService();

				if (type === 'approve') {
					await vacationService.approve().updateVaction(params);
				} else {
					await vacationService.disapprove().updateVaction(params);
				}
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.$emit('reload');
				this.loadingData = false;
			}
		},
		getTexts(type) {
			if (type === 'approve') {
				return {
					text: 'Você deseja aprovar esse pedido de férias?',
					title: 'Aprovar pedido de férias.',
					icon: 'success',
				};
			}
			return {
				text: 'Você deseja reprovar esse pedido de férias?',
				title: 'Reprovar pedido de férias.',
				icon: 'error',
			};
		},
		edit() {
			this.$emit('edit', this.item);
		},

		show(item) {
			this.$emit('show', { id: item.vacations_id });
		},
	},
};
</script>
