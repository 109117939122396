const mixin = {
	methods: {
		/**
		 * replaceListItem
		 *
		 * busca um item numa lista e atualiza os valores desse item com os passados
		 * utiliza da funcao de spread para isso.
		 *
		 * @param {Array} items - items a serem buscados
		 * @param {String} identifier - campo a ser buscado para utilização
		 * @param {*} id_value - valor que o comparador deve possuir para corresponder
		 * @param {*} replace_value - valor que sera adiciona no lugar do valor
		 * original atraves da funcao spread
		 *
		 * @return {Array}
		 */
		// eslint-disable-next-line
		replaceListItem({ items, replace_value, id_value, identifier = 'id' }) {
			const result = items.map((item) => {
				if (item[identifier] == id_value) {
					const toReplaceItem = { ...item, ...replace_value };

					return toReplaceItem;
				}

				return item;
			});

			return result;
		},
		/**
		 * findOne
		 *
		 * busca um item atraves do array
		 *
		 * @param {Array} items - items a serem buscados
		 * @param {String} identifier - campo a ser buscado para utilização
		 * @param {*} id_value - valor que o comparador deve possuir para corresponder
		 *
		 * @return {*}
		 */
		findOne({ items, id_value, identifier = 'id' }) {
			const result = items.filter((item) => item[identifier] == id_value);

			return result[0];
		},
		/**
		 * deleteListItem
		 *
		 * adiciona um valor de removed para um item encontrado na lista e retorna para filtro
		 *
		 * @param {Array} items - items a serem buscados
		 * @param {String} identifier - campo a ser buscado para utilização
		 * @param {*} id_value - valor que o comparador deve possuir para corresponder
		 *
		 * @return {Array}
		 */
		deleteListItem({ items, id_value, identifier = 'id' }) {
			const result = items.filter((item) => {
				if (item[identifier] == id_value) {
					return false;
				}

				return true;
			});

			return result;
		},

		filterOnlyRemovedItems({ items }) {
			if (!items) {
				return [];
			}

			const result = items.filter((item) => !item.removed);

			return result;
		},
	},
};

export default mixin;
