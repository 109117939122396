<template>
  <page-container no-column>
    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <user-select
        v-model="vacation.user"
        :user="vacation.creator || vacation.user"
        label="Usuário"
      />
    </v-col>

		<v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <v-text-field
				label="Quantidade de dias"
				v-model="vacation.number_days"
				type="number"
			>

			</v-text-field>
    </v-col>

    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <v-text-field label="Motivo" v-model="vacation.reason" />
    </v-col>
    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <date-picker
        :rules="[rules.required]"
        label="Data"
        v-model="vacation.start_date"
        placeholder=""
        clearable
        @input="validate"
      />
    </v-col>

    <v-col x xl="5" lg="5" md="5" sm="12" xs="12" class="mb-n4">
      <v-text-field label="Observação" v-model="vacation.observation"> </v-text-field>
    </v-col>
  </page-container>
</template>
<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import DatePicker from '@/components/defaults/DatePicker.vue';

import UserSelect from './inputs/UserSelect.vue';

export default {
	name: 'CreditSaveMainForm',

	components: {
		PageContainer,
		UserSelect,
		DatePicker,
	},

	props: {
		user: { type: Object, required: true },
		oldVacation: Object,
		form: Boolean,
	},

	data() {
		return {
			vacation: {
				user: this.user.user,
				start_date: '',
				end_date: null,
				reason: '',
				observation: '',
				type: 'C',
				period: {
					id: null,
					name: null,
				},
				substitute: {
					id: null,
					name: null,
				},
				number_days: null,
			},

			rules: {
				required: (v) => (v !== 'undefined' && v !== null) || 'Campo Necessário',
				date: () => {
					if (this.vacation.start_date > this.vacation.end_date) {
						return 'Data início deve ser menor que a final';
					}
					return true;
				},
			},
		};
	},
	watch: {
		form: {
			deep: true,
			handler(value) {
				if (value) {
					this.save();
				}
			},
		},
		oldVacation: {
			handler(value) {
				this.vacation = value;
			},
		},
	},
	methods: {
		validate() {
			return this.vacation.end_date > this.vacation.start_date;
		},

		async save() {
			this.$emit('save', this.vacation);
		},
	},
};
</script>
