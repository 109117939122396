<template>
	<svg
		class="mb-n3 mx-12 flex-item"
		:width="width || '45'"
		:height="height || '18'"
		viewBox="0 0 70 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M34.5 0L68.708 26.25H0.291996L34.5 0Z" :fill="color || 'black'"/>
	</svg>
</template>

<script>
export default {
	name: 'CardArrowIcon',
	props: {
		color: String,
		width: String,
		height: String,
		right: Boolean,
	},
};
</script>

<style>

</style>
