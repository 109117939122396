<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:search="search"
		:loading="loadingData"
		:customFilter="filterByAllData"
		hide-default-footer
	>
		<template v-slot:item.companies="{ item }">
			<table-chip
				v-for="company in (item.companies)"
				:key="company.id"
				class="my-1 mx-1"
			>
				{{ company.name }}
			</table-chip>
		</template>

		<!-- ACTIONS -->
		<template v-slot:item.actions="{ item }">
			<list-edit-button @edit="edit(item)"/>
		</template>

		<template v-slot:loading>
			<loading-data-circular/>
		</template>
	</v-data-table>
</template>

<script>
import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';
import TableChip from '@/components/defaults/layout/TableChip.vue';

import ProjectService from '@/services/ProjectService';
import TableTools from '@/utils/TableTools';

export default {
	name: 'ProjectListTable',
	components: {
		ListEditButton,
		LoadingDataCircular,
		TableChip,
	},
	props: {
		search: String,
	},
	data() {
		return {
			items: [],
			loadingData: false,
			headers: [
				{ text: 'Grupo', value: 'name' },
				{ text: 'Empresas', value: 'companies' },
				{ text: '', value: 'actions' },
			],
		};
	},
	created() {
		this.loadProjects();
	},
	mixins: [TableTools],
	methods: {
		edit(item) {
			this.$emit('edit', item.id);
		},
		async loadProjects() {
			try {
				this.loadingData = true;

				const projectService = new ProjectService();

				const response = await projectService.index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
	},
};
</script>

<style>

</style>
