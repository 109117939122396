<template>
	<v-img
		src="@/assets/images/logo_verovia_horizontal.svg"
		class="cursor-pointer mt-n3"
		max-width="255"
		max-height="48"
		@click="goHome"
	>
	</v-img>
</template>

<script>
export default {
	name: 'BackHomeLogo',
	data: () => ({}),
	methods: {
		goHome() {
			this.$router.push('/home');
		},
	},
};
</script>
