<template>
  <div>
    <v-data-table :headers="headers" :items="items" :loading="loadingData">
      <template v-slot:[`item.options`]="{ item }">
        <template>
          <div>
            <v-btn icon @click="edit(item)" color="grey">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template></template
      ></v-data-table
    >
  </div>
</template>

<script>
import CompanyService from '@/services/CompanyService';

export default {
	name: 'CompanyTableList',

	data: () => ({
		loadingData: false,

		headers: [
			{
				text: '#',
				value: 'id',
				sortable: true,
			},
			{
				text: 'Nome da empresa',
				value: 'name',
				sortable: true,
			},
			{
				text: 'CNPJ',
				value: 'cnpj',
				sortable: true,
			},
			{
				text: 'Telefone',
				value: 'telephone',
				sortable: true,
			},
			{
				text: 'Agência',
				value: 'agency',
				sortable: true,
			},
			{
				text: 'Cidade',
				value: 'city',
				sortable: true,
			},
			{
				text: '',
				value: 'options',
				sortable: false,
			},
		],

		items: [],
	}),

	created() {
		this.getCompanys();
	},

	methods: {
		async getCompanys() {
			try {
				this.loadingData = true;
				const companyService = new CompanyService();

				const response = await companyService.index();
				this.items = response;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		edit(item) {
			this.$emit('edit', { id: item.id });
		},
	},
};
</script>
