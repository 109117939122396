<template>
	<v-select
		label="Fundo"
		v-model="model"
		:items="items"
		item-text="name"
		item-value="id"
		:rules="rules"
		:loading="loading || isLoading"
		:return-object="returnObject"
		:dense="dense"
	/>
</template>

<script>
import FundService from '@/services/FundService';

export default {
	name: 'FundSelect',
	props: {
		value: [Object, Number],
		rules: Array,
		loading: Boolean,
		returnObject: Boolean,
		dense: Boolean,
	},
	data() {
		return {
			model: this.value,
			items: [],
			isLoading: false,
		};
	},
	watch: {
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
		model() {
			this.$emit('input', this.model);
			this.$emit('change', this.model);
		},
	},
	mounted() {
		this.loadFunds();
	},
	methods: {
		async loadFunds() {
			try {
				this.isLoading = true;

				const fundService = new FundService();

				const response = await fundService.index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style>

</style>
