<template>
	<v-scale-transition>
		<div
			class="w-100 mt-n3 col flex-item flex-column functionalities-card"
			:class="right ? 'mr-0 pr-0 functionalities-card__right' : 'ml-0 pl-0'"
			v-show="visibility == 'open'"
			v-click-outside="onClickOutside"
		>
			<div :class="right ? 'text-end' : 'text-left'">
				<card-arrow-icon
					width="35"
					:color="getPrimaryColor()"
				/>
			</div>

			<v-card
				flat
				class="bg-primary-400"
				:class="right ? 'mr-0' : 'ml-0'"
			>
				<page-container no-column>
					<v-col
						v-for="functionality in orderedFunctionalities"
						:key="functionality.id"
						class="d-flex flex-column cursor-pointer"
						@click="isGrouper(functionality) || onClickFunctionalities(functionality)"
						:class="isGrouper(functionality) ? '' : 'functionalities-card-item'"
						:xl="isGrouper(functionality) ? '12' : ''"
						:lg="isGrouper(functionality) ? '12' : ''"
						:md="isGrouper(functionality) ? '12' : ''"
						:sm="isGrouper(functionality) ? '12' : ''"
						:xs="isGrouper(functionality) ? '12' : ''"
					>

						<!-- divider -->
						<span
							v-if="isGrouper(functionality)"
							class="
								text-caption
								text--secondary-dark
								font-weight-light
							"
						>
							{{ functionality.title }}
						</span>

						<v-divider dark v-if="isGrouper(functionality)"/>

						<!-- comum item -->
						<v-icon
							v-if="!isGrouper(functionality)"
							class="
								flex-item
								mt-3
								mb-2
								mx-6
								white--text
							"
						>
							{{ functionality.icon }}
						</v-icon>

						<span
							v-if="!isGrouper(functionality)"
							class="
								flex-item
								mt-2
								pa-2
								mb-1
								mx-6
								white--text
								text-center
							"
						>
							{{ functionality.title }}
						</span>
					</v-col>
				</page-container>
			</v-card>
		</div>
	</v-scale-transition>
</template>

<script>
// layout components
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import CardArrowIcon from '@/components/defaults/layout/CardArrowIcon.vue';

import AccessesHandler from '@/components/accesses/accesses.handler';

import ClickOutside from 'vue-click-outside';

export default {
	name: 'ProjectFunctionalitiesCard',
	components: {
		PageContainer,
		CardArrowIcon,
	},
	directives: {
		ClickOutside,
	},
	props: {
		project: Object,
		visibility: String,
		right: Boolean,
	},
	computed: {
		orderedFunctionalities() {
			const { functionalities } = this.project;

			const ordered = AccessesHandler.orderAccesses(functionalities);

			return ordered;
		},
	},
	methods: {
		isGrouper(access) {
			return AccessesHandler.isGrouper(access);
		},
		getPrimaryColor() {
			return this.$vuetify.theme.themes.light.primary.lighten1;
		},
		onClickOutside(e) {
			this.$emit('outsideClick', e);
		},
		onClickFunctionalities(functionalities) {
			functionalities.project = this.project;

			this.$emit('functionalityClick', functionalities);
		},
	},
};
</script>

<style lang="sass" scoped>
@import "@/styles/variables"

.functionalities-card
	position: relative
	z-index: 2

	&-item
		&:hover
			background-color: rgba(0,0,0,0.2)
</style>
