<template>
	<v-hover v-slot="{ hover }">
		<v-btn
			icon
			:color="hover ? 'error' : 'grey'"
			@click="clickEvent"
			@click.stop="clickToStopEvent">
			<v-icon> {{ hover ? 'mdi-delete-empty' : 'mdi-delete' }} </v-icon>
		</v-btn>
	</v-hover>
</template>

<script>
export default {
	name: 'ListRemoveButton',
	props: {
		stopPropagation: Boolean,
	},
	data() {
		return {};
	},
	methods: {
		remove() {
			this.$emit('remove');
			this.$emit('click');
		},
		clickToStopEvent() {
			if (this.stopPropagation) {
				this.remove();
			}
		},
		clickEvent() {
			if (!this.stopPropagation) {
				this.remove();
			}
		},
	},
};
</script>

<style>

</style>
