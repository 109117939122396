import axios from 'axios';

const locationToRedirect = '/login';

function getAuthentication() {
	if (sessionStorage.getItem('logged_user')) {
		const rawLoggedUser = sessionStorage.getItem('logged_user');
		const loggedUser = JSON.parse(rawLoggedUser);

		return `${loggedUser.token_type} ${loggedUser.access_token}`;
	}

	return 'none';
}

function authenticateAllRoutes(config) {
	config.headers.common.Authorization = getAuthentication();

	return config;
}

function redirectIfUnauthorized(error) {
	if (error && error.response && error.response.status === 401) {
		sessionStorage.clear();
		window.location = locationToRedirect;
	}

	return Promise.reject(error);
}

/**
 * Build
 * funcao responsavel por definir os valores padrao para acesso das rotas do sistema
 * imediatamente apos a instancia do Vue ser criada os dados de http devem ser modificados
 */
const standard = axios.create({
	baseURL: process.env.VUE_APP_API_ENDPOINT,
	timeout: 100000,
	headers: {
		'Access-Control-Allow-Origin': '*',
	},
});

/**
 * Interceptor
 * adiciona o token a requisicao
 */
standard.interceptors.request.use(authenticateAllRoutes, (error) => Promise.reject(error));

/**
 * Interceptor
 * responsavel por retornar o usuário a tela inicial
 * caso alguma requisicao retorne o codigo 401
 */
standard.interceptors.response.use((response) => response, redirectIfUnauthorized);

export default standard;
