import Vue from 'vue';

import ReceiptsService from '@/services/ReceiptsService';
import OperationCalculationService from './receipts_calculation.service';
import OperationCalculations from './receipts_calculations';

const state = Vue.observable({
	operation: {
		id: null,
		project: null,
		fund: {},
		cnab: {},
		type: {},
		company: {},
		file: {},
		default_expenses: {},
		registers: [],
		conciliated: [],
		observation: '',
		origin: 'receipts',
		calculated_expenses: {},

		// expenses
		expenses: {
			serasa_consultation_value: 0,
			registry_send_value: 0,
			registry_receivable_movement_value: 0,
			other_charges: 0,
			net_received_value: 0,
			negotiation_retention_value: 0,
			repurchase_value: 0,
			mora_cost: 0,
			repurchase_fine_value: 0,
			registry_send_movement_value: 0,
			registry_repurchase_fine_value: 0,
			down_movement_value: 0,
			prorrogation_movement_value: 0,
			bank_slip_registry_value: 0,
		},
	},
	old_operation: {},
	holidays: [],
	project: null,
});

export const getters = {
	project: () => state.project,
	holidays: () => state.holidays,
	operation: () => state.operation,
	old_operation: () => state.old_operation,

	config: (column) => {
		try {
			return state.operation[column];
		} catch (error) {
			return undefined;
		}
	},

	old_operation_config: (column) => {
		try {
			return state.old_operation[column];
		} catch (error) {
			return undefined;
		}
	},

	expense: (column) => {
		try {
			return state.operation.expenses[column];
		} catch (error) {
			return undefined;
		}
	},

	default_expense: (column) => {
		try {
			return state.operation.default_expenses[column];
		} catch (error) {
			return undefined;
		}
	},

	calculated_expense: (column) => {
		try {
			return state.operation.calculated_expenses[column];
		} catch (error) {
			return undefined;
		}
	},

	conciliated: () => state.operation.conciliated,

	expected: (column) => {
		const expected = getters.calculated_expense(column);

		if (!expected) return '';

		return expected;
	},

	suffix: (column) => {
		const suffix = getters.calculated_expense(column);

		if (!suffix) return '';

		return suffix;
	},
};

export const mutations = {
	project: (val) => { state.project = val; },
	holidays: (val) => { state.holidays = val; },
	operation: (val) => { state.operation = val; },
	old_operation: (val) => { state.old_operation = val; },
	conciliated: (val) => { state.operation.conciliated = val; },
	old_operation_config: (column, val) => {
		try {
			state.old_operation[column] = val;
		} catch (error) {
			state.old_operation = {};
			state.old_operation[column] = val;
		}
	},

	config: (column, val) => {
		try {
			state.operation[column] = val;
		} catch (error) {
			state.operation = {};
			state.operation[column] = val;
		}
	},

	expense: (column, val) => {
		try {
			state.operation.expense[column] = val;
		} catch (error) {
			state.operation.expense = {};
			state.operation.expense[column] = val;
		}
	},

	default_expense: (column, val) => {
		try {
			state.operation.default_expenses[column] = val;
		} catch (error) {
			state.operation.default_expenses = {};
			state.operation.default_expenses[column] = val;
		}
	},

	calculated_expense: (column, val) => {
		try {
			state.operation.calculated_expenses[column] = val;
		} catch (error) {
			state.operation.calculated_expenses = {};
			state.operation.calculated_expenses[column] = val;
		}
	},
};

export const actions = {
	clean() {
		state.operation = {
			id: null,
			project: null,
			fund: {},
			cnab: {},
			type: {},
			company: {},
			file: {},
			default_expenses: {},
			registers: [],
			conciliated: [],
			observation: '',
			calculated_expenses: {},

			// expenses
			expenses: {
				serasa_consultation_value: 0,
				registry_send_value: 0,
				registry_receivable_movement_value: 0,
				other_charges: 0,
				net_received_value: 0,
				negotiation_retention_value: 0,
				repurchase_value: 0,
				serasa_consultation_quantity: 0,
				mora_cost: 0,
				repurchase_fine_value: 0,
				registry_send_movement_value: 0,
				registry_repurchase_fine_value: 0,
				down_movement_value: 0,
				prorrogation_movement_value: 0,
				bank_slip_registry_value: 0,
			},
		};

		state.old_operation = {};
	},
	async load() {
		const receiptsService = new ReceiptsService();

		const project_id = getters.project();

		const id = getters.config('id');

		if (!id || !project_id) return {};

		const response = await receiptsService.upload(project_id).show(id);

		mutations.operation(response.data);

		mutations.old_operation(response.data);

		return response.data;
	},

	async remove() {
		const receiptsService = new ReceiptsService();

		const operation = getters.operation();

		const project = getters.project();

		await receiptsService.upload(project).destroy(operation);

		mutations.operation({});
	},

	async save() {
		const receiptsService = new ReceiptsService();

		const project = getters.project();
		const operation = getters.operation();
		operation.origin = 'receipts';

		operation.project = project;
		let response = null;

		if (this.isANewOperation()) {
			response = await receiptsService.upload(project).store(operation);
		} else {
			response = await receiptsService.upload(project).update(operation);
		}

		return response;
	},

	isANewOperation() {
		const id = getters.config('id');

		const isNewOrNotExists = !id || id == 'new' || id == undefined;

		return isNewOrNotExists;
	},
};

/**
 * instalacao de metodos (plugins) adicionais
 */
actions.calculation_service = OperationCalculationService.install(state, getters, mutations);
actions.calculate = OperationCalculations.install(getters, mutations, actions);

export default state;
