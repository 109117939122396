<template>
	<div class="
		w-100
		bg-light-2
		rounded-md
		pa-1
		d-flex
		flex-column
	"
	>
		<!-- LISTA DE ITENS RELACIONADOS -->
		<v-list
			class="
				bg-light-2
			"
			v-if="list && list.length > 0"
		>
			<slot name="list">
				<related-list-item
					v-for="(item, index) in list"
					:key="item.id"
					:name="item.name"
					:no-divider="(list && list.length - 1) == index"
					@edit="onEdit"
					@remove="onRemove"
				>
					<template
						v-if="customActions"
						v-slot:actions
					>
						<slot name="item.actions" :item="item">
						</slot>
					</template>
				</related-list-item>
			</slot>
		</v-list>

		<!-- CASO NAO HAJA ITENS NA LISTA -->
		<span
			v-else
			class="
				text-center
				pa-4
				my-4
				text-subtitle-2
				font-weight-bold
				text--secondary
			"
		>
			{{ noDataMessage || 'Nenhum Dado adicionado, clique no botão abaixo para inserir' }}
		</span>

		<!-- BOTAO PARA ADICIONAR/SALVAR -->
		<v-divider></v-divider>

		<related-list-add-button
			v-if="!noAdd"
			@click="addDependOfClick"
			:clicked="clicked"
			:valid="(!error && valid) || isToAdd()"
		/>

		<v-form v-model="valid" ref="relatedForm">
			<v-slide-y-transition>
				<page-container no-column>
					<slot name="create" v-if="isToSave()">
					</slot>

					<v-col
						v-if="isToSave()"
						cols="12"
						class="mt-n12"
					>
						<span class="text-caption error--text">
							{{ error }}
						</span>
					</v-col>
				</page-container>
			</v-slide-y-transition>
		</v-form>
	</div>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import RelatedListAddButton from './RelatedListAddButton.vue';
import RelatedListItem from './RelatedListItem.vue';

export default {
	name: 'RelatedList',
	components: {
		RelatedListAddButton,
		RelatedListItem,
		PageContainer,
	},
	props: {
		noDataMessage: String,
		list: [Array, Object],
		noAdd: Boolean,
		customActions: Boolean,
		error: String,
	},
	data() {
		return {
			clicked: 'add',
			valid: false,
		};
	},
	methods: {
		/**
		 * ACTIONS
		 */
		onRemove(value) {
			this.$emit('remove', value);
		},
		onEdit(value) {
			this.$emit('edit', value);
		},
		addDependOfClick() {
			this.toggleSaveForm();

			this.$refs.relatedForm.validate();

			if (this.isToAddNewItemAndIsValid()) {
				this.$emit('add');
				return;
			}

			if (this.isToAddNewItemAndIsInvalid()) {
				this.toggleSaveForm();
			}
		},
		isToAddNewItemAndIsValid() {
			return (this.isToAdd() && this.valid);
		},
		isToAddNewItemAndIsInvalid() {
			return (!this.isToAdd() && !this.valid);
		},
		revalidateForm() {
			this.$refs.relatedForm.validate();
		},
		toggleSaveForm() {
			this.clicked = this.clicked == 'add' ? 'save' : 'add';
		},
		toggleSaveFormToEdit() {
			this.clicked = this.clicked == 'add' ? 'save' : 'save';
		},
		isToAdd() {
			return this.clicked == 'add';
		},
		isToSave() {
			return this.clicked == 'save';
		},
	},
};
</script>

<style>

</style>
