<template>
	<page-container>
		<breadcrumbs page-title="Projetos"/>

		<list-structure>
			<template v-slot:search>
				<v-text-field
					label="Pesquisar"
					v-model="searchTerm"
					prepend-icon="mdi-magnify"
					dense
				/>
			</template>

			<template v-slot:actions>
				<list-add-button @add="add"></list-add-button>
			</template>

			<template v-slot:table>
				<project-list-table
					:search="searchTerm"
					@edit="edit"
				/>
			</template>
		</list-structure>
	</page-container>
</template>

<script>
// layout components
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';
import breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';

// nested components
import ProjectListTable from './ProjectListTable.vue';

export default {
	name: 'ProjectList',
	components: {
		breadcrumbs,
		PageContainer,
		ListStructure,
		ListAddButton,
		ProjectListTable,
	},
	data() {
		return {
			searchTerm: '',
		};
	},
	methods: {
		add() {
			this.pushToProjectSaveRoute({ id: 'new' });
		},
		edit(id) {
			this.pushToProjectSaveRoute({ id });
		},
		pushToProjectSaveRoute(params) {
			this.$router.push({ name: 'ProjectSave', params });
		},
	},
};
</script>

<style>

</style>
