import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { pt },
		current: 'pt',
	},
	theme: {
		themes: {
			light: {
				primary: {
					darken1: '#42080D',
					base: '#6b0515',
					lighten1: '#822d3a',
					lighten2: '#98464C',
					lighten3: '#C47D85',
					lighten4: '#C5A2A4',
				},
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
			},
		},
	},
});
