<template>
	<page-container icon="mdi-home-outline">
		<breadcrumbs page-title="Lista de CNAB's"/>

		<list-structure :loading="isLoading">
				<template v-slot:actions>
					<list-add-button
						@add="save('new')"
					/>
				</template>

				<template v-slot:table>
					<cnab-table
						:items="items"
						:loading="isLoading"
						@save="save"
						@open="openDuplicateDialog"
					/>

					<cnab-duplicate-modal
						v-model="show"
						:cnab-id="selectedCnab"
						@duplicated="duplicated"
					/>
				</template>
		</list-structure>
	</page-container>
</template>

<script>
import breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';

import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';
import CnabService from '@/services/CnabService';
import CnabDuplicateModal from './CnabDuplicateModal.vue';
import CnabTable from './CnabTable.vue';

export default {
	name: 'CnabList',
	components: {
		CnabTable,
		PageContainer,
		ListStructure,
		CnabDuplicateModal,
		ListAddButton,
		breadcrumbs,
	},
	data() {
		return {
			show: false,
			selectedCnab: null,
			isLoading: false,
			tableLoading: false,
			items: [],
		};
	},
	mounted() {
		this.loadCnabs();
	},
	methods: {
		async loadCnabs() {
			this.isLoading = true;

			const cnabService = new CnabService();
			const response = await cnabService.index();

			this.items = response.data;

			this.isLoading = false;
		},
		duplicated() {
			this.items = [];

			this.loadCnabs();
		},
		openDuplicateDialog(cnabId) {
			this.selectedCnab = cnabId;
			this.show = true;
		},
		unpluckCnab(id) {
			const unplucked = this.items.filter((item) => item.id != id);

			this.items = unplucked;
		},
		save(id) {
			this.$router.push({ name: 'CnabRegister', params: { id } });
		},
	},
};
</script>

<style>

</style>
