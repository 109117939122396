<template>
	<page-container no-column>
		<v-col xl="3" lg="4" md="4" sm="6" xs="12">
			<v-text-field
				label="Descrição"
				v-model="fund.name"
				:rules="[ (v) => !!v || 'Descrição necessária']"
			/>
		</v-col>

		<v-col xl="3" lg="4" md="4" sm="6" xs="12">
			<v-select
				label="Tipo de fundo"
				v-model="fund.fund_type_id"
				:items="fundTypes"
				item-text="name"
				item-value="id"
			/>
		</v-col>

		<v-col cols="12" class="mb-n6 mt-n2">
			<separator label="Informações do Fundo Bancário"/>
		</v-col>

		<v-col xl="3" lg="4" md="4" sm="6" xs="12" class="d-flex flex-column">
			<v-text-field
				class="flex-item"
				label="Endereço"
				v-model="fund.web_address"
			/>

			<div class="flex-item text-end">
				<span
					v-if="fund.web_address"
					@click="goToWebAddress()"
					class="
						cursor-pointer
						text-caption
						primary--text
						pa-2
						ma-n2
						mt-n5
					"
				>
					Ir para o site
				</span>
			</div>
		</v-col>

		<v-col xl="9" lg="8" md="8" sm="6" xs="12" class="d-sm-flex d-xs-none">
		</v-col>

		<v-col xl="3" lg="4" md="4" sm="6" xs="12">
			<v-text-field
				label="Gerente"
				v-model="fund.manager_name"
			/>
		</v-col>

		<v-col xl="3" lg="4" md="4" sm="6" xs="12">
			<v-text-field
				label="Telefone do Gerente"
				v-model="fund.manager_phone"
				v-mask="$phoneMask"
			/>
		</v-col>

		<v-col lg="4" md="4" sm="6" class="d-xl-none d-sm-none d-md-flex">
		</v-col>

		<v-col xl="3" lg="4" md="4" sm="6" xs="12">
			<v-text-field
				label="Operador"
				v-model="fund.operator_name"
			/>
		</v-col>

		<v-col xl="3" lg="4" md="4" sm="6" xs="12">
			<v-text-field
				label="Telefone do Operador"
				v-model="fund.operator_phone"
				v-mask="$phoneMask"
			/>
		</v-col>

		<v-col cols="12" class="mb-n6 mt-n2">
			<separator label="Informações de Segurança"/>
		</v-col>

		<v-col xl="3" lg="4" md="4" sm="6" xs="12">
			<v-text-field
				label="Login"
				v-model="fund.login"
			/>
		</v-col>

		<v-col xl="3" lg="4" md="4" sm="6" xs="12">
			<v-text-field
				label="Senha"
				v-model="fund.password"
				:type="passwordIsVisible() ? 'text' : 'password'"
				:append-icon="passwordIsVisible() ? 'mdi-eye-off' : 'mdi-eye'"
				@click:append="togglePasswordVisibility"
			/>
		</v-col>
	</page-container>
</template>

<script>
import FundTypeService from '@/services/FundTypeService';

import Separator from '@/components/defaults/layout/Separator.vue';
import PageContainer from '../../../components/defaults/layout/PageContainer.vue';

export default {
	name: 'FundForm',
	components: {
		PageContainer,
		Separator,
	},
	props: {
		value: Object,
	},
	watch: {
		value() {
			if (this.fund == this.value) return;

			this.fund = this.value;
		},
		fund() {
			this.$emit('input', this.fund);
		},
	},
	data() {
		return {
			fundTypes: [],
			fund: this.value,
			passwordVisibility: 'hidden',
		};
	},
	created() {
		this.loadFundTypes();
	},
	methods: {
		/**
		 * HELPERS & UX
		 */
		goToWebAddress() {
			try {
				window.open(this.fund.web_address);
			} catch (error) {
				this.$http.defaultCatchError(error);
			}
		},

		togglePasswordVisibility() {
			this.passwordVisibility = this.passwordVisibility == 'visible' ? 'hidden' : 'visible';
		},

		passwordIsVisible() {
			return this.passwordVisibility == 'visible';
		},

		/**
		 * CRUD
		 */
		async loadFundTypes() {
			try {
				this.loadingData = true;

				const fundTypeService = new FundTypeService();

				const response = await fundTypeService.index();

				this.fundTypes = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
	},
};
</script>

<style>

</style>
