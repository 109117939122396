<template>
	<page-container no-column>
		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<image-dialog
				:aspect-ratio="1 / 1"
				md-w="200"
				md-h="200"
				sm-w="150"
				sm-h="150"
				v-model="user.image"
				rounded
			/>
		</v-col>

		<v-col xl="8" lg="8" md="6" sm="12" xs="12" class="d-md-flex d-none"></v-col>

		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<v-text-field
				label="Nome"
				v-model="user.name"
				placeholder="Digite um nome"
				:rules="[(v) => !!v || 'Nome de usuário não informado']"

			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="Email"
				v-model="user.email"
				placeholder="Digite um email válido"
				:rules="emailRules"
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="Telefone"
				v-model="user.phone"
				v-mask="$phoneMask"
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
      <date-picker
        label="Data de Nascimento"
        v-model="user.birth_date"
      />
    </v-col>
		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
      <date-picker
        label="Data de Contratação"
        v-model="user.hiring_date"
      />
    </v-col>
    <v-col
      xl="4"
      lg="4"
      md="6"
      sm="6"
      xs="12"
      v-if="permission.user_type.name === 'admin'"
    >
      <v-text-field
        label="Quantidade de dias de férias anuais"
        type="number"
        max="120"
        min="1"
        v-model="user.vacation_days"
      />
    </v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<user-type-select v-model="user.user_type" :rules="[ rules.required ]"/>
		</v-col>

		<v-col cols="12" v-if="!isANewUser()">
			<separator label="Alterar Senha: "/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12" v-if="!isANewUser() && permission.user_type.name !== 'admin'">
			<v-text-field
				label="Senha anterior"
				v-model="user.old_password"
				type="password"

			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12" v-if="!isANewUser()">
			<v-text-field
				label="Nova senha"
				v-model="user.new_password"
				type="password"
				:rules="passwordValidationRules"
				@input="revalidate"
				:disabled="permission.user_type.name !== 'admin' && !user.old_password"
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12" v-if="!isANewUser()">
			<v-text-field
				label="Confirmar senha"
				v-model="user.confirm_password"
				type="password"
				:rules="passwordValidationRules"
				@input="revalidate"
				:disabled="permission.user_type.name !== 'admin' && !user.old_password"
			/>
		</v-col>

		<v-col
			v-show="permission.user_type.name === 'admin'"
			cols="12"
		>
			<separator label="Conexões: "/>
		</v-col>

		<v-col
			xl="4" lg="4" md="6" sm="6" xs="12"
		>
			<v-checkbox
				class="mt-n6"
				v-model="user.connect_to_inntravel"
				input-value="true"
				label="Conectar ao Inntravel"
			/>
		</v-col>

		<v-col
			v-if="!isANewUser() && permission.user_type.name !== 'admin'"
			xl="4" lg="4" md="6" sm="6" xs="12"
			class="d-flex flex-column"
		>
			<span class="text-caption">
				Sua conexão falhou? tente pelo botão abaixo:
			</span>

			<v-btn
				depressed
				class="my-2"
				@click="connectToInntravel"
				:loading="loadingLink"
			>
				<v-icon class="mx-1"> mdi-link-variant </v-icon>
				Conectar com o inntravel
			</v-btn>

			<span class="text-caption error--text">
				{{ getErrorMessage() }}
			</span>
		</v-col>
	</page-container>
</template>

<script>
import UserService from '@/services/UserService';

import ImageDialog from '@/components/image_upload/ImageDialog.vue';
import Separator from '@/components/defaults/layout/Separator.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import DatePicker from '@/components/defaults/DatePicker.vue';
import UserTypeSelect from './UserTypeSelect.vue';

export default {
	name: 'UserSaveForm',
	components: {
		ImageDialog,
		UserTypeSelect,
		PageContainer,
		Separator,
		DatePicker,

	},
	props: {
		value: Object,
	},
	created() {
		this.validatePermission();
	},
	data() {
		return {
			permission: {},

			user: this.value,
			loadingLink: false,
			rules: {
				required: (v) => (v !== 'undefined' && v !== null) || 'Campo Necessário',
			},
			emailRules: [
				(v) => !!v || 'Email não informado',
				(v) => /.+@.+\..+/.test(v) || 'Email deve ser válido',
			],
		};
	},
	watch: {
		value() {
			if (this.user == this.value) return;

			this.user = this.value;

			this.loadImage();

			this.setConnectToInntravelToTrueAsDefault();
		},
		user() {
			this.$emit('input', this.user);
		},
	},
	computed: {
		passwordValidationRules() {
			let rules = [];

			if (this.user.old_password) {
				const newPasswordHasToBeTheSame = (v) => (v == this.user.new_password
					&& v == this.user.confirm_password)
					|| ' As novas senhas devem ser iguais';

				rules = [...rules, ...[newPasswordHasToBeTheSame]];
			}

			return rules;
		},
	},
	methods: {
		/**
		 * Helper functions
		 */
		setConnectToInntravelToTrueAsDefault() {
			if (this.user.connect_to_inntravel != 'undefined') return;

			this.user.connect_to_inntravel = true;
		},
		userIdIsNewOrNotExists(userId) {
			return (!userId || userId == 'new');
		},
		getUserIdOfRoute() {
			const userId = this.$route.params.id;
			return userId;
		},
		isANewUser() {
			if (this.user.id) return false;

			const userId = this.getUserIdOfRoute();

			return (this.userIdIsNewOrNotExists(userId));
		},
		revalidate() {
			this.$emit('revalidate');
		},
		getErrorMessage() {
			if (!this.user.inntravel_token && !this.user.not_connect_to_inntravel) {
				return 'Usuário não conectado';
			}

			return '';
		},
		/**
		 * @require {UserType} this.user.userType
		 * @return {Boolean}
		 */
		userCanAccessInntravel() {
			if (!this.user.user_type || !this.user.user_type.menus) return false;

			if (!this.user.user_type.menus.length) return false;

			const canAccessInntravel = this.user.user_type.menus.find((access) => access.name == 'inntravel');

			if (!canAccessInntravel || !canAccessInntravel.id) return false;

			return true;
		},

		async connectToInntravel() {
			const token = await this.getToken();

			const base_url = `${process.env.VUE_APP_INNTRAVEL_URL}`;

			const inntravelUrl = `${base_url}/login?token=${token}`;

			this.user.inntravel_token = token;

			window.open(inntravelUrl);
		},

		/**
		 * send a request to backend to get a token for this user
		 * @return {String} token
		 */
		async getToken() {
			try {
				this.loadingLink = true;

				const userService = new UserService();

				const response = await userService.token(this.user.id).index();

				return response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
				return '';
			} finally {
				this.loadingLink = false;
			}
		},

		validatePermission() {
			const loggedUser = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(loggedUser.access_token);

			this.permission = tokenData;
		},

		loadImage() {
			if (!this.user && !this.user.image_url) return;

			this.user.image = {
				preview: this.user.image_url,
			};
		},
	},
};
</script>

<style>

</style>
