<template>
	<page-container>
		<breadcrumbs :page-title="getRouteTitle()"/>

		<v-form v-model="valid" ref="form" @submit.prevent="() => {}">
			<form-structure :loading="loadingData">
				<template v-slot:content>
					<fund-tabs
						v-model="fund"
					/>
				</template>

				<template v-slot:actions>
					<form-cancel-button/>

					<form-save-button
						:valid="valid"
						:loading="loadingSubmit"
						:disabled="loadingData"
						@click="save"
					/>

					<form-destroy-button
						v-if="!isANewFund()"
						:loading="loadingRemove"
						:disabled="loadingData"
						@remove="remove"
					/>
				</template>
			</form-structure>
		</v-form>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import FormDestroyButton from '@/components/defaults/buttons/FormDestroyButton.vue';
import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';

import FundService from '@/services/FundService';
import FundTabs from './FundTabs.vue';

export default {
	name: 'FundSave',
	components: {
		PageContainer,
		Breadcrumbs,
		FormStructure,
		FormSaveButton,
		FormDestroyButton,
		FormCancelButton,
		FundTabs,
	},
	data() {
		return {
			fund: {
				default_expenses: {},
			},
			valid: false,
			loadingData: false,
			loadingSubmit: false,
			loadingRemove: false,
		};
	},
	created() {
		this.loadFund();
	},
	methods: {
		/**
		 * METHODS
		 */
		async loadFund() {
			try {
				const id = this.getIdOfRoute();

				if (this.isANewFund()) return;

				this.loadingData = true;

				const fundService = new FundService();

				const response = await fundService.show(id);

				this.fund = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},

		async save() {
			try {
				const canSave = this.$refs.form.validate();

				if (!canSave) return;

				this.loadingSubmit = true;

				const fundService = new FundService();

				if (this.isANewFund()) {
					await fundService.store(this.fund);
				} else {
					await fundService.update(this.fund);
				}

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingSubmit = false;
			}
		},

		async remove() {
			try {
				if (this.isANewFund()) return;

				this.loadingRemove = true;

				const fundService = new FundService();

				await fundService.destroy(this.fund);

				this.fund = {};
				this.returnToPreviousRoute();
			}	catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingRemove = false;
			}
		},

		/**
		 * Helper functions
		 */
		getRouteTitle() {
			if (this.idIsNewOrNotExists()) return 'Criação de Fundos';

			return 'Edição de Fundos';
		},

		idIsNewOrNotExists(id) {
			return (!id || id == 'new');
		},

		getIdOfRoute() {
			const { id } = this.$route.params;
			return id;
		},

		isANewFund() {
			if (this.fund.id) return false;

			const id = this.getIdOfRoute();

			return (this.idIsNewOrNotExists(id));
		},

		returnToPreviousRoute() {
			this.$router.push({ name: 'FundList' });
		},
	},
};
</script>

<style>

</style>
