<template>
  <login-layout v-model="valid" ref="form">
    <template v-slot:form>

      <v-col
        cols="12"
        class="text-center my-auto py-12"
        v-if="!oldDataVacation.status"
      >
        <span class="text-subtitle-1 font-weight-bold">
          O pedido de férias vai ser aprovado! Caso tenha alguma observação, coloque
          observação abaixo e clique no botão salvar.
        </span>
        <v-textarea
          style="margin-top: 70px !important"
          label="Digite uma observação"
          v-model="admin_observation"
          outlined
        ></v-textarea>

        <form-save-button @click="approve" :valid="true" :loading="isSaving" />
      </v-col>
      <v-col cols="12" class="text-center my-auto py-12" v-else>
        <span class="text-subtitle-1 font-weight-bold">
          O pedido de férias já foi analisado! Você não pode mais fazer nada
          sobre esse pedido.
        </span>
      </v-col>
    </template>
  </login-layout>
</template>

<script>
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import VacationsService from '@/services/VacationsService';

import LoginLayout from '../../components/auth/LoginLayout.vue';

export default {
	name: 'ApproveVacationRequest',
	components: {
		LoginLayout,
		FormSaveButton,
	},
	data() {
		return {
			validToken: false,
			valid: false,
			isSaving: false,
			loading: false,

			admin_observation: '',
			id: null,

			oldDataVacation: {},
		};
	},
	computed: {
		rules() {
			let rules = [];

			const needToBeSet = (v) => !!v || 'Senha precisa ser definida';

			rules = [...rules, ...[needToBeSet]];

			return rules;
		},
	},
	created() {
		this.validateJWTToken();
	},
	methods: {
		/**
     * JWT
     *
     * validateJWTToken
     * verifica se o token passado pode ser utilizado para o envio do email
     *
     * @requires String verify_token - retornado pela rota como paramêtro
     */
		validateJWTToken() {
			const { token, id } = this.$route.params;

			const decodedToken = this.$helpers.jwt.decode(token);
			const hasExpired = this.$helpers.jwt.hasExpired(decodedToken);
			this.validToken = !hasExpired && id;
			this.id = id;
		},

		/**
     * CRUD
     */

		async approve() {
			try {
				this.isSaving = true;
				const params = {
					id: this.id,
					admin_observation: this.admin_observation,
				};

				const vacationService = new VacationsService();
				await vacationService.approve().update(params);
				this.$snackbar.success({ message: 'Pedido de férias aprovado' });
				this.$router.push({ name: 'Login' });
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isSaving = false;
			}
		},

	},
};
</script>
