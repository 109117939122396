import Rest from './base/Rest';

export default class AuthService extends Rest {
	static resource = 'auth';

	verify() {
		this.relationship = 'verify';

		return this;
	}

	lostPassword() {
		this.relationship = 'forget';

		return this;
	}
}
