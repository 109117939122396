<template>
	<v-hover v-slot="{ hover }">
		<v-checkbox
			v-if="!bindModel"
			@change="onChange"
			@click="onClick"
			:value="value"
			:label="label"
			color="success"
			class="py-3 px-3 my-n2"
			:class="hover ? 'bg-light-3' : ''"
			hide-details
		/>

		<v-checkbox
			v-else
			@change="onChange"
			@click="onClick"
			v-model="model"
			:label="label"
			color="success"
			class="py-3 px-3 my-n2"
			:class="hover ? 'bg-light-3' : ''"
			hide-details
		/>
	</v-hover>
</template>

<script>
export default {
	name: 'Checkbox',
	props: {
		bindModel: Boolean,
		value: [Boolean, Object, String, Number],
		checkboxValue: [Boolean, Object, String, Number],
		label: String,
	},
	data() {
		return {
			model: {},
		};
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				if (this.value == undefined) {
					this.model = false;
					return;
				}

				if (!this.bindModel || this.model == this.value) return;

				this.model = this.value;
			},
		},
		model() {
			if (!this.bindModel) return;

			this.$emit('input', this.model);
		},
	},
	methods: {
		onChange() {
			this.$emit('input', this.value);
			this.$emit('change', this.value);
		},
		onClick() {
			this.$emit('click', this.value);
		},
	},
};
</script>

<style>

</style>
