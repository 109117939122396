<template>
	<page-container>
		<breadcrumbs page-title="Tipos de usuários" />

		<list-structure>
			<template v-slot:search>
				<v-text-field
					v-model="searchTerm"
					prepend-icon="mdi-magnify"
					label="Pesquisar"
					dense
				/>
			</template>

			<template v-slot:actions>
				<list-add-button @add="add"/>
			</template>

			<template v-slot:table>
				<user-type-table
					@edit="edit"
					:search="searchTerm"
					:items="items"
					:loading="loadingData"
					ref="userTypeList"
				/>
			</template>
		</list-structure>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';

// logic components
import UserTypeService from '@/services/UserTypeService';
import UserTypeTable from './UserTypeTable.vue';

export default {
	name: 'UserTypeList',
	components: {
		PageContainer,
		Breadcrumbs,
		ListStructure,
		ListAddButton,
		UserTypeTable,
	},
	data() {
		return {
			items: [],
			loadingData: false,
			searchTerm: '',
		};
	},
	created() {
		this.loadUserTypes();
	},
	computed: {
		filteredItemsBySearch() {
			return this.items;
		},
	},
	methods: {
		/**
		 * CRUD
		 */
		async loadUserTypes() {
			try {
				this.loadingData = true;

				const userTypeService = new UserTypeService();

				const response = await userTypeService.index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		/**
		 * ACTIONS
		 */
		add() {
			this.pushToUserTypeSaveRoute({ id: 'new' });
		},
		edit(item) {
			this.pushToUserTypeSaveRoute({ id: item.id });
		},
		pushToUserTypeSaveRoute(params) {
			this.$router.push({ name: 'UserTypeSave', params });
		},
	},
};
</script>

<style>

</style>
