<template>
	<v-btn
		icon
		@click="clickEvent"
		@click.stop="clickToStopEvent"
		color="grey"
	>
		<v-icon>mdi-pencil</v-icon>
	</v-btn>
</template>

<script>
export default {
	name: 'ListEditButton',
	props: {
		stopPropagation: Boolean,
	},
	data() {
		return {};
	},
	methods: {
		edit() {
			this.$emit('edit');
			this.$emit('click');
		},
		clickToStopEvent() {
			if (this.stopPropagation) {
				this.edit();
			}
		},
		clickEvent() {
			if (!this.stopPropagation) {
				this.edit();
			}
		},
	},
};
</script>

<style>

</style>
