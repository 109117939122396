<template>
	<div class="w-100 text-center">
		<div class="image-preview elevation-0">
			<v-img
				class="image-preview"
				:max-width="maxWidth"
				:max-height="maxHeight"
				:src="image.preview | getWithToken"
				:class="noCenter ? '' : 'mx-auto'"
			/>
		</div>
		<div
			class="d-flex"
			:class="noCenter ? '' : 'justify-center'"
		>
			<v-btn
				class="flex-item w-100 my-1"
				:style="{ 'max-width': `${maxWidth}px`}"
				depressed
				text
				@click="changeImage"
			>
				<v-icon class="mx-1"> mdi-pen </v-icon>

				Editar
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImagePreview',
	props: {
		image: [File, Object, String],
		maxHeight: String,
		maxWidth: String,
		noCenter: Boolean,
	},
	methods: {
		changeImage() {
			this.$emit('changeImage');
		},
	},
};
</script>

<style>

</style>
