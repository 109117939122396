<template>
	<v-hover v-slot="{ hover }">
		<div
			:class="hover ? 'bg-light-2' : 'bg-light-1'"
			class="w-100 px-6 py-12 cursor-pointer rounded"
			@click="changeFile"
		>
			<div
				class="
					file-preview
					d-flex
					flex-column
					text-center
					elevation-0
					my-4
				"
			>
				<v-icon x-large> mdi-paperclip </v-icon>
				<span
					class="
						text--secondary
						text-subtitle
						font-weight-bold
					"
				>
					{{ file ?  file.name : '' }}
				</span>
			</div>

			<div class="remove-file">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							v-bind="attrs"
							icon
							@click="changeFile"
						>
							<v-icon large> mdi-close </v-icon>
						</v-btn>
					</template>

					<span>Trocar Arquivo</span>
				</v-tooltip>
			</div>
		</div>
	</v-hover>
</template>

<script>
export default {
	name: 'FilePreview',
	props: {
		file: [File, Object, String],
	},
	methods: {
		changeFile() {
			this.$emit('changeFile');
		},
	},
	watch: {
		file: {
			immediate: true,
			handler() {
				this.$forceUpdate();
			},
		},
	},
};
</script>

<style>
.remove-file {
	position: relative;
    float: right;
    margin-top: -7%;
}

</style>
