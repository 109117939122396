import moment from 'moment';
import HelperMethods from '@/plugins/helpers/helper_methods';

const operationTaxes = [
	'goodwill', 'ad_valorem', 'iof', 'ted_cost',
	'tac', 'custody_fee', 'non_especified_goodwill',
	'serasa_consultation_value', 'bank_slip_registry_value',
	'other_charges', 'mora_cost', 'repurchase_value', 'repurchase_fine_value',
	'registry_send_movement_value', 'registry_repurchase_fine_value',
	'down_movement_value', 'prorrogation_movement_value', 'slaughter_cost',
	'negotiation_retention_value', 'repurchase_fine_rate',
];

export default class OperationCalculationService {
	/**
	 *
	 * Calculate Methods
	 *
	 */
	static install(state, getters, mutations) {
		return {
			operationFieldIsNotDefined(field) {
				return !state.operation[field];
			},

			registersIsNotDefined() {
				return !state.operation
					|| !state.operation.registers
					|| state.operation.registers.length == 'undefined';
			},

			paramsIsNotDefined() {
				return this.defaultExpensesValueIsNotDefined()
				|| this.registersIsNotDefined();
			},

			defaultExpensesValueIsNotDefined() {
				return !state.operation.default_expenses || !state.operation.default_expenses.id;
			},

			defaultExpenseIsNotDefined(comparisonField) {
				return !state.operation.default_expenses[comparisonField]
					&& state.operation.default_expenses[comparisonField] !== 0;
			},

			defineComparisonField(operationField, defaultField) {
				return defaultField || operationField;
			},

			calculateGoodwill() {
				if (this.paramsIsNotDefined()) {
					mutations.calculated_expense('goodwill', 0);
					return;
				}

				const rate = this.getRate('goodwill');

				const averageOperationTime = getters.calculated_expense('average_operation_time');
				const month = 30;

				const goodwillRate = (Number(rate) * Number(averageOperationTime)) / month;

				const total = this.getTotalOfRegistersValues();

				const result = Number(goodwillRate * total).toFixed(2);

				mutations.calculated_expense('goodwill', Number(result));
			},

			calculateSpecifiedGoodwill() {
				if (this.paramsIsNotDefined()) {
					mutations.calculated_expense('goodwill', 0);
					return;
				}

				let goodwill = 0;

				const month = 30;
				const { noFormattedResult: mediumTerm } = this.average_operation_time_calculate();

				const rate = this.getRate('goodwill');

				state.operation.registers.forEach((register) => {
					const { net_value } = register;

					// const averageOperationTime = this.getDateBetween(due_date, issue_date);
					const specifiedRate = (Number(rate) * Number(mediumTerm)) / month;

					const result = Number(specifiedRate * net_value);

					goodwill += Number(result);
				});
				const serializedGoodwill = Number(goodwill).toFixed(2);

				mutations.calculated_expense('goodwill', Number(serializedGoodwill));
			},

			/**
			 *
			 * DATES SERVICES
			 *
			 */
			getDatesBetween() {
				const result = state.operation.registers.map((register) => {
					const issue_date = state.operation.hiring_date;
					const { due_date } = register;

					return this.getDateBetween(due_date, issue_date);
				});

				return result;
			},

			getDateBetween(due_date, issue_date) {
				const dateBetween = this.getDateDifference(due_date, issue_date);

				const utilDays = this.changeDateByUtilDays(dateBetween, due_date);

				return utilDays;
			},

			changeDateByUtilDays(utilDays, dueDate) {
				const handleDate = moment(dueDate);

				const isSunday = handleDate.isoWeekday() === 7;
				const isSaturday = handleDate.isoWeekday() == 6;

				if (isSunday) {
					utilDays += 1;
					handleDate.add(1, 'day');
				}

				if (isSaturday) {
					utilDays += 2;
					handleDate.add(2, 'days');
				}

				while (this.isAHoliday(handleDate)) {
					utilDays += 1;
					handleDate.add(1, 'day');
				}

				return utilDays;
			},

			isAHoliday(date) {
				const holidays = getters.holidays();

				const isAHoliday = holidays.some((holiday) => {
					const momentHoliday = moment(holiday, 'D/M/yyyy');

					return momentHoliday.isSame(date);
				});

				return isAHoliday;
			},

			getDateDifference(dateOne, dateTwo) {
				const momentDateOne = moment(dateOne);
				const momentDateTwo = moment(dateTwo);

				const dateDiff = momentDateOne.diff(momentDateTwo, 'days');

				return dateDiff;
			},

			getWeekendDaysAndHolidaysBetween(toHandleDate, dates_to) {
				const handleDate = moment(toHandleDate);
				let daysBetween = 0;

				// eslint-disable-next-line
				for (let index = 0; index < dates_to; index++) {
					if (!this.isAWeekend(handleDate)) {
						daysBetween += 1;
					}
				}

				return daysBetween;
			},

			isAWeekend(date) {
				return date.isoWeekday() !== 6 && date.isoWeekday() !== 7;
			},

			sumArrayIndex(array) {
				let sum = 0;

				array.forEach((toSum) => {
					sum += toSum;
				});

				return sum;
			},

			getTotalOfRegistersValues() {
				if (this.registersIsNotDefined()) {
					return 0;
				}

				// Comentando para fazer o recalculo toda vez que mudar algo opção

				// if (state.operation.gross_value
				// 	&& Number(state.operation.gross_value) != 'NaN') {
				// 	return Number(state.operation.gross_value);
				// }

				let total = 0;

				state.operation.registers.forEach((register) => {
					const netValue = Number(register.net_value).toFixed(2);
					total += Number(netValue);
				});

				const decimalTotal = Number(total).toFixed(2);

				state.operation.gross_value = Number(decimalTotal);

				return Number(decimalTotal);
			},

			getTotalOfRegisterTaxes() {
				let taxes = 0;
				HelperMethods.mapByKey(operationTaxes, state.operation, (tax) => {
					const decimalTax = Number(tax).toFixed(2);

					taxes += Number(decimalTax) || 0;
				});

				return taxes;
			},

			calculateRate(operationField, defaultField = null) {
				if (this.paramsIsNotDefined()) {
					mutations.calculated_expense(operationField, 0);
					return;
				}

				const comparisonField = this.defineComparisonField(operationField, defaultField);

				const rate = this.getRate(comparisonField);

				const total = this.getTotalOfRegistersValues();

				const result = HelperMethods.accurateToFixed((rate * total), 2);

				mutations.calculated_expense(operationField, result);
			},

			getRate(comparisonField) {
				const decimalBase = Number(getters.default_expense(comparisonField) || 0);
				const base = Number(decimalBase);

				const rate = base / 100;

				const decimalRate = Number(rate);

				return Number(decimalRate);
			},

			calculateDefault(operationField, defaultField = null) {
				const comparisonField = this.defineComparisonField(operationField, defaultField);

				if (this.paramsIsNotDefined()
					|| this.defaultExpenseIsNotDefined(comparisonField)) {
					mutations.calculated_expense(operationField, 0);
					return;
				}

				const defaultValue = getters.default_expense(comparisonField);

				const result = HelperMethods.accurateToFixed(Number(defaultValue), 2);

				mutations.calculated_expense(operationField, result);
			},

			calculateByRegisterLength(operationField, defaultField = null) {
				if (this.paramsIsNotDefined()) {
					mutations.calculated_expense(operationField, 0);
					return;
				}

				const comparisonField = this.defineComparisonField(operationField, defaultField);

				const multiplied = this.multiplyByRegisterLength(comparisonField);

				const result = HelperMethods.accurateToFixed(Number(multiplied), 2);

				mutations.calculated_expense(operationField, result);
			},

			calculateByFieldQuantity(operationField, quantityField, defaultField = null) {
				if (this.paramsIsNotDefined()) {
					mutations.calculated_expense(operationField, 0);
					return;
				}

				const comparisonField = this.defineComparisonField(operationField, defaultField);

				const quantity = Number(state.operation[quantityField]);
				const serializedQuantity = Number.isNaN(quantity) ? 0 : quantity;

				const compare = Number(getters.default_expense(comparisonField));
				const value = HelperMethods.accurateToFixed(compare, 2);

				const multiplied = Number(value) * Number(serializedQuantity);

				const result = HelperMethods.accurateToFixed(Number(multiplied), 2);

				mutations.calculated_expense(operationField, result);
			},

			multiplyByRegisterLength(comparisonField) {
				const compare = Number(getters.default_expense(comparisonField));
				const value = HelperMethods.accurateToFixed(compare, 2);

				const quantity = Number(state.operation.registers.length).toFixed(0);

				const result = Number(value) * Number(quantity);

				return result;
			},

			calculateRateByValueField(operationField, valueField, defaultField = null) {
				if (this.paramsIsNotDefined()
					|| this.operationFieldIsNotDefined(valueField)) {
					mutations.calculated_expense(operationField, 0);
					return;
				}

				const comparisonField = this.defineComparisonField(operationField, defaultField);

				const rate = this.getRate(comparisonField);

				const toNumberValue = Number(state.operation[valueField]);
				const value = HelperMethods.accurateToFixed(toNumberValue, 2);

				const calculatedResult = Number(value) * Number(rate);

				const result = HelperMethods.accurateToFixed(Number(calculatedResult), 2);

				mutations.calculated_expense(operationField, result);
			},

			calculateByQuantityField(operationField, quantityField, defaultField = null) {
				if (this.paramsIsNotDefined()
					|| this.operationFieldIsNotDefined(quantityField)) {
					mutations.calculated_expense(operationField, 0);
					return;
				}

				const comparisonField = this.defineComparisonField(operationField, defaultField);

				const multiplied = this.multiplyByFieldQuantity(comparisonField, quantityField);

				const result = HelperMethods.accurateToFixed(Number(multiplied), 2);

				mutations.calculated_expense(operationField, result);
			},

			calculateByConciliationStatus(operationField, status, defaultField = null) {
				if (this.paramsIsNotDefined() || (!status || !status.length)) {
					mutations.calculated_expense(operationField, 0);
					return;
				}

				const comparisonField = this.defineComparisonField(operationField, defaultField);

				const multiplied = this.multiplyByConciliationStatus(comparisonField, status);
				const result = Number(multiplied).toFixed(2);

				mutations.calculated_expense(operationField, Number(result));
			},

			multiplyByConciliationStatus(comparisonField, status) {
				const comparisonValue = Number(getters.default_expense(comparisonField));
				const value = HelperMethods.accurateToFixed(comparisonValue, 2);

				const quantity = this.findConciliatedStatusQuantity(status);

				const result = Number(value) * Number(quantity);

				return result;
			},

			findConciliatedStatusQuantity(status) {
				const { id } = getters.operation();

				let quantity = 0;

				if (!state.operation.conciliated) {
					return 0;
				}

				state.operation.conciliated.forEach((register) => {
					const hasThisStatus = register.status
						.some((operation_status) => {
							const statusMatch = status.some((wantedStatus) => operation_status.status == wantedStatus);
							const operationHasReturnForThisStatus = operation_status.fund_return_id == id;

							return statusMatch && operationHasReturnForThisStatus;
						});

					if (hasThisStatus) {
						quantity += 1;
					}
				});

				return quantity;
			},
			average_operation_time_calculate() {
				const issue_date = state.operation.hiring_date;
				let specifiedRate = 0;
				let result = 0;
				let sumNetValue = 0;

				state.operation.registers.forEach((register) => {
					const { due_date, net_value } = register;

					const averageOperationTime = this.getDateBetween(due_date, issue_date);

					sumNetValue += Number(net_value);

					specifiedRate += (Number(net_value) * Number(averageOperationTime));
				});

				result += specifiedRate / sumNetValue;
				const float = !state.operation.default_expenses.float || Number(state.operation.default_expenses.float) == 'NaN'
					? 0
					: Number(state.operation.default_expenses.float);

				result += float;

				return {
					noFormattedResult: result,
					result: HelperMethods.accurateToFixed(Number(result), 2),
					formattedResult: `${result} Dias`,
				};
			},
			calculateAll() {
				const operation = getters.operation();

				let value = 0;

				operation.conciliated.forEach((element) => {
					if (element.status.length <= 0) return;

					if (!this.statusMatch(element.status)) return;

					if (element.repurchased_value) {
						value += Number(element.repurchased_value) + (element.interest ? element.interest : 0);
					} else {
						value += Number(element.gross_value) + (element.interest ? element.interest : 0);
					}
				});

				mutations.calculated_expense('repurchase_value', Number(value));
			},

			statusMatch(status) {
				return status.some((row) => ['partial_repurchased_amount', 'repurchase_value'].includes(row.status));
			},
		};
	}
}
