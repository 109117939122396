<template>
	<page-container>
		<breadcrumbs page-title="Perfil"/>

		<v-form class="w-100" v-model="valid" ref="form">
			<form-structure :loading="loadingData">
				<template v-slot:content>
					<div class="mt-n7 d-flex flex-column w-100">
						<v-tabs v-model="activeTab">
							<v-tab href="#user">
								Informações Principais
							</v-tab>
							<v-tab href="#content">
								Adicionais
							</v-tab>
						</v-tabs>

						<v-tabs-items v-model="activeTab">
							<v-tab-item value="user">
								<user-profile-form v-model="user"/>
							</v-tab-item>

							<v-tab-item value="content">
								<user-profile-content-form
									:user="user"
									v-model="user.profile"
									ref="profile_content_form"
								/>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</template>

				<template v-slot:actions>
					<form-save-button
						:valid="true"
						@click="save"
						:loading="loadingSubmit"
						:disabled="loadingData"
					/>
				</template>
			</form-structure>
		</v-form>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';

import UserService from '@/services/UserService';
import UserProfileForm from './UserProfileForm.vue';
import UserProfileContentForm from './UserProfileContentForm.vue';

export default {
	name: 'UserProfile',
	components: {
		PageContainer,
		Breadcrumbs,
		FormStructure,
		FormSaveButton,
		UserProfileForm,
		UserProfileContentForm,
	},

	data() {
		return {
			valid: false,
			loadingSubmit: false,
			loadingData: false,
			activeTab: null,
			user: {
				profile: null,
			},
		};
	},
	created() {
		this.loadingUserByRoute();
	},
	methods: {
		async save() {
			try {
				if (!this.$refs.form.validate()) return;

				// if (!canSave) return;

				this.loadingSubmit = true;

				const userService = new UserService();

				await userService.update(this.user);

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingSubmit = false;
			}
		},
		async loadingUserByRoute() {
			try {
				const userId = this.getUserIdOfRoute();

				this.loadingData = true;

				const userService = new UserService();

				const response = await userService.show(userId);

				this.user = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
				this.user = {};
			} finally {
				this.loadProfile();
				this.loadingData = false;
			}
		},
		loadProfile() {
			if (!this.user.profile) {
				this.user.profile = {};
			}
			this.user.profile.user_id = this.user.id;
			this.user.profile.business_email = this.user.email;
			this.user.profile.business_phone = this.user.phone;
			this.user.profile.vacation_days = this.user.vacation_days;

			this.loadImages();

			if (!this.$refs || !this.$refs.profile_content_form) return;

			this.$refs.profile_content_form.loadFile();
		},
		loadImages() {
			if (!this.user.profile) return;

			this.loadImage('proof_of_address');

			this.loadImage('proof_of_identity_back');

			this.loadImage('proof_of_identity_front');

			this.loadImage('clt_profile_front');

			this.loadImage('clt_profile_back');

			this.loadImage('clt_contract');

			this.loadImage('clt_next_empty_page');
		},
		loadImage(column) {
			const field = `${column}_image`;

			if (!this.user.profile[column]) return;

			this.user.profile[field] = {
				preview: this.user.profile[column],
			};
		},
		/**
		 * Helper functions
		 */
		userIdIsNewOrNotExists(userId) {
			return (!userId || userId == 'new');
		},
		getUserIdOfRoute() {
			const userId = this.$route.params.id;
			return userId;
		},
		returnToPreviousRoute() {
			this.$router.go(-1);
		},
		revalidate() {
			this.$refs.form.validate();
		},
	},
};
</script>

<style>

</style>
