import { render, staticRenderFns } from "./ModalLayout.vue?vue&type=template&id=71817a6e&"
import script from "./ModalLayout.vue?vue&type=script&lang=js&"
export * from "./ModalLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports