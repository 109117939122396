<template>
	<v-container>
		<v-row>
			<v-col cols="12" class="w-100">
				<div class="d-flex flex-column flex-item my-6 mx-auto text-center">
					<v-progress-circular class="mx-auto mb-3" :color="color || 'grey'" indeterminate/>
					<span class="flex-item text-subtitle-2 text--secondary"> Carregando Dados...</span>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'LoadingDataCircular',
	props: {
		color: String,
	},
};
</script>

<style>

</style>
