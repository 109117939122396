var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-col w-100"},[_c('v-data-table',{staticClass:"w-100",attrs:{"headers":_vm.filteredHeaders,"options":_vm.options,"items":_vm.items,"loading":_vm.loading,"show-select":"","color":"primary","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"item-selected":_vm.toggleConciliation},scopedSlots:_vm._u([{key:"item.net_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.net_value))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDateToBr")(item.due_date))+" ")]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDateToBr")(item.issue_date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(_vm.validateStatus(item))+" ")])]}},{key:"item.repurchased_value_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.sumAllRepurchasedValues(item)))+" ")]}},{key:"item.repurchased_value",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.repurchased_value},on:{"save":function($event){return _vm.checkField(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-currency-field',{attrs:{"prefix":"R$","label":"Valor","single-line":"","counter":""},model:{value:(item.repurchased_value),callback:function ($$v) {_vm.$set(item, "repurchased_value", $$v)},expression:"item.repurchased_value"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getRepurchasedValue(item)))+" ")])]}},{key:"item.interest",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.interest},on:{"save":function($event){return _vm.checkField(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-currency-field',{attrs:{"prefix":"R$","label":"Juros","single-line":"","counter":""},model:{value:(item.interest),callback:function ($$v) {_vm.$set(item, "interest", $$v)},expression:"item.interest"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getInterest(item)))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }