<template>
	<page-container>
		<breadcrumbs page-title="Taxas Padrões de Fundos"/>

		<list-structure>
			<template v-slot:search>
				<v-text-field
					label="Pesquisar"
					v-model="searchTerm"
					prepend-icon="mdi-magnify"
					dense
				/>
			</template>

			<template v-slot:actions>
				<list-add-button @add="add"/>
			</template>

			<template v-slot:table>
				<default-expenses-list-table
					:search="searchTerm"
					@edit="edit"
				/>
			</template>
		</list-structure>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import DefaultExpensesListTable from './DefaultExpensesListTable.vue';

export default {
	name: 'DefaultExpensesList',
	components: {
		Breadcrumbs,
		PageContainer,
		ListStructure,
		ListAddButton,
		DefaultExpensesListTable,
	},
	data() {
		return {
			searchTerm: '',
			project: this.$route.params.project,
		};
	},
	methods: {
		add() {
			this.pushToDefaultExpenseSaveRoute({ project: this.project, expense: 'new' });
		},
		edit(item) {
			const { id } = item;

			this.pushToDefaultExpenseSaveRoute({ project: this.project, expense: id });
		},
		pushToDefaultExpenseSaveRoute(params) {
			this.$router.push({ name: 'DefaultExpensesSave', params });
		},
	},
};
</script>

<style>

</style>
