<template>
	<v-app>
		<Drawer/>

		<Header/>

		<v-main class="bg-light-3">
			<router-view/>
		</v-main>

		<snackbar/>
	</v-app>
</template>

<script>
import Drawer from '@/components/drawer/Drawer.vue';
import Header from '@/components/header/Header.vue';
import Snackbar from '@/components/snackbar/Snackbar.vue';

export default {
	name: 'SystemView',
	components: {
		Snackbar,
		Drawer,
		Header,
	},
};
</script>

<style>

</style>
