<template>
  <v-select
    label="Período"
    v-model="model"
    :items="items"
    item-text="name"
		:disabled="fixedPeriod"
    item-value="id"
		:rules="rules"
		return-object
  >
  </v-select>
</template>

<script>
export default {
	name: 'PeriodSelect',
	props: {
		rules: Array,
		fixedPeriod: Boolean,
		period: [String, Object],
	},
	data: () => ({
		items: [
			{ name: 'Manhã', id: 'morning' },
			{ name: 'Tarde', id: 'evening' },
			{ name: 'Integral', id: 'integral' },
		],

		model: {},
	}),

	watch: {
		fixedPeriod: {
			deep: true,
			handler() {
				this.validate();
			},
		},
		model() {
			this.$emit('input', this.model);
		},
		period: {
			deep: true,
			handler(value) {
				this.$nextTick(() => {
					if (typeof value === 'object') {
						this.model = value;
					}
				});
			},
		},
	},

	methods: {
		validate() {
			if (this.fixedPeriod) {
				this.model = { name: 'Integral', id: 'integral' };
			} else {
				this.model = {};
			}
		},

	},
};
</script>
