<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				icon
				v-on="on"
				v-bind="attrs"
				@click="openDuplicateDialog"
			>
				<v-icon> mdi-content-duplicate</v-icon>
			</v-btn>
		</template>

		<span> Duplicar Config. de CNAB</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'CnabDuplicateButton',
	props: {
		value: [String, Number],
	},
	data() {
		return {
			identifier: this.value,
		};
	},
	methods: {
		openDuplicateDialog() {
			this.$emit('open', this.identifier);
		},
	},
};
</script>

<style>

</style>
