<template>
  <page-container>
    <breadcrumbs :page-title="getRouteTitle()" />

    <v-form ref="operationForm" class="w-100 d-flex flex-column">
      <form-structure :loading="loading">
        <template v-slot:content>
          <company-save-main-form @save="save" :saving="saving" :oldCompany="oldCompany" />
        </template>

        <template v-slot:actions>
          <form-cancel-button />

          <form-save-button
            @click="saving = true"
            :disabled="loading"
            :valid="valid"
            :loading="loading"
          />

          <form-destroy-button
						:loading="loading"
						:disabled="loading"
						@remove="remove"
					/>
        </template>
      </form-structure>
    </v-form>
  </page-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import FormDestroyButton from '@/components/defaults/buttons/FormDestroyButton.vue';
import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';

import CompanySaveMainForm from './form/CompanySaveMainForm.vue';

export default {
	name: 'OperationSave',
	components: {
		PageContainer,
		Breadcrumbs,
		FormStructure,
		FormSaveButton,
		FormDestroyButton,
		CompanySaveMainForm,
		FormCancelButton,
	},
	data() {
		return {
			loading: false,
			saving: false,
			valid: true,

			oldCompany: {},
		};
	},

	created() {
		this.loadOperation();
	},
	methods: {
		showRemoveSuccessDialog() {
			this.$swal({
				title: 'Empresa excluída excluida',
				icon: 'success',
				showConfirmButton: true,
			});
		},

		returnToPreviousRoute() {
			this.$router.push({ name: 'CompanyList' });
		},
		async loadOperation() {
			try {
				if (this.isANewOperation()) return;

				const { params } = this.$route;

				this.loading = true;
				const companyService = new CompanyService();

				const response = await companyService.show({ id: params.id });

				[this.oldCompany] = response;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},

		isANewOperation() {
			const id = this.getIdOfRoute();

			return this.idIsNewOrNotExists(id);
		},
		showSaveSuccessDialog() {
			const title = this.isANewOperation()
				? 'Empresa cadastrada com sucesso!'
				: 'Empresa atualizada com sucesso!';

			this.$swal({
				title,
				icon: 'success',
				showConfirmButton: true,
			});
		},
		/**
     * HELPER FUNCTIONS
     */
		getRouteTitle() {
			const id = this.getIdOfRoute();

			if (this.idIsNewOrNotExists(id)) return 'Criação de empresas';

			return 'Edição de empresas';
		},

		idIsNewOrNotExists(id) {
			return !id || id == 'new' || id == undefined;
		},

		getIdOfRoute() {
			const { id } = this.$route.params;

			return id;
		},
		async remove() {
			try {
				if (this.isANewOperation()) return;
				const { id } = this.$route.params;
				this.loading = true;
				const companyService = new CompanyService();
				await companyService.destroy({ id });

				this.showRemoveSuccessDialog();
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
				this.removing = false;
			}
		},

		async save(company) {
			try {
				if (!this.$refs.operationForm.validate()) return;

				this.loading = true;
				const companyService = new CompanyService();

				if (this.isANewOperation()) {
					await companyService.store(company);
				} else {
					await companyService.update(company);
				}
				this.showSaveSuccessDialog();
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
				this.saving = false;
			}
		},
	},
};
</script>
