<template>
	<v-app>
		<v-main class="fill-height pa-0">
			<router-view/>
		</v-main>

		<snackbar/>
	</v-app>
</template>

<script>
import Snackbar from '@/components/snackbar/Snackbar.vue';

export default {
	name: 'LoginView',
	components: {
		Snackbar,
	},
	data() {
		return {};
	},
};
</script>

<style>

</style>
