<template>
	<login-form
		@login="login"
		@forgotPassword="forgotPassword"
		:logging-in="loggingIn"
		logo-url="@/assets/images/logo_verovia_horizontal.svg"
		disable-signup
		text-secondary
	>
	</login-form>
</template>

<script>
import AuthService from '@/services/AuthService';
import LoginForm from './LoginForm.vue';

export default {
	name: 'Login',
	components: {
		LoginForm,
	},
	data() {
		return {
			loggingIn: false,
		};
	},
	methods: {
		async login({ email, password }) {
			try {
				this.loggingIn = true;

				const authService = new AuthService();
				const response = await authService.send({ email, password }, '/login');

				this.saveLoginOnToken(response.token);

				this.$router.push({ name: 'Home' });
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loggingIn = false;
			}
		},
		signup() {
			console.log('login/signup() - notImplemented');
		},
		forgotPassword() {
			this.$router.push({ name: 'LostPassword' });
		},
		saveLoginOnToken(loggedUserToken) {
			sessionStorage.logged_user = JSON.stringify(loggedUserToken);
		},
	},
};
</script>
