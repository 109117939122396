<template>
	<v-col xl="3" lg="3" md="6" sm="12" xs="12" :class="projectCardClass">
		<v-card
			class="lazy-elevation-card"
		>
			<v-img
				max-height="200"
				height="200"
				contain
				:src="project.image_url | getWithToken"
			/>

			<v-card-title class="text-center" style="font-size: 15px;">
				{{ project.name }}
			</v-card-title>

			<v-card-text>
				<div class="py-1 d-flex flex-column" v-if="project.last_operation">
					<span class="text-caption"> Última Ação: </span>
					<span class="text-subtitle-2"> {{ project.last_operation.date | mysqlTimestampToBr }} </span>
				</div>
			</v-card-text>

			<div class="d-flex flex-column w-100">
				<div class="flex-item">
					<v-divider/>
					<v-btn
						text
						class="flex-item w-100"
						color="primary"
						style="font-size: 12px;"
						@click="onDashboardClick('first')"
					>
						Acessar Dashboard
					</v-btn>
					<v-divider v-if="project.power_bi_url2"/>
					<v-btn
						v-if="project.power_bi_url2"
						text
						class="flex-item w-100"
						color="primary"
						style="font-size: 12px;"
						@click="onDashboardClick('second')"
					>
						Acessar Dashboard 2
					</v-btn>

					<v-divider/>
					<v-btn
						text
						class="flex-item w-100"
						color="primary"
						style="font-size: 12px;"
						@click="onFunctionalitiesClick"
						v-if="hasFunctionalities(project) && notOnlyGroupersFunctionalities(project)"
					>
						FUNCIONALIDADES
					</v-btn>
				</div>
			</div>
		</v-card>

		<project-functionalities-card
			:project="project"
			:visibility="functionalitiesVisibility"
			:right="verifyPosition()"
			@outsideClick="outsideClick"
			@functionalityClick="functionalityClick"
		/>
	</v-col>
</template>

<script>
import ProjectFunctionalitiesCard from './ProjectFunctionalitiesCard.vue';

export default {
	name: 'ProjectCard',
	components: {
		ProjectFunctionalitiesCard,
	},
	props: {
		project: Object,
		index: Number,
	},
	data() {
		return {
			functionalitiesVisibility: 'close',
			projectCardClass: 'project-card-area',
		};
	},
	methods: {
		onDashboardClick(type) {
			this.$emit('dashboardClick', this.project, type);
		},

		onFunctionalitiesClick() {
			this.toogleFunctionalitiesCard();
		},
		outsideClick(e) {
			if (this.functionalitiesVisibility == 'open'
				&& !this.clickedInAProjectCard(e)
			) {
				this.toogleFunctionalitiesCard();
			}
		},
		toogleFunctionalitiesCard() {
			this.functionalitiesVisibility = this.functionalitiesVisibility == 'open' ? 'close' : 'open';
		},
		clickedInAProjectCard(event) {
			const parents = event.target.parentNode;
			const classToFind = this.projectCardClass;

			const targetIsAProjectCard = event.target.className.indexOf(`${classToFind}`) != -1;
			if (targetIsAProjectCard) {
				return true;
			}

			const result = this.goThroughParentsToMatchClass(parents, classToFind);

			return result;
		},
		goThroughParentsToMatchClass(parents, classToFind) {
			if (!parents) {
				return false;
			}

			const result = parents.closest(`.${classToFind}`);

			return result;
		},
		functionalityClick(functionality) {
			this.$emit('functionalityClick', functionality);
		},
		/**
		 * verifyPosition
		 *
		 * Verifica se a posicao do cartao e na direita ou na esquerda
		 * Atraves do indice consegue chegar a esse resultado pois
		 * se o item for de indice par significa que ele estara na direita
		 * isso por que os cartoes ocupam exatamente metade do espaco
		 *
		 * @requires index {Number} - variavel adicionada atraves do props utilizada para comparacao
		 *
		 * @return {Boolean}
		 */
		verifyPosition() {
			const isToRight = this.indexIsDividedByThree(this.index);

			if (isToRight) return true;

			return false;
		},
		indexIsDividedByThree(index) {
			const realIndex = index + 1;
			const dividedByThree = realIndex % 3;

			return (dividedByThree == 0);
		},
		hasFunctionalities(project) {
			return project.functionalities.length;
		},
		notOnlyGroupersFunctionalities(project) {
			const groupersAndLimiters = project.functionalities.filter((functionality) => this.isGrouperOrLimiters(functionality));

			return project.functionalities.length != groupersAndLimiters.length;
		},
		isGrouperOrLimiters(functionality) {
			return (functionality.type).indexOf('grouper') != -1;
		},
	},
};
</script>

<style>

</style>
