<template>
  <v-container>
		<v-row>
    <v-col xl="12" lg="12" md="12" sm="12" xs="12">
      <image-dialog
        md-w="330"
        md-h="450"
        sm-w="200"
        sm-h="350"
        v-model="news.attachment_image"
        ref="fileDialog"
      />
    </v-col>
    <v-col xl="12" lg="12" md="12" sm="12" xs="12">
      <v-text-field
        label="Título"
        v-model="news.title"
        :rules="[rules.required]"
      ></v-text-field>
    </v-col>
    <v-col xl="6" lg="6" md="6" sm="12" xs="12">
      <date-picker
        label="Data início"
        v-model="news.start_date"
        placeholder=""
        clearable
        :rules="[rules.required]"
      />
    </v-col>

    <v-col xl="6" lg="6" md="6" sm="12" xs="12">
      <date-picker
        label="Data fim"
        v-model="news.end_date"
        placeholder=""

        clearable
      />
    </v-col>

    <v-col xl="12" lg="12" md="12" sm="12" xs="12" id="editor">
      <quill-editor
        ref="myQuillEditor"
        :options="options"
        :rules="[rules.required]"
        v-model="news.description"
      />
    </v-col>
		</v-row>
  </v-container>
</template>

<script>

import Base64 from '@/utils/Base64';

import ImageDialog from '@/components/image_upload/ImageDialog.vue';

import DatePicker from '@/components/defaults/DatePicker.vue';

import { quillEditor } from 'vue-quill-editor';

export default {
	components: {
		quillEditor,
		DatePicker,
		ImageDialog,
	},
	props: {
		user: { type: Object, required: true },
		loading: Boolean,
		old: Object,
		form: Boolean,
	},
	watch: {
		form: {
			deep: true,
			handler(value) {
				if (value) {
					this.save();
				}
			},
		},
		old: {
			handler(value) {
				this.news = value;
				this.loadFile();
			},
		},
	},
	data() {
		return {
			news: {
				title: '',
				description: '',
				attachment_image: [],
				start_date: '',
				end_date: '',
				user_id: this.user.user.id,
			},
			options: {
				modules: {
					toolbar: {
						container: [
							['bold', 'italic', 'underline', 'strike'],
							[{ size: ['small', false, 'large', 'huge'] }],
							[{ list: 'ordered' }, { list: 'bullet' }],
							[{ color: [] }, { background: [] }],
							[{ font: [] }],
							[{ align: [] }],
						],
					},
				},
				placeholder: 'Descrição',
			},
			rules: {
				required: (v) => (v !== 'undefined' && v !== null && v !== '') || 'Campo Necessário',
				date: () => {
					if (this.news.start_date > this.news.end_date) {
						return 'Data início deve ser menor que a final';
					}
					return true;
				},
			},
		};
	},

	methods: {
		save() {
			this.$emit('save', this.news);
		},

		async onChangeImage() {
			const { image } = this.news;
			this.news.attachment = await Base64.encode(image);
		},

		async loadFile() {
			if (!this.news && !this.news.attachment) return;

			this.news.attachment_image = {
				preview: this.news.attachment,
			};
		},
	},
};
</script>
<style scoped>
#editor {
	max-width: 1800px !important;
}
</style>
