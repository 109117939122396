<template>
	<v-list-group
		:prepend-icon="link.icon"
		max="1"
	>
		<template v-slot:activator>
			<v-list-item-title> {{ link.title }} </v-list-item-title>
		</template>

		<drawer-item
			v-for="child in link.children"
			:key="child.title"
			:link="child"
			item-class="pl-6"
		>
		</drawer-item>
	</v-list-group>
</template>

<script>
import DrawerItem from '@/components/drawer/DrawerItem.vue';

export default {
	name: 'DrawerSubmenu',
	data() {
		return {
		};
	},
	components: {
		DrawerItem,
	},
	props: {
		link: Object,
	},
};
</script>

<style>

</style>
