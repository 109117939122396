<template>
  <v-select
		label="Usuário"
		:items="users"
		item-text="name"
		item-value="id"
		v-model="model"
		:loading="loading"
		return-object
		:rules="rules"
	>
  </v-select>
</template>

<script>
import UserService from '@/services/UserService';

export default {
	name: 'UserSelect',

	data: () => ({
		loading: false,
		users: [],
		model: {},
	}),
	props: {
		rules: Array,
		user: [Number, Object],
	},
	watch: {
		model() {
			this.$emit('input', this.model);
		},
		user(value) {
			if (value) {
				this.model = value;
			}
		},
	},
	created() {
		this.getUsers();
	},

	methods: {
		async getUsers() {
			try {
				this.loading = true;
				const userService = new UserService();

				const response = await userService.index();

				this.users = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
