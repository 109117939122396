<template>
	<v-col xl="4" lg="4" md="12" sm="12" xs="12">
		<v-col cols="12">
			<separator label="Incidentes Diretos"/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<v-text-field
				label="Prazo Médio de Operação"
				type="number"
				v-model="operation.average_operation_time"
				:suffix="averageOperationTime"
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="Deságio"
				field="goodwill"
				v-model="operation.goodwill"
				:disabled="disabled"
				ref="goodwill"
				prefix="R$"
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="AD VALOREM"
				field="ad_valorem"
				v-model="operation.ad_valorem"
				:disabled="disabled"
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="IOF"
				field="iof"
				v-model="operation.iof"
				:disabled="disabled"
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="Custos de TED"
				field="ted_cost"
				v-model="operation.ted_cost"
				:disabled="disabled"
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="TAC"
				field="tac"
				v-model="operation.tac"
				:disabled="disabled"
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="Tarifa de Custódia"
				field="custody_fee"
				v-model="operation.custody_fee"
				:disabled="disabled"
				no-validation
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="Deságio não especificado"
				field="non_especified_goodwill"
				v-model="operation.non_especified_goodwill"
				:disabled="disabled"
				no-validation
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<v-text-field
				label="Quantidade Consulta SERASA"
				v-model="operation.serasa_consultation_quantity"
				type="number"
				:suffix="operation.serasa_consultation_quantity ? 'Registros' : 'Registro'"
				placeholder="0"
				:disabled="disabled"
				@input="calculateSerasaConsultation"
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="Valor Consulta SERASA"
				field="serasa_consultation_value"
				v-model="operation.serasa_consultation_value"
				ref="serasa_consultation_field"
				:disabled="disabled"
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<operation-expense-field
				label="Custos Totais de Registro de Boleto"
				v-model="operation.bank_slip_registry_value"
				field="bank_slip_registry_value"
				:disabled="disabled"
				calculated
			/>
		</v-col>
	</v-col>
</template>

<script>
import Separator from '@/components/defaults/layout/Separator.vue';
import { bus } from '@/main';

import operationHandler from '../../state/operation.handler';
import OperationExpenseField from '../inputs/OperationExpenseField.vue';
import { actions, getters } from '../../state/operation.state';

export default {
	name: 'OperationSaveExpensesDirect',
	props: {
		disabled: Boolean,
	},
	data() {
		return {
			average_operation_time: null,
			test: 1,
		};
	},
	mixins: [operationHandler],
	components: {
		Separator,
		OperationExpenseField,
	},
	created() {
		actions.calculate.average_operation_time();

		bus.$on('updateNetReceivedValue', this.revalidateNetReceivedValue);
	},
	computed: {
		averageOperationTime() {
			const toFormat = this.average_operation_time;

			if (typeof toFormat === 'number') return `${toFormat} Dias`;

			if (!toFormat) return '0 Dias';

			const formated = toFormat.replace('.', ',');

			if (!formated) return '0 Dias';

			return `${formated} Dias`;
		},
		holidays() {
			return getters.holidays();
		},
	},
	watch: {
		'operation.registers': {
			deep: true,
			handler() {
				actions.calculate.average_operation_time();
			},
		},
		holidays() {
			this.$refs.goodwill.calculate();
			this.$refs.goodwill.rebuild();
		},
	},
	methods: {
		calculate() {
			bus.$emit('updateNetReceivedValue');
		},
		calculateSerasaConsultation() {
			if (!actions.calculate.serasa_consultation_value) return;

			actions.calculate.serasa_consultation_value();

			actions.calculate.net_received_value();

			this.$refs.serasa_consultation_field.rebuild();

			bus.$emit('updateNetReceivedValue');
		},
		revalidateNetReceivedValue() {
			this.$nextTick(() => {
				this.average_operation_time = this.operation.calculated_expenses.average_operation_time;
			});

			if (!this.$refs.net_received_value) return;

			this.$refs.net_received_value.validate();
		},
	},
};
</script>

<style>

</style>
