<template>
  <page-container>
    <breadcrumbs page-title="Configuração de empresa" />

    <list-structure>
      <template v-slot:actions>
        <list-add-button @add="add" color="#E09A11" />
      </template>

      <template v-slot:table>
        <company-table-list
          @edit="edit"
        />
      </template>
    </list-structure>
  </page-container>
</template>

<script>
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import CompanyTableList from './CompanyTableList.vue';

export default {
	name: 'CompanyList',

	data: () => ({
		user: {},
		search: '',

		calculated: 0,

		showFilters: false,
	}),

	created() {
		this.getLoggedUser();
	},

	components: {
		ListAddButton,
		ListStructure,
		PageContainer,
		Breadcrumbs,
		CompanyTableList,
	},

	methods: {
		add() {
			this.pushToSaveCompany({ id: 'new' });
		},

		getLoggedUser() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);
			this.user = tokenData;
		},

		pushToSaveCompany(params) {
			this.$router.push({
				name: 'CompanySave',
				params,
			});
		},

		edit(params) {
			this.pushToSaveCompany(params);
		},
	},
};
</script>
