<template>
	<v-select
		label="Configuração de Leitura"
		v-model="model"
		:items="items"
		:loading="isLoading"
		:disabled="disabled || isLoading"
		item-text="description"
		item-value="id"
		:return-object="returnObject"
	/>
</template>

<script>
import CnabService from '@/services/CnabService';
import FundService from '@/services/FundService';

export default {
	name: 'CnabConfigSelect',
	props: {
		value: [Object, Number],
		returnObject: Boolean,
		byFund: Boolean,
		fundId: [Object, Number, String],
		disabled: Boolean,
	},
	data() {
		return {
			model: this.value,
			items: [],
			isLoading: false,
		};
	},
	watch: {
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
		model() {
			this.$emit('input', this.model);
			this.$emit('change', this.model);
		},
	},
	mounted() {
		this.loadCnab();
	},
	methods: {
		async loadCnab(fund_id = null) {
			if (this.isLoading) {
				return;
			}

			if (fund_id) {
				this.fundId = fund_id;
			}

			try {
				this.model = null;
				this.isLoading = true;

				let response = {};

				response = await this.loadAllCnab(response);

				response = await this.loadCnabByFund(response);

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isLoading = false;
			}
		},
		async loadCnabByFund(response) {
			if (!this.byFund || !this.fundId) {
				return response;
			}

			const fundService = new FundService();

			response = await fundService.cnab(this.fundId).index();

			return response;
		},
		async loadAllCnab(response) {
			if (this.byFund && this.fundId) {
				return response;
			}

			const cnabService = new CnabService();

			response = await cnabService.index();

			return response;
		},
	},
};
</script>

<style>

</style>
