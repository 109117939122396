<template>
	<v-card flat>
		<v-container fluid>
			<v-row>
				<v-col xl="3" lg="3" md="6" sm="6" xs="12">
					<slot name="search"></slot>
				</v-col>

				<v-col xl="9" lg="9" md="6" sm="6" xs="12">
					<slot name="actions"></slot>
				</v-col>
			</v-row>
		</v-container>

		<v-divider/>

		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<slot name="table"></slot>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
export default {
	name: 'ListStructure',
};
</script>

<style>

</style>
