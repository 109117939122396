import Rest from './base/Rest';

export default class OperationService extends Rest {
	static resource = 'operations';

	upload(projectId) {
		this.relationship = `${projectId}/upload/`;

		return this;
	}

	registers(projectId) {
		this.relationship = `${projectId}/registers/`;

		return this;
	}
}
