<template>
	<page-container no-column>
		<v-col xl="5" lg="5" md="5" sm="6" xs="12">
			<v-text-field
				label="Descrição"
				v-model="cnab.description"
				:rules="[ rules.required ]"
			/>
		</v-col>
		<v-col xl="2" lg="2" md="3" sm="6" xs="12">
			<v-select
				label="Tipo"
				v-model="cnab.type"
				:items="options.operation_types"
				item-text="text"
				item-value="value"
				:rules="[ rules.required ]"
			/>
		</v-col>
		<v-col xl="2" lg="2" md="3" sm="6" xs="12">
			<v-text-field
				label="Tamanho"
				v-model="cnab.size"
				:rules="[ rules.required ]"
			/>
		</v-col>
		<v-col xl="3" lg="3" md="4" sm="6" xs="12">
			<fund-select
				v-model="cnab.fund_id"
			/>
		</v-col>
		<v-col xl="3" lg="3" md="4" sm="6" xs="12">
			<v-select
				v-show="isReceiveType()"
				v-model="cnab.situation"
				:items="options.situations"
				:rules="[ rules.requiredIfIsReceiveType ]"
				item-text="text"
				item-value="value"
				label="Situação Carteira"
			/>
		</v-col>
	</page-container>
</template>

<script>
import FundSelect from '@/components/funds/FundSelect.vue';
import PageContainer from '../../../components/defaults/layout/PageContainer.vue';
import CNAB from './cnab_model';

export default {
	name: 'CnabForm',
	components: {
		PageContainer,
		FundSelect,
	},
	props: {
		value: Object,
	},
	data() {
		return {
			options: CNAB.options,
			cnab: this.value,
			rules: {
				required: (v) => (v !== 'undefined' && v !== null) || 'Campo Necessário',
				requiredIfIsReceiveType: (v) => this.requiredIfIsReceiveType(v) || 'Campo Necessário',
			},
		};
	},
	watch: {
		cnab() {
			this.$emit('input', this.cnab);
		},
		value() {
			if (this.cnab == this.value) return;

			this.cnab = this.value;
		},
	},
	methods: {
		requiredIfIsReceiveType(value) {
			if (!this.isReceiveType()) return true;

			return this.rules.required(value);
		},
		isReceiveType() {
			const receiveType = 1;

			return (this.cnab.type == receiveType);
		},
	},
};
</script>

<style>

</style>
