import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(
	VueSweetalert2,
	{
		confirmButtonColor: '#4CAF50',
		cancelButtonColor: '#FFFFFF',
		confirmButtonText: 'Continuar',
		cancelButtonText: 'cancelar',
	},
);

export default VueSweetalert2;
