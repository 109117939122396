var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-col w-100"},[_c('v-data-table',{staticClass:"w-100",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"show-select":"","color":"primary","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"item-selected":_vm.toggleConciliation},scopedSlots:_vm._u([{key:"item.net_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.net_value))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDateToBr")(item.due_date))+" ")]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDateToBr")(item.issue_date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.status[0])+" "+_vm._s(Object.keys(item.status).length > 0 ? _vm.serializeStatusText(item.status[0].status): 'Em Aberto')+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }