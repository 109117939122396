<template>
	<div>
		<v-snackbar
			ref="snackbar"
			v-model="visible"
			:color="snackbar.color"
			:timeout="snackbar.timeout"
			:multi-line="snackbar.multiLine"
			:vertical="snackbar.false"
			:bottom="snackbar.bottom"
			:left="snackbar.left"
			:right="snackbar.right"
			:top="snackbar.top"
		>
			{{ snackbar.message }}

			<template v-slot:action="{ attrs}">
				<v-btn
          v-bind="attrs"
					dark
					icon
					@click="hide"
				>
					<v-icon> mdi-close </v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import { bus } from '@/main';

export default {
	name: 'SnackBar',
	data() {
		return {
			visible: false,
			snackbar: {
				message: '',
				color: 'success',
				timeout: 15000,
				bottom: false,
				left: false,
				right: true,
				top: true,
				multiLine: false,
			},
		};
	},
	created() {
		bus.$on('showSnackbar', this.show);
	},
	methods: {
		show(messaging) {
			const { message, obj } = messaging;

			this.visible = true;

			this.snackbar.message = message;

			if (obj) {
				this.snackbar = { ...this.snackbar, ...obj };
			}
		},
		hide() {
			this.visible = false;
		},
	},
};
</script>
