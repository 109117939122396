<template>
	<v-btn
		@click="onClick"
		color="error"
		class="float-right mx-4"
		:disabled="disabled || loading"
		:class="buttonClass"
		:loading="loading"
		outlined
	>
		<v-icon class="mr-2">
			{{ buttonIcon() }}
		</v-icon>

		Inativar
	</v-btn>
</template>

<script>
export default {
	name: 'FormSaveButton',
	props: {
		valid: Boolean,
		loading: Boolean,
		icon: String,
		buttonClass: String,
		disabled: Boolean,
	},
	methods: {
		async onClick() {
			const result = await this.$swal({
				text: 'Você tem certeza que deseja inativar?',
				title: 'Inativar',
				icon: 'error',
				showConfirmButton: true,
				showCancelButton: true,
			});

			const { value } = result;

			if (value) {
				this.$emit('remove');
				this.$emit('click');
			}
		},
		buttonIcon() {
			return this.icon || 'mdi-account-off';
		},
	},
};
</script>

<style>

</style>
