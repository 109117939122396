<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="value"
      disable-sort
      item-key="id"
      group-key="status"
      class="w-100 mb-n3 mt-2"
    >
      <template v-slot:[`item.net_value`]="{ item }">
        {{ item.net_value | toCurrency }}
      </template>

      <template v-slot:[`item.due_date`]="{ item }">
        {{ item.due_date | mysqlDateToBr }}
      </template>

      <template v-slot:[`item.issue_date`]="{ item }">
        {{ item.issue_date | mysqlDateToBr }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-for="(status, index) in item.status"
          :key="index"
          class="mx-2"
        >
          {{ serializeStatusText(status.status) }}
        </v-chip>
      </template>
    </v-data-table>
    <v-row>
      <v-col xl="6" lg="6" md="6" sm="6" xs="12" class="d-flex justify-center">
        <h3>Saldo total</h3>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        class="d-flex justify-space-around"
      >
        <h4>{{ `${calculate()}` }}</h4>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getters } from '../state/receipts.state';

export default {
	name: 'ConciliationList',
	props: {
		value: Array,
	},
	data() {
		return {
			headers: [
				{ text: 'NF', value: 'number' },
				{ text: 'Valor', value: 'net_value' },
				{ text: 'Cliente', value: 'client_name' },
				{ text: 'Data de Vencimento', value: 'due_date' },
				{ text: 'Data da baixa', value: 'issue_date' },
			],
		};
	},
	mounted() {
		this.calculate();
	},
	computed: {
		conciliated() {
			return getters.conciliated();
		},
	},
	methods: {
		serializeStatusText(status) {
			switch (status) {
			case 'partial_repurchased_amount':
				return 'Título recomprado parcial';
			case 'registry_sent':
				return 'Registro Enviado ao Cartório';
			case 'registry_send_movement_value':
				return 'Registro Enviado ao Cartório';
			case 'title_in_serasa':
				return 'Consultado no SERASA';
			case 'registry_repurchased':
				return 'Retirado do Cartório';
			case 'title_repurchased':
				return 'Título Recomprado';
			case 'title_down':
				return 'Título baixado';
			case 'title_prorrogated':
				return 'Título Prorrogado';
			default:
				return '';
			}
		},
		filterOnlyValidConciliatedRegisters() {
			this.conciliated = this.conciliated.filter((row) => row.status.length);
		},

		calculate() {
			let sum = 0;
			this.value.forEach((element) => {
				sum += parseInt(element.net_value, 10);
			});

			return this.$helpers.toCurrency(sum);
		},
	},
};
</script>

<style>
</style>
