<template>
	<section class="login-grid-layout h-100 w-100">
		<content class="h-100 d-none d-lg-block">
			<v-img
				class="h-100"
				src="@/assets/background/background-sm.webp"
				:lazy-src="'@/assets/background/background-lg.webp'"
			/>
		</content>

		<aside class="shadow-2__left index-1">
			<v-form
				class = "
					login-form-grid-layout
					h-100
					pa-4
					pa-lg-8
					pt-8
					pt-lg-12"
				v-model="valid"
				ref="authForm"
				@submit.prevent="onSubmit"
				lazy-validation
			>
				<v-container
					class="text-center"
					justify="center"
					align="center"
				>
					<v-row>
						<v-col cols="12">
							<slot name="logo">
								<v-img
									src="@/assets/images/logo_verovia_horizontal.svg"
									aspect-ratio="1.0"
									contain
									max-height="75"
								></v-img>
							</slot>
						</v-col>
					</v-row>
				</v-container>

				<v-container>
					<v-row>
						<slot name="form"></slot>
					</v-row>
				</v-container>

				<v-container>
					<v-row>
						<slot name="actions"></slot>
					</v-row>
				</v-container>
			</v-form>
		</aside>
	</section>
</template>

<script>
export default {
	name: 'LoginLayout',
	data() {
		return {
			valid: false,
		};
	},
	watch: {
		valid() {
			this.$emit('input', this.valid);
		},
	},
	methods: {
		onSubmit() {
			this.$emit('submit');
		},
		revalidate() {
			this.$refs.authForm.validate();
		},
	},
};
</script>

<style>

</style>

<style lang="sass" scoped>
@import '@/styles/breakpoints.sass'

.login-grid-layout
	display: grid
	grid-template: auto / 65% auto

	@include xl
		grid-template: auto / auto 33.33%

	@include lg
		grid-template: auto / 55% auto

	@include md
		grid-template: auto / auto

	@include sm
		grid-template: auto / auto

.login-form-grid-layout
	display: grid
	grid-template: 20% 50% 20% / auto
	row-gap:5%

	@include xl
		grid-template: 20% 47% 10% / 100%
		row-gap:7%

	@include lg
		grid-template: 20% 57% 10% / 100%
		row-gap:7%

	@include md
		grid-template: 20% 57% 10% / auto
		row-gap:7%
</style>
