var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.filterHeaders,"items":_vm.vacations.itens,"loading":_vm.loadingData,"sort-by":['vacations_id'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","small":"","color":_vm.colors(item.type)}},[_vm._v(_vm._s(_vm.types(item.type)))])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToDate(item.start_date))+" ")]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToDate(item.end_date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.status(item.status, item)))]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.period(item.period)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('vacations-table-options',{attrs:{"item":item,"user":_vm.user},on:{"loading":function($event){_vm.loadingData = true},"reload":function($event){return _vm.loadVacationsRequest()},"edit":_vm.editData,"show":_vm.showData}})]}}],null,true)},[(_vm.validateFilters() && _vm.validatePermission())?_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_c('h2',[_vm._v("Saldo")])]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',[_c('h2',[_vm._v(_vm._s(_vm.calculated)+" dias")])])])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }