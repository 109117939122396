<template>
	<div class="w-100">
		<separator label="Imagem" v-if="noDivider"/>

		<image-upload-button
			ref="img-upload-btn"
			v-if="!selectedFile && (!image || !image.preview)"
			v-model="selectedFile"
		/>

		<image-cropper
			v-else-if="(!image || !image.preview) && selectedFile"
			:image="selectedFile"
			:md-w="mdW"
			:md-h="mdH"
			:sm-w="smW"
			:sm-h="smH"
			:aspect-ratio="aspectRatio"
			:cropType="cropType"
			v-model="image"
		/>

		<image-preview
			v-else
			:max-width="mdW"
			:max-height="mdH"
			:image="image"
			@changeImage="changeImage"
			:noCenter="noCenter"
		/>
	</div>
</template>

<script>
import Base64 from '@/utils/Base64';

import Separator from '@/components/defaults/layout/Separator.vue';
import ImageUploadButton from './ImageUploadButton.vue';
import ImageCropper from './ImageCropper.vue';
import ImagePreview from './ImagePreview.vue';

export default {
	name: 'ImageDialog',
	components: {
		Separator,
		ImageUploadButton,
		ImageCropper,
		ImagePreview,
	},
	props: {
		mdW: String,
		mdH: String,
		smW: String,
		smH: String,
		aspectRatio: Number,
		value: [File, Object, Array],
		noDivider: Boolean,
		skipCropping: Boolean,
		noCenter: Boolean,
		cropType: Number,
	},
	data() {
		return {
			selectedFile: null,
			oldImage: null,
			image: this.value,
		};
	},
	watch: {
		value() {
			if (this.image == this.value) return;
			this.image = this.value;
		},
		image() {
			this.$emit('input', this.image);
		},
		selectedFile(newFile) {
			if (!this.image) {
				this.$emit('input', newFile);
			}

			if (this.skipCropping) {
				this.skipCrop(newFile);
			}
		},
	},
	methods: {
		changeImage() {
			this.oldImage = { ...this.image };

			this.selectedFile = null;
			this.image = null;

			this.$nextTick(() => {
				this.$refs['img-upload-btn'].uploadArchive();
			});
		},
		getImage() {
			if (this.image) return this.image;

			return this.oldImage;
		},
		async skipCrop(file) {
			const imagePreview = await Base64.encode(file);

			const image = {
				object: file,
				preview: imagePreview,
			};

			this.image = image;
		},
	},
};
</script>

<style>

</style>
