import Vue from 'vue';
import VueRouter from 'vue-router';

// LOGIN ROUTES
import LoginView from '@/components/navigation/LoginView.vue';
import Login from '@/views/login/Login.vue';
import VerifyPassword from '@/views/auth/VerifyPassword.vue';
import LostPassword from '@/views/auth/LostPassword.vue';
import ApproveVacationRequest from '@/views/approve/ApproveVacationRequest.vue';
import DisapproveVacationRequest from '@/views/disapprove/DisapproveVacationRequest.vue';

// SETUP ROUTES
import SystemView from '@/components/navigation/SystemView.vue';
import Home from '@/views/home/Home.vue';
import ProjectsView from '@/views/projects/ProjectsView.vue';

// CUSTOM ROUTES
import UserList from '@/views/users/list/UserList.vue';
import UserSave from '@/views/users/save/UserSave.vue';

import ProjectList from '@/views/projects/list/ProjectList.vue';
import ProjectSave from '@/views/projects/save/ProjectSave.vue';

import FundTypeList from '@/views/fund_types/FundTypeList.vue';
import FundTypeSave from '@/views/fund_types/FundTypeSave.vue';

import UserProfile from '@/views/users/profile/UserProfile.vue';

import UserTypeList from '@/views/user_types/list/UserTypeList.vue';
import UserTypeSave from '@/views/user_types/save/UserTypeSave.vue';

import OperationTypeList from '@/views/operation_types/OperationTypeList.vue';
import OperationTypeSave from '@/views/operation_types/OperationTypeSave.vue';

import OperationSave from '@/views/operations/save/OperationSave.vue';
import OperationList from '@/views/operations/list/OperationList.vue';

import ReceiptsList from '@/views/receipts/list/ReceiptsList.vue';
import ReceiptsSave from '@/views/receipts/save/ReceiptsSave.vue';

import PowerBIView from '@/views/power_bi/PowerBIView.vue';

import Inntravel from '@/views/systems/Inntravel.vue';

import FundList from '@/views/funds/list/FundList.vue';
import FundSave from '@/views/funds/save/FundSave.vue';

import CnabRegister from '@/views/cnab/register/CnabRegister.vue';
import CnabList from '@/views/cnab/list/CnabList.vue';

import DefaultExpensesList from '@/views/default_expenses/list/DefaultExpensesList.vue';
import DefaultExpensesSave from '@/views/default_expenses/save/DefaultExpensesSave.vue';

import VacationsList from '@/views/rh/Vacations/list/VacationsList.vue';
import VacationsShow from '@/views/rh/Vacations/list/VacationsShow.vue';
import VacationsSave from '@/views/rh/Vacations/save/VacationsSave.vue';
import BuyVacationSave from '@/views/rh/BuyVacations/save/BuyVacationsSave.vue';
import CreditVacationSave from '@/views/rh/CreditVacations/save/CreditVacationsSave.vue';

import NewsSave from '@/views/news/save/NewsSave.vue';
import NewsList from '@/views/news/list/NewsList.vue';
import FullListNews from '@/views/home/HomeView/News/FullListNews.vue';

import CompanyList from '@/views/company/list/CompanyList.vue';
import CompanySave from '@/views/company/save/CompanySave.vue';

import RouterMiddleware from './router.middleware';

Vue.use(VueRouter);

const routes = [
	{ path: '*', redirect: '/login' },
	{
		path: '/',
		component: LoginView,
		children: [
			{ path: '/login', name: 'Login', component: Login },
			{ path: '/auth/verify/:token', name: 'VerifyPassword', component: VerifyPassword },
			{ path: '/auth/verify', name: 'LostPassword', component: LostPassword },
			{ path: '/approve/:id/:token', name: 'ApproveRequest', component: ApproveVacationRequest },
			{ path: '/disapprove/:id/:token', name: 'DisapproveRequest', component: DisapproveVacationRequest },
		],
	},
	{
		path: '/',
		component: SystemView,
		children: [
			{ path: '/home', name: 'Home', component: Home },

			{ path: '/user/profile/:id', name: 'UserProfile', component: UserProfile },
			{ path: '/users/types/', name: 'UserTypes', component: UserTypeList },
			{ path: '/users/types/:id', name: 'UserTypeSave', component: UserTypeSave },

			{ path: '/users/:id', name: 'UserSave', component: UserSave },
			{ path: '/users', name: 'UsersList', component: UserList },

			{ path: '/projects', name: 'ProjectView', component: ProjectsView },
			{ path: '/projects-list', name: 'ProjectList', component: ProjectList },
			{ path: '/projects/:id', name: 'ProjectSave', component: ProjectSave },

			// OPERATIONS
			{ path: '/projects/:project/operations', name: 'OperationList', component: OperationList },
			{ path: '/projects/:project/operations/:id', name: 'OperationUpload', component: OperationSave },

			{ path: '/projects/:project/expenses', name: 'DefaultExpensesList', component: DefaultExpensesList },
			{ path: '/projects/:project/expenses/:expense', name: 'DefaultExpensesSave', component: DefaultExpensesSave },

			{ path: '/operations/types', name: 'OperationTypeList', component: OperationTypeList },
			{ path: '/operations/types/:id', name: 'OperationTypeSave', component: OperationTypeSave },

			// RECEIPTS
			{ path: '/projects/:project/receipts', name: 'ReceiptsList', component: ReceiptsList },
			{ path: '/projects/:project/receipts/:id', name: 'ReceiptsUpload', component: ReceiptsSave },

			// FUNDS
			{ path: '/funds/types', name: 'FundTypes', component: FundTypeList },
			{ path: '/funds/types/:id', name: 'FundTypesSave', component: FundTypeSave },

			{ path: '/funds/', name: 'FundList', component: FundList },
			{ path: '/funds/save/:id', name: 'FundSave', component: FundSave },

			// POWERBI
			{ path: '/dashboard/:id', name: 'PowerBIView', component: PowerBIView },

			// Inntravel
			{ path: '/inntravel', name: 'Inntravel', component: Inntravel },

			{ path: '/cnab', name: 'CnabList', component: CnabList },
			{ path: '/cnab/:id', name: 'CnabRegister', component: CnabRegister },

			{ path: '/vacations', name: 'VacationsList', component: VacationsList },
			{ path: '/vacations/:id', name: 'VacationsSave', component: VacationsSave },
			{ path: '/vacations/buy/:id', name: 'BuyVacationSave', component: BuyVacationSave },
			{ path: '/vacations/credit/:id', name: 'CreditVacationSave', component: CreditVacationSave },
			{ path: '/vacations/show/:id', name: 'VacationsShow', component: VacationsShow },

			{ path: '/company', name: 'CompanyList', component: CompanyList },
			{ path: '/company/:id', name: 'CompanySave', component: CompanySave },

			{ path: '/news', name: 'NewsList', component: NewsList },
			{ path: '/news/save/:id', name: 'NewsSave', component: NewsSave },
			{ path: '/news/:id', name: 'FullListNews', component: FullListNews },
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => RouterMiddleware.trait({
	router, to, from, next,
}));

export default router;
