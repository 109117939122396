<template>
	<v-form v-model="valid" ref="positionForm">
		<page-container no-column>
			<v-col xl="3" lg="3" md="4" sm="6" xs="12">

				<v-autocomplete
					label="Segmento"
					v-model="position.segment"
					:items="segments"
					item-text="description"
					item-value="line_number"
					@change="changeVisualizationSegment"
					:rules="[ rules.required ]"
				/>
			</v-col>

			<v-col xl="4" lg="4" md="4" sm="6" xs="12">
				<v-text-field
					label="Descrição"
					v-model="position.description"
					:rules="[ rules.required ]"
				/>
			</v-col>

			<v-col xl="4" lg="4" md="4" sm="6" xs="12">
				<v-text-field
					label="Coluna"
					v-model="position.column"
					:rules="[ rules.required ]"
				/>
			</v-col>

			<v-col xl="3" lg="3" md="4" sm="6" xs="12">
				<v-text-field
					label="Posição inicial"
					v-model="position.initial_position"
					type="number"
					:rules="[ rules.required ]"
				/>
			</v-col>

			<v-col xl="3" lg="3" md="4" sm="6" xs="12">
				<v-text-field
					label="Posição final"
					v-model="position.final_position"
					type="number"
					:rules="[ rules.required ]"
				/>
			</v-col>

			<v-spacer/>

			<v-col xl="10" lg="10" md="9" sm="8" xs="12">
				<v-textarea
					label="Conteúdo"
					v-model="position.content"
					rows="3"
				/>
			</v-col>

			<v-col xl="2" lg="2" md="3" sm="4" xs="12" class="d-flex">
				<form-save-button
					@click="save"
					:create="isToCreate()"
					:valid="valid"
					button-class="mx-auto my-auto"
				/>
			</v-col>

			<v-col cols="12" md="12">
				<position-table
					:positions="segmentShown.positions"
					:disable-ordering="isEditing()"
					@delete="destroy"
					@edit="edit"
					@reorder="reorder"
				/>
			</v-col>
		</page-container>
	</v-form>
</template>

<script>
import BelongsToMixin from '@/components/related/BelongsToMixin';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';

import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import CNAB from '../cnab_model';
import PositionTable from './PositionTable.vue';

export default {
	name: 'PositionForm',
	components: {
		PositionTable,
		FormSaveButton,
		PageContainer,
	},
	mixins: [BelongsToMixin],
	props: {
		value: Array,
		changeSegmentsToShow: Object,
	},
	data() {
		return {
			options: CNAB.options,
			segments: this.value,
			rules: {
				required: (v) => (v !== 'undefined' && v !== null) || 'Campo Necessário',
			},
			position: {
				segment: null,
			},
			primaryKey: 'line',
			segment_primary_key: 'line_number',
			model: this.segment,
			list: [],
			valid: false,
			toClean: {
				description: '',
				initial_position: '',
				final_position: '',
				content: '',
				line: null,
				id: null,
				column: '',
			},
		};
	},
	mounted() {
		this.useDefaultSegmentToShow();
	},
	watch: {
		value() {
			if (this.segments == this.value) return;

			this.segments = this.value;
		},
		segments() {
			this.$emit('input', this.segments);
		},
		list() {
			this.changeSegmentPositions();
		},
		position() {
			this.model = this.position;

			this.list = this.segmentShown.positions;
		},
		model() {
			if (this.position == this.model) return;

			this.position = this.model;
		},
	},
	computed: {
		segmentShown() {
			if (!this.segments || !this.segments.length) return { segment: [] };

			if (!this.position || !this.position.segment) return this.segments[0];

			const toReturnSegment = this.findByLineNumber(this.position);

			if (!toReturnSegment) return {};

			if (!toReturnSegment.positions || !toReturnSegment.positions.length) {
				toReturnSegment.positions = [];
			}

			this.setList(toReturnSegment.positions);

			return toReturnSegment;
		},
	},
	methods: {
		changeSegmentPositions() {
			const segment = this.findByLineNumber(this.position);

			if (!segment) return;

			segment.positions = this.list;
		},
		validateForm() {
			this.$refs.positionForm.validate();

			return this.valid;
		},
		resetForm() {
			this.$refs.positionForm.reset();
		},
		setList(list) {
			this.list = list;
		},
		edit(model) {
			const found = this.findByLineNumber(this.position);

			model.segment = found.line_number;

			this.model = { ...this.model, ...model };
		},
		findByLineNumber(position) {
			const found = this.segments.find((segment) => segment[this.segment_primary_key] == position.segment);

			return found;
		},
		/**
		 * METODOS AUXILIARES E UX
		 */
		changeVisualizationSegment() {
			const segment = this.findByLineNumber(this.position);

			this.position.segment = segment[this.segment_primary_key];
		},
		useDefaultSegmentToShow() {
			if (!this.segments || !this.segments.length) return { positions: [] };

			this.position.segment = this.segmentShown[this.segment_primary_key];

			return this.position.segment;
		},
	},
};
</script>

<style>

</style>
