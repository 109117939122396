var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.operation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOperationDescription(item.operation))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":_vm.getTypeColor(item.type)}},[_vm._v(" "+_vm._s(_vm.getTypeDescription(item.type))+" ")])]}},{key:"item.duplicate",fn:function(ref ){
var item = ref.item;
return [_c('cnab-duplicate-button',{on:{"open":_vm.open},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('list-edit-button',{on:{"edit":function($event){return _vm.save(item.id)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }