<template>
	<v-dialog
		v-model="show"
		persistent
		:max-width="maxWidth || '600px'"
	>
		<v-card>
			<v-card-title>
				<span class="headline"> {{ title }} </span>
			</v-card-title>

			<v-divider/>

			<v-card-text>
				<v-container>
					<v-row>
						<slot name="content"></slot>
					</v-row>
				</v-container>
			</v-card-text>

			<v-divider/>

			<v-card-actions>
				<v-spacer></v-spacer>

				<slot name="actions"></slot>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'ModalLayout',
	props: {
		value: Boolean,
		maxWidth: String,
		title: String,
	},
	data() {
		return {
			show: this.value,
		};
	},
	watch: {
		value() {
			if (this.show == this.value) return;

			this.show = this.value;
		},
		show() {
			this.$emit('input', this.show);
		},
	},
};
</script>

<style>

</style>
