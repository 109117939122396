/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

export default class ReceiptsService extends Rest {
  static resource = 'receipts';

  registers(projectId) {
  	this.relationship = `${projectId}/registers/`;
  	return this;
  }

  upload(projectId) {
  	this.relationship = `${projectId}/upload/`;

  	return this;
  }
}
