import Rest from './base/Rest';

export default class FundTypesService extends Rest {
	static resource = 'funds/types/';

	// types() {
	// 	this.relationship = '';

	// 	return this;
	// }
}
