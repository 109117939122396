<template>
	<div class="w-100">
		<separator label="Arquivo" v-if="!noDivider"/>

		<file-upload-button
			:contract="contract"
			ref="file-upload-btn"
			v-if="!selectedFile && (!file || !file.name)"
			v-model="selectedFile"
		/>

		<file-preview
			v-else
			:file="selectedFile"
			@changeFile="changeFile"
		/>
	</div>
</template>

<script>
import Separator from '@/components/defaults/layout/Separator.vue';
import FileUploadButton from './FileUploadButton.vue';
import FilePreview from './FilePreview.vue';

export default {
	name: 'FileDialog',
	components: {
		Separator,
		FileUploadButton,
		FilePreview,
	},
	props: {
		value: [File, Object, Array],
		noDivider: Boolean,
		contract: { type: Boolean, require: false, default: false },
	},
	data() {
		return {
			selectedFile: null,
			file: this.value,
		};
	},
	watch: {
		value() {
			if (this.file == this.value) return;

			this.file = this.value;

			this.changeSelectedFileToNewer();
		},
		file() {
			this.$emit('input', this.file);
			this.$emit('change', this.file);
		},
		selectedFile(newFile) {
			this.changeFileForRead(newFile);
		},
	},
	methods: {
		changeFile() {
			this.selectedFile = null;
			this.file = null;

			this.$nextTick(() => {
				this.$refs['file-upload-btn'].uploadArchive();
			});
		},
		changeSelectedFileToNewer(newer = null) {
			const value = this.value || newer;

			if (!this.value && !value) {
				return;
			}

			if (!value.filename) {
				this.selectedFile = value;
				return;
			}

			const file = new File(
				[`${value.content}`],
				value.filename,
			);

			this.selectedFile = file;
		},
		async changeFileForRead(file) {
			this.file = null;

			const content = await this.readAsText(file);

			const newFile = {
				filename: file.name,
				content,
			};

			this.file = newFile;
		},
		readAsText(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();

				reader.readAsText(file, 'UTF-8');

				reader.onload = (evt) => resolve(evt.target.result);

				reader.onerror = (evt) => reject(evt.target.result);
			});
		},
	},
};
</script>

<style>

</style>
