	<template>
  <page-container style="margin-top: -16px" no-column v-if="!loadingData">
			<v-col xl="6" lg="7" md="12" sm="12" xs="12">
				<calendar/>
				<market-data/>
			</v-col>
			<v-col xl="6" lg="5" md="12" sm="12" xs="12">
				<news-list/>
			</v-col>
  </page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import NewsList from './News/NewsList.vue';
import MarketData from './Market/MarketData.vue';
import Calendar from './Calendar/CalendarView.vue';

export default {
	name: 'GridSystemHome',
	components: {
		Calendar,
		PageContainer,
		MarketData,
		NewsList,
	},

	data() {
		return {
			loadingData: false,
		};
	},
};
</script>
