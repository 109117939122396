import state, { getters } from './receipts.state';

export default {
	name: 'OperationHandler',
	data() {
		return {
			operation: state.operation,
		};
	},
	computed: {
		project() {
			return getters.project();
		},
		operation_handled() {
			return getters.operation();
		},
	},
	watch: {
		operation_handled(val) {
			this.operation = val;
		},
	},
};
