<template>
	<v-currency-field
		:label="label"
		v-model="model"
		:suffix="noValidation || noSuffix ? '' : suffix()"
		:rules="[ mustMatch() ]"
		@input="debounceUpdatePreview"
		@keydown="debounceUpdatePreview"
		:disabled="disabled"
		:append-icon="conciliation ? 'mdi-magnify' : ''"
		@click:append="onClickAppend"
		prefix="R$"
		:readonly="readonly"
		ref="input"
	/>
</template>

<script>
import { bus } from '@/main';

import { actions, getters } from '../../state/operation.state';

export default {
	name: 'OperationExpenseField',
	props: {
		label: String,
		value: [String, Number, Object],
		field: String,
		disabled: Boolean,
		conciliation: Boolean,
		noValidation: Boolean,
		readonly: Boolean,
		noSuffix: Boolean,
	},
	data() {
		return {
			model: this.value,
			timeout: null,
			debounceUpdatePreview: this.$helpers
				.registerDebounce(this.calculate, 20, this.timeout),
		};
	},
	watch: {
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
		model() {
			this.$emit('input', this.model);
		},
	},
	created() {
		if (this.field == 'net_received_value') {
			bus.$on('updateNetReceivedValue', this.rebuild);
		}

		bus.$on('updateAllCalculatedField', this.rebuild);
	},
	methods: {
		rebuild() {
			this.$nextTick(() => {
				this.$forceUpdate();
			});
		},
		updateWhenConciliationField() {
			if (this.conciliation) {
				this.calculate();

				this.rebuild();

				actions.calculate.net_received_value();

				bus.$emit('updateNetReceivedValue');
			}
		},
		suffix() {
			if (!this.field) return '';

			const expected = getters.expected(this.field);

			const formated = this.$helpers.toCurrency(expected);

			return formated;
		},
		onClickAppend() {
			this.$emit('click:append');
		},
		calculateOnlyIfNotConciliationField() {
			if (this.conciliation) {
				return;
			}

			this.calculate();
		},
		calculate() {
			const { field } = this;

			if (!actions.calculate[field]) {
				actions.calculate.net_received_value();

				bus.$emit('updateNetReceivedValue');
				return;
			}

			actions.calculate[field]();

			actions.calculate.net_received_value();

			bus.$emit('updateNetReceivedValue');
		},

		mustMatch() {
			if (this.noValidation) {
				return true;
			}

			const column = this.field;

			const isUndefinedOrNaN = typeof this.model === 'undefined' || Number.isNaN(this.model);

			const valueToMatch = isUndefinedOrNaN ? 0 : Number(this.model);

			const taxToMatch = Number(getters.expected(column));

			if (valueToMatch == undefined || taxToMatch == undefined) {
				return true;
			}

			if (valueToMatch != taxToMatch) {
				if (column == 'net_received_value') {
					return 'O Valor Líquido deve ser igual ao calculado';
				}

				return 'Valores não correspondem';
			}

			return true;
		},

		getSuffixManually(field) {
			if (this.$refs.input) {
				this.$refs.input.suffix = this.$helpers.toCurrency(getters.expected(field));
				this.$forceUpdate();
			}
		},
	},
};
</script>

<style>

</style>
