import Rest from './base/Rest';

export default class ProjectService extends Rest {
	static resource = 'projects'

	user() {
		this.relationship = 'users';

		return this;
	}

	company(project_id) {
		this.relationship = `${project_id}/companies`;

		return this;
	}

	defaultExpenses(project_id) {
		this.relationship = `${project_id}/expenses`;

		return this;
	}

	/**
	 *
	 * CUSTOM METHODS THAT NOT DESERVES A SERVICE LAYER
	 *
	 */

	expense(project_id, fund_id) {
		return this.get(`${project_id}/funds/${fund_id}/expenses`);
	}
}
