<template>
  <v-card style="padding: 20px; min-width: 220px">
    <p style="color: #5b4b4b; font-size: 25px !important">
      {{ item.ask | toCurrency }}
      <span style="font-size: 20px !important" class="float-right">
        {{ type(item.code) }}
      </span>
    </p>
    <span style="margin-top: -15px" class="float-right">{{
      formatDate()
    }}</span>
  </v-card>
</template>
<script>
import Moment from 'moment';

export default {
	name: 'CurrencysCard',

	props: {
		item: [Object],
	},

	methods: {
		formatDate(date = new Date()) {
			return Moment(date).format('DD/MM');
		},

		type(string) {
			switch (string) {
			case 'USD':
				return 'Dólar';
			case 'EUR':
				return 'Euro';
			default:
				return '';
			}
		},
	},
};
</script>
