import { render, staticRenderFns } from "./DrawerSubmenu.vue?vue&type=template&id=15743ba4&"
import script from "./DrawerSubmenu.vue?vue&type=script&lang=js&"
export * from "./DrawerSubmenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports