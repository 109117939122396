<template>
  <page-container no-column>
    <v-col cols="12" lg="12" sm="12" md="12">
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="12" xs="12">
          <v-text-field label="Nome da empresa" v-model="company.name" :rules="[...rules.required]" />
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="12" xs="12">
          <v-text-field
            label="CNPJ"
            v-model="company.cnpj"
            v-mask="$cnpjMask"
						:rules="[...rules.required]"
          />
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="12" xs="12">
          <v-text-field
            label="Telefone"
            v-model="company.telephone"
            v-mask="$phoneMask"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" lg="12" sm="12" md="12">
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="12" xs="12">
          <v-text-field label="Rua" v-model="company.street" />
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="12" xs="12">
          <v-text-field label="Bairro" v-model="company.district" />
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="12" xs="12">
          <v-text-field label="Cidade" v-model="company.city" />
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="12" xs="12">
          <v-text-field
            label="Agência"
            v-model="company.agency"
            :rules="[...rules.email]"
          />
        </v-col>
      </v-row>
    </v-col>
  </page-container>
</template>
<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';

export default {
	name: 'CompanySaveMainForm',

	components: {
		PageContainer,
	},

	props: {
		saving: [Boolean],
		oldCompany: [Object],
	},
	watch: {
		saving: {
			deep: true,
			handler(value) {
				if (value) {
					this.save();
				}
			},
		},
		oldCompany: {
			deep: true,
			handler(value) {
				this.company = value;
			},
		},
	},
	data() {
		return {
			company: {
				name: '',
				cnpj: '',
				telephone: '',
				street: '',
				district: '',
				city: '',
				agency: '',
			},
			cnpj: '##.###.###/####-##',
			mask: { cnpj: '##.###.###/####-##' },

			rules: {
				email: [(v) => !v || /.+@.+\..+/.test(v) || 'Email inválido.'],
				required: (v) => (v !== 'undefined' && !!v) || 'Campo Necessário',
			},
		};
	},

	mounted() {},
	methods: {
		async save() {
			this.$emit('save', this.company);
		},
	},
};
</script>
