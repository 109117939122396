<template>
  <v-card color="#F3F3F3" min-height="300" class="elevation-0">
    <v-card-title style="color: #630517">Cotações</v-card-title>
    <v-card-content v-if="!loading">
      <v-row align="start" justify="space-around" class="pa-1">
        <v-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
          xs="12"
          class="pa-2"
          v-for="(item, index) in currencys"
          :key="index"
        >
          <currency-card :item="item" />
        </v-col>
        <v-col xl="4" lg="4"  md="12" sm="12" xs="12" class="pa-2">
          <v-card style="padding: 20px; min-width: 220px">
            <p style="color: #5b4b4b; font-size: 25px !important">
              {{ taxes.imgp[0].valor }}%
              <span style="font-size: 20px !important; margin-bottom: 10px" class="float-right">
                IGPM
              </span>
            </p>
            <span class="float-right margin-fixed">{{
              formatDate(taxes.imgp[0].data)
            }}</span>
          </v-card>
        </v-col>
        <v-col xl="4" lg="4" md="12" sm="12" xs="12" class="pa-2">
          <v-card style="padding: 20px; min-width: 220px">
            <p style="color: #5b4b4b; font-size: 25px !important">
              {{ taxes.ipca[0].valor }}%
              <span style="font-size: 20px !important" class="float-right">
                IPCA
              </span>
            </p>
            <span class="float-right margin-fixed">{{
              formatDate(taxes.ipca[0].data)
            }}</span>
          </v-card>
        </v-col>

        <v-col xl="4" lg="4" md="12" sm="12" xs="12" class="pa-2">
          <v-card style="padding: 20px; min-width: 220px">
            <p style="color: #5b4b4b; font-size: 25px !important">

              {{ taxes.selic[0].valor }}%
              <span style="font-size: 20px !important" class="float-right">
                SELIC
              </span>
            </p>
            <span class="float-right margin-fixed">{{
            formatDate(taxes.selic[0].data)
            }}</span>
          </v-card>
        </v-col>
        <v-col xl="4" lg="4" md="12" sm="12" xs="12" class="pa-2">
          <v-card style="padding: 20px; min-width: 220px">
            <p style="color: #5b4b4b; font-size: 25px !important">
              {{ taxes.inflation.toFixed(2) }}%
              <span style="font-size: 20px !important" class="float-right">
                Inflação
              </span>
            </p>
            <span class="float-right margin-fixed">12 Meses</span>
          </v-card>
        </v-col>
      </v-row>
    </v-card-content>
  <div class="text-center" v-else>
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>

    </div>
  </v-card>
</template>
<script>
import Moment from 'moment';
import CurrencyCard from '../Currencys/CurrencyCard.vue';

import MarketDataService from './MarketDataService';

export default {
	name: 'MarketData',

	created() {
		this.currency();
	},
	components: {
		CurrencyCard,
	},
	data() {
		return {
			currencys: {},

			loading: false,

			taxes: {
				imgp: {},
				ipca: {},
				IPCA: {},
				SELIC: {},
				inflation: {},
			},
		};
	},
	methods: {
		async currency() {
			try {
				this.loading = true;
				const service = new MarketDataService();

				const [currencys, IGMP, IPCA, SELIC, inflation] = await service.request();
				const calculatedInflation = MarketDataService.calculateInflation(inflation);
				this.currencys = Object.values(currencys);
				this.taxes = {
					imgp: IGMP,
					ipca: IPCA,
					selic: SELIC,
					inflation: calculatedInflation,
				};
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		},

		formatDate(date = new Date()) {
			Moment.locale('pt-br');
			return Moment(date, 'DD/MM/YYYY').format('MMMM/YY');
		},

		type(string) {
			switch (string) {
			case 'USD':
				return 'Dólar EUA';
			case 'EUR':
				return 'Euro';
			default:
				return '';
			}
		},
		validateTaxes() {
			return (
				Object.keys(this.imgp).length > 0 && Object.keys(this.ipca).length > 0
			);
		},
	},
};
</script>
<style scoped>
.margin-fixed {
  margin-top: -15px
}
</style>
