<template>
		<div>
				<modal-layout
						v-model="dialog"
						title="Duplicar CNAB"
				>
						<template v-slot:content>
								<v-col cols="12">
										<v-radio-group
												v-model="duplicateType"
												:mandatory="false"
										>
												<v-radio label="Para o mesmo Fundo" value="same_fund"/>
												<v-radio label="Escolher outro" value="other_fund"/>
										</v-radio-group>
								</v-col>

								<v-col v-if="haveToDuplicateToAnotherFund()">
										<fund-select
											v-model="fundId"
										/>
								</v-col>
						</template>

						<template v-slot:actions>
							<v-col cols="12" class="d-flex">
								<v-btn
									color="primary"
									text
									@click="dialog = false"
								>
									Fechar
								</v-btn>

								<v-spacer/>
								<v-btn
									depressed
									color="success"
									:loading="isDuplicating"
									:disabled="!fundId && duplicateType == 'other_fund'"
									@click="duplicate"
								>
									Salvar
								</v-btn>
							</v-col>
						</template>
				</modal-layout>
		</div>
</template>

<script>
import ModalLayout from '@/components/defaults/layout/ModalLayout.vue';

import CnabService from '@/services/CnabService';

import FundSelect from '@/components/funds/FundSelect.vue';

export default {
	name: 'CnabDuplicateModal',
	components: {
		ModalLayout,
		FundSelect,
	},
	props: {
		value: Boolean,
		cnabId: Number,
	},
	data() {
		return {
			dialog: this.value,
			duplicateType: 'same_fund',
			isLoading: false,
			isDuplicating: false,
			fundId: null,
		};
	},
	watch: {
		duplicateType() {
			this.fundId = null;
		},
		value() {
			if (this.dialog == this.value) return;

			this.dialog = this.value;
		},
		dialog() {
			this.$emit('input', this.dialog);
		},
	},
	methods: {
		async duplicate() {
			try {
				if (this.haveToDuplicateToAnotherFund() && !this.fundIsDefined()) {
					this.$toasted.info('informe o grupo de empresa para continuar', { icon: 'mdi-information' });
					return;
				}

				this.isDuplicating = true;

				const cnabService = new CnabService();

				await cnabService.duplicate({
					duplicate_type: this.duplicateType,
					fund_id: this.fundId,
					cnab_id: this.cnabId,
				});

				this.$emit('duplicated');

				this.dialog = false;
				this.isDuplicating = true;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isDuplicating = false;
			}
		},
		haveToDuplicateToAnotherFund() {
			const result = (this.duplicateType == 'other_fund');

			if (!result) return result;

			return result;
		},
		fundIsDefined() {
			return this.fundId;
		},
	},
};
</script>

<style>

</style>
