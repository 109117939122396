<template>
	<v-select
		label="Operação"
		v-model="model"
		:items="serializedItems"
		:loading="isLoading"
		item-text="name"
		item-value="id"
		:return-object="returnObject"
		:rules="rules"
	/>
</template>

<script>
import OperationService from '@/services/OperationService';

export default {
	name: 'OperationTypeSelect',
	props: {
		value: [Object, Number],
		returnObject: Boolean,
		rules: Array,
		specificItems: Array,
	},
	data() {
		return {
			model: this.value,
			items: [],
			isLoading: false,
		};
	},
	computed: {
		serializedItems() {
			if (this.specificItems && this.specificItems.length) {
				return this.specificItems;
			}

			return this.items;
		},
	},
	watch: {
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
		model() {
			this.$emit('input', this.model);
		},
	},
	mounted() {
		this.loadOperations();
	},
	methods: {
		async loadOperations() {
			try {
				this.isLoading = true;

				const operationService = new OperationService();

				const response = await operationService.index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style>

</style>
