<template>
  <v-data-table
    :headers="news.headers"
    :items="news.itens"
    :search="search"
    :loading="isLoading"
    :sort-by="['id']"
  >
    <template v-slot:[`item.start_date`]="{ item }">
      {{ convertToDate(item.start_date) }}
    </template>

    <template v-slot:[`item.end_date`]="{ item }">
      {{ convertToDate(item.end_date) }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <news-table-options
        :item="item"
        :user="user"
        @reload="getNews()"
        @edit="editData"
      />
    </template>
  </v-data-table>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import NewsService from '@/services/NewsService';

import TableTools from '@/utils/TableTools';
import NewsTableOptions from './NewsTableOptions.vue';

export default {
	name: 'NewsTableList',
	mixins: [TableTools],
	data: () => ({
		calculated: 0,

		news: {
			itens: [],
			headers: [
				{
					text: '#',
					value: 'id',
					sortable: true,
					permission: false,
				},
				{
					text: 'Usuário',
					value: 'creator.name',
					sortable: true,
					permission: true,
				},
				{
					text: 'Título',
					value: 'title',
					sortable: true,
					permission: false,
				},

				{
					text: 'Data de início',
					value: 'start_date',
					sortable: true,
					permission: false,
				},
				{
					text: 'Data final',
					value: 'end_date',
					sortable: true,
					permission: false,
				},

				{ text: '', value: 'actions', sortable: false },
			],
		},
		user: {},
		isLoading: false,
	}),
	components: {
		NewsTableOptions,
	},
	props: {
		search: String,
	},

	created() {
		this.getLoggedUser();
		this.getNews();
	},
	methods: {
		async getNews() {
			try {
				this.isLoading = true;

				const newsService = new NewsService();

				const params = {
					type: 'all',
					limit: null,
				};

				const response = await newsService.index({ params });

				this.news.itens = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isLoading = false;
			}
		},

		convertToDate(field) {
			return this.$helpers.mysqlTimestampToBr(field);
		},

		getLoggedUser() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);
			this.user = tokenData;
		},
		editData(item) {
			this.$emit('edit', item);
		},
	},
};
</script>
