import standard from '../../config/standard';
import Service from './Service';

/**
 * @typedef {Http}
 */
export default class Http extends Service {
	/**
	 * @typedef { String }
	 */
	relationship = '';

	/**
	 * @param {String} path
	 * @param {Object} options
	 * @param {Object} http
	 */
	constructor(path, options = {}, http = null) {
		super(options);

		this.path = path;
		this.options = options;
		this.http = http || standard;
	}

	/**
	 * PrepareRoute
	 * funcao utilizada para definir a rota necessaria, sempre. até quando a relação for iniciada
	 *
	 * @param {String} end
	 */
	prepareRoute(end) {
		const url = `${this.path}/${this.relationship}/${end}`;

		const routeToSend = `${url}`.replace(/([^:]\/)\/+/g, '$1');

		this.cleanRelationship();

		return routeToSend;
	}

	/**
	 * CleanRelationship
	 * essa funcao deve ser sempre chamada para nao persistencia de dados na classe http
	 */
	cleanRelationship() {
		this.relationship = '';
	}

	/**
	 * @param {String} url
	 * @returns {*|Promise<any>}
	 */
	get(url, filter = null) {
		return this.http
			.get(this.prepareRoute(url), filter)
			.then(this.constructor.then);
	}

	/**
	 * @param {String} url
	 * @param {Object} data
	 * @returns {*|Promise<any>}
	 */
	post(url, data) {
		return this.http
			.post(this.prepareRoute(url), data)
			.then(this.constructor.then);
	}

	raw(url, filter) {
		return this
			.http({ url, ...filter })
			.then(this.constructor.then);
	}

	/**
	 * @param {String} url
	 * @param {Object} data
	 * @returns {*|Promise<any>}
	 */
	put(url, data) {
		return this.http
			.put(this.prepareRoute(url), data)
			.then(this.constructor.then);
	}

	/**
	 * @param {String} url
	 * @param {Object} data
	 * @returns {*|Promise<any>}
	 */
	patch(url, data) {
		return this.http
			.patch(this.prepareRoute(url), data)
			.then(this.constructor.then);
	}

	/**
	 * @param {String} url
	 * @returns {*|Promise<any>}
	 */
	delete(url, options = null) {
		return this.http
			.delete(this.prepareRoute(url), options)
			.then(this.constructor.then);
	}

	/**
	 * @param {Object} response
	 * @returns {Object}
	 */
	static then(response) {
		if (!response.data) {
			return {};
		}

		if (typeof response.data === 'string') {
			try {
				return JSON.parse(response.data);
			} catch (error) {
				return response.data;
			}
		}
		return response.data;
	}

	/**
	 * @param {String} start
	 * @param {String} end
	 * @returns {String}
	 */
	static normalize(start, end) {
		return `${start}/${end}`.replace(/([^:]\/)\/+/g, '$1');
	}
}
