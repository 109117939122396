<template>
	<page-container no-column>
		<v-col xl="6" lg="6" md="6" sm="12" xs="12">
			<v-text-field
				label="Número da Nota Fiscal"
				v-model="filters.number"
				placeholder=""
				clearable
				@focus="onFocus(true)"
				@blur="onFocus(false)"

			/>
		</v-col>

		<v-col xl="6" lg="6" md="6" sm="12" xs="12">
			<v-currency-field
				label="Valor"
				v-model="filters.net_value"
				prefix="R$"
				clearable
				@focus="onFocus(true)"
				@blur="onFocus(false)"

			/>
		</v-col>

		<v-col xl="6" lg="6" md="6" sm="12" xs="12">
			<date-picker
				label="Data de Vencimento"
				v-model="filters.due_date"
				placeholder=""
				clearable
				@focus="onFocus(true)"
				@blur="onFocus(false)"

			/>
		</v-col>

		<v-col cols="12">
			<v-divider/>
		</v-col>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import DatePicker from '@/components/defaults/DatePicker.vue';

export default {
	name: 'ConciliationDialogFilters',
	components: {
		PageContainer,
		DatePicker,
	},
	props: {
		value: [Object, String],
	},
	data() {
		return {
			filters: {},
			clearTime: 0,
			debounceFilterUpdate: this.$helpers.registerDebounce(this.input, 1200, this.clearTime),
		};
	},
	watch: {
		value() {
			if (this.filters == this.value) {
				return;
			}

			this.filters = this.value;
		},
		filters() {
			this.debounceFilterUpdate();
		},
	},
	methods: {
		input() {
			this.$emit('input', this.filters);
		},
		onFocus(value) {
			this.$emit('onFocus', value);
		},
	},
};
</script>

<style>

</style>
