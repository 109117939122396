<template>
	<v-container fluid>
		<v-row @click="$emit('click')">
			<operation-save-expenses-direct
				:disabled="disabled"
			/>

			<operation-save-expenses-indirect
				:disabled="disabled"
			/>

			<operation-resume
				:disabled="disabled"
			/>
		</v-row>
	</v-container>
</template>

<script>
import { bus } from '@/main';

import { actions } from '../state/operation.state';

import OperationSaveExpensesIndirect from './expenses/OperationSaveExpensesIndirect.vue';
import OperationSaveExpensesDirect from './expenses/OperationSaveExpensesDirect.vue';
import OperationResume from './expenses/OperationResume.vue';

import operationHandler from '../state/operation.handler';

export default {
	name: 'OperationSaveExpensesForm',
	mixins: [operationHandler],
	props: {
		disabled: Boolean,
	},
	components: {
		OperationSaveExpensesIndirect,
		OperationSaveExpensesDirect,
		OperationResume,
	},
	mounted() {
		actions.calculate.calculateExpenses();

		bus.$emit('updateAllCalculatedField');
		bus.$emit('updateNetReceivedValue');

		this.$emit('focus');
	},
	methods: {
	},
};
</script>

<style>

</style>
