import Vue from 'vue';

import helpers from '@/plugins/helpers/helpers';

import VueMask from 'v-mask';
import App from './App.vue';

/**
 * PLUGINS
 */
import router from './router';
import vuetify from './plugins/vuetify';
import swal from './plugins/swal';
import snackbar from './plugins/snackbar';
import vCurrencyField from './plugins/v_currency_field';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

/**
 * instancia pura do vue para manipulacao de eventos
 */

export const bus = new Vue();

/**
 * Definicao do aplicativo
 */
Vue.use(VueMask);

Vue.use(helpers);
Vue.use(snackbar, {
	bus,
});

Vue.config.productionTip = false;

const vue = new Vue({
	swal,
	router,
	vuetify,
	vCurrencyField,
	render: (h) => h(App),
}).$mount('#app');

export default vue;
