var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      (['C', 'CG'].includes(_vm.item.type) && this.user.user_type.name === 'admin') ||
      (['approve', 'disapprove'].includes(_vm.item.status.trim()) && this.user.user_type.name === 'admin') ||
      (!_vm.item.status.trim() && !['C', 'CG'].includes(_vm.item.type))
    )?_c('list-edit-button',{on:{"edit":_vm.edit}}):_vm._e(),(
				this.user.user_type.name === 'admin' &&
        _vm.item.type === 'S' &&
        _vm.item.status.trim() !== 'approve' &&
        _vm.item.status.trim() !== 'disapprove'
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2 cursor-pointer",on:{"click":function($event){return _vm.onClick(_vm.item, _vm.user.user, 'approve')}}},on),[_vm._v(" mdi-check-all ")])]}}],null,false,145316545)},[_c('span',[_vm._v("Aprovar pedido")])]):_vm._e(),(
				this.user.user_type.name === 'admin' &&
        _vm.item.type === 'S' &&
        _vm.item.status.trim() !== 'approve' &&
        _vm.item.status.trim() !== 'disapprove'
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"cursor-pointer mr-2",on:{"click":function($event){return _vm.onClick(_vm.item, _vm.user.user, 'disapprove')}}},on),[_vm._v(" mdi-block-helper ")])]}}],null,false,2061511927)},[_c('span',[_vm._v("Reprovar pedido")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.show(_vm.item)}}},on),[_vm._v(" mdi-eye ")])]}}])},[_c('span',[_vm._v("Visualizar")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }