<template>
	<v-btn
		@click="emitClick"
		text
		class="text--secondary w-100 flex-item mx-auto"
		:disabled="!valid"
	>
		{{ changeTextOfButton() }}

		<v-icon class="mx-2">
			{{ changeIconOfButton() }}
		</v-icon>
	</v-btn>
</template>

<script>
export default {
	name: 'RelatedListAddButton',
	props: {
		clicked: String,
		valid: [Boolean, String],
	},
	methods: {
		emitClick() {
			this.$emit('click');
		},
		changeIconOfButton() {
			const result = this.clicked == 'save' ? 'mdi-content-save' : 'mdi-plus';
			return result;
		},
		changeTextOfButton() {
			const result = this.clicked == 'save' ? 'Salvar' : 'Adicionar';
			return result;
		},
	},
};
</script>

<style>

</style>
