<template>
	<operation-expense-field
		v-model="model"
		:field="field"
		:label="label"
		:disabled="disabled"
		@click:append="openConciliationDialog"
		:ref="ref"
		:conciliation="conciliation"
		:noValidation="noValidation"
		:no-suffix="noSuffix"
		:readonly="readonly"
	/>
</template>

<script>
import { bus } from '@/main';

import OperationExpenseField from './OperationExpenseField.vue';

export default {
	name: 'ConciliationField',
	components: { OperationExpenseField },
	props: {
		status: String,
		value: [Number, String, Object],
		label: String,
		disabled: Boolean,
		calculated: Boolean,
		field: String,
		noValidation: Boolean,
		readonly: Boolean,
		noSuffix: Boolean,
		conciliation: { type: Boolean, require: false, default: true },
	},
	data() {
		return {
			model: this.value,
			ref: `operation_${this.field}`,
		};
	},
	watch: {
		value() {
			if (this.model == this.value) {
				return;
			}

			this.model = this.value;
		},
		model() {
			this.$emit('input', this.model);
			this.$emit('change', this.model);
		},
	},
	computed: {
		conciliateRule() {
			if (!this.conciliated || !this.conciliated.length) {
				return 'Dados não conciliados';
			}

			return true;
		},
	},
	mounted() {
		bus.$on('updateConciliationFields', () => {
			if (this.ref) {
				this.updateWhenConciliationField();
			}
		});
	},
	methods: {
		updateWhenConciliationField() {
			try {
				this.$refs[this.ref]
					.updateWhenConciliationField();
			} catch (err) {
				console.log(err);
			}
		},
		openConciliationDialog() {
			bus.$emit('openConciliationDialog', this);
		},

	},
};
</script>

<style>

</style>
