<template>
	<div class="w-100">
		<input
			ref="fileInput"
			v-show="false"
			:value="selectedFile"
			:accept="acceptFormats"
			@change="onImageChange"
			type="file"
		/>

		<v-col cols="12" class="mx-n3">
			<v-hover v-slot="{ hover }">
				<div
					@click="uploadArchive"
					:class="hover ? 'bg-light-2' : 'bg-light-1'"
					class="
						text-center
						d-flex
						flex-column
						pa-12
						w-100
						cursor-pointer
						rounded
					"
				>
					<span
						class="
							flex-item
							text-subtitle
							text--secondary
							font-weight-bold
							my-4
						"
					>
						Clique aqui para adicionar uma imagem
					</span>

					<v-icon
						class="flex-item mx-auto"
						x-large
					>
						mdi-paperclip
					</v-icon>
				</div>
			</v-hover>
		</v-col>
	</div>
</template>

<script>
export default {
	name: 'ImageUploadButton',
	components: {
	},
	props: {
		value: [Object, Array, File],
	},
	data() {
		return {
			acceptFormats: 'image/png, image/jpeg',
			selectedFile: this.value,
		};
	},
	methods: {
		uploadArchive() {
			this.$refs.fileInput.click();
		},
		onImageChange(e) {
			const [file] = e.target.files;
			this.selectedFile = file;

			this.$emit('input', this.selectedFile);
		},
	},
};
</script>

<style>

</style>
