<template>
	<div class="w-100">
		<div class="text-center">
			<div class="overline mx-auto" id="original">
				<div class="image-container w-100 elevation-0">
					<img
						ref="source"
						class="image-preview"
						:src="objectUrl | getWithToken"
					/>
				</div>

				<div
					class="d-flex justify-center mx-auto"
				>
					<v-btn
						class="flex-item w-100"
						depressed
						text
						@click="doneCrop"
					>
						<v-icon> mdi-check </v-icon>

						Salvar
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default {
	name: 'ImageCropper',
	components: {
	},
	props: {
		image: [File, Object, String],
		aspectRatio: Number,
		smH: String,
		smW: String,
		mdH: String,
		mdW: String,
		cropType: Number,
	},
	data() {
		return {
			cropper: null,
			objectUrl: null,
			previewCropped: null,
			debounceUpdatePreview: this.$helpers.registerDebounce(this.updatePreview, 257),
		};
	},
	mounted() {
		this.setupCropper();
	},
	methods: {
		setupCropper() {
			if (this.cropper) {
				this.cropper.destroy();
			}

			if (this.objectUrl) {
				window.URL.revokeObjectURL(this.objectUrl);
			}

			if (!this.image) {
				this.cropper = null;
				this.objectUrl = null;
				this.previewCropped = null;
				return;
			}

			this.objectUrl = window.URL.createObjectURL(this.image);
			this.$nextTick(this.setupCropperInstance);
		},
		setupCropperInstance() {
			const cropType = this.cropType != 'undefined' ? this.cropType : this.$cropTypes.fitContainer;

			this.cropper = new Cropper(this.$refs.source, {
				aspectRatio: this.aspectRatio,
				crop: this.debounceUpdatePreview,
				guides: false,
				center: true,
				highlight: true,
				minContainerHeight: `${this.getHeight()}px`,
				minContainerWidth: `${this.getWidth()}px`,
				dragMode: 'move',
				autoCrop: true,
				responsive: true,
				viewMode: cropType,
			});
		},
		getHeight() {
			const isSmOrLess = this.$vuetify.breakpoint.smAndDown;

			if (isSmOrLess) {
				return this.smH;
			}

			return this.mdH;
		},
		getWidth() {
			const isSmOrLess = this.$vuetify.breakpoint.smAndDown;

			if (isSmOrLess) {
				return this.smW;
			}

			return this.mdW;
		},
		updatePreview() {
			const canvas = this.cropper.getCroppedCanvas();
			this.previewCropped = canvas.toDataURL('image/png');
		},
		doneCrop() {
			this.$emit('input', {
				object: this.objectUrl,
				preview: this.previewCropped,
			});
		},
	},
};
</script>

<style lang="sass" scoped>
.image-container
	display: inline-block
	max-height: 200px
	max-width: 800px

.image-preview
	display: block
	max-width: 100%
</style>
