<template>
	<page-container>
		<breadcrumbs :page-title="getRouteTitle()"/>

		<v-form class="w-100" v-model="valid" ref="form">
			<form-structure :loading="loadingData">
				<template v-slot:content>
					<div class="mt-n7 d-flex flex-column w-100">
					<v-tabs v-model="activeTab">
							<v-tab href="#user">
								Informações do Usuário
							</v-tab>

							<v-tab href="#content">
								Adicionais
							</v-tab>

							<v-tab href="#projects">
								Projetos
							</v-tab>
						</v-tabs>

						<v-tabs-items v-model="activeTab">
							<v-tab-item value="user">
								<user-save-form
									v-model="user"
									@revalidate="revalidate"
								/>
							</v-tab-item>
							<v-tab-item value="content">
								<user-profile-content-form
									:user="user"
									v-model="user.profile"
									ref="profile_content_form"
								/>
							</v-tab-item>

							<v-tab-item value="projects">
								<user-project-save-form
									v-model="user.projects"
								/>
							</v-tab-item>

						</v-tabs-items>
					</div>
				</template>

				<template v-slot:actions>
					<form-cancel-button/>

					<form-save-button
						:valid="true"
						@click="save"
						:loading="loadingSubmit"
						:disabled="loadingData"
					/>

					<form-inactivate-button
						v-if="!isANewUser()"
						:loading="loadingRemove"
						:disabled="loadingData"
						@remove="remove"
					/>
				</template>
			</form-structure>
		</v-form>
	</page-container>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import FormInactivateButton from '@/components/defaults/buttons/FormInactivateButton.vue';
import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';

import UserService from '@/services/UserService';
import UserSaveForm from './UserSaveForm.vue';
import UserProjectSaveForm from './UserProjectSaveForm.vue';
import UserProfileContentForm from '../profile/UserProfileContentForm.vue';

export default {
	name: 'UserSave',
	components: {
		FormInactivateButton,
		FormSaveButton,
		Breadcrumbs,
		FormStructure,
		PageContainer,
		UserSaveForm,
		UserProjectSaveForm,
		FormCancelButton,
		UserProfileContentForm,
	},
	data() {
		return {
			user: {},
			activeTab: '',
			loadingData: false,
			loadingSubmit: false,
			loadingRemove: false,
			valid: false,
		};
	},
	created() {
		this.loadingUserByRoute();
	},
	methods: {
		/**
     * UX
     */
		getRouteTitle() {
			if (this.isANewUser()) return 'Criação de Usuário';

			return 'Edição de Usuário';
		},

		serializeUserToSave() {
			const { user } = this;

			if (!user || !user.projects) return user;

			user.projects = user.projects.map((project) => {
				project.functionalities = project.functionalities.filter(
					(func) => func.user_allowed,
				);

				return project;
			});

			return user;
		},

		/**
     * CRUD functions
     */
		async save() {
			try {
				const canSave = this.$refs.form.validate();

				if (!canSave) return;

				this.loadingSubmit = true;

				const toSave = this.serializeUserToSave();

				const userService = new UserService();

				if (this.isANewUser()) {
					await userService.store(toSave);

					this.showSaveSuccessDialog();
				} else {
					await userService.update(toSave);
				}

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingSubmit = false;
			}
		},

		showSaveSuccessDialog() {
			this.$swal({
				text: `Um Email foi enviado para ${this.user.email} para prosseguir com o confirmação e cadastro de senha`,
				title: 'Usuário Cadastrado',
				icon: 'success',
				showConfirmButton: true,
			});
		},

		async remove() {
			try {
				if (this.isANewUser()) return;

				this.loadingRemove = true;

				const userService = new UserService();

				await userService.destroy(this.user);

				this.user = {};

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadinRemove = false;
			}
		},

		async loadingUserByRoute() {
			try {
				const userId = this.getUserIdOfRoute();

				if (this.isANewUser()) return;

				this.loadingData = true;

				const userService = new UserService();

				const response = await userService.show(userId);

				this.user = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
				this.user = {};
			} finally {
				this.loadingData = false;
				this.loadProfile();
				this.serializeUserBeforeLoad();
			}
		},

		loadProfile() {
			if (!this.user.profile) {
				this.user.profile = {};
			}
			this.user.profile.user_id = this.user.id;
			this.user.profile.business_email = this.user.email;
			this.user.profile.business_phone = this.user.phone;
			this.user.profile.vacation_days = this.user.vacation_days;

			// this.loadImages();

			// if (!this.$refs || !this.$refs.profile_content_form) return;

			// this.$refs.profile_content_form.loadFile();
		},

		serializeUserBeforeLoad() {
			const { user } = this;

			if (!user || !user.projects) return;

			user.projects = user.projects.map((project) => {
				project.functionalities = project.functionalities.map((func) => {
					func.user_allowed = true;

					return func;
				});

				return project;
			});

			this.user = user;
		},
		/**
     * Helper functions
     */
		userIdIsNewOrNotExists(userId) {
			return !userId || userId == 'new';
		},
		getUserIdOfRoute() {
			const userId = this.$route.params.id;
			return userId;
		},
		isANewUser() {
			if (this.user.id) return false;

			const userId = this.getUserIdOfRoute();

			return this.userIdIsNewOrNotExists(userId);
		},
		returnToPreviousRoute() {
			this.$router.push({ name: 'UsersList' });
		},
		revalidate() {
			this.$refs.form.validate();
		},
	},
};
</script>

<style>
</style>
