var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"w-100 mb-n3 mt-2",attrs:{"headers":_vm.headers,"items":_vm.value,"disable-sort":"","item-key":"id","group-key":"status"},scopedSlots:_vm._u([{key:"item.net_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.net_value))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDateToBr")(item.due_date))+" ")]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDateToBr")(item.issue_date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return _vm._l((item.status),function(status,index){return _c('v-chip',{key:index,staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.serializeStatusText(status.status))+" ")])})}}],null,true)}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"xl":"6","lg":"6","md":"6","sm":"6","xs":"12"}},[_c('h3',[_vm._v("Saldo total")])]),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"xl":"6","lg":"6","md":"6","sm":"6","xs":"12"}},[_c('h4',[_vm._v(_vm._s(("" + (_vm.calculate()))))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }