<template>
	<page-container>
		<breadcrumbs page-title="Tipos de Fundos"/>

		<list-structure>
			<template v-slot:search>
			</template>

			<template v-slot:actions>
				<list-add-button @add="add"/>
			</template>

			<template v-slot:table>
				<fund-type-list-table
					@edit="edit"
					ref="fundTypesTable"
				/>
			</template>
		</list-structure>
	</page-container>
</template>

<script>
// layout components
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';

import FundTypeListTable from './FundTypeListTable.vue';

export default {
	name: 'FundTypeList',
	components: {
		// layout components
		PageContainer,
		ListStructure,
		ListAddButton,
		Breadcrumbs,
		// system components
		FundTypeListTable,
	},
	methods: {
		/**
		 * ACTIONS
		 */
		add() {
			this.pushToFundTypesSaveRoute({ id: 'new' });
		},
		edit(id) {
			this.pushToFundTypesSaveRoute({ id });
		},
		/**
		 * HELPERS
		 */
		pushToFundTypesSaveRoute(params) {
			this.$router.push({ name: 'FundTypesSave', params });
		},
	},
};
</script>

<style>

</style>
