<template>
	<page-container>
		<breadcrumbs page-title="Operações" />

		<list-structure>
			<template v-slot:search>
			</template>

			<template v-slot:actions>
				<list-add-button @add="add"/>
			</template>

			<template v-slot:table>
				<operation-list-table
					@edit="edit"
					@remove="remove"
					:items="items"
					:loading="loadingData"
					ref="operationList"
				/>
			</template>
		</list-structure>
	</page-container>
</template>

<script>
// layout components
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';

import OperationService from '@/services/OperationService';
import OperationListTable from './OperationTypeListTable.vue';

export default {
	name: 'OperationTypeList',
	components: {
		Breadcrumbs,
		PageContainer,
		ListAddButton,
		ListStructure,
		OperationListTable,
	},
	data() {
		return {
			items: [],
			loadingData: false,
		};
	},
	created() {
		this.loadOperations();
	},
	methods: {
		/**
		 * CRUD
		 */
		async loadOperations() {
			try {
				this.loadingData = true;

				const operationService = new OperationService();

				const response = await operationService.index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},

		async remove(item) {
			try {
				this.loadingData = true;

				const operationService = new OperationService();

				await operationService.destroy(item);

				this.removeListItem(item);
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		/**
		 * ACTIONS
		 */
		add() {
			this.pushToOperationSaveRoute({ id: 'new' });
		},
		edit(item) {
			this.pushToOperationSaveRoute({ id: item.id });
		},
		pushToOperationSaveRoute(params) {
			this.$router.push({ name: 'OperationTypeSave', params });
		},
		/**
		 * HELPERS
		 */
		removeListItem(item) {
			this.items = this.items.filter((row) => row.id != item.id);
		},
	},
};
</script>

<style>

</style>
