<template>
	<v-card flat :loading="loading">
		<v-container fluid>
			<v-row :class="styling ? 'mt-4' : ''">
				<slot name="content"></slot>
			</v-row>
		</v-container>

		<v-divider/>

		<v-container fluid>
			<v-row>
				<v-col xl="12" lg="12" md="12" sm="12" xs="12">
					<slot name="actions"></slot>
				</v-col>
			</v-row>
		</v-container>

	</v-card>
</template>

<script>
export default {
	name: 'FormStructure',
	props: {
		loading: Boolean,
		styling: {
			require: false,
			default: true,
		},
	},
};
</script>

<style>

</style>
