<template>
	<div class="d-flex flex-column">
		<span class="text-caption text--secondary">
				<slot> {{ label }} </slot>
		</span>

		<v-divider class="my-2"/>
	</div>
</template>

<script>
export default {
	name: 'Separator',
	props: {
		label: String,
	},
};
</script>

<style>

</style>
