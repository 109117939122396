import moment from 'moment';
import jwt from 'jsonwebtoken';

const helperMethods = {
	$cnpjMask: '##.###.###/####-##',
	$phoneMask: '(##) # ####-####',
	$cepMask: '#####-###',
	$cpfMask: '###.###.###-##',
	$timeout: null,

	/**
	 * registerDebounce
	 * funcao de registerDebounce do javascript vanilla
	 *
	 * @param {closure} func
	 * @param {Number} wait
	 * @return {function}
	 */
	registerDebounce(func, wait = 100, timeoutVar = this.$timeout) {
		return (...args) => {
			clearTimeout(timeoutVar);

			timeoutVar = setTimeout(() => {
				func.apply(this, args);
			}, wait);
		};
	},

	/**
	 * getPropsByString
	 * retorna as propriedades de um objeto dinamicamente
	 * pode retornar dados relacionados atraves da adicao de um . na busca, ex:
	 * string.chave
	 * retorna o valor do objeto de obj[string][chave]
	 *
	 * @param {Object} obj
	 * @param {String} stringToFind
	 */
	getPropsByString(stringToFind, obj) {
		if (!stringToFind) return '';

		const nested = stringToFind.split('.');

		let variable = { ...obj };

		nested.forEach((splitted) => {
			const candidate = variable[splitted];

			if (candidate !== undefined) {
				variable = candidate;
			}
		});

		return variable;
	},
	/**
	 * jwt
	 *
	 * objeto class com funcoes auxiliares de JWT
	 */
	jwt: {

		/**
		 * decode JWT token to object
		 * @param {String} token
		 * @return {Object}
		 */
		decode(token) {
			return jwt.decode(token);
		},

		/**
		 * return the sessionLogged user
		 * @return {Object}
		 */
		getLoggedUser() {
			const jsonUser = sessionStorage.logged_user;

			const user = JSON.parse(jsonUser);

			return user;
		},

		/**
		 * verify if token has expired
		 * @param {Object} token
		 * @return {Boolean}
		 */
		hasExpired(token) {
			const timezoneExpirationTime = token.exp * 1000;
			const now = (Date.now());

			if (now >= timezoneExpirationTime) {
				return true;
			}

			return false;
		},
	},

	/**
	 * FUNCOES AUXILIARES
	 *
	 * like
	 * semelhante a funcao like do SQL
	 * compara registros que contem certo valor naquela string
	 *
	 * @param {String} compare
	 * @param {String} value
	 * @return {Boolean}
	 */
	like(compare, value) {
		const upperCasedCompare = `${compare}`.toLocaleUpperCase();
		const upperCasedValue = `${value}`.toLocaleUpperCase();

		const result = upperCasedCompare.indexOf(upperCasedValue) != -1;

		return result;
	},

	/**
	 * FILTROS DE SISTEMA
	 *
	 * utilizados para definir funcoes globais de facil uso no vue.
	 * os filtros ativam o sistema de pipes nos hooks de variaveis do vue
	 * exemplo de uso:
	 *
	 * {{ variavel | filtro }}
	 *
	 * num caso de aplicação
	 *
	 * {{ user.createdAt | formatMysqlDate }}
	 *
	 * formatMysqlDate
	 * transforma a data padrao do banco de dados para data padrao do brasil.
	 *
	 *
	 * @param {String} value
	 * @param {Object} opt - opcoes gerais, withHour define se a string ira ou nao retornar a hora
	 *
	 * @return {String}
	 */
	formatMysqlDate(value, opt = { withHour: false }) {
		const mysqlDateFormat = 'YYYY-MM-DD HH:mm:ss';

		const brDate = 'DD/MM/YYYY';
		const brHour = 'HH:mm';

		let brDateFormat = `${brDate}`;

		if (opt.withHour) {
			brDateFormat = `${brDateFormat} ${brHour}`;
		}

		const toFormatDate = moment(value, mysqlDateFormat);

		const formatedDate = toFormatDate.format(brDateFormat);

		return formatedDate;
	},

	/**
	 * @param {String} value
	 * @return {String}
	 */
	mysqlTimestampToBr(value) {
		if (!value) return '';

		const opt = { withHour: false };

		return this.formatMysqlDate(value, opt);
	},

	/**
	 * @param {String} value
	 * @return {String}
	 */
	mysqlDateToBr(value) {
		const opt = { withHour: false };

		return helperMethods.mysqlTimestampToBr(value, opt);
	},

	/**
	 * maskCNPJ
	 * aplica uma mascara de CNPJ
	 *
	 * @param {String} cnpj
	 * @return {String}
	 */
	maskCNPJ(cnpj) {
		const notLetterOrNumber = /([^\w\d])+/g;
		const text = (`${cnpj}`).replace(notLetterOrNumber, '');
		const cnpjMask = this.$cnpjMask;

		const identifier = this.maskText(text, cnpjMask);

		return identifier;
	},

	/**
	 * MaskText
	 *
	 * Codigo legado de mascara de texto. pode ser usado com qualquer mascara nao substituindo os caracteres:
	 * ()/-. e espaco
	 *
	 * @param {String} text
	 * @param {String} mask - aceita apenas # para substituicao
	 *
	 * @return {String}
	 */
	maskText: (text, mask) => {
		if (text === undefined) {
			return text;
		}

		let aux;

		let pos = 0;
		let newValue = '';
		let maskLength = text.length;

		for (let i = 0; i <= maskLength; i += 1) {
			aux = ((mask.charAt(i) === '-') || (mask.charAt(i) === '.') || (mask.charAt(i) === '/'));
			aux = aux || ((mask.charAt(i) === '(') || (mask.charAt(i) === ')') || (mask.charAt(i) === ' '));

			if (aux) {
				newValue += mask.charAt(i);
				maskLength += 1;
			} else {
				newValue += text.charAt(pos);
				pos += 1;
			}
		}

		return newValue;
	},

	getUrlWithToken(imageUrl) {
		if (!imageUrl
			|| helperMethods.isBlob(imageUrl)
			|| helperMethods.isBase64(imageUrl)
		) return imageUrl;

		const loggedUser = helperMethods.jwt.getLoggedUser();

		const token = loggedUser.access_token;

		return `${imageUrl}?token=${token}`;
	},

	isBlob(imageUrl) {
		return (imageUrl.indexOf('blob:') == 0);
	},

	isBase64(imageUrl) {
		if (imageUrl.indexOf('data:') == -1) return false;

		if (imageUrl.indexOf(';base64') == -1) return false;

		return true;
	},

	/**
	 * groupBy
	 * Pega um Array<V> e agrupa pela funcao,
	 * e retorna um map com o array agrupado pela funcao.
	 *
	 * @references: https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
	 *
	 * @param {Array} list - Array utilizado na listagem.
	 * @param {Strin} keyGetter - A funcao que pega o array e retorna o valor do seu tipo.
	 *                  O tipo geralmente e uma propriedade de um dos items do array.
	 *
	 * @returns {Array} Mapa com grupos serializados
	 */
	groupBy(list, keyGetter) {
		const map = new Map();

		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);

			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});

		const mapInArray = [...map];

		const array = mapInArray.map(([key, values]) => ({ key, values }));
		return array;
	},

	/**
	 * Object Array where
	 * @param {String} key - object key to compare
	 * @param {String} value - value
	 * @param {Array} array
	 */
	find(key, value, array) {
		const found = array.find((item) => item[key] == value);

		return found;
	},

	/**
	 * Object Array where
	 * @param {String} key - object key to compare
	 * @param {String} value - value
	 * @param {Array} array
	 */
	where(key, value, array) {
		const found = array.filter((item) => item[key] == value);

		return found;
	},

	/**
	 * Object Array where
	 * @param {String} key - object key to compare
	 * @param {Array} value - value
	 * @param {Array} array
	 */
	whereIn(key, value, array) {
		const found = array.filter((item) => value.includes(`${item[key]}`));

		return found;
	},

	/**
	 * return only the key that you lookin for
	 * @param {*} key
	 * @param {*} array
	 */
	pluck(key, array) {
		const plucked = array.map((item) => item[key]);

		return plucked;
	},

	/**
	 * return the attributes passing as array for that
	 * if find they, then enable a execution of a closure
	 * @param {Array} keys
	 * @param {Object} object
	 * @param {Function} closure
	 */
	mapByKey(keys, object, closure = (value) => value) {
		const serializedObject = {};

		keys.forEach((key) => {
			if (object[key] !== 'undefined') {
				serializedObject[key] = closure(object[key]);
			}
		});

		return serializedObject;
	},

	/**
	 * Transforma um valor numerico em um valor de String formatado para string
	 *
	 * @param {Number} value
	 * @param {Object} config - valores adicionais de configuracoes que podem ser usados com a funcao toCurrency
	 *
	 * @returns {String}
	 * @throws {Error}
	 */
	toCurrency: (value, config = {}) => {
		const toCurrency = Number(value);

		if (Number.isNaN(toCurrency)) {
			return value;
		}

		const defaultConfigs = {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 2,
		};

		// eslint-disable-next-line
		var formatter = new Intl.NumberFormat([], {...defaultConfigs, ...config});
		return formatter.format(toCurrency);
	},

	// https://helloacm.com/javascripts-tofixed-implementation-without-rounding/
	accurateToFixed: (number, n) => {
		try {
			const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, 'g');
			const a = number.toString().match(reg)[0];
			const dot = a.indexOf('.');

			if (dot === -1) { // integer, insert decimal dot and pad up zeros
				const pad = '0'.repeat(n);
				return `${a}.${pad}`;
			}

			const b = n - (a.length - dot) + 1;
			return b > 0 ? (a + '0'.repeat(b)) : a;
		} catch (error) {
			return 0;
		}
	},
};

export default helperMethods;
