<template>
	<page-container no-column>
		<v-col
			cols="12"
			class="d-flex flex-column"
			v-if="projectIsDefined()"
		>
			<span class="text-caption font-weight-light">
				Empresas do grupo:
			</span>
			<span class="text-subtitle-1 font-weight-bold">
				{{ this.project.name }}
			</span>
		</v-col>

		<v-col cols="12" v-if="projectIsDefined()">
			<related-list
				@add="saveOrCreate"
				:list="withoutRemovedCompanies"
				ref="companyForm"
			>
				<!-- LISTA DE COMPANHIAS -->
				<template v-slot:list>
					<related-list-item
						v-for="(company, index) in withoutRemovedCompanies"
						:key="index"
						:item="company"
						:name="company.name"
						:content="company.cnpj"
						@edit="onEdit"
						@remove="onRemove"
						:no-divider="withoutRemovedCompanies.length - 1 == index"
					/>
				</template>

				<!-- FORMULARIO DE CRIACAO -->
				<template v-slot:create>
					<page-container no-column>
						<v-col xl="4" lg="4" md="6" sm="6" xs="12">
							<v-text-field
								label="Nome"
								v-model="toAddCompany.name"
								:rules="[ (v) => !!v || 'Nome necessário']"
							/>
						</v-col>

						<v-col xl="4" lg="4" md="6" sm="6" xs="12">
							<v-text-field
								label="CNPJ"
								v-model="toAddCompany.cnpj"
								v-mask="cnpjMask"
								:rules="[
									(v) => !!v || 'CNPJ necessário',
									uniqueCNPJRule(),
								]"
							/>
						</v-col>

						<v-col xl="4" lg="4" md="6" sm="6" xs="12">
							<v-checkbox
								label="Matriz"
								v-model="toAddCompany.type"
							/>
						</v-col>
					</page-container>
				</template>
			</related-list>
		</v-col>

		<v-col cols="12" v-else>
			<disclaimer
				title="Grupo de Empresas não definido"
				subtitle="Por favor, defina o grupo de empresas antes de adicionar empresas."
			/>
		</v-col>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import RelatedList from '@/components/related/RelatedList.vue';
import RelatedListItem from '@/components/related/RelatedListItem.vue';
import RelatedMixin from '@/components/related/RelatedMixin';
import Disclaimer from '@/components/defaults/layout/Disclaimer.vue';

export default {
	name: 'CompanyForm',
	components: {
		PageContainer,
		RelatedList,
		RelatedListItem,
		Disclaimer,
	},
	mixins: [RelatedMixin],
	props: {
		value: [Object, Array],
		project: Object,
	},
	data() {
		return {
			companies: this.value || [],
			clicked: false,
			valid: false,
			cnpjMask: this.$cnpjMask,
			toAddCompany: {
				id: '',
				name: '',
				cnpj: '',
				type: null,
				fantasy_name: '',
				company_group_id: null,
				index: null,
			},
			cleanCompany: {
				id: null,
				name: '',
				cnpj: '',
				type: null,
				fantasy_name: '',
				company_group_id: null,
				edit: null,
				index: null,
			},
		};
	},
	computed: {
		withoutRemovedCompanies() {
			return this.filterOnlyRemovedItems({ items: this.companies });
		},
	},
	watch: {
		companies() {
			const serializedCompanies = this.serializeCompanies(this.companies);

			this.$emit('input', serializedCompanies);
		},
	},
	methods: {
		serializeCompanies(companies) {
			if (!companies) return [];

			const serialized = companies.map((company, index) => {
				company.index = index + 1;

				return company;
			});

			return serialized;
		},
		/**
		 * RULES & HELPERS
		 */
		projectIsDefined() {
			return !!this.project.name;
		},
		uniqueCNPJRule() {
			return () => {
				const toVerifyCompany = this.toAddCompany;
				const isntEditing = !this.isEditingCompany(toVerifyCompany);

				const result = this.companies.some((company) => company.cnpj == toVerifyCompany.cnpj);

				return result && isntEditing ? 'CNPJ já cadastrado' : true;
			};
		},

		companyExists(company) {
			return (company && company.index && company.cnpj);
		},

		isEditingCompany(company) {
			return (company && company.edit);
		},

		disableCNPJFieldWhenEditing(company) {
			if (this.companyExists(company)) {
				return true;
			}

			return false;
		},

		cleanForm() {
			this.toAddCompany = { ...this.toAddCompany, ...this.cleanCompany };
		},
		isABranch(company) {
			if (!company) return true;

			if (this.isAStringAndNotNull(company.type)) {
				return company.type == 'branch';
			}

			return !company.type;
		},
		isAStringAndNotNull(value) {
			return typeof value == 'string' && value !== '';
		},
		serializeCompanyToSave(company) {
			company.type = this.isABranch(company) ? 'branch' : 'head';

			return company;
		},
		serializeCompanyToEdit(company) {
			company.edit = true;
			company.type = !this.isABranch(company);

			return company;
		},
		/**
		 * SAVE
		 */
		saveOrCreate() {
			const companyToHandle = this.toAddCompany;
			const serializedCompany = this.serializeCompanyToSave(companyToHandle);

			if (this.companyExists(serializedCompany)) {
				this.save(serializedCompany);
			} else {
				this.create(serializedCompany);
			}
		},

		save(changedCompany) {
			const unchangedCompanies = this.companies;

			const changedCompanies = this.replaceListItem({
				items: unchangedCompanies,
				identifier: 'index',
				id_value: changedCompany.index,
				replace_value: changedCompany,
			});

			this.companies = changedCompanies;

			this.cleanForm();
		},
		/**
		 * EDIT
		 */
		onEdit(company) {
			const companyToEdit = { ...this.toAddCompany, ...company };

			const serializedCompany = this.serializeCompanyToEdit(companyToEdit);
			this.toAddCompany = serializedCompany;

			const relatedList = this.$refs.companyForm;
			relatedList.toggleSaveFormToEdit();

			this.$forceUpdate();
		},
		/**
		 * CREATE
		 */
		create(company) {
			company.is_new = true;
			company.index = this.companies.length;

			this.companies.push(company);

			this.cleanForm();
		},
		/**
		 * DELETE
		 */
		onRemove(company) {
			if (!company.index) return;

			const result = this.deleteListItem({
				items: this.companies,
				identifier: 'index',
				id_value: company.index,
			});

			this.companies = result;
		},
	},
};
</script>

<style>

</style>
