<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:loading="loading"
		hide-default-footer
	>
		<template v-slot:item.action="{ item }">
			<div class="flex-right d-flex flex-row-reverse">
				<list-edit-button @edit="onEdit(item)"/>

				<list-remove-button @remove="onRemove(item)"/>
			</div>
		</template>

		<template v-slot:loading>
			<loading-data-circular/>
		</template>
	</v-data-table>
</template>

<script>
import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';
import ListRemoveButton from '@/components/defaults/buttons/ListRemoveButton.vue';

export default {
	name: 'OperationTypeListTable',
	components: {
		LoadingDataCircular,
		ListEditButton,
		ListRemoveButton,
	},
	props: {
		items: Array,
		loading: Boolean,
	},
	data() {
		return {
			headers: [
				{ text: 'Operação', value: 'name' },
				{ text: '', value: 'action' },
			],
		};
	},
	created() {},
	methods: {
		/**
		 * ACTIONS
		 */
		onEdit(item) {
			this.$emit('edit', item);
		},
		onRemove(item) {
			this.$emit('remove', item);
		},
	},
};
</script>

<style>

</style>
