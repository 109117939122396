<template>
	<v-currency-field
		:label="label"
		v-model="model"
		:prepend-inner-icon="!noPrepend ? 'mdi-percent' : ''"
		v-mask="mask"
		:max="max"
		:min="min"
		allow-negative
		:dense="dense"
	/>
</template>

<script>
export default {
	name: 'PercentField',
	props: {
		value: [String, Number],
		label: String,
		allowNegatives: Boolean,
		noPrepend: Boolean,
		dense: Boolean,
	},
	data() {
		return {
			model: this.value,
			isNegative: false,
			negativeMask: '- ###,##',
			positiveMask: '###,##',
			max: 100.00,
			min: -100.00,
		};
	},
	watch: {
		model() {
			this.isNegative = (`${this.model}`).indexOf('-') != -1;

			this.limitValue();

			this.$emit('input', this.model);
		},
		value() {
			if (this.model === this.value) return;

			this.model = this.value;
		},
	},
	computed: {
		mask() {
			return this.isNegative ? this.negativeMask : this.positiveMask;
		},
	},
	methods: {
		limitValue() {
			if (this.model > 100) {
				this.model = 100;
			}

			if (this.model < -100) {
				this.model = -100;
			}
		},
	},
};
</script>

<style>

</style>
