<template>
	<v-list-item
		class="d-flex flex-row"
		:class="itemClass"
		link
		@click="navigateToLink(link)"
	>
		<v-list-item-icon>
			<v-icon> {{ link.icon }} </v-icon>
		</v-list-item-icon>

		<v-list-item-content>
			<v-list-item-title>
				{{ link.title }}
			</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	name: 'DrawerItem',
	data: () => ({}),
	props: {
		link: Object,
		itemClass: String,
		noAction: Boolean,
	},
	methods: {
		navigateToLink(link) {
			this.$router.push(link.path);
		},
	},
};
</script>

<style>

</style>
