<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:loading="loadingData"
		hide-default-footer
	>
		<template v-slot:item.action="{ item }">
			<div class="flex-right d-flex flex-row-reverse">
				<list-edit-button class="flex-right" @edit="onEdit(item)"/>

				<list-remove-button class="flex-right" @remove="onRemove(item)"/>
			</div>
		</template>

		<template v-slot:loading>
			<loading-data-circular/>
		</template>
	</v-data-table>
</template>

<script>
import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';
import ListRemoveButton from '@/components/defaults/buttons/ListRemoveButton.vue';
import FundTypeService from '@/services/FundTypeService';

export default {
	name: 'FundTypeListTable',
	components: {
		LoadingDataCircular,
		ListEditButton,
		ListRemoveButton,
	},
	data() {
		return {
			items: [],
			loadingData: false,
			headers: [
				{ text: 'Tipo de Fundo', value: 'name' },
				{ text: '', value: 'action', sortable: false },
			],
		};
	},
	created() {
		this.loadFundTypes();
	},
	methods: {
		/**
		 * CRUD
		 */
		async loadFundTypes() {
			try {
				this.loadingData = true;

				const fundTypeService = new FundTypeService();

				const response = await fundTypeService.index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		async remove(item) {
			try {
				this.loadingData = true;

				const fundTypeService = new FundTypeService();

				await fundTypeService.destroy(item);

				this.removeListItem(item);
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		/**
		 * ACTIONS
		 */
		onEdit(item) {
			this.$emit('edit', item.id);
		},
		onRemove(item) {
			this.remove(item);
		},
		/**
		 * HELPERS
		 */
		removeListItem(item) {
			this.items = this.items.filter((row) => row.id != item.id);
		},
	},
};
</script>

<style>

</style>
