<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:loading="loading"
		:search="search"
		:custom-filter="filterWithNestedAccesses"

		hide-default-footer
	>
		<template v-slot:item.action="{ item }">
			<list-edit-button @edit="onEdit(item)"/>
		</template>

		<template v-slot:loading>
			<loading-data-circular/>
		</template>
	</v-data-table>
</template>

<script>
import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';

export default {
	name: 'UserTypeTable',
	components: {
		LoadingDataCircular,
		ListEditButton,
	},
	props: {
		items: Array,
		loading: Boolean,
		search: String,
	},
	data() {
		return {
			headers: [
				{ text: 'Tipo de Usuário', value: 'title' },
				{ text: '', value: 'action', width: '10%' },
			],
		};
	},
	methods: {
		onEdit(item) {
			this.$emit('edit', item);
		},
		/**
		 * FILTER METHODS
		 * usados em diversos codigos para filtragem especifica ou de relacionamentos
		 *
		 * filterFunction
		 * Filtra todas as linhas atraves do seus campos comumns,
		 * geralmente em String, por isso o switch case.
		 * alem dos valores associados que estarao no tipo objeco.
		 * no caso por exemplo de filterWithNestedAccesses (UserTypeTable.vue - 81)
		 * 	o objeto a ser filtrado (tipo de usuario) possui uma relacao de com muitos acessos
		 *
		 * !important: apesar de retornar um array com as relacoes, o tipo definido atraves
		 * 	da funcao typeof eh sempre um objeto.
		 *
		 * utiliza da chamada padrao de customFilter presente na documentacao do vuetify
		 *
		 * @reference https://vuetifyjs.com/en/api/v-data-table/
		 *
		 * @param {String|*} value - valor do campo percorrido pela funcao de
		 * 	filtro do vuetify, cada value refere a um valor do objeto ou item passado.
		 * @param {String|*} search - valor passado na propriedade :search para o v-data-table
		 * @return {Boolean} result
		 */
		filterWithNestedAccesses(value, search) {
			const typeofComparisonValue = typeof (value);

			switch (typeofComparisonValue) {
			case 'string':
				return this.$helpers.like(value, search);

			case 'object':
				return this.filterAccesses(value, search);

			default:
				return false;
			}
		},
		/**
		 * filterRelationships
		 *
		 * divisao de conceito para filtro de dados relacionados a linha em busca.
		 * filtra uma relacao atraves de sua chave de busca (geralmente uma string).
		 * usados em diversos codigos para filtragem especifica ou de relacionamentos
		 *
		 * @param {String|*} value - valor do campo percorrido pela funcao de
		 * 	filtro do vuetify, cada value refere a um valor do objeto ou item passado.
		 * @param {String|*} search - valor passado na propriedade :search para o v-data-table
		 * @return {Boolean} result
		 */
		filterRelationships(value, search) {
			const result = value.some((item) => {
				const nameOfRelationField = item.title;

				return this.$helpers.like(nameOfRelationField, search);
			});

			return result;
		},
	},
};
</script>

<style  scoped>
.user-accesses {
	overflow: hidden;
	max-width: 90% !important;
	margin-right: -350px !important;
}

</style>
