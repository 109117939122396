import Rest from './base/Rest';

export default class CnabService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = '/cnab';

	duplicate(record = {}) {
		return this.post('/duplicate', record);
	}
}
