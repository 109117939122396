<template>
  <page-container no-column>
    <breadcrumbs :page-title="getRouteTitle()" />

    <v-form ref="operationForm" class="w-100 d-flex flex-column">
      <form-structure :styling="false" :loading="loading">
        <template v-slot:content>
          <v-form ref="newsFormRequest">
            <news-save-main-form :user="user" :form="isSaving" :old="oldNews" :loading="loading" @save="save" />
          </v-form>
        </template>

        <template v-slot:actions>
          <form-cancel-button />

          <form-save-button @click="isSaving = true" :valid="!saving" :loading="saving" />

          <form-destroy-button
            v-if="!isANewOperation()"
            :loading="removing"
            :disabled="loading"
            @remove="remove"
          />
        </template>
      </form-structure>
    </v-form>
  </page-container>
</template>

<script>
// eslint-disable-next-line
import { bus } from "@/main";

import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import FormDestroyButton from '@/components/defaults/buttons/FormDestroyButton.vue';

import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';
import NewsService from '@/services/NewsService';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import NewsSaveMainForm from './form/NewsSaveMainForm.vue';

export default {
	name: 'VacationsSave',
	components: {
		Breadcrumbs,
		FormStructure,
		FormSaveButton,
		FormDestroyButton,
		FormCancelButton,
		NewsSaveMainForm,
		PageContainer,
	},
	data() {
		return {
			loading: false,
			saving: false,
			removing: false,
			valid: true,
			oldNews: {},

			user: {},
			isSaving: false,

			id: this.$route.params.id,
		};
	},

	created() {
		this.loadOperation();
		this.getLoggedUser();
	},

	methods: {
		changeValidation(val) {
			this.valid = val;
		},

		validateForm() {
			return this.$refs.newsFormRequest.validate();
		},

		async loadOperation() {
			try {
				if (this.isANewOperation()) return;

				const { params } = this.$route;

				this.loading = true;
				const newsService = new NewsService();

				const response = await newsService.show(params.id);
				const [item] = response.data;

				this.oldNews = item;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},

		async remove() {
			try {
				if (this.isANewOperation()) return;
				this.loading = true;
				this.removing = true;
				const newsService = new NewsService();
				await newsService.destroy({ id: this.id });

				this.showRemoveSuccessDialog();
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
				this.removing = false;
			}
		},

		showRemoveSuccessDialog() {
			this.$swal({
				title: 'Notícia apagada com sucesso',
				icon: 'success',
				showConfirmButton: true,
			});
		},

		async save(value) {
			try {
				if (!this.$refs.newsFormRequest.validate()) return;
				this.saving = true;

				const newsService = new NewsService();
				if (this.isANewOperation()) {
					await newsService.store(value);
				} else {
					await newsService.update(value);
				}

				this.showSaveSuccessDialog();
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.vacations = false;
				this.saving = false;
			}
		},

		showSaveSuccessDialog() {
			const title = this.isANewOperation()
				? 'Notícia cadastrada com sucesso '
				: 'Notícia atualizada com sucesso';

			this.$swal({
				title,
				icon: 'success',
				showConfirmButton: true,
			});
		},
		/**
     * HELPER FUNCTIONS
     */
		getRouteTitle() {
			const id = this.getIdOfRoute();

			if (this.idIsNewOrNotExists(id)) return 'Cadastro de notícias';

			return 'Edição de Notícias';
		},

		idIsNewOrNotExists(id) {
			return !id || id == 'new' || id == undefined;
		},

		getIdOfRoute() {
			const { id } = this.$route.params;

			return id;
		},

		isANewOperation() {
			const id = this.getIdOfRoute();

			return this.idIsNewOrNotExists(id);
		},

		returnToPreviousRoute() {
			this.$router.push({ name: 'NewsList' });
		},

		getLoggedUser() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);
			this.user = tokenData;
		},
	},
};
</script>

<style></style>
