<template>
	<div class="d-flex w-100">
		<v-data-table
			:headers="headers"
			:items="conciliated"
			disable-sort
			item-key="id"
			group-key="status"
			class="w-100 bg-light-3 mb-n3 mt-2"
			v-if="conciliated.length"
		>
			<template v-slot:item.net_value="{ item }">
				{{ item.net_value | toCurrency }}
			</template>

			<template v-slot:item.due_date="{ item }">
				{{ item.due_date | mysqlDateToBr }}
			</template>

			<template v-slot:item.issue_date="{ item }">
				{{ item.issue_date | mysqlDateToBr }}
			</template>

			<template v-slot:item.repurchased_value_total="{ item }">
				{{ sumAllRepurchasedValues(item)  | toCurrency }}
			</template>
			<template v-slot:item.status="{ item }">
				<v-chip
					v-for="(status, index) in item.status"
					:key="index"
					class="mx-2"
				>
					{{ serializeStatusText(status.status) }}
				</v-chip>
			</template>
			<template v-slot:[`item.repurchased_value`]="{item}">
          {{getRepurchasedValue(item) |toCurrency}}
      </template>

      <template v-slot:[`item.interest`]="{item}">
          {{ getInterest(item) | toCurrency}}
      </template>
		</v-data-table>

		<disclaimer
			v-else
			title="Nenhum Registro Selecionado"
			subtitle="Adicione registros relacionados à operação a ser enviada."
		/>
	</div>
</template>

<script>
import Disclaimer from '@/components/defaults/layout/Disclaimer.vue';
import { getters } from '../state/operation.state';

export default {
	components: {
		Disclaimer,
	},
	name: 'ConciliationList',
	props: {
		value: Array,
	},
	data() {
		return {
			headers: [
				{ text: 'NF', value: 'number' },
				{ text: 'Valor', value: 'net_value' },
				{ text: 'Valor Recomprado Total', value: 'repurchased_value_total' },
				{ text: 'Valor Recomprado', value: 'repurchased_value' },
				{ text: 'Juros', value: 'interest' },
				{ text: 'Data de Vencimento', value: 'due_date' },
				{ text: 'Data de Emissão', value: 'issue_date' },
				{ text: 'Operações', value: 'status' },
			],
		};
	},
	computed: {
		conciliated() {
			const concilated = getters.conciliated();

			return concilated.filter((element) => element.status.length > 0);
		},
	},
	methods: {
		serializeStatusText(status) {
			switch (status) {
			case 'partial_repurchased_amount':
				return 'Título recomprado parcial';
			case 'registry_sent':
				return 'Registro Enviado ao Cartório';
			case 'registry_send_movement_value':
				return 'Registro Enviado ao Cartório';
			case 'title_in_serasa':
				return 'Consultado no SERASA';
			case 'registry_repurchased':
				return 'Retirado do Cartório';
			case 'title_repurchased':
				return 'Título Recomprado';
			case 'repurchase_value':
				return 'Título Recomprado';
			case 'title_down':
				return 'Título baixado';
			case 'title_prorrogated':
				return 'Título Prorrogado';
			default:
				return '';
			}
		},
		getRepurchasedValue(item) {
			if (item.repurchased_value) return item.repurchased_value;

			if (item.status[0]) return item.status[0].repurchased_value;

			return '';
		},
		getInterest(item) {
			if (item.interest) return item.interest;

			if (item.status[0]) return item.status[0].interest;

			return '';
		},
		filterOnlyValidConciliatedRegisters() {
			this.conciliated = this.conciliated
				.filter((row) => row.status.length);
		},

		sumAllRepurchasedValues(item) {
			let value = 0;

			item.status.forEach((element) => {
				if (element.repurchased_value || item.repurchased_value) {
					value += Number(element.repurchased_value) || Number(item.repurchased_value);
				}
			});

			return value;
		},
	},
};
</script>

<style>

</style>
