<template>
	<v-list-item link @click="goToProfile()">
		<v-list-item-avatar>
			<img v-if="user.image_url" :src="user.image_url | getWithToken"/>

			<v-icon
				v-else
			>
				mdi-account
			</v-icon>
		</v-list-item-avatar>

		<v-list-item-content>
			<v-list-item-title> {{ user.name }} </v-list-item-title>
			<v-list-item-subtitle> Clique para acessar o perfil </v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	name: 'UserProfileListItem',
	props: {
		user: Object,
	},
	methods: {
		goToProfile() {
			if (!this.user || !this.user.id) return;

			const params = { id: this.user.id };

			this.$router.push({ name: 'UserProfile', params });
		},
	},
};
</script>

<style>

</style>
