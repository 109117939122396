<template>
	<page-container no-column>
		<v-col cols="12">
			<image-dialog
				md-w="860"
				md-h="200"
				sm-w="400"
				sm-h="100"
				:crop-type="$cropTypes.noRestriction"
				:aspect-ratio="21 / 6"
				v-model="companyGroup.image"
			/>
		</v-col>

		<v-col cols="12">
			<separator label="Informações do Grupo de Empresa"/>
		</v-col>

		<v-col xl="6" lg="6" md="6" sm="6" xs="12">
			<v-text-field
				label="Nome do projeto"
				v-model="companyGroup.name"
				:rules="[(v) => !!v || 'Nome da Empresa necessário']"
			/>
		</v-col>

		<v-col xl="6" lg="6" md="6" sm="6" xs="12">
			<v-text-field
				label="URL do projeto no PowerBI"
				v-model="companyGroup.power_bi_url"

			/>
		</v-col>
		<v-col xl="6" lg="6" md="6" sm="6" xs="12">
			<v-text-field
				label="URL do projeto no PowerBI2"
				v-model="companyGroup.power_bi_url2"
			/>
		</v-col>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ImageDialog from '@/components/image_upload/ImageDialog.vue';
import Separator from '@/components/defaults/layout/Separator.vue';

export default {
	name: 'CompanyGroupForm',
	components: {
		PageContainer,
		ImageDialog,
		Separator,
	},
	props: {
		value: Object,
		project: Object,
	},
	data() {
		return {
			companyGroup: this.value || {},
		};
	},
	mounted() {
		this.loadImage();
	},
	watch: {
		// two-way data-binding
		companyGroup: {
			deep: true,
			handler() {
				this.$emit('input', this.companyGroup);
			},
		},
		value() {
			if (this.companyGroup != this.value || !this.companyGroup) {
				this.companyGroup = this.value;

				this.loadImage();
			}
		},
	},
	methods: {
		loadImage() {
			if (!this.companyGroup && !this.companyGroup.image_url) return;

			this.companyGroup.image = {
				preview: this.companyGroup.image_url,
			};
		},
	},
};
</script>

<style>

</style>
