<template>
  <page-container no-column>
    <v-row>

      <v-col xs="12" lg="5" lx="5">
        <v-radio-group v-model="row" row>
          <v-radio
            v-for="item in fieldToSearch"
            :key="item.field"
            :label="item.description"
            :value="item"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </page-container>
</template>
<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';

export default {
	components: {
		PageContainer,
	},
	watch: {
		value() {
			if (this.filter == this.value) {
				return;
			}

			this.filter = this.value;
		},
		filter: {
			deep: true,
			handler() {
				this.input();
			},
		},
	},
	mounted() {
		this.getLoggedUser();

		this.row = this.fieldToSearch[0];
	},
	data() {
		return {
			user: {},

			filter: { search: '' },
			row: null,
			fieldToSearch: [
				{
					description: 'Nome do usuário',
					field: 'name',
					type: 'string',
				},
				{
					description: 'Período',
					field: 'period',
					type: 'string',
				},
				{
					description: 'Data inicial',
					field: 'start_date',
					type: 'date',
				},
				{
					description: 'Data final',
					field: 'end_date',
					type: 'date',
				},
				{
					description: 'Quantidade de dias',
					field: 'number_days',
					type: 'string',
				},
			],
		};
	},

	methods: {
		input() {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.$emit('input', this.row);
			}, 1000);
		},

		validatePermission() {
			return this.user.user_type.name === 'admin';
		},
		getLoggedUser() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);
			this.user = tokenData;
		},
	},
};
</script>
