<template>
	<page-container no-column v-if="!loadingData">
		<project-card
			v-for="(project, index) in projects"
			:key="index"
			:project="project"
			:index="index"
			@dashboardClick="dashboardClick"
			@dashboardClick2="dashboardClick"
			@functionalityClick="functionalityClick"
		/>
	</page-container>

	<loading-data-circular
		v-else
	/>
</template>

<script>
// systems components
import ProjectService from '@/services/ProjectService';
import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';

// layout components
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ProjectCard from './ProjectCard.vue';

export default {
	name: 'ProjectDashboard',
	components: {
		LoadingDataCircular,
		PageContainer,
		ProjectCard,
	},
	data() {
		return {
			projects: [],
			loadingData: null,
		};
	},
	created() {
		this.loadProjects();
	},
	methods: {
		async loadProjects() {
			try {
				this.loadingData = true;

				const projectService = new ProjectService();

				const response = await projectService.user().index();

				this.projects = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		functionalityClick(functionality) {
			const treatedRoute = this.treatRoute(functionality);

			this.$router.push(treatedRoute);
		},
		dashboardClick(project, type) {
			const params = { id: project.id, type };
			this.$router.push({ name: 'PowerBIView', params });
		},
		treatRoute(functionality) {
			const hasParametersToGive = (functionality.path).includes(':');

			if (!hasParametersToGive) return functionality.path;

			const param = this.replaceParameters(functionality);

			return param;
		},
		replaceParameters(functionality) {
			const splittedInBars = (functionality.path).split('/');

			const withParameters = splittedInBars
				.map((path) => this.returnFormatedPath(path, functionality));

			const path = withParameters.join('/');

			return path;
		},
		returnFormatedPath(unformatedPath, functionality) {
			const hasKey = (`${unformatedPath}`).includes(':');

			if (hasKey) {
				const param = this
					.getParameterOnKey(unformatedPath, functionality);

				return param;
			}

			return unformatedPath;
		},
		getParameterOnKey(path, functionality) {
			const withoutIdentifier = (`${path}`).replace(':', '');

			const parameter = this.$helpers
				.getPropsByString(withoutIdentifier, functionality);

			return parameter;
		},
	},
};
</script>

<style>

</style>
