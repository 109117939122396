<template>
	<router-view/>
</template>

<script>

export default {
	name: 'App',

	components: {
	},

	data: () => ({
		//
	}),
};
</script>

<style lang="sass">
@import "@/styles/main.sass"
@import "@/styles/globals.sass"
@import "@/styles/swal.sass"
</style>
