<template>
	<div class="flex-item d-flex flex-column w-100">
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title>
					{{ name || label }}
				</v-list-item-title>

				<v-list-item-subtitle>
					{{ content }}
				</v-list-item-subtitle>
			</v-list-item-content>

			<v-list-item-action
				v-if="!noAction"
				class="d-flex flex-row"
			>
				<slot name="actions">
					<list-edit-button @edit="onEdit" v-if="!noEdit"/>

					<list-remove-button @remove="onRemove"/>
				</slot>
			</v-list-item-action>
		</v-list-item>

		<v-divider v-if="!noDivider"/>
	</div>
</template>

<script>
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';
import ListRemoveButton from '@/components/defaults/buttons/ListRemoveButton.vue';

export default {
	name: 'relatedListItem',
	components: {
		ListEditButton,
		ListRemoveButton,
	},
	props: {
		name: String,
		label: String,
		item: Object,
		content: String,
		noAction: Boolean,
		noEdit: Boolean,
		noDivider: Boolean,
	},
	methods: {
		onEdit() {
			this.$emit('edit', this.item);
		},
		onRemove() {
			this.$emit('remove', this.item);
		},
	},
};
</script>

<style>

</style>
