var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"segment-table",attrs:{"headers":_vm.headers,"items":_vm.segments,"items-per-page":-1,"sort-by":_vm.sortby,"must-sort":"","hide-default-footer":"","item-key":"line_number"},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.handle",fn:function(){return [_c('v-btn',{staticClass:"handle",attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-reorder-horizontal ")])],1)]},proxy:true},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptionText('payment_methods', item.payment_method))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptionText('register_types', item.type))+" ")]}},{key:"item.account_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptionText('account_types', item.account_type))+" ")]}},{key:"item.is_tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptionText('is_tax', item.is_tax))+" ")]}},{key:"item.allow_duplicate_lines",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptionText('allow_duplicate_lines', item.allow_duplicate_lines))+" ")]}},{key:"item.same_bank",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptionText('same_bank', item.same_bank))+" ")]}},{key:"item.same_owner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptionText('same_owner', item.same_owner))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 d-flex flex-item flex-row"},[_c('list-edit-button',{attrs:{"stop-propagation":""},on:{"click":function($event){return _vm.editSegment(item)}}}),_c('list-remove-button',{attrs:{"class-style":"mx-2","stop-propagation":""},on:{"click":function($event){return _vm.deleteSegment(item)}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }