<template>
  <page-container>
    <breadcrumbs :page-title="getRouteTitle()" />

    <v-form ref="operationForm" class="w-100 d-flex flex-column">
      <form-structure :loading="loading">
        <template v-slot:content>
          <v-form ref="vacationsRequestForm">
            <vacations-save-main-form
              :user="user"
              :form="vacations"
              :oldVacation="oldDataVacation"
							:loading="loading"
              @save="save"
            />
          </v-form>
        </template>

        <template v-slot:actions>
          <form-cancel-button />

          <form-save-button
            @click="vacations = true"
            :valid="valid && !loading"
            :loading="saving"
          />

          <form-destroy-button
            v-if="!isANewOperation()"
            :loading="removing"
            :disabled="loading"
            @remove="remove"
          />
        </template>
      </form-structure>
    </v-form>
  </page-container>
</template>

<script>
// eslint-disable-next-line
import { bus } from "@/main";

import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import FormDestroyButton from '@/components/defaults/buttons/FormDestroyButton.vue';
import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';
import VacationsService from '@/services/VacationsService';
import VacationsSaveMainForm from './form/BuyVacationsSaveMainForm.vue';

export default {
	name: 'VacationsSave',
	components: {
		PageContainer,
		Breadcrumbs,
		FormStructure,
		FormSaveButton,
		FormDestroyButton,
		FormCancelButton,
		VacationsSaveMainForm,
	},
	data() {
		return {
			loading: false,
			saving: false,
			removing: false,
			valid: true,
			oldDataVacation: {},

			user: {},
			vacations: false,

			id: this.$route.params.id,
		};
	},

	created() {
		this.loadOperation();
		this.getLoggedUser();
	},

	methods: {
		changeValidation(val) {
			this.valid = val;
		},

		async loadOperation() {
			try {
				if (this.isANewOperation()) return;

				let item = {};

				const { params } = this.$route;

				this.loading = true;
				const vacationsService = new VacationsService();

				const response = await vacationsService.show(params.id);
				if (response.data) {
					item = response.data;
				} else {
					item = response;
				}
				this.oldDataVacation = item;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},

		async remove() {
			try {
				if (this.isANewOperation()) return;
				this.loading = true;
				this.removing = true;
				const vacationsService = new VacationsService();
				await vacationsService.destroy({ id: this.id });

				this.showRemoveSuccessDialog();
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
				this.removing = false;
			}
		},

		showRemoveSuccessDialog() {
			this.$swal({
				title: 'Requisição de férias apagada com sucesso',
				icon: 'success',
				showConfirmButton: true,
			});
		},

		async save(value) {
			try {
				this.saving = true;

				const vacationsService = new VacationsService();
				if (this.isANewOperation()) {
					await vacationsService.store(value);
				} else {
					await vacationsService.update(value);
				}

				this.showSaveSuccessDialog();
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.vacations = false;
				this.saving = false;
			}
		},

		showSaveSuccessDialog() {
			const title = this.isANewOperation()
				? 'Baixa de férias cadastrada'
				: 'Baixa de férias atualizada';

			this.$swal({
				title,
				icon: 'success',
				showConfirmButton: true,
			});
		},
		/**
     * HELPER FUNCTIONS
     */
		getRouteTitle() {
			const id = this.getIdOfRoute();

			if (this.idIsNewOrNotExists(id)) return 'Baixa de férias';

			return 'Edição de Baixa de férias';
		},

		idIsNewOrNotExists(id) {
			return !id || id == 'new' || id == undefined;
		},

		getIdOfRoute() {
			const { id } = this.$route.params;

			return id;
		},

		isANewOperation() {
			const id = this.getIdOfRoute();

			return this.idIsNewOrNotExists(id);
		},

		returnToPreviousRoute() {
			this.$router.push({ name: 'VacationsList' });
		},

		getLoggedUser() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);
			this.user = tokenData;
		},
	},
};
</script>

<style></style>
