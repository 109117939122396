<template>
	<page-container>
		<breadcrumbs :page-title="getRouteTitle()"/>

		<v-form class='w-100' v-model="valid" ref="form" @submit.prevent="save">
			<form-structure :loading="loadingData">
				<template v-slot:content>
					<page-container no-column>
						<!-- ESPACAMENTO PARA CENTRALIZAR -->
						<v-col xl="2" lg="2" class="d-lg-block d-xs-none"/>

						<v-col xl="8" lg="8" md="12" sm="12" xs="12">
							<v-text-field
								label="Operação (Descrição)"
								v-model="toSave.name"
								:rules="[ v => !!v || 'Adicione uma descrição para a operação']"
							/>
						</v-col>

						<!-- ESPACAMENTO PARA CENTRALIZAR -->
						<v-col xl="2" lg="2" class="d-lg-block d-xs-none"/>
					</page-container>
				</template>

				<template v-slot:actions>
					<form-cancel-button/>

					<form-save-button
						:valid="valid"
						@click="save"
						:loading="loadingSubmit"
						:disabled="loadingData"
					/>
				</template>
			</form-structure>
		</v-form>
	</page-container>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';

import OperationService from '@/services/OperationService';
import FormCancelButton from '../../components/defaults/buttons/FormCancelButton.vue';

export default {
	name: 'OperationTypeSave',
	components: {
		Breadcrumbs,
		FormStructure,
		FormSaveButton,
		PageContainer,
		FormCancelButton,
	},
	data() {
		return {
			valid: false,
			loadingSubmit: false,
			loadingData: false,
			toSave: {},
		};
	},
	created() {
		this.loadingItemByRoute();
	},
	methods: {
		/**
		 * UX
		 */
		getRouteTitle() {
			if (this.isANewOperation()) return 'Criação de Operação';

			return 'Edição de Operação';
		},
		/**
		 * HELPERS
		 */
		idIsNewOrNotExists(id) {
			return (!id || id == 'new');
		},
		getIdOfRoute() {
			const { id } = this.$route.params;
			return id;
		},
		isANewOperation() {
			if (this.toSave.id) return false;

			const id = this.getIdOfRoute();

			return (this.idIsNewOrNotExists(id));
		},
		returnToPreviousRoute() {
			this.$router.push({ name: 'OperationTypeList' });
		},
		revalidate() {
			this.$regs.form.validate();
		},
		/**
		 * CRUD METHODS
		 */
		async save() {
			try {
				const canSave = this.$refs.form.validate();

				if (!canSave) return;

				this.loadingSubmit = true;

				const operationService = new OperationService();

				if (this.isANewOperation()) {
					await operationService.store(this.toSave);
				} else {
					await operationService.update(this.toSave);
				}

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			}
		},

		async loadingItemByRoute() {
			try {
				const id = this.getIdOfRoute();

				if (this.isANewOperation(id)) return;

				this.loadingData = true;

				const operationService = new OperationService();

				const response = await operationService.show(id);

				this.toSave = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
				this.toSave = {};
			} finally {
				this.loadingData = false;
			}
		},
	},
};
</script>

<style>

</style>
