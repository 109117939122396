<template>
	<div class="text-center">
		<v-dialog
			v-model="dialog"
			width="420"
			modal
		>
			<v-card>
				<page-container no-column>
					<!-- Lista de sistemas para aquele projeto -->
					<v-col
						v-for="(functionality) in handleProject.functionalities"
						:key="functionality.id"
						cols="12"
					>
						<checkbox
							v-model="functionality.user_allowed"
							:label="functionality.title"
							@click="changeProjectFunctionalities"
							bind-model
						/>
					</v-col>

					<v-col cols="12">
						<v-divider class="my-1"></v-divider>

						<v-btn
							text
							color="primary"
							class="float-right mb-n3"
							@click="toggleFunctionalitiesModal"
						>
							FECHAR
						</v-btn>
					</v-col>
				</page-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Checkbox from '@/components/defaults/Checkbox.vue';

export default {
	name: 'UserFunctionalitiesModal',
	components: {
		PageContainer,
		Checkbox,
	},
	data() {
		return {
			dialog: this.value,
			handleProject: this.project,
		};
	},
	props: {
		value: Boolean,
		project: [Array, Object],
	},
	watch: {
		dialog() {
			this.$emit('input', this.dialog);
		},
		value() {
			if (this.dialog == this.value) return;

			this.dialog = this.value;
		},
		project() {
			this.handleProject = this.project;
		},
		handleProject() {
			this.changeProjectFunctionalities();
		},
	},
	methods: {
		toggleFunctionalitiesModal() {
			this.dialog = !this.dialog;
		},
		changeProjectFunctionalities() {
			this.$emit('change', this.handleProject);
		},
	},
};
</script>

<style>

</style>
