<template>
  <div>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Hoje
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ type === "month" ? "Mês" : "Dia" }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Dia</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Mês</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :event-color="getEventColor"
        :type="type"
        @click:more="viewDay"
        @mouseenter:event="showEvent"
        @mouseleave:event="selectedOpen = false"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title style="font-size: 15px;" v-html="selectedEvent.name"></v-toolbar-title>
          </v-toolbar>
        </v-card>
      </v-menu>
    </v-sheet>
  </div>
</template>
<script>
import HolidaysService from '@/services/HolidaysService';
import UserService from '@/services/UserService';
import moment from 'moment';

export default {
	data: () => ({
		focus: '',
		type: 'month',
		typeToLabel: {
			month: 'Month',
			week: 'Week',
			day: 'Day',
			'4day': '4 Days',
		},
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		events: [],
		colors: [
			'blue',
			'indigo',
			'deep-purple',
			'cyan',
			'green',
			'orange',
			'grey darken-1',
		],
		names: [
			'Meeting',
			'Holiday',
			'PTO',
			'Travel',
			'Event',
			'Birthday',
			'Conference',
			'Party',
		],

		holidays: [],
		users: [],
	}),
	mounted() {
		this.$refs.calendar.checkChange();
		this.loadHolidays();
		this.getUsers();
	},
	methods: {
		getEventColor(event) {
			return event.color;
		},
		setToday() {
			this.focus = '';
		},
		prev() {
			this.$refs.calendar.prev();
		},
		next() {
			this.$refs.calendar.next();
		},
		viewDay({ date }) {
			this.focus = date;
			this.type = 'day';
		},
		showEvent({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event;
				this.selectedElement = nativeEvent.target;
				setTimeout(() => {
					this.selectedOpen = true;
				}, 10);
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				setTimeout(open, 10);
			} else {
				open();
			}

			nativeEvent.stopPropagation();
		},

		async loadHolidays() {
			try {
				this.loading = true;

				const holidayService = new HolidaysService();

				const response = await holidayService.index();

				this.holidays = response.data;

				this.formatEvents();
			} catch (err) {
				this.$http.defaultCatchError(err);
			} finally {
				this.loading = false;
			}
		},
		async getUsers() {
			try {
				const userService = new UserService();

				const response = await userService.index();

				this.users = response.data;
				this.formatedUsersEvents();
				this.formatedUserVacations();
			} catch (error) {
				this.$http.defaultCatchError(error);
			}
		},

		formatEvents() {
			this.holidays.forEach((element) => {
				this.events.push({
					name: element.localName,
					start: element.date,
					end: element.date,
					color: 'green',
					description: element.localName,
				});
			});
			this.removeDuplicatedItens();
		},
		formatedUsersEvents() {
			const users = this.users.filter((user) => user.birth_date !== null);
			users.forEach((element) => {
				const [, month, day] = element.birth_date.split('-');
				const formattedDate = `${new Date().getFullYear()}-${month}-${day}`;

				this.events.push({
					name: `Aniversário: ${element.name.split(' ')[0]}`,
					start: moment(formattedDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
					end: moment(formattedDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
					color: 'blue',
					description: `Aniversário de: ${element.name}`,
				});
			});
		},
		formatedUserVacations() {
			const users = this.users.filter((user) => user.vacations.length > 0);

			users.forEach((user) => {
				this.getVacations(user);
			});
		},
		getVacations(user) {
			for (let index = 0; index < user.vacations.length; index += 1) {
				const vacation = user.vacations[index];

				if (vacation.status === 'approve' && vacation.type === 'S') {
					this.events.push({
						name: `Férias: ${user.name.split(' ')[0]}`,
						start: vacation.start_date,
						end: vacation.end_date,
						color: 'red',
						description: `Férias de: ${user.name}`,
					});
				}
			}
		},
		removeDuplicatedItens() {
			this.events = this.events.filter(
				(thing, index, self) => self.findIndex(
					(t) => t.name.toUpperCase() === thing.name.toUpperCase(),
				) === index,
			);
		},
	},
};
</script>
