<template>
	<page-container>
		<breadcrumbs :page-title="getRouteTitle()"/>

		<v-form
			ref="operationForm"
			class="w-100 d-flex flex-column"
		>
			<form-structure :loading="loading">
				<template v-slot:content>
					<receipts-save-tabs
						@changeValidation="changeValidation"
						:loading="loading"
					/>
				</template>

				<template v-slot:actions>
					<form-cancel-button/>

					<form-save-button
						@click="save"
						:disabled="loading"
						:valid="valid"
						:loading="saving"
					/>

					<form-destroy-button
						v-if="!isANewOperation()"
						:loading="removing"
						:disabled="loading"
						@remove="remove"
					/>
				</template>
			</form-structure>
		</v-form>

		<conciliation-dialog
			:project="project"
		/>
	</page-container>
</template>

<script>
// eslint-disable-next-line
import { bus } from '@/main';

import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import FormDestroyButton from '@/components/defaults/buttons/FormDestroyButton.vue';
import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';

import HolidaysService from '@/services/HolidaysService';

import ConciliationDialog from './conciliation/ConciliationDialog.vue';

// eslint-disable-next-line
import { mutations, getters, actions } from './state/receipts.state';

// eslint-disable-next-line
import operationHandler from './state/receipts.handler';
import ReceiptsSaveTabs from './ReceiptsSaveTabs.vue';

export default {
	name: 'OperationSave',
	components: {
		PageContainer,
		Breadcrumbs,
		FormStructure,
		FormSaveButton,
		FormDestroyButton,
		ConciliationDialog,
		ReceiptsSaveTabs,
		FormCancelButton,
	},
	data() {
		return {
			loading: false,
			saving: false,
			removing: false,
			debounceUpdatePreview: this.$helpers.registerDebounce(this.updateCalculations, 500),
			valid: true,
		};
	},
	mixins: [operationHandler],
	created() {
		mutations.project(this.$route.params.project);

		mutations.config('id', this.$route.params.id);

		bus.$on('updateNetReceivedValue', this.$forceUpdate);
		bus.$on('operationSaveLoading', (val) => { this.loading = val; });

		this.loadOperation();

		this.loadHolidays();
	},
	computed: {
		project() {
			return getters.project;
		},
		netReceivedValueMatch() {
			if (!this.operation
				|| !this.operation.net_received_value
				|| !this.operation.calculated_expenses
				|| !this.operation.calculated_expenses.net_received_value) {
				return false;
			}

			if (this.operation.net_received_value != this.operation.calculated_expenses.net_received_value) {
				return false;
			}

			return true;
		},
	},
	methods: {
		async loadHolidays() {
			this.loading = true;

			const holidayService = new HolidaysService();

			const response = await holidayService.index();

			this.loading = false;

			mutations.holidays(response.data);
		},
		valueMatchAndNotLoading() {
			return this.netReceivedValueMatch && !this.loading;
		},
		changeValidation(val) {
			this.valid = val;
		},

		async loadOperation() {
			try {
				if (this.isANewOperation()) return;

				this.loading = true;

				await actions.load();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},

		async remove() {
			try {
				if (this.isANewOperation()) return;

				this.removing = true;

				await actions.remove();

				this.returnToPreviousRoute();
				this.showRemoveSuccessDialog();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.removing = false;
			}
		},

		showRemoveSuccessDialog() {
			this.$swal({
				title: 'Operação excluida',
				icon: 'success',
				showConfirmButton: true,
			});
		},

		async save() {
			try {
				this.saving = true;

				await actions.save();

				this.showSaveSuccessDialog();
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.saving = false;
			}
		},

		showSaveSuccessDialog() {
			const title = this.isANewOperation() ? 'Envio de retorno cadastrado com sucesso!' : 'Envio de retorno atualizado com sucesso!';

			this.$swal({
				title,
				icon: 'success',
				showConfirmButton: true,
			});
		},
		/**
		 * HELPER FUNCTIONS
		 */
		getRouteTitle() {
			const id = this.getIdOfRoute();

			if (this.idIsNewOrNotExists(id)) return 'Envio de retornos';

			return 'Edição de retornos';
		},

		idIsNewOrNotExists(id) {
			return (!id || id == 'new' || id == undefined);
		},

		getIdOfRoute() {
			const { id } = this.$route.params;

			return id;
		},

		isANewOperation() {
			if (this.operation.id && this.operation.id != 'new') return false;

			const id = this.getIdOfRoute();

			return (this.idIsNewOrNotExists(id));
		},

		returnToPreviousRoute() {
			this.$router.push({ name: 'ReceiptsList' });
		},
	},
};
</script>

<style>

</style>
