<template>
	<v-chip
		small
		class="mr-2"
	>
		<slot></slot>
	</v-chip>
</template>

<script>
export default {
	name: 'TableChip',
};
</script>

<style>

</style>
