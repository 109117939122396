export default {
	$vue: null,

	/**
	 * Usado na construcao dos erros no sistema para todas as telas.
	 * trata os erros recebidos notificando-os com uma snackbar
	 * @param {Throwable} error
	 * @param {String} defaultMessage
	 *
	 * @return {void}
	 */
	defaultCatchError(error, defaultMessage = 'Ocorreu um erro desconhecido') {
		this.logErrorInDevelopmentMode(error);

		const message = this.getExceptionResponseMessage(error) || defaultMessage;
		this.$vue.prototype.$snackbar.error({ message });
	},

	/**
	 * @param {Throwable} error
	 * @return {String}
	 */
	getExceptionResponseMessage(error) {
		try {
			const { message } = error.response.data;

			if (!message) {
				return null;
			}

			return message;
		} catch (e) {
			return null;
		}
	},

	/**
	 * Verifica se o sistema está em modo de desenvolvimento.
	 * se estiver, notifica o erro
	 * @param {Throwable} error
	 * @return {void}
	 */
	logErrorInDevelopmentMode(error) {
		if (process.env.NODE_ENV !== 'production') {
			console.error(error);
		}
	},
};
