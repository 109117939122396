<template>
  <v-btn
	text
	small
	class="w-100"
	@click="toggleForm()"
  >
	<v-icon> mdi-filter-variant </v-icon>
  </v-btn>
</template>

<script>
export default {
	name: 'ToggleFilterBtn',
	props: {
		value: Boolean,
	},
	data() {
		return {
			toggleValue: this.value,
		};
	},
	watch: {
		toggleValue() {
			this.$emit('input', this.toggleValue);
		},
	},
	methods: {
		toggleForm() {
			this.toggleValue = !this.toggleValue;
		},
	},
};
</script>

<style>

</style>
