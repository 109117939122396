<template>
	<v-form v-model="valid" ref="segmentForm">
		<page-container no-column>
			<v-col cols="12" class="mb-n6">
				<span class="text-caption secondary--text">Cadastro:</span>
			</v-col>

			<v-col xl="3" lg="3" md="6" sm="6" xs="12">
				<v-text-field
					v-model="segment.description"
					label="Descrição"
					:rules="[ rules.required ]"
				/>
			</v-col>
			<v-col xl="3" lg="3" md="3" sm="6" xs="12">
				<v-select
					label="Tipo de registro"
					v-model="segment.type"
					:items="options.register_types"
					:rules="[ v => rules.required(v) ]"
					@change="validateOrResetByType"
					item-value="value"
					item-text="text"
				/>
			</v-col>

			<v-spacer/>

			<v-col xl="2" lg="2" md="3" sm="5" xs="12">
				<form-save-button
					@click="save"
					:create="isToCreate()"
					:valid="valid"
				/>
			</v-col>

			<v-col cols="12">
				<v-divider/>

				<toggle-filter-btn v-model="toggleFilters"/>
			</v-col>

			<v-col cols="12" class="mb-n6" v-show="toggleFilters">
				<span class="text-caption secondary--text">Filtros:</span>
			</v-col>

			<v-col xl="3" lg="3" md="4" sm="6" xs="12" v-show="toggleFilters">
				<v-select
					label="Tipo de Registro"
					v-model="filter.register_type"
					:items="options.register_types"
					item-value="value"
					item-text="text"
					clearable
				/>
			</v-col>

			<v-col xl="3" lg="3" md="4" sm="6" xs="12" v-show="toggleFilters">
				<v-select
					label="Forma de Pagamento"
					v-model="filter.payment_method"
					:items="options.payment_methods"
					item-value="value"
					item-text="text"
					clearable
				/>
			</v-col>

			<v-col cols="12">
				<v-divider/>
			</v-col>

			<v-col cols="12">
				<segment-table
					:segments="filterVisibleSegments"
					:valid="valid"
					:loading="loadingTable"
					:disable-ordering="isEditing()"
					@delete="destroy"
					@edit="edit"
					@view="view"
					@reorder="reorder"
				/>
			</v-col>
		</page-container>
	</v-form>
</template>

<script>
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import BelongsToMixin from '@/components/related/BelongsToMixin';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';

import CNAB from '../cnab_model';
import SegmentTable from './SegmentTable.vue';
import ToggleFilterBtn from '../toggleFilterBtn.vue';

/**
 *
 * ALGUMAS CONSTANTES PARA MELHOR DEFINICAO SINTATICA
 *
 */
const account_type = {
	saving: '0',
	current: '1',
	both: '2',
};

const old_inncash_params = {
	no: '0',
	yes: '1',
	both: '2',
};

/**
 *
 * Definicao do componente
 *
 */
export default {
	name: 'SegmentForm',
	components: {
		PageContainer,
		FormSaveButton,
		SegmentTable,
		ToggleFilterBtn,
	},
	mixins: [BelongsToMixin],
	props: {
		// segmentos cadastrados para o cnab
		value: Array,
	},
	data() {
		return {
			valid: false,
			segment: {},
			options: CNAB.options,
			loadingTable: false,
			rules: {
				required: (v) => !!v || v === 0 || 'Campo Necessário',
				requiredIfIsNotArchiveType: (v) => this.requiredIfIsNotArchiveType(v) || 'campo necessário ',
			},
			filter: {
				register_type: null,
				payment_method: null,
			},
			toggleFilters: false,
			// mixin variables
			primaryKey: 'line_number',
			model: this.segment,
			list: this.value,
			toClean: {
				description: '',
				payment_method: '',
				type: '',
				account_type: account_type.saving,
				is_tax: old_inncash_params.both,
				allow_duplicate_lines: old_inncash_params.no,
				same_bank: old_inncash_params.no,
				same_owner: old_inncash_params.no,
				positions: [],
				id: null,
				line_number: null,
			},
		};
	},
	computed: {
		filterVisibleSegments() {
			let filtered = [];

			if (!this.filter) return this.list;

			filtered = this.filterByRegisterType(this.list);

			filtered = this.filterByPaymentMethod(filtered);

			return filtered;
		},
	},
	watch: {
		list() {
			this.$emit('input', this.list);
		},
		segment() {
			this.model = this.segment;
		},
		model() {
			if (this.segment == this.model) return;

			this.segment = this.model;
		},
	},
	methods: {
		/**
		 * events
		 *
		 * view
		 * apos clicar na linha o usuario eh redirecionado para o segmento de posicoes
		 * com todos as posicoes daquele segmento sendo mostradas
		 */
		view(segment) {
			this.$emit('view', segment);
		},
		/**
		 * helpers e funcoes auxiliares do formulario de segmentos
		 */
		validateForm() {
			this.$refs.segmentForm.validate();

			return this.valid;
		},
		resetForm() {
			this.$refs.segmentForm.reset();
		},
		/**
		 * VALIDACOES E UX
		 */
		validateOrResetByType() {
			const { type } = this.segment;

			if (this.cnabIsOfTypeArchive(type)) {
				this.clearUnecessaryFields();
				this.validateForm();
			}
		},
		cnabIsOfTypeArchive(type) {
			return (CNAB.isArchiveHeader(type) || CNAB.isArchiveTrailer(type));
		},
		/**
		 * paymentTypeRule
		 * verifica atraves dos tipos de registro para checar se:
		 * quando for um registro do tipo lote, possui forma de pagamento.
		 *
		 * @return {Boolean}
		 */
		paymentTypeRule() {
			const { payment_method, type } = this.segment;

			if (!payment_method) {
				if (!this.cnabIsOfTypeArchive(type)) {
					return false;
				}
			}

			return true;
		},
		requiredIfIsNotArchiveType(v) {
			if (!this.cnabIsOfTypeArchive(this.segment.type)) {
				return this.rules.required(v);
			}

			return true;
		},
		clearUnecessaryFields() {
			const {
				description,
				type,
				id,
				index,
			} = { ...this.segment };

			this.segment = {
				...this.toClean,
				description,
				type,
				id,
				index,
			};
		},
		filterByRegisterType(toFilter) {
			if (typeof (this.filter.register_type) == 'undefined'
				|| this.filter.register_type === null
			) {
				return toFilter;
			}

			const filteredByRegisterType = toFilter.filter((segment) => segment.type == this.filter.register_type);

			return filteredByRegisterType;
		},
		filterByPaymentMethod(toFilter) {
			if (typeof (this.filter.payment_method) == 'undefined'
				|| this.filter.payment_method === null
			) {
				return toFilter;
			}

			const filteredByPaymentMethod = toFilter.filter((segment) => segment.payment_method == this.filter.payment_method);

			return filteredByPaymentMethod;
		},
	},
};
</script>

<style>

</style>
