<template>
	<div class="mt-n7 d-flex flex-column w-100">
		<v-tabs v-model="activeTab">
			<v-tab href="#companyGroup">
				Projeto
			</v-tab>

			<v-tab href="#companies" :disabled="loading">
				Empresas
			</v-tab>

			<v-tab href="#functionalities" :disabled="loading">
				Funcionalidades
			</v-tab>
		</v-tabs>

		<v-tabs-items v-model="activeTab">
			<v-tab-item value="companyGroup">
				<company-group-form
					v-model="project"
					:project="project"
				/>
			</v-tab-item>

			<v-tab-item value="companies">
				<company-form
					v-model="project.companies"
					:project="project"
				/>
			</v-tab-item>

			<v-tab-item value="functionalities">
				<functionalities-form
					v-model="project.functionalities"
					:project="project"
				/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import CompanyGroupForm from './companyGroup/CompanyGroupForm.vue';
import CompanyForm from './company/CompanyForm.vue';
import FunctionalitiesForm from './functionalities/FunctionalitiesForm.vue';

export default {
	name: 'ProjectSaveTabs',
	components: {
		CompanyGroupForm,
		CompanyForm,
		FunctionalitiesForm,
	},
	props: {
		value: Object,
		loading: Boolean,
	},
	data() {
		return {
			activeTab: '',
			project: this.value || {},
			valid: false,
		};
	},
	watch: {
		// two-way data-binding
		project: {
			deep: true,
			handler(value) {
				this.$emit('input', value);
			},
		},

		value() {
			if (this.project != this.value) {
				this.project = this.value;
			}
		},
	},
};
</script>

<style>

</style>
