import { bus } from '@/main';

const methods = [
	'average_operation_time', 'gross_value', 'bank_slip_registry_value',
	'serasa_consultation_value', 'ad_valorem', 'tac', 'iof', 'ted_cost',
	'negotiation_retention_value', 'registry_repurchase_fine_value',
	'goodwill', 'net_received_value', 'down_movement_value',
	'repurchase_fine_value', 'registry_send_movement_value', 'prorrogation_movement_value',
];

export default class OperationCalculations {
	static install(getters, mutations, actions) {
		return {
			calculateExpenses() {
				methods.forEach((func) => {
					this[func]();
				});
			},
			average_operation_time() {
				if (actions.calculation_service.paramsIsNotDefined() || actions.calculation_service.registersIsNotDefined()) {
					mutations.calculated_expense('average_operation_time', 0, '0 Dias');
					return;
				}

				const { result, formattedResult } = actions.calculation_service.average_operation_time_calculate();

				mutations.calculated_expense('average_operation_time', result, formattedResult);

				bus.$emit('updateNetReceivedValue');
			},

			gross_value() {
				const operation = getters.operation();

				operation.gross_value = actions.calculation_service.getTotalOfRegistersValues();
			},

			bank_slip_registry_value() {
				actions.calculation_service.calculateByRegisterLength(
					'bank_slip_registry_value',
					'bank_slip_cost',
				);
			},

			serasa_consultation_value() {
				actions.calculation_service.calculateByFieldQuantity(
					'serasa_consultation_value',
					'serasa_consultation_quantity',
					'serasa_cost',
				);
			},

			down_movement_value() {
				actions.calculation_service.calculateByConciliationStatus(
					'down_movement_value',
					['title_down'],
					'down_cost',
				);
			},

			repurchase_fine_value() {
				actions.calculation_service.calculateByConciliationStatus(
					'repurchase_fine_value',
					['title_repurchased', 'partial_repurchased_amount'],
					'repurchase_fine_cost',
				);
			},

			prorrogation_movement_value() {
				actions.calculation_service.calculateByConciliationStatus(
					'prorrogation_movement_value',
					['title_prorrogated'],
					'prorrogation_cost',
				);
			},

			registry_send_movement_value() {
				actions.calculation_service.calculateByConciliationStatus(
					'registry_send_movement_value',
					['registry_sent'],
					'registry_sent_cost',
				);
			},

			registry_repurchase_fine_value() {
				actions.calculation_service.calculateByConciliationStatus(
					'registry_repurchase_fine_value',
					['registry_repurchased'],
					'registry_down_cost',
				);
			},

			ad_valorem() {
				actions.calculation_service.calculateRate('ad_valorem', 'ad_valorem_rate');
			},

			tac() {
				actions.calculation_service.calculateDefault('tac', 'tac_cost');
			},

			iof() {
				actions.calculation_service.calculateRate('iof', 'iof_rate');
			},

			ted_cost() {
				actions.calculation_service.calculateDefault('ted_cost');
			},

			negotiation_retention_value() {
				actions.calculation_service.calculateRate('negotiation_retention_value', 'negotiation_retention');
			},

			/**
			 * Funcao baseada na funcao calculateRate do calculatonService
			 */
			goodwill() {
				actions.calculation_service.calculateSpecifiedGoodwill();
			},

			net_received_value() {
				if (actions.calculation_service.paramsIsNotDefined()
					|| !getters.expense('net_received_value')) {
					mutations.calculated_expense('net_received_value', 0);
				}

				const total = actions.calculation_service.getTotalOfRegistersValues();
				const taxes = actions.calculation_service.getTotalOfRegisterTaxes();

				const value = Number(total) - Number(taxes);

				const result = Number(value).toFixed(2);

				mutations.calculated_expense('net_received_value', result);
			},

			getFirstValidDefaultExpense(fields) {
				let value = '';

				fields.forEach((field) => {
					const returned = getters.default_expenses(field);

					if (Number(returned) != 0) {
						value = field;
					}
				});

				if (value == '') {
					return fields[0];
				}

				return value;
			},
		};
	}
}
