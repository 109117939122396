<template>
  <div>
    <list-edit-button @edit="edit" />

  </div>
</template>
<script>

import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';

export default {
	name: 'VacationsTableOptions',
	components: {
		ListEditButton,
	},

	props: {
		item: { type: Object, required: true },
		user: { type: Object, required: true },
	},

	methods: {

		edit() {
			this.$emit('edit', this.item);
		},
	},
};
</script>
