<template>
	<page-container>
		<breadcrumbs :page-title="getTitleText()"/>

		<v-form v-model="valid" ref="cnabRegisterForm">
			<v-card class="mr-10 mt-8" flat :loading="isLoading">
				<cnab-register-tabs v-model="cnab"/>

				<v-divider/>

				<v-card-actions class="pa-6">
					<v-spacer/>

					<form-destroy-button
						v-if="cnabExists()"
						:loading="isRemoving"
						@remove="remove"
					/>

					<form-save-button
						@click="save"
						:valid="valid"
						:loading="isSaving"
					/>
				</v-card-actions>
			</v-card>
		</v-form>
	</page-container>
</template>

<script>
import CnabService from '@/services/CnabService';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';

import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import CnabRegisterTabs from './CnabRegisterTabs.vue';
import FormDestroyButton from '../../../components/defaults/buttons/FormDestroyButton.vue';
import FormSaveButton from '../../../components/defaults/buttons/FormSaveButton.vue';

export default {
	name: 'CnabRegister',
	components: {
		Breadcrumbs,
		PageContainer,
		CnabRegisterTabs,
		FormDestroyButton,
		FormSaveButton,
	},
	data() {
		return {
			valid: false,
			id: this.$route.params.id,
			isLoading: false,
			isSaving: false,
			isRemoving: false,
			cnab: {
				segments: [],
				positions: [],
			},
		};
	},
	mounted() {
		this.loadCnab();
	},
	methods: {
		cnabExists() {
			return this.id && this.id != 'new';
		},
		getTitleText() {
			if (this.cnabExists()) {
				return 'Editar CNAB';
			}

			return 'Cadastro de CNAB';
		},
		returnToCnabList() {
			this.$router.push({ name: 'CnabList' });
		},
		/**
		 * loadCnab
		 * ao inserir ou adicionar um cnab a necessidade eh a mesma.
		 * para tratar apenas um caso e desenvolver mais rapidamente.
		 * caso haja um id enviado a essa tela
		 * pede os dados atraves do backend.
		 *
		 * caso seja um novo cnab
		 * sera enviado uma requisicao de criacao de um rascunho no backend
		 * caso ja exista um rascunho no backend que nao foi atualizado entao retorna esse registro de rascunho
		 * os dados entao serao sempre tratados como um UPDATE.
		 */
		async loadCnab() {
			const { id } = this;

			this.isLoading = true;

			try {
				const cnabService = new CnabService();
				let response;

				if (this.cnabExists()) {
					response = await cnabService.show({ id });
				} else {
					response = await cnabService.store({ id });
				}

				this.cnab = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isLoading = false;
			}
		},
		async remove() {
			this.isRemoving = true;

			try {
				const cnabService = new CnabService();
				await cnabService.destroy({ id: this.id });

				this.showRemoveSuccessDialog();

				this.returnToCnabList();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isRemoving = false;
			}
		},
		async save() {
			const valid = this.validate();

			if (!valid) return;

			try {
				this.isSaving = true;

				const payload = {
					cnab: { ...this.cnab },
					segments: { ...this.cnab.segments },
				};

				payload.id = this.cnab.id;

				const cnabService = new CnabService();
				await cnabService.update(payload);

				this.showSuccessDialog();

				this.returnToCnabList();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isSaving = false;
			}
		},
		showRemoveSuccessDialog() {
			this.$snackbar.success({ message: 'CNAB Removido com sucesso' });
		},
		showSuccessDialog() {
			this.$snackbar.success({ message: 'CNAB Alterado com sucesso' });
		},
		validate() {
			const valid = this.$refs.cnabRegisterForm.validate();

			return valid;
		},
	},
};
</script>

<style>

</style>
