<template>
	<page-container>
		<breadcrumbs :page-title="getRouteTitle()" />

		<v-form class='w-100' v-model="valid" ref="form" @submit.prevent="save">
			<form-structure :loading="loadingData">
				<template v-slot:content>
					<page-container no-column>
						<!-- CONTENT -->
						<v-col xl="3" lg="3" md="4" sm="6" xs="12">
							<v-text-field
								label="Descrição"
								v-model="userType.title"
								:rules="[(v) => !!v || 'Descrição necessária']"
							/>
						</v-col>

						<!-- SEPARATOR -->
						<v-col xl="9" lg="9" md="8" sm="6" xs="0" class="d-none d-sm-flex"></v-col>

						<!-- LISTA DE ACESSOS -->
						<accesses-list
							v-model="userType.menus"
							@changeItems="onChangeItems"
							:items="accesses"
							:loading="loadingData"
							ref="accessesList"
						/>
					</page-container>
				</template>

				<template v-slot:actions>
					<form-cancel-button/>

					<form-save-button
						:valid="valid"
						@click="save"
						:loading="loadingSubmit"
						:disabled="loadingData"
					/>

					<form-destroy-button
						v-if="!isANewUserType()"
						:loading="loadingRemove"
						:disabled="loadingData"
						@remove="remove"
					/>
				</template>
			</form-structure>
		</v-form>

	</page-container>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';

import AccessService from '@/services/AccessService';
import UserTypeService from '@/services/UserTypeService';
import AccessesList from '@/components/accesses/AccessesList.vue';
import FormDestroyButton from '@/components/defaults/buttons/FormDestroyButton.vue';
import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';

export default {
	name: 'UserTypeSave',
	components: {
		FormStructure,
		PageContainer,
		FormSaveButton,
		Breadcrumbs,
		AccessesList,
		FormDestroyButton,
		FormCancelButton,
	},
	data() {
		return {
			valid: false,
			userType: {
				menus: [],
			},
			accesses: [],
			switchAll: false,
			loadingData: false,
			loadingSubmit: false,
			loadingRemove: false,
		};
	},
	async created() {
		await this.loadAllAccesses();
		await this.showUserType();
	},
	methods: {
		/**
		 * UX
		 */
		getRouteTitle() {
			if (this.isANewUserType()) return 'Criação de Tipos de Usuários';

			return 'Edição de Tipos de Usuários';
		},
		isANewUserType() {
			if (this.userType && this.userType.id) return false;

			const id = this.getIdOfRoute();

			return this.idIsNewOrNotExists(id);
		},
		idIsNewOrNotExists(id) {
			return (!id || id == 'new');
		},
		getIdOfRoute() {
			const { id } = this.$route.params;
			return id;
		},
		returnToPreviousRoute() {
			this.$router.push({ name: 'UserTypes' });
		},
		/**
		 * CRUD METHODS
		 */
		async loadAllAccesses() {
			try {
				this.loadingData = true;

				const accessService = new AccessService();

				const response = await accessService.menus().index();

				this.accesses = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);

				this.accesses = [];
			} finally {
				this.loadingData = false;
			}
		},

		async showUserType() {
			try {
				const id = this.getIdOfRoute();

				if (this.isANewUserType(id)) return;

				this.loadingData = true;

				const userTypeService = new UserTypeService();

				const response = await userTypeService.show(id);

				this.userType = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},

		async save() {
			try {
				const canSave = this.$refs.form.validate();

				if (!canSave) return;

				this.loadingSubmit = true;

				const userTypeService = new UserTypeService();

				if (this.isANewUserType()) {
					await userTypeService.store(this.userType);
				} else {
					await userTypeService.update(this.userType);
				}

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingSubmit = false;
			}
		},

		async remove() {
			try {
				if (this.isANewUserType()) return;

				this.loadingRemove = true;

				const userService = new UserTypeService();

				await userService.destroy(this.userType);

				this.userType = {};
				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingRemove = false;
			}
		},

		onChangeItems(accesses) {
			this.accesses = accesses;
		},
	},
};
</script>

<style>

</style>
