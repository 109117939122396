<template>
	<page-container no-column>
		<v-col cols="12">
			<!-- SELECT PARA ADICIONAR NOVOS PROJETOS PARA O USUÁRIO -->
			<v-col cols="6">
				<v-select
					label="Projetos"
					v-model="selectedProjects"
					@change="allowFunctionalities"
					item-text="name"
					item-value="id"
					:loading="loadingData"
					:readonly="loadingData"
					:items="projects"
					return-object
					multiple
				/>
			</v-col>

			<v-divider></v-divider>

			<related-list
				:list="selectedProjects"
				ref="company"
				no-add
				custom-actions
			>
				<template v-slot:item.actions="{ item }">
					<v-btn
						text
						@click="changeSystemAccesses(item)"
					>
						FUNCIONALIDADES
					</v-btn>
				</template>
			</related-list>
		</v-col>

		<user-functionalities-modal
			:project="handleProject"
			v-model="dialog"
			@change="changeProjectFunctionalities"
		/>
	</page-container>
</template>

<script>
import RelatedList from '@/components/related/RelatedList.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ProjectService from '@/services/ProjectService';

import UserFunctionalitiesModal from './UserFunctionalitiesModal.vue';

export default {
	name: 'UserProjectSaveForm',
	components: {
		PageContainer,
		RelatedList,
		UserFunctionalitiesModal,
	},
	props: {
		value: Array,
	},
	data() {
		return {
			selectedProjects: this.value || [],
			loadingData: false,
			dialog: false,
			handleProject: {
				functionalities: [],
			},
			projects: [],
		};
	},
	watch: {
		value() {
			if (this.selectedProjects == this.value) return;

			this.selectedProjects = this.value;
		},
		selectedProjects() {
			this.$emit('input', this.selectedProjects);
		},
	},
	created() {
		this.loadingProjects();
	},
	methods: {
		async loadingProjects() {
			try {
				this.loadingData = true;

				const projectService = new ProjectService();

				const response = await projectService.index();

				this.projects = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},

		changeSystemAccesses(item) {
			item = this.mergeDatabaseProjectWithManipulated(item);

			this.handleProject = { ...this.handleProject, ...item };

			this.dialog = !this.dialog;
		},

		mergeDatabaseProjectWithManipulated(item) {
			const dataProject = this.projects.find((project) => project.id == item.id);

			item = this.mergeFunctionalities(item, dataProject);

			return item;
		},

		mergeFunctionalities(handle, project) {
			const toMerge = project.functionalities.filter((functionality) => {
				const alreadyHaveFunctionality = handle.functionalities.some((func) => func.id == functionality.id);

				return !alreadyHaveFunctionality;
			});

			handle.functionalities = [...handle.functionalities, ...toMerge];

			return handle;
		},

		changeProjectFunctionalities(project) {
			const updatedSelectedProjects = this.selectedProjects.map((selectedProject) => {
				if (project.id == selectedProject.id) {
					selectedProject = { ...selectedProject, ...project };
					return selectedProject;
				}

				return selectedProject;
			});

			this.selectedProjects = updatedSelectedProjects;
		},

		allowFunctionalities() {
			const withFunctionatilies = this.selectedProjects
				.map((selectedProject) => this.allowAllFunctionalities(selectedProject));

			this.selectedProjects = withFunctionatilies;
		},

		allowAllFunctionalities(project) {
			const selectedFunctionalities = project.functionalities.map((functionality) => {
				if (functionality.user_allowed == undefined) {
					functionality.user_allowed = true;
				}

				return functionality;
			});

			project.functionalities = selectedFunctionalities;

			return project;
		},
	},
};
</script>

<style>

</style>
