<template>
	<page-container no-column>
		<related-list
			@add="onAdd"
			:list="withoutRemovedGoodwills"
			:error="validateError"
			ref="goodwillList"
		>
			<template v-slot:list>
				<related-list-item
					v-for="(goodwill, index) in withoutRemovedGoodwills"
					:key="index"
					:item="goodwill"
					:label="getGoodwillLabel(goodwill)"
					:content="getGoodwillContent(goodwill)"
					@edit="onEdit"
					@remove="onRemove"
					:no-divider="isTheLastItem(withoutRemovedGoodwills, index)"
				/>
			</template>

			<template v-slot:create>
				<v-col xl="4" lg="4" md="6" sm="6" xs="12">
					<operation-type-select
						v-model="toAdd.operation"
						return-object
					/>
				</v-col>

				<v-col xl="4" lg="4" md="6" sm="6" xs="12">
					<percent-field
						label="Taxa"
						v-model="toAdd.goodwill"
					/>
				</v-col>
			</template>
		</related-list>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import RelatedList from '@/components/related/RelatedList.vue';
import OperationTypeSelect from '@/components/operations/OperationTypeSelect.vue';
import RelatedMixin from '@/components/related/RelatedMixin';
import PercentField from '@/components/defaults/PercentField.vue';
import RelatedListItem from '@/components/related/RelatedListItem.vue';

export default {
	name: 'DefaultExpensesGoodwill',
	components: {
		RelatedList,
		OperationTypeSelect,
		PageContainer,
		PercentField,
		RelatedListItem,
	},
	mixins: [RelatedMixin],
	props: {
		value: [Object, Array],
	},
	data() {
		return {
			rules: {
				required: (v) => (!!v && v !== 0) || 'Campo Necessário',
			},
			validateError: null,
			goodwills: this.value,
			toAdd: {

			},
			toClean: {
				id: null,
				operation: null,
				goodwill: null,
				index: null,
			},
		};
	},
	watch: {
		toAdd: {
			deep: true,
			handler() {
				this.validate();
			},
		},
		goodwills() {
			this.$emit('input', this.goodwills);
		},
		value() {
			if (this.goodwills == this.value) {
				return;
			}

			this.goodwills = this.value;

			this.$forceUpdate();
		},
	},
	computed: {
		withoutRemovedGoodwills() {
			return this.filterOnlyRemovedItems({ items: this.goodwills });
		},
	},
	methods: {
		validate() {
			const error = this.validateForm();

			this.validateError = error;

			if (error) {
				return false;
			}

			return true;
		},
		validateForm() {
			if (!this.toAdd.operation) {
				return 'Operação necessária';
			}

			if (!this.toAdd.goodwill) {
				return 'deságio necessário';
			}

			return '';
		},
		getGoodwillLabel(goodwill) {
			return goodwill.operation.name;
		},
		getGoodwillContent(object) {
			const value = object.goodwill;

			const toBrl = (`${value}`).replace('.', ',');

			return `${toBrl} %`;
		},
		isTheLastItem(array, index) {
			return array.length - 1 == index;
		},
		/**
		 * HELPER METHODS
		 */

		alreadyExists(object) {
			return (object && (object.index || object.id));
		},
		cleanForm() {
			this.toAdd = { ...this.toAdd, ...this.toClean };
		},
		/**
		 * CRUD
		 */
		saveOrCreate() {
			const { toAdd } = this;

			if (this.alreadyExists(toAdd)) {
				this.save(toAdd);
			} else {
				this.create(toAdd);
			}
		},
		save(toSave) {
			const unchangedList = this.goodwills;

			const changedList = this.replaceListItem({
				items: unchangedList,
				identifier: 'index',
				id_value: toSave.index,
				replace_value: toSave,
			});

			this.goodwills = changedList;

			this.cleanForm();
		},
		create() {
			const { toAdd } = this;

			if (!this.goodwills) {
				this.goodwills = [];
			}

			toAdd.index = this.goodwills.length + 1;

			this.goodwills.push(toAdd);

			this.cleanForm();
		},
		onEdit(object) {
			const { toAdd } = this;

			this.toAdd = { ...toAdd, ...object };

			const relatedList = this.$refs.goodwillList;
			relatedList.toggleSaveFormToEdit();

			this.$forceUpdate();
		},
		onRemove(object) {
			if (!object.index) return;

			const result = this.deleteListItem({
				items: this.goodwills,
				identifier: 'index',
				id_value: object.index,
			});

			this.goodwills = result;
		},
		onAdd(toAdd) {
			this.saveOrCreate(toAdd);
		},
	},
};
</script>

<style>

</style>
