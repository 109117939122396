<template>
	<login-layout
		@submit="login"
		v-model="valid"
	>
		<template v-slot:form>
			<v-col cols="12" class="text-center">
				<span
					class="
						text-subtitle-1
						font-weight-bold"
					:class="textSecondary ? 'secondary--text' : 'primary--text'"
				>
					Seja bem-vindo(a)
				</span>
			</v-col>

			<v-col cols="12">
				<v-text-field
					outlined
					label="E-mail"
					v-model="user.email"
					:rules="[...rules.email, ...rules.required]"
					:color="textSecondary ? 'secondary' : 'primary'"
					placeholder="Insira seu e-mail"
				/>
			</v-col>

			<v-col cols="12">
				<v-text-field
					outlined
					label="Senha"
					type="password"
					v-model="user.password"
					:rules="[...rules.required]"
					@keydown.enter="login"
					:color="textSecondary ? 'secondary' : 'primary'"
					placeholder="Insira sua senha"
				/>
			</v-col>

			<v-col cols="12">
				<a
					@click="forgotPassword"
					class="
						text-subtitle-1
						ma-0
						mt-4
						text-decoration-none
						font-weight-bold
						text-center"
					:class="forgetPasswordClasses"
				>
					Esqueceu sua senha?
				</a>
			</v-col>

			<v-col cols="12" class="text-center" v-if="!disableSignup">
				<span
					class="
						ma-0
						mb-4
						font-weight-medium"
				>
					Ou se você ainda não possuir acesso, tente cadastrar-se
				</span>
			</v-col>
		</template>

		<template v-slot:actions>
			<v-col class="pa-0" v-if="!disableSignup">
				<v-btn
					depressed
					text
					:color="textSecondary ? 'secondary' : 'primary'"
					@click="signup"
				>
					Cadastre-se
				</v-btn>
			</v-col>

			<v-col class="pa-0 text-end ml-n1">
				<v-btn
					depressed
					color="primary"
					:loading="design.loading"
					@click="login"
					:disabled="!valid"
					block
				>
					Entrar
				</v-btn>
			</v-col>
		</template>
	</login-layout>
</template>

<script>
import LoginLayout from '../../components/auth/LoginLayout.vue';

export default {
	props: {
		logoUrl: String,
		loggingIn: Boolean,
		disableSignup: Boolean,
		textSecondary: Boolean,
	},
	components: {
		LoginLayout,
	},
	watch: {
		loggingIn(value) {
			this.design.loading = value;
		},
	},
	computed: {
		forgetPasswordClasses() {
			let classes = '';

			classes += this.textSecondary ? 'secondary--text' : 'primary--text';

			classes += this.forgotPassword ? ' mt-n3' : '';

			return classes;
		},
	},
	data() {
		return {
			user: {
				email: '',
				password: '',
			},
			rules: {
				required: [(value) => !!value || 'Campo obrigatório.'],
				email: [(v) => /.+@.+\..+/.test(v) || 'Email inválido.'],
			},
			design: {
				loading: false,
			},
			valid: false,
		};
	},
	methods: {
		signup() {
			this.$emit('signup');
		},
		login() {
			const { email, password } = this.user;
			this.$emit('login', { email, password });
		},
		forgotPassword() {
			this.$emit('forgotPassword');
		},
	},
};
</script>

<style lang="sass" scoped>
@import '@/styles/breakpoints.sass'

.login-form-grid-layout
	display: grid
	grid-template: 20% 50% 20% / 100%
	row-gap:5%
</style>
