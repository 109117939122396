<template>
	<project-dashboard/>
</template>
<script>
import ProjectDashboard from '@/views/projects/dashboard/ProjectDashboard.vue';

export default {
	name: 'ProjectView',
	components: {
		ProjectDashboard,
	},
	created() {
	},
};
</script>

<style>
</style>
