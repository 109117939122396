<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				:class="right ? 'float-right' : 'float-left'"
				v-bind="attrs"
				v-on="on"
				@click="invertSelect"
				icon
			>
				<v-icon> {{ selectedOnce ? 'mdi-select-inverse' : 'mdi-select' }} </v-icon>
			</v-btn>
		</template>
		<span> Inverter Seleção</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'InverSelectionButton',
	props: {
		right: Boolean,
		items: Array,
	},
	data() {
		return {
			selectedOnce: false,
		};
	},
	methods: {
		invertSelect() {
			this.selectedOnce = !this.selectedOnce;

			const itemsToInvert = [...this.items];

			const invertedItems = itemsToInvert.map((item) => {
				item.value = !item.value;

				return item;
			});

			this.$emit('selectInverted', invertedItems);
		},
	},
};
</script>

<style>

</style>
