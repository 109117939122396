<template>
  <div>
    <v-data-table
      :headers="filterHeaders"
      :items="vacations.itens"
      :loading="loadingData"
      :sort-by="['vacations_id']"
      :sort-desc="[true]"
    >
      <template
        slot="body.append"
        v-if="validateFilters() && validatePermission()"
      >
        <tr>
          <th><h2>Saldo</h2></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <h2>{{ calculated }} dias</h2>
          </th>
        </tr>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-btn depressed small :color="colors(item.type)">{{
          types(item.type)
        }}</v-btn>
      </template>

      <template v-slot:[`item.start_date`]="{ item }">
        {{ convertToDate(item.start_date) }}
      </template>

      <template v-slot:[`item.end_date`]="{ item }">
        {{ convertToDate(item.end_date) }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        {{ status(item.status, item) }}</template
      >
      <template v-slot:[`item.period`]="{ item }">
        {{ period(item.period) }}</template
      >
      <template v-slot:[`item.actions`]="{ item }">
        <vacations-table-options
          :item="item"
          :user="user"
          @loading="loadingData = true"
          @reload="loadVacationsRequest()"
          @edit="editData"
          @show="showData"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';

import VacationsService from '@/services/VacationsService';
import TableTools from '@/utils/TableTools';
import VacationsTableOptions from './VacationsTableOptions.vue';

export default {
	name: 'VacationsTableList',
	mixins: [TableTools],
	data: () => ({
		calculated: 0,
		sortable: true,

		vacations: {
			itens: [],
			headers: [
				{
					text: '#',
					value: 'vacations_id',
					sortable: true,
					permission: false,

				},
				{
					text: 'Usuário',
					value: 'creator.name',
					sortable: false,
					permission: true,
				},
				{
					text: 'Tipo',
					value: 'type',
					sortable: false,
					permission: false,
				},
				{
					text: 'Observação',
					value: 'observation',
					sortable: false,
					permission: false,

				},
				{
					text: 'Data de início',
					value: 'start_date',
					sortable: false,
					permission: false,

				},
				{
					text: 'Data final',
					value: 'end_date',
					sortable: false,
					permission: false,

				},
				{
					text: 'Período',
					value: 'period',
					sortable: false,
					permission: false,

				},
				{
					text: 'Dias',
					value: 'number_days',
					sortable: false,
					permission: false,

				},
				{
					text: 'Status',
					value: 'status',
					sortable: false,
					align: 'center',
					permission: false,

				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					permission: false,

				},
			],
		},
		user: {},
		loadingData: false,
	}),
	components: {
		VacationsTableOptions,
	},
	props: {
		filters: [Object, String],
		fields: [Object, Array],
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.loadVacationsRequest();
			},
		},
	},
	computed: {
		filterHeaders() {
			return this.vacations.headers.filter((header) => {
				if (this.user.user_type.name === 'admin') return header;

				return header.permission === false;
			});
		},
	},
	created() {
		this.getLoggedUser();
		this.loadVacationsRequest();
	},
	methods: {
		async loadVacationsRequest() {
			try {
				this.loadingData = true;
				const params = {
					...this.getParams(),
					permission: this.user.user_type.name === 'admin',
				};

				const vacationService = new VacationsService();

				const response = await vacationService.index({ params });

				this.vacations.itens = response;
				this.$emit('calculate', this.vacations.itens);
				this.calculate(this.vacations.itens);
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		getParams() {
			let value = this.filters;

			if (this.fields.type === 'date') {
				value = moment(this.filters, 'DD/MM/YYYY').format('YYYY-MM-DD');
			}

			const params = {
				value,
				fields: this.fields,
			};

			return params;
		},
		status(string, item) {
			if (string === 'approve') {
				return 'Pedido aprovado';
			}

			if (string === 'disapprove') {
				return 'Pedido reprovado';
			}

			if (string === '' && item.type === 'S') {
				return 'Pendente de análise';
			}

			return '';
		},
		types(string) {
			switch (string) {
			case 'V':
				return 'Venda';
			case 'C':
				return 'Crédito';
			case 'CG':
				return 'Crédito Gerado';
			case 'B':
				return 'Baixa';
			default:
				return 'Solicitação';
			}
		},
		period(string) {
			switch (string) {
			case 'evening':
				return 'Tarde';
			case 'morning':
				return 'Manhã';
			case 'integral':
				return 'Integral';
			default:
				return '-';
			}
		},
		colors(string) {
			switch (string) {
			case 'V':
				return '#FFEBEB';
			case 'C':
				return '#C1E3C8';
			case 'CG':
				return '#C1E3C8';
			case 'B':
				return '#FFEBEB';
			default:
				return '#F5E0B5';
			}
		},
		convertToDate(field) {
			return this.$helpers.mysqlTimestampToBr(field);
		},

		getLoggedUser() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);
			this.user = tokenData;
		},
		editData(item) {
			this.$emit('edit', item);
		},

		validateFilters() {
			return (
				Object.values(this.getParams()).length > 0
        && this.fields.field === 'name'
        && this.filters.length > 1
        && !this.loadingData
			);
		},

		calculate(itens) {
			let day = 0;
			itens.forEach((element) => {
				if (element.type === 'C' || element.type === 'CG') {
					day += element.number_days;
				}
				if (element.type === 'S' && element.status === 'approve') {
					day -= element.number_days;
				}

				if (element.type === 'B') {
					day -= element.number_days;
				}
			});

			this.calculated = day;
		},
		validatePermission() {
			return this.user.user_type.name === 'admin';
		},

		async showData(params) {
			this.$router.push({ name: 'VacationsShow', params });
		},
	},
};
</script>
