<template>
	<page-container no-column>
		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<percent-field
				label="Multa de Recompra"
				v-model="default_expenses.repurchase_fine_rate"
				@input="updateNetReceivedValue"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<percent-field
				label="Juros de Recompra"
				v-model="default_expenses.repurchase_tax_rate"
				@input="updateNetReceivedValue"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<percent-field
				label="Juros de mora"
				v-model="default_expenses.mora_tax_rate"
				@input="updateNetReceivedValue"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="Tarifa de Recompra"
				v-model="default_expenses.repurchase_fine_cost"
				@input="updateNetReceivedValue"
				prefix="R$"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="Tarifa de Envio p/ Cartório"
				v-model="default_expenses.registry_sent_cost"
				@input="updateNetReceivedValue"
				prefix="R$"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="Tarifa de Retirada de Cartório"
				v-model="default_expenses.registry_down_cost"
				@input="updateNetReceivedValue"
				prefix="R$"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="Tarifa de Prorrogação"
				v-model="default_expenses.prorrogation_cost"
				@input="updateNetReceivedValue"
				prefix="R$"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="Tarifa de Baixa de Título"
				v-model="default_expenses.down_cost"
				@input="updateNetReceivedValue"
				prefix="R$"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="Tarifa de Segunda Via de Boleto"
				v-model="default_expenses.bank_slip_duplicate_cost"
				@input="updateNetReceivedValue"
				prefix="R$"
			/>
		</v-col>
	</page-container>
</template>

<script>
import { bus } from '@/main';

import PercentField from '@/components/defaults/PercentField.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ProjectService from '@/services/ProjectService';

export default {
	name: 'DefaultIndirectExpensesForm',
	components: {
		PercentField,
		PageContainer,
	},
	props: {
		value: Object,
		project: [String, Number, Object],
	},
	data() {
		return {
			rules: {
				required: (v) => (!!v && v !== 0) || 'Campo Necessário',
				doesntHaveDefinedThisFund: () => this.defaultExpensesAlreadyHaveFund(),
			},
			default_expenses: this.value || {},
			alreadyDefinedFunds: [],
			loadingAlreadyDefinedFunds: false,
		};
	},
	watch: {
		default_expenses() {
			this.$emit('input', this.default_expenses);
		},
		value() {
			if (this.default_expenses === this.value) return;

			this.default_expenses = this.value;
		},
	},
	created() {
		this.loadAlreadyDefinedFunds();
	},
	methods: {
		updateNetReceivedValue() {
			bus.$emit('updateNetReceivedValue');
		},
		async loadAlreadyDefinedFunds() {
			if (this.alreadyDefinedFunds && this.alreadyDefinedFunds.length) {
				return this.alreadyDefinedFunds;
			}

			try {
				const projectService = new ProjectService();

				this.loadingAlreadyDefinedFunds = true;

				const response = await projectService
					.defaultExpenses(this.project)
					.index();

				this.alreadyDefinedFunds = this.$helpers.pluck('fund', response.data);

				return this.alreadyDefinedFunds;
			} catch (error) {
				this.$http.defaultCatchError(error);

				return [];
			} finally {
				this.loadingAlreadyDefinedFunds = false;
			}
		},
		defaultExpensesAlreadyHaveFund() {
			this.loadAlreadyDefinedFunds();

			const alreadyFund = this.alreadyDefinedFunds;

			if (!alreadyFund || !alreadyFund.length) {
				return true;
			}

			const alreadyDefined = this.$helpers
				.find('id', this.default_expenses.fund_id, alreadyFund);

			const isToSave = this.isToSaveDefaultExpenses();

			if (alreadyDefined && isToSave) {
				return 'Fundo já utilizado, por favor acesse a edição desse fundo';
			}

			return true;
		},
		isToSaveDefaultExpenses() {
			return !this.$route.params.expense
				|| this.$route.params.expense == 'new';
		},
	},
};
</script>

<style>

</style>
