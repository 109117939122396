<template>
  <page-container>
	<breadcrumbs page-title="Envio de recebimentos"/>

	<list-structure>
		<template v-slot:search>
			<v-text-field
				label="Pesquisar"
				v-model="searchTerm"
				prepend-icon="mdi-magnify"
				dense
			/>
		</template>

		<template v-slot:actions>
			<list-add-button @add="add"/>
		</template>

		<template v-slot:table>
			<operation-list-table
				:search="searchTerm"
				@edit="edit"
			/>
		</template>
	</list-structure>
  </page-container>
</template>

<script>
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';

// nested components
import OperationListTable from './ReceiptsTable.vue';
// eslint-disable-next-line
import { actions } from '../save/state/receipts.state';

export default {
	components: {
		ListAddButton,
		ListStructure,
		PageContainer,
		Breadcrumbs,
		OperationListTable,
	},
	data() {
		return {
			project: this.$route.params.id,
			searchTerm: '',
		};
	},
	created() {
		actions.clean();
	},
	methods: {
		add() {
			this.pushToOperationSendRoute({ id: 'new' });
		},
		edit(id) {
			this.pushToOperationSendRoute({ id });
		},
		pushToOperationSendRoute(params) {
			this.$router.push({ name: 'ReceiptsUpload', params });
		},
	},
};
</script>

<style>

</style>
