<template>
	<v-subheader class="mb-6 mt-2">
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-on="on"
					v-bind="attrs"
					icon
					@click="returnToPreviousRoute"
					class="mr-3"
				>
					<v-icon> mdi-arrow-left </v-icon>
				</v-btn>
			</template>

			<span>Voltar</span>
		</v-tooltip>

		<span class="text-h6 font-weight-bold ml-n2"> {{ pageTitle }} </span>

		<span class="text-h6 mx-3"> | </span>

		<!-- BACK TO HOME BUTTON -->
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-on="on"
					v-bind="attrs"
					icon
					@click="goToHomePage"
				>
					<v-icon> mdi-home-outline </v-icon>
				</v-btn>
			</template>

			<span>Voltar a Página inicial</span>
		</v-tooltip>
	</v-subheader>
</template>

<script>
export default {
	name: 'Breadcrumbs',
	props: {
		pageTitle: String,
	},
	methods: {
		goToHomePage() {
			this.$router.replace({ name: 'Home' });
		},
		returnToPreviousRoute() {
			this.$router.go(-1);
		},
	},
};
</script>

<style>

</style>
