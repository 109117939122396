<template>
  <v-data-table
	:headers="headers"
	:items="segments"
	:items-per-page="-1"
	v-show="!loading"
	:sort-by="sortby"
	must-sort
	hide-default-footer
	item-key="line_number"
	class="segment-table"
	@click:row="handleClick"
  >
	<template v-slot:item.handle>
		<v-btn icon class="handle">
			<v-icon> mdi-reorder-horizontal </v-icon>
		</v-btn>
	</template>

	<template v-slot:item.payment_method="{ item }">
		{{ getOptionText('payment_methods', item.payment_method) }}
	</template>

	<template v-slot:item.type="{ item }">
		{{ getOptionText('register_types', item.type) }}
	</template>

	<template v-slot:item.account_type="{ item }">
		{{ getOptionText('account_types', item.account_type) }}
	</template>

	<template v-slot:item.is_tax="{ item }">
		{{ getOptionText('is_tax', item.is_tax) }}
	</template>

	<template v-slot:item.allow_duplicate_lines="{ item }">
		{{ getOptionText('allow_duplicate_lines', item.allow_duplicate_lines) }}
	</template>

	<template v-slot:item.same_bank="{ item }">
		{{ getOptionText('same_bank', item.same_bank) }}
	</template>

	<template v-slot:item.same_owner="{ item }">
		{{ getOptionText('same_owner', item.same_owner) }}
	</template>

	<template v-slot:item.actions="{ item }">
		<div class="w-100 d-flex flex-item flex-row">
			<list-edit-button
				@click="editSegment(item)"
				stop-propagation
			/>

			<list-remove-button
				@click="deleteSegment(item)"
				class-style="mx-2"
				stop-propagation
			/>
		</div>
	</template>
  </v-data-table>
</template>

<script>
import Sortable from 'sortablejs';
import ListRemoveButton from '@/components/defaults/buttons/ListRemoveButton.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';
import CNAB from '../cnab_model';

export default {
	components: {
		ListEditButton,
		ListRemoveButton,
	},
	name: 'SegmentTable',
	props: {
		segments: Array,
		loading: Boolean,
		disableOrdering: Boolean,
	},
	data() {
		return {
			sortby: ['line_number'],
			options: CNAB.options,
			headers: [
				{ text: '', value: 'handle', sortable: false },
				{ text: 'Descrição', value: 'description', sortable: false },
				{ text: 'Forma de Pagamento', value: 'payment_method', sortable: false },
				{ text: 'Tipo de Registro', value: 'type', sortable: false },
				{ text: 'Tipo de Conta', value: 'account_type', sortable: false },
				{ text: 'Tributo', value: 'is_tax', sortable: false },
				{ text: 'Linhas Duplicadas', value: 'allow_duplicate_lines', sortable: false },
				{ text: 'Mesmo Banco', value: 'same_bank', sortable: false },
				{ text: 'Mesmo Titular', value: 'same_owner', sortable: false },
				{ text: '', value: 'actions', sortable: false },
			],
		};
	},
	mounted() {
		this.createSortableTable();
	},
	methods: {
		createSortableTable() {
			const table = document.querySelector('.segment-table tbody');

			Sortable.create(table, {
				handle: '.handle',
				onEnd: this.dragAndDrop,
			});
		},
		dragAndDrop(event) {
			const { newIndex, oldIndex } = event;
			const { segments } = this;

			const selected = segments.splice(oldIndex, 1);

			segments.splice(newIndex, 0, selected[0]);

			this.$emit('reorder', segments);
		},
		deleteSegment(segment) {
			this.$emit('delete', segment);
		},
		editSegment(segment) {
			this.$emit('edit', segment);
		},
		getOptionText(option, value) {
			const found = this.options[option].find((row) => row.value == value);

			return found ? found.text : '';
		},
		handleClick(segment) {
			this.$emit('view', segment);
		},
	},
};
</script>

<style>

</style>
