var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loadingData,"customFilter":_vm.filterByAllData},scopedSlots:_vm._u([{key:"item.companies",fn:function(ref){
var item = ref.item;
return _vm._l(((item.companies)),function(company){return _c('table-chip',{key:company.id,staticClass:"my-1 mx-1"},[_vm._v(" "+_vm._s(company.name)+" ")])})}},{key:"item.operation_expense.gross_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")((item.operation_expense ? item.operation_expense.gross_value : '')))+" ")]}},{key:"item.operation_expense.net_received_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")((item.operation_expense ? item.operation_expense.net_received_value : '')))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToDate(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('list-edit-button',{on:{"edit":function($event){return _vm.edit(item)}}})]}},{key:"loading",fn:function(){return [_c('loading-data-circular')]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }