<template>
  <div class="d-flex flex-col w-100">
    <v-data-table
      v-model="selected"
      :headers="filteredHeaders"
			:options.sync="options"
      :items="items"
      :loading="loading"
      show-select
      class="w-100"
      color="primary"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @item-selected="toggleConciliation"
    >
      <template v-slot:item.net_value="{ item }">
        {{ item.net_value | toCurrency }}
      </template>

      <template v-slot:item.due_date="{ item }">
        {{ item.due_date | mysqlDateToBr }}
      </template>

      <template v-slot:item.issue_date="{ item }">
        {{ item.issue_date | mysqlDateToBr }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip>
          {{
							validateStatus(item)
          }}
        </v-chip>
      </template>
				<template v-slot:item.repurchased_value_total="{ item }">
				{{ sumAllRepurchasedValues(item)  | toCurrency }}
			</template>

      <template v-slot:[`item.repurchased_value`]="{item}">
        <v-edit-dialog
          :return-value="item.repurchased_value"
					@save="checkField(item)"
        >
          {{
            getRepurchasedValue(item) |toCurrency
          }}
          <template v-slot:input>
            <v-currency-field
              prefix="R$"
              v-model="item.repurchased_value"
              label="Valor"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.interest`]="{item}">
        <v-edit-dialog
          :return-value="item.interest"
					@save="checkField(item)"
        >
          {{ getInterest(item) | toCurrency}}
          <template v-slot:input>
            <v-currency-field
              prefix="R$"
              v-model="item.interest"
              label="Juros"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>

    <v-pagination v-model="page" :length="pageCount" total-visible="7" />
  </div>
</template>

<script>
import OperationService from '@/services/OperationService';
import { getters } from '../state/operation.state';

export default {
	name: 'ConciliationDialogList',
	data() {
		return {
			snack: false,
			snackColor: '',
			snackText: '',
			defaultStatus: this.status,

			selected: this.value,
			headers: [
				{ text: 'NF', value: 'number', status: true },
				{ text: 'Valor', value: 'net_value', status: true },
				{ text: 'Valor Recomprado Total', value: 'repurchased_value_total' },
				{ text: 'Valor Recomprado', value: 'repurchased_value', status: false },
				{ text: 'Juros', value: 'interest', status: false },
				{ text: 'Data de Vencimento', value: 'due_date', status: true },
				{ text: 'Data de Emissão', value: 'issue_date', status: true },
				{ text: 'Status', value: 'status', status: true },
			],
			items: [],
			loading: false,
			focusT: this.focus,
			error: '',
			page: 1,
			itemsPerPage: 4,
			pageCount: 0,
			options: {},

		};
	},

	props: {
		value: Array,
		filters: Object,
		project: [Number, String],
		status: String,
		conciliated: Array,
		focus: Boolean,
	},
	watch: {
		value() {
			if (this.selected == this.value) {
				return;
			}

			this.selected = this.value;
		},
		selected() {
			this.$emit('input', this.selected);

			this.$emit('change', this.selected);
		},
		filters: {
			deep: true,
			handler() {
				this.search();
			},
		},
		page() {
			this.search();
		},
		focus: {
			deep: true,
			handler(value) {
				this.focusT = value;
			},
		},
	},
	computed: {
		filteredHeaders() {
			if (['partial_repurchased_amount', 'repurchase_value'].includes(this.status)) return this.headers;
			return this.headers.filter((header) => !!header.status);
		},
	},
	created() {
		this.search();
	},
	methods: {
		returnToFirstPage() {
			this.page = 1;
		},
		async search() {
			try {
				this.items = [];
				this.loading = true;

				const params = this.getParams();

				const operationService = new OperationService();

				const response = await operationService.registers(this.project).index({ params });
				this.items = response.data;

				this.paginate(response);

				this.items = this.items.map((element) => {
					const item = this.selected.filter((select) => element.id === select.id);

					if (item[0]) {
						element.interest = item[0].interest || 0;
						element.repurchased_value = item[0].repurchased_value || 0;
					}

					return element;
				});
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
		sumAllRepurchasedValues(item) {
			let value = 0;

			item.status.forEach((element) => {
				if (element.repurchased_value || item.repurchased_value) {
					value += Number(element.repurchased_value) || Number(item.repurchased_value);
				}
			});

			return value;
		},
		serializeStatusText(status) {
			switch (status) {
			case 'partial_repurchased_amount':
				return 'Título recomprado parcial';
			case 'registry_sent':
				return 'Registro Enviado ao Cartório';
			case 'registry_send_movement_value':
				return 'Registro Enviado ao Cartório';
			case 'title_in_serasa':
				return 'Consultado no SERASA';
			case 'registry_repurchased':
				return 'Retirado do Cartório';
			case 'title_repurchased':
				return 'Título Recomprado';
			case 'repurchase_value':
				return 'Título Recomprado';
			case 'title_down':
				return 'Título baixado';
			case 'title_prorrogated':
				return 'Título Prorrogado';
			case '':
				return 'Em aberto';
			default:
				return '';
			}
		},
		getParams() {
			const pagination = {
				page: this.page,
				per_page: this.itemsPerPage,
			};

			const filters = Object.entries(this.filters);

			filters.forEach(([filter, value]) => {
				if (!value) {
					delete this.filters[`${filter}`];
				}
			});

			const params = {
				...pagination,
				...this.filters,
			};

			return params;
		},

		getRepurchasedValue(item) {
			if (item.repurchased_value !== null) return item.repurchased_value;

			return '';
		},
		getInterest(item) {
			if (item.interest !== null) return item.interest;

			return '';
		},
		toggleConciliation({ item }) {
			if (this.alreadyChecked(item)) {
				this.uncheck(item);
			} else {
				this.check(item);
			}

			this.$emit('change', this.selected);
		},

		alreadyChecked(item) {
			if (!this.selected || !this.selected.length) {
				return false;
			}

			const alreadyChecked = this.selected.find((row) => row.id == item.id);

			return alreadyChecked;
		},

		validateStatus(item) {
			if (Object.keys(item.status).length > 0) {
				return this.serializeStatusText(item.status[0].status);
			}

			return 'Em aberto';
		},
		uncheck(item) {
			if (this.error == item.number) {
				this.$emit('error', '');
			}

			const operationId = getters.operation().id;

			this.selected = this.selected.map((register) => {
				if (!register.status) return register;

				register.status = register.status.filter((status) => {
					const sameStatus = status.status != item.status;
					const sameOperation = status.fund_return_id == operationId;

					return sameStatus && sameOperation;
				});

				return register;
			});

			const withoutItem = this.selected.filter((select) => select.id != item.id);

			this.selected = withoutItem;
		},
		checkField(item) {
			this.uncheck(item);

			this.$nextTick(() => {
				this.check(item);
			});
		},
		check(item) {
			if (!this.selected || !this.selected.length) {
				this.selected = [];
			}

			if (this.alreadyConciliateInAnotherUpload(item)) {
				console.log(`Título:   ${item.number}  - Já Conciliado em outra operação`);
				this.$emit('error', `Título:   ${item.number}  - Já recomprado em outra operação`);
				this.error = item.number;
			}

			this.selected.push(item);
		},

		close() {
			console.log('Dialog closed');
		},

		alreadyConciliateInAnotherUpload(item) {
			const selected = this.conciliated.find((conciliation) => conciliation.id == item.id);

			let preparedToConciliate = false;
			let alreadyConciliated = false;

			if (selected && selected.status) {
				preparedToConciliate = selected.status.some((row) => row.status == (this.status || 'partial_repurchased_amount'));
			}

			if (item && item.status) {
				alreadyConciliated = item.status.some((row) => row.status == (this.status || 'partial_repurchased_amount'));
			}

			return preparedToConciliate || alreadyConciliated;
		},

		paginate(response) {
			this.page = response.current_page;

			const perPage = Number(response.per_page);

			this.itemsPerPage = Number.isNaN(perPage) ? 6 : perPage;

			this.pageCount = response.last_page;
			const filters = Object.entries(this.filters);

			filters.forEach(([, value]) => {
				if (value && this.focus) {
					this.page = 1;
				}
			});

			if (filters.length <= 0 && this.focus) {
				this.page = 1;
			}
		},
		existInSelected(item) {
			const status = item.status.some((row) => row.status == this.status);
			const index = this.conciliated.findIndex((conciliation) => conciliation.id == item.id
			&& conciliation.number == item.number && status);

			if (index) {
				this.selected.splice(index, 1);
			}
		},
	},
};
</script>

<style></style>
