<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:search="search"
		:loading="loadingData"
		:customFilter="filterByAllData"
	>
		<template v-slot:item.companies="{ item }">
			<table-chip
				v-for="company in (item.companies)"
				:key="company.id"
				class="my-1 mx-1"
			>
				{{ company.name }}
			</table-chip>
		</template>

		<template v-slot:item.operation_expense.gross_value="{ item }">
			{{ (item.operation_expense ? item.operation_expense.gross_value : '') | toCurrency }}
		</template>

		<template v-slot:item.operation_expense.net_received_value="{ item }">
			{{ (item.operation_expense ? item.operation_expense.net_received_value : '') | toCurrency }}
		</template>

		<template v-slot:item.created_at="{ item }">
			{{ convertToDate(item.created_at) }}
		</template>

		<!-- ACTIONS -->
		<template v-slot:item.actions="{ item }">
			<list-edit-button @edit="edit(item)"/>
		</template>

		<template v-slot:loading>
			<loading-data-circular/>
		</template>
	</v-data-table>
</template>

<script>
import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';
import TableChip from '@/components/defaults/layout/TableChip.vue';

import OperationService from '@/services/OperationService';
import TableTools from '@/utils/TableTools';

export default {
	name: 'OperationListTable',
	components: {
		ListEditButton,
		LoadingDataCircular,
		TableChip,
	},
	props: {
		search: String,
	},
	data() {
		return {
			items: [],
			loadingData: false,
			project: this.$route.params.project,
			headers: [
				{ text: '#', value: 'id', sortable: true },
				{ text: 'Data da Operação', value: 'created_at', sortable: false },
				{ text: 'Usuário', value: 'user.name', sortable: false },
				{ text: 'Fundo', value: 'fund.name', sortable: false },
				{ text: 'Valor Total', value: 'operation_expense.gross_value', sortable: false },
				{ text: 'Valor Líquido', value: 'operation_expense.net_received_value', sortable: false },
				{ text: 'Observação', value: 'observation', sortable: false },
				{ text: '', value: 'actions', sortable: false },
			],
		};
	},
	created() {
		this.loadProjects();
	},
	mixins: [TableTools],
	methods: {
		edit(item) {
			this.$emit('edit', item.id);
		},

		convertToDate(field) {
			return this.$helpers.mysqlTimestampToBr(field);
		},

		async loadProjects() {
			try {
				this.loadingData = true;

				const operationService = new OperationService();

				const response = await operationService.upload(this.project).index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
	},
};
</script>

<style>

</style>
