<template>
	<v-data-table
		:headers="headers"
		:items="positions"
		:items-per-page="-1"
		hide-default-footer
		class="position-table"
		:sort-by="['line']"
	>
		<template v-slot:item.line>
			<v-btn icon class="handle-positions">
				<v-icon> mdi-reorder-horizontal </v-icon>
			</v-btn>
		</template>

		<template v-slot:item.actions="{ item }">
			<div class="w-100 d-flex flex-item flex-row">
				<list-edit-button
					@edit="editPosition(item)"
					stop-propagation
				/>

				<list-remove-button
					@remove="deletePosition(item)"
					class-style="mx-2"
					stop-propagation
				/>
			</div>
		</template>

		<template v-slot:item.is_key="{ item }">
			<v-switch
				v-model="item.is_key"
			/>
		</template>
	</v-data-table>
</template>

<script>
import Sortable from 'sortablejs';
import ListRemoveButton from '@/components/defaults/buttons/ListRemoveButton.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';

export default {
	name: 'PositionTable',
	components: {
		ListRemoveButton,
		ListEditButton,
	},
	props: {
		positions: Array,
		disableOrdering: Boolean,
	},
	data() {
		return {
			headers: [
				{ text: '', value: 'line', sortable: false },
				{ text: 'Descrição', value: 'description', sortable: false },
				{ text: 'Coluna', value: 'column', sortable: false },
				{ text: 'Posição Inicial', value: 'initial_position', sortable: false },
				{ text: 'Posição Final', value: 'final_position', sortable: false },
				{ text: 'Conteúdo', value: 'content', sortable: false },
				{ text: 'Identificador', value: 'is_key' },
				{ text: '', value: 'actions', sortable: false },
			],
		};
	},
	mounted() {
		this.createSortableTable();
	},
	methods: {
		deletePosition(item) {
			this.$emit('delete', item);
		},
		editPosition(item) {
			this.$emit('edit', item);
		},
		createSortableTable() {
			const table = document.querySelector('.position-table tbody');

			Sortable.create(table, {
				handle: '.handle-positions',
				onEnd: this.dragAndDrop,
			});
		},
		dragAndDrop(event) {
			const { newIndex, oldIndex } = event;

			const { positions } = this;
			const selected = positions.splice(oldIndex, 1);

			positions.splice(newIndex, 0, selected[0]);

			this.$emit('reorder', positions);
		},
	},
};
</script>

<style>
.handle-positions {
	cursor: move !important;
	cursor: -webkit-grabbing !important;
}
</style>
