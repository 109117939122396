<template>
  <v-col xl="4" lg="4" md="12" sm="12" xs="12">
    <v-col cols="12">
      <separator label="Resumo" />
    </v-col>

    <v-col cols="12">
      <div class="bg-light-3 w-100 rounded pa-4 text--secondary mb-n3">
        <div class="w-100 d-flex text-center" v-if="fileIsRead">
          <span class="text-h6 font-weight-normal mx-auto my-4">
            Aguardando informações principais
          </span>
        </div>

        <div class="w-100" v-else>
          <div class="d-flex flex-row pt-3 pb-1">
            <span class="flex-item text-body-1 item-3 w-100">
              Nº Total de Títulos
            </span>
            <span
              class="
                flex-item
                text-body-1
                item-1
                w-100
                font-weight-bold
                text-end
                mr-1
              "
            >
              {{ itemsLength() }}
            </span>
          </div>

          <v-divider class="mt-1 mb-3"></v-divider>

          <div class="d-flex flex-row pt-3 pb-1">
            <span class="flex-item text-body-1 item-3 w-100">
              Valor dos Títulos
            </span>
            <span
              class="
                flex-item
                text-body-1
                item-1
                w-100
                font-weight-bold
                text-end
                mr-1
              "
            >
              {{ grossTotal() }}
            </span>
          </div>

          <v-divider class="mt-1 mb-3"></v-divider>

          <div class="d-flex flex-row pt-3 pb-1">
            <span class="flex-item text-body-1 item-3 w-100">
              Incidentes Diretos
            </span>
            <span
              class="
                flex-item
                text-body-1
                item-1
                w-100
                font-weight-bold
                text-end
                mr-1
              "
            >
              {{ directTotal }}
            </span>
          </div>

          <v-divider class="mt-1 mb-3"></v-divider>

          <div class="d-flex flex-row pt-3 pb-1">
            <span class="flex-item text-body-1 item-3 w-100">
              Incidentes Indiretos
            </span>
            <span
              class="
                flex-item
                text-body-1
                item-1
                w-100
                font-weight-bold
                text-end
                mr-1
              "
            >
              {{ indirectTotalSum }}
            </span>
          </div>

          <v-divider class="mt-1 mb-3"></v-divider>

          <div class="d-flex fl ex-row pt-3 pb-1">
            <span class="flex-item text-body-1 item-3 w-100">
              Valor Líquido Final da Operação
            </span>
            <span
              class="
                flex-item
                text-body-1
                item-1
                w-100
                font-weight-bold
                text-end
                mr-1
              "
            >
              {{ net_received_value }}
            </span>
          </div>

          <v-divider class="mt-1 mb-3"></v-divider>
          <div class="d-flex fl ex-row pt-3 pb-1">
            <span
              class="flex-item text-body-1 item-3 w-100"
             :style="getStyle()"
            >
              Valor Líquido Final Calculado
            </span>
            <span
              class="
                flex-item
                text-body-1
                item-1
                w-100
                font-weight-bold
                text-end
                mr-1
              "
              :style="getStyle()"
            >
              {{ $helpers.toCurrency(getDifference()) }}
            </span>
          </div>

          <v-divider class="mt-1 mb-3"></v-divider>

          <div class="d-flex fl ex-row pt-3 pb-1">
            <span class="flex-item text-body-1 item-3 w-100" style="color: red">
              Diferença Total Final
            </span>
            <span
              class="
                flex-item
                text-body-1
                item-1
                w-100
                font-weight-bold
                text-end
                mr-1
              "
              :style="getStyle()"
            >
              {{
                $helpers.toCurrency(
                  getAllDifference()
                )
              }}
            </span>
          </div>
        </div>
      </div>
    </v-col>
  </v-col>
</template>

<script>
import { bus } from '@/main';
import {
	actions,
	getters,
} from '@/views/operations/save/state/operation.state';
import OperationHandler from '@/views/operations/save/state/operation.handler';
import Separator from '@/components/defaults/layout/Separator.vue';

export default {
	components: { Separator },
	name: 'OperationResume',
	props: {
		fileIsRead: Boolean,
	},
	data() {
		return {
			net_received_value: 'R$ 0,00',
			indirectTotalSum: 'R$ 0,00',

			directFields: [
				'goodwill',
				'ad_valorem',
				'iof',
				'ted_cost',
				'tac',
				'custody_fee',
				'non_especified_goodwill',
				'serasa_consultation_value',
				'bank_slip_registry_value',
			],
			indirectFields: [
				'other_charges',
				'mora_cost',
				'repurchase_value',
				'repurchase_fine_value',
				'registry_send_movement_value',
				'registry_repurchase_fine_value',
				'down_movement_value',
				'prorrogation_movement_value',
				'slaughter_cost',
				'negotiation_retention_value',
				'repurchase_fine_rate',
			],
		};
	},
	mixins: [OperationHandler],
	watch: {
		operation: {
			deep: true,
			handler() {
				this.recalculateNetTotal();
				this.indirectTotal();
				this.$forceUpdate();
			},
		},
	},
	created() {
		bus.$on('updateNetReceivedValue', this.recalculateNetTotal);
		bus.$on('updateIndirectField', this.indirectTotal);
	},
	computed: {
		directTotal() {
			const operation = getters.operation();
			let value = 0;

			this.directFields.forEach((field) => {
				const price = Number(operation[field]).toFixed(2);

				value += price == 'NaN' ? 0 : Number(price);
			});

			return this.$helpers.toCurrency(value);
		},

		netTotal() {
			const netTotal = getters.expected('net_received_value');

			if (!netTotal || netTotal == 'NaN' || Number.isNaN(netTotal)) {
				return 'R$ 0,00';
			}

			const formated = this.$helpers.toCurrency(netTotal);

			return formated;
		},
	},
	methods: {
		recalculateNetTotal() {
			let netTotal = 0;
			if (this.operation && this.operation.calculated_expenses) {
				netTotal = Number(
					this.operation.calculated_expenses.net_received_value,
				);
			}

			netTotal = netTotal == 'NaN' || Number.isNaN(netTotal) ? 0 : netTotal;

			const result = this.$helpers.toCurrency(netTotal);

			this.net_received_value = result;

			this.$forceUpdate();
		},
		itemsLength() {
			const operation = getters.operation();

			return operation.registers.length;
		},
		grossTotal() {
			const total = actions.calculation_service.getTotalOfRegistersValues();

			return this.$helpers.toCurrency(total);
		},

		indirectTotal() {
			this.$nextTick(() => {
				const operation = getters.operation();
				let value = 0;
				this.indirectFields.forEach((field) => {
					const price = Number(operation[field]).toFixed(2);

					value += price == 'NaN' ? 0 : Number(price);
				});

				this.indirectTotalSum = this.$helpers.toCurrency(value);
				this.$forceUpdate();
			});
		},

		getDifference() {
			let directFieldsSum = 0;
			let indirectFields = 0;
			const netTotal = Number(
				actions.calculation_service.getTotalOfRegistersValues(),
			);

			this.directFields.forEach((field) => {
				if (getters.expected(field) && getters.expected(field) !== '') {
					directFieldsSum += Number(getters.expected(field));
				} else if (this.operation[field]) {
					directFieldsSum += Number(this.operation[field]);
				}
			});

			this.indirectFields.forEach((field) => {
				if (getters.expected(field) && getters.expected(field) !== '') {
					indirectFields += Number(getters.expected(field));
				} else if (this.operation[field]) {
					indirectFields += Number(this.operation[field]);
				}
			});

			const total = netTotal - (directFieldsSum + indirectFields);

			return total;
		},

		taxes() {
			const operationTaxes = actions.calculation_service.getTotalOfRegisterTaxes();
			const numericNegotiationRetention = Number(
				this.operation.negotiation_retention_value,
			);

			const negotiationRetention = Number.isNaN(numericNegotiationRetention)
				? 0
				: numericNegotiationRetention;

			const result = operationTaxes - negotiationRetention;

			return this.$helpers.toCurrency(result);
		},
		negotiationRetention() {
			const negotiationRetention = this.operation.negotiation_retention_value || 0;

			return this.$helpers.toCurrency(negotiationRetention);
		},

		getStyle() {
			return Math.abs(Number(this.operation.calculated_expenses.net_received_value)
        !== this.getDifference())
				? 'color: red'
				: '';
		},

		getAllDifference() {
			return Number(this.operation.calculated_expenses.net_received_value) - Number(this.getDifference());
		},
	},
};
</script>

<style></style>
