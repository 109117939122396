<template>
  <v-list
    class="scroll-x text-wrap"
    v-resize="onResize"
    :disabled="$route.name === 'FullListNews'"
    style="padding: 0px; padding-bottom: 15px;"
    elevation="1"
  >
    <v-card-title style="color: #630517">{{ $route.name === "FullListNews" ? "Notícias" : "Últimas notícias" }}</v-card-title>

    <div v-for="(item, index) in items" :key="index">
      <div :key="item.id" class="ml-4">
        <h3
          style="color: #6c1424;"
          class="text-wrap mt-3 mb-3 fontSize-15"
          v-text="item.title"
        ></h3>
      </div>
      <v-card elevation="0">
        <v-list-item three-line>
          <v-avatar
            tile
            v-if="!isMobile"
            size="130"
            class="mb-3 mr-2"
          >
            <v-img
              :src="item.attachment | getWithToken"
              v-if="item.attachment"
            />
          </v-avatar>

          <v-list-item-content class="reset-content fontSize-15" style="text-align: justify;">
            <p :style="$route.name === 'FullListNews' ? '' : 'height: 100px;'">
              {{ $route.name === 'FullListNews' ? domDecoder(item.description) : stringTruncate(item.description, 230) }}
              <a
                v-if="$route.name !== 'FullListNews' && item.description.replaceAll(/(<([^>]+)>)/gi, '').length > 230"
                text
                color="red"
                @click="goToNews(item.id)"
                x-small>
                Veja mais
              </a>
            </p>
            <p :class="($route.name === 'FullListNews' ? 'mt-4' : '') + ' text-sm-subtitle-3'" style="color: #808080; margin-bottom: 0;">
                Criado por {{ item.creator.name }} em {{ $helpers.formatMysqlDate(item.created_at) }}
            </p>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
      <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
    </div>
  </v-list>
</template>
<script>
import NewsService from '@/services/NewsService';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
	name: 'NewsList',
	data() {
		return {
			isMobile: false,
			items: [],
			isLoading: false,
		};
	},
	created() {
		this.getNews();
	},
	methods: {
		async getNews() {
			try {
				this.isLoading = true;

				const newsService = new NewsService();

				const params = this.getParams();

				const response = await newsService.index({ params });

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isLoading = false;
			}
		},

		goToNews(id) {
			this.$router.push({ name: 'FullListNews', params: { id } });
		},
		getParams() {
			const { name, params } = this.$route;

			if (name === 'FullListNews') {
				return {
					type: 'all',
					limit: null,
					id: params.id,
				};
			}

			return {
				type: 'specific',
				limit: 5,
			};
		},
		onResize() {
			if (window.innerWidth < 700) {
				this.isMobile = true;
				return;
			}

			this.isMobile = false;
		},

		stringTruncate(str, length) {
			const string = str.replaceAll(/(<([^>]+)>)/gi, '');
			const dots = string.length > length ? '...' : '';
			const final = string.substring(0, length) + dots;

			return this.domDecoder(final);
		},

		domDecoder(str) {
			const parser = new DOMParser();
			const dom = parser.parseFromString(`<!doctype html><body>${str}`, 'text/html');
			return dom.body.textContent;
		},
	},
};
</script>

<style scoped>
.theme--light.v-divider {
  border-color: #630517 !important;
}

.v-list-item__avatar {
  height: 250px !important;
  min-width: 40px;
  width: 300px !important;
  min-height: 250px;
}
.v-list-group {
  padding: 0;
}

.reset-content {
  align-items: initial;
  margin-top: 0px;
  padding-top: 0px;
}

.fontSize-15 {
  font-size: 15px;
}

.fontSize-12 {
  font-size: 12px;
}

.fontTitle {
  font-size: 1rem;
}

</style>
