<template>
	<div class="d-flex flex-col w-100">
		<v-data-table
			v-model="selected"
			:headers="headers"
			:items="items"
			:loading="loading"

			show-select

			class="w-100"
			color="primary"

			:page.sync="page"
			:items-per-page="itemsPerPage"

			@item-selected="toggleConciliation"
		>
			<template v-slot:[`item.net_value`]="{ item }">
				{{ item.net_value | toCurrency }}
			</template>

			<template v-slot:[`item.due_date`]="{ item }">
				{{ item.due_date | mysqlDateToBr }}
			</template>

			<template v-slot:[`item.issue_date`]="{ item }">
				{{ item.issue_date | mysqlDateToBr }}
			</template>
						<template v-slot:[`item.status`]="{ item }">
				<v-chip>
					{{item.status[0] }}
							{{Object.keys(item.status).length > 0 ? serializeStatusText(item.status[0].status): 'Em Aberto'}}
				</v-chip>
			</template>
		</v-data-table>

		<v-pagination
			v-model="page"
			:length="pageCount"
			total-visible="7"
		/>
	</div>
</template>

<script>
import OperationService from '@/services/OperationService';
import { getters } from '../state/receipts.state';

export default {
	name: 'ConciliationDialogList',
	data() {
		return {
			selected: this.value,
			headers: [
				{ text: 'NF', value: 'number' },
				{ text: 'Valor', value: 'net_value' },
				{ text: 'Data de Vencimento', value: 'due_date' },
				{ text: 'Data de Emissão', value: 'issue_date' },
				{ text: 'Operações', value: 'status' },
			],
			items: [],
			loading: false,
			error: '',
			page: 1,
			itemsPerPage: 4,
			pageCount: 0,
		};
	},
	props: {
		value: Array,
		filters: Object,
		project: [Number, String],
		status: String,
		conciliated: Array,
	},
	watch: {
		value() {
			if (this.selected == this.value) {
				return;
			}

			this.selected = this.value;
		},
		selected() {
			this.$emit('input', this.selected);

			this.$emit('change', this.selected);
		},
		filters: {
			deep: true,
			handler() {
				this.search();
			},
		},
		page() {
			this.search();
		},
	},
	created() {
		this.search();
	},
	methods: {
		returnToFirstPage() {
			this.page = 1;
		},
		async search() {
			try {
				this.loading = true;

				const params = this.getParams();

				const operationService = new OperationService();

				const response = await operationService
					.registers(this.project)
					.index({ params });

				this.items = response.data;

				this.paginate(response);
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
		serializeStatusText(status) {
			switch (status) {
			case 'partial_repurchased_amount':
				return 'Título recomprado parcial';
			case 'registry_sent':
				return 'Registro Enviado ao Cartório';
			case 'registry_send_movement_value':
				return 'Registro Enviado ao Cartório';
			case 'title_in_serasa':
				return 'Consultado no SERASA';
			case 'registry_repurchased':
				return 'Retirado do Cartório';
			case 'title_repurchased':
				return 'Título Recomprado';
			case 'title_down':
				return 'Título baixado';
			case 'title_prorrogated':
				return 'Título Prorrogado';
			case '':
				return 'Em aberto';
			default:
				return '';
			}
		},
		getParams() {
			const pagination = {
				page: this.page,
				per_page: this.itemsPerPage,
			};

			const filters = Object.entries(this.filters);

			filters.forEach(([filter, value]) => {
				if (!value) {
					delete this.filters[`${filter}`];
				}
			});

			const params = {
				...pagination,
				...this.filters,
			};

			return params;
		},
		toggleConciliation({ item }) {
			if (this.alreadyChecked(item)) {
				this.uncheck(item);
			} else {
				this.check(item);
			}

			this.$emit('change', this.selected);
		},

		alreadyChecked(item) {
			if (!this.selected || !this.selected.length) {
				return false;
			}

			const alreadyChecked = this.selected.find((row) => row.id == item.id);

			return alreadyChecked;
		},

		uncheck(item) {
			if (this.error == item.number) {
				this.$emit('error', '');
			}

			const operationId = (getters.operation()).id;

			this.selected = this.selected.map((register) => {
				if (!register.status) return register;

				register.status = register.status.filter((status) => {
					const sameStatus = status.status != item.status;
					const sameOperation = status.fund_return_id != operationId;

					return sameStatus && sameOperation;
				});

				return register;
			});

			const withoutItem = this.selected.filter((select) => select.id != item.id);

			this.selected = withoutItem;
		},
		check(item) {
			if (!this.selected || !this.selected.length) {
				this.selected = [];
			}

			if (this.alreadyConciliateInAnotherUpload(item)) {
				console.log(`Título:   ${item.number}  - Já Conciliado em outra operação`);
				// this.$emit('error', `Título:   ${item.number}  - Já Conciliado em outra operação`);
				// this.error = item.number;
			}

			this.selected.push(item);
		},

		alreadyConciliateInAnotherUpload(item) {
			const selected = this.conciliated
				.find((conciliation) => conciliation.id == item.id);

			let preparedToConciliate = false;
			let alreadyConciliated = false;

			if (selected && selected.status) {
				preparedToConciliate = selected.status
					.some((row) => row.status == this.status);
			}

			if (item && item.status) {
				alreadyConciliated = item.status
					.some((row) => row.status == this.status);
			}

			return preparedToConciliate || alreadyConciliated;
		},

		paginate(response) {
			this.page = response.current_page;

			const perPage = Number(response.per_page);

			this.itemsPerPage = Number.isNaN(perPage)
				? 6
				: perPage;

			this.pageCount = response.last_page;
		},
	},
};
</script>

<style>

</style>
