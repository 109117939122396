<template>
	<page-container no-column>
		<v-col cols="12">
			<separator label="Informações Pessoais:"/>
		</v-col>
		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<v-text-field
				label="E-mail Pessoal"
				v-model="userProfile.personal_email"
				:rules="[...rules.email]"
			/>
		</v-col>
		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<v-text-field
				label="Telefone Pessoal"
				v-model="userProfile.personal_phone"
				v-mask="$phoneMask"
			/>
		</v-col>

		<!-- SPACE -->
		<v-col xl="4" class="d-none d-xl-flex d-lg-flex">
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="Email Corporativo"
				v-model="userProfile.business_email"
				:rules="[...rules.email]"
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="Telefone Corporativo"
				v-model="userProfile.business_phone"
				v-mask="$phoneMask"
			/>
		</v-col>

		<v-col cols="12">
			<separator label="Contatos de Emergência:"/>
		</v-col>
		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="Telefone de Emergência 1:"
				v-model="userProfile.emergency_phone"
				v-mask="$phoneMask"

			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="Telefone de Emergência 2:"
				v-model="userProfile.second_emergency_phone"
				v-mask="$phoneMask"

			/>
		</v-col>

		<!-- SPACE -->
		<v-col xl="4" class="d-none d-xl-flex d-lg-flex">
		</v-col>

		<v-col cols="12">
			<separator label="Identificação:"/>
		</v-col>
		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<span class="text-caption text-left secondary--text">
				Comprovante de Identidade - Frente
			</span>
			<image-dialog
				:aspect-ratio="3 / 4"
				md-w="330"
				md-h="450"
				sm-w="200"
				sm-h="350"
				v-model="userProfile.proof_of_identity_front_image"
				skip-cropping
				no-center
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<span class="text-caption text-left secondary--text">
				Comprovante de Identidade - Verso
			</span>
			<image-dialog
				:aspect-ratio="3 / 4"
				md-w="330"
				md-h="450"
				sm-w="200"
				sm-h="350"
				v-model="userProfile.proof_of_identity_back_image"
				skip-cropping
				no-center
			/>
		</v-col>

		<!-- SPACE -->
		<v-col xl="4" class="d-none d-xl-flex d-lg-flex">
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="CPF"
				v-model="userProfile.cpf"

				v-mask="$cpfMask"
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="RG"
				v-model="userProfile.rg"

			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="Órgão Emissor"
				v-model="userProfile.rg_emissor"

			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<date-picker
				label="Data de Emissão"
				v-model="userProfile.rg_emitted_date"

			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="6" xs="12">
			<v-text-field
				label="PIS/PASEP"
				v-model="userProfile.pis_pasep"
			/>
		</v-col>

		<v-col cols="12">
			<separator label="Endereço:"/>
		</v-col>
		<v-col xl="12" lg="12" md="12" sm="12" xs="12">
			<v-text-field
				label="Endereço"
				v-model="userProfile.street"
			/>
		</v-col>
		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<v-text-field
				label="Bairro"
				v-model="userProfile.neighborhood"
			/>
		</v-col>
		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<v-text-field
				label="Cidade"
				v-model="userProfile.city"
			/>
		</v-col>
		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<v-text-field
				label="CEP"
				v-model="userProfile.zip_code"
				v-mask="$cepMask"
			/>
		</v-col>
		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<span class="text-caption text-left secondary--text">
				Comprovante de Residência
			</span>
			<image-dialog
				:aspect-ratio="3 / 4"
				md-w="330"
				md-h="450"
				sm-w="200"
				sm-h="350"
				v-model="userProfile.proof_of_address_image"
				skip-cropping
				no-center
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<separator label="CLT:"/>
		</v-col>

		<v-col xl="3" lg="3" md="6" sm="12" xs="12">
			<span class="text-caption text-left secondary--text">
				Identificação - Frente
			</span>
			<image-dialog
				:aspect-ratio="3 / 4"
				md-w="330"
				md-h="450"
				sm-w="200"
				sm-h="350"
				v-model="userProfile.clt_profile_front_image"
				skip-cropping
				no-center
			/>
		</v-col>
		<v-col xl="3" lg="3" md="6" sm="12" xs="12">
			<span class="text-caption text-left secondary--text">
				Identificação - Verso
			</span>
			<image-dialog
				:aspect-ratio="3 / 4"
				md-w="330"
				md-h="450"
				sm-w="200"
				sm-h="350"
				v-model="userProfile.clt_profile_back_image"
				skip-cropping
				no-center
			/>
		</v-col>
		<v-col xl="3" lg="3" md="6" sm="12" xs="12">
			<span class="text-caption text-left secondary--text">
				Último Contrato
			</span>
			<image-dialog
				:aspect-ratio="3 / 4"
				md-w="330"
				md-h="450"
				sm-w="200"
				sm-h="350"
				v-model="userProfile.clt_contract_image"
				skip-cropping
				no-center
			/>
		</v-col>
		<v-col xl="3" lg="3" md="6" sm="12" xs="12">
			<span class="text-caption text-left secondary--text">
				Página Seguinte
			</span>
			<image-dialog
				:aspect-ratio="3 / 4"
				md-w="330"
				md-h="450"
				sm-w="200"
				sm-h="350"
				v-model="userProfile.clt_next_empty_page_image"
				skip-cropping
				no-center
			/>
		</v-col>

		<v-col cols="12" class="mb-n4">
			<separator label="Contrato"/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<file-dialog
				:contract="true"
				v-model="userProfile.contract_file"
				ref="fileDialog"
				no-divider
			/>
		</v-col>
	</page-container>
</template>

<script>
import UserService from '@/services/UserService';

import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Separator from '@/components/defaults/layout/Separator.vue';
import DatePicker from '@/components/defaults/DatePicker.vue';
import ImageDialog from '@/components/image_upload/ImageDialog.vue';
import FileDialog from '../../../components/file_upload/FileDialog.vue';

export default {
	name: 'UserProfileContentForm',
	components: {
		PageContainer,
		Separator,
		DatePicker,
		ImageDialog,
		FileDialog,
	},
	props: {
		value: Object,
		user: Object,
	},

	data() {
		return {
			userProfile: this.value,
			permission: {},

			rules: {
				required: [(value) => !!value || 'Campo obrigatório.'],
				email: [(v) => !v || (/.+@.+\..+/.test(v) || 'Email inválido.')],
				number: [
					(v) => parseInt(v, 10) <= 120 || 'O campo permite no máximo 120 dias de férias',
					(v) => parseInt(v, 10) >= 1 || 'É necessário pelo menos um dia de férias',
				],
			},
		};
	},
	watch: {
		value() {
			if (this.userProfile == this.value) return;

			this.userProfile = this.value;
		},
		userProfile() {
			this.$emit('input', this.userProfile);
		},
		'userProfile.contract_file': {
			immediate: true,
			handler() {
				this.loadFile();
			},
		},
	},
	methods: {
		async loadFile() {
			const fileUrl = this.user.profile.contract;

			if (!fileUrl) return;

			const userService = new UserService();

			const content = await userService
				.raw(fileUrl, { baseURL: '' });

			const splittedUrl = fileUrl.split('/');

			const filename = splittedUrl[splittedUrl.length - 1];

			const file = new File(
				[content],
				filename,
			);

			this.user.profile.contract_file = file;

			if (!this.$refs || !this.$refs.fileDialog) return;

			this.$refs.fileDialog.changeSelectedFileToNewer(this.user.profile.contract_file);
		},

	},
};
</script>

<style>

</style>
