<template>
	<v-app-bar
		dense
		app
		clipped-left
		elevation="1"
		floatind
		class="bg-primary"
	>
		<v-row class="py-0 mx-n4">
			<!-- HEADER HOME ICON -->
			<v-col xl="2" lg="3" md="4" sm="4" xs="0" class="pb-0 pl-0">
				<back-home-logo/>
			</v-col>

			<!-- ACTIONS -->
			<v-col xl="10" lg="9" md="8" sm="8" xs="12" class="pb-0">
				<user-menu/>
			</v-col>
		</v-row>
	</v-app-bar>
</template>

<script>
import BackHomeLogo from '@/components/header/BackHomeLogo.vue';
import UserMenu from '@/components/header/user_menu/UserMenu.vue';

export default {
	name: 'Header',
	components: {
		BackHomeLogo,
		UserMenu,
	},
};
</script>

<style>

</style>
