import HelperMethods from '@/plugins/helpers/helper_methods';

export default class AccessesHandler {
	/**
	 * @typedef {Object} Access
	 *
	 * @property {Number|String} id
	 * @property {String} type
	 * @property {String} title
	 * @property {String} name
	 * @property {String} path
	 * @property {Number} order
	 * @property {String} icon
	 * @property {String|Array<Number>} children - para agrupadores eh serializado para array
	 * @property {?Number} rendered_children
	 * @property {Number} father_id
	 */

	/**
	 * @param {Array<Access>} accesses
	 * @return {Array<Access>}
	 */
	static serializeAccesses(accesses) {
		const ordered = AccessesHandler.orderAccesses(accesses);

		const serialized = AccessesHandler.grouperAccesses(ordered);

		return serialized;
	}

	/**
	 * @param {Array<Access>} toOrder
	 * @return {Array<Access>} ordered
	 */
	static orderAccesses(toOrder) {
		const ordered = toOrder.sort((a, b) => a.order - b.order);

		return ordered;
	}

	/**
	 * faz o trabalho de agrupar os items com seus filhos em uma ordem de acesso para listagem,
	 * não deixa com que a referencia seja perdida
	 * @param {Array<Access>} accesses
	 */
	static grouperAccesses(accesses) {
		/** @type {Array<Access>} */
		const groupedAccesses = [];
		const ignore = [];

		accesses.forEach((access) => {
			if (ignore.includes(`${access.id}`)) return;

			groupedAccesses.push(access);

			if (access.children) {
				const children = AccessesHandler.getChildren(access, accesses);

				const ids = AccessesHandler.getChildIds(access);

				access.rendered_children = 0;
				access.children = ids;

				ignore.push(...ids);

				groupedAccesses.push(...children);
			}
		});

		return groupedAccesses;
	}

	/**
	 * @param {Access} access
	 * @return {String} childrens
	 */
	static getChildIds(access) {
		if (typeof access.children !== 'string') return access.children;

		return access.children.split(',');
	}

	/**
	 * @param {Access} access
	 * @param {Array<Access>} accesses
	 */
	static getChildren(access, accesses) {
		const childrenIds = AccessesHandler.getChildIds(access);

		const children = HelperMethods.whereIn('id', childrenIds, accesses);

		const serializedChildren = AccessesHandler.serializeChildren(children, access);

		return serializedChildren;
	}

	/**
	 * @param {Array<Access>} children
	 * @param {Access} father
	 */
	static serializeChildren(children, father) {
		const serialized = children.map((child) => {
			child.father_id = father.id;

			return child;
		});

		return serialized;
	}

	/**
	 * @param {Array<Access>} loadedAccess
	 * @param {Access} father
	 */
	static matchAccessesValue(accesses, userActiveAccesses) {
		const activeAccesses = accesses.map((access) => {
			if (AccessesHandler.isAGrouperAndAChildHasSelected(access, userActiveAccesses)) {
				access.value = true;
			} else if (AccessesHandler.someAccessValueMatch(access, userActiveAccesses)) {
				access.value = true;
			} else {
				access.value = false;
			}

			return access;
		});

		return activeAccesses;
	}

	/**
	 * verifica se existe um accesso adicionado para determinado modelo
	 * @param {Access} access
	 * @param {Array<Access>} userActiveAccesses - lista de acessos de deterinado modelo
	 * @return {Boolean}
	 */
	static someAccessValueMatch(access, userActiveAccesses) {
		const accessId = `${access.id}`;

		return userActiveAccesses.some((active) => active.id == accessId);
	}

	/**
	 * @param {Access} access
	 * @param {Array<Access>} userActiveAccesses - lista de acessos de deterinado modelo
	 * @return {Boolean}
	 */
	static isAGrouperAndAChildHasSelected(access, userActiveAccesses) {
		if (!AccessesHandler.isGrouper(access)) return false;

		const childIds = AccessesHandler.getChildIds(access);

		if (childIds.length === 0) return false;

		const match = userActiveAccesses
			.some((activeAccess) => childIds.includes(`${activeAccess.id}`));

		return match;
	}

	/**
	 * @param {Access} access
	 * @return {boolean}
	 */
	static isGrouper(access) {
		if (access.type != 'item-grouper' && access.type != 'functionality-grouper') return false;

		return true;
	}

	static isChild(access) {
		if (access.type == 'item-grouper' || access.type == 'functionality-grouper') return false;

		if (access.father_id == 'undefined') return false;

		return true;
	}

	static changeValueOfChildrenIfHaveAny(access, userActiveAccesses) {
		if (!AccessesHandler.isGrouper(access)) return;

		const childIds = AccessesHandler.getChildIds(access);

		if (childIds.length === 0) return;

		for (let index = 0; index < userActiveAccesses.length; index += 1) {
			const toChange = userActiveAccesses[index];
			// eslint-disable-next-line
			if (!childIds.includes(`${toChange.id}`)) continue;

			if (access.value !== toChange.value) {
				toChange.value = !toChange.value;
			}
		}
	}

	static changeValueOfFatherIfHaveAny(access, userActiveAccesses) {
		if (!AccessesHandler.isChild(access)) return;

		if (access.value) {
			AccessesHandler.checkFather(access, userActiveAccesses);
		} else {
			AccessesHandler.uncheckFather(access, userActiveAccesses);
		}
	}

	static checkFather(access, userActiveAccesses) {
		const father = HelperMethods.find('id', access.father_id, userActiveAccesses);

		if (!father || father.value) return;

		father.value = !father.value;
	}

	static uncheckFather(access, userActiveAccesses) {
		const father = HelperMethods.find('id', access.father_id, userActiveAccesses);

		if (!father || !father.value) return;

		const childIds = AccessesHandler.getChildIds(father);

		const children = HelperMethods.whereIn('id', childIds, userActiveAccesses);

		const activeChildrens = HelperMethods.where('value', true, children);

		if (activeChildrens.length != 0) return;

		father.value = !father.value;
	}
}
