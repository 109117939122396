<template>
  <page-container>
    <breadcrumbs page-title="Notícias" />

    <list-structure>
		<template v-slot:search>
			<v-text-field
				label="Pesquisar"
				v-model="searchTerm"
				prepend-icon="mdi-magnify"
				dense
			/>
		</template>
      <template v-slot:actions>
        <list-add-button @add="add" color="#E09A11" />

      </template>

      <template v-slot:table>
        <news-table-list @edit="edit" :search="searchTerm" />

      </template>
    </list-structure>
  </page-container>
</template>

<script>
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';

// nested components
import NewsTableList from './NewsTableList.vue';

export default {
	name: 'VacationsList',

	data: () => ({
		user: {},

		searchTerm: '',

		showFilters: false,
	}),

	created() {
		this.getLoggedUser();
	},

	components: {
		ListAddButton,
		ListStructure,
		PageContainer,
		Breadcrumbs,
		NewsTableList,
	},

	methods: {
		add() {
			this.pushToNewsSendRoute({ id: 'new' });
		},
		edit(item) {
			this.pushToNewsSendRoute({ id: item.id });
		},
		pushToNewsSendRoute(params) {
			this.$router.push({ name: 'NewsSave', params });
		},
		validatePermission() {
			return this.user.user_type.name === 'admin';
		},

		getLoggedUser() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);
			this.user = tokenData;
		},
	},
};
</script>
