<template>
	<div class="d-flex flex-row float-right white--text my-auto mt-n1">
		<!-- USER AVATAR -->
		<v-avatar
			v-if="user.image_url"
			size="36"
			class="
				flex-item
				my-auto
			"
		>
			<img :src="user.image_url | getWithToken"/>
		</v-avatar>

		<v-icon
			color="white"
			class="
				flex-item
				my-auto
				text-h4
			"
			v-else
		>
			mdi-account
		</v-icon>

		<!-- USER INFO -->
		<div
			class="
				flex-item
				d-flex
				flex-column
				text-no-wrap
				px-2
				mx-2
			"
		>
			<span class="text-subtitle-1 my-n1"> {{ user.name }} </span>
			<span class="text-subtitle-2 text--secondary-dark my-n1"> {{ userType.title }} </span>
		</div>

		<v-menu v-model="openUserMenu">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					icon
					small
					color="white"
					v-on="on"
					v-bind="attrs"
				>
					<v-icon> mdi-chevron-down </v-icon>
				</v-btn>
			</template>

			<v-list>
				<user-profile-list-item :user="user"/>

				<v-list-item link @click="exit">
					<v-list-item-title> Sair </v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import UserProfileListItem from './UserProfileListItem.vue';

export default {
	components: {
		UserProfileListItem,
	},
	data() {
		return {
			openUserMenu: false,
			user: {},
			userType: {},
		};
	},
	mounted() {
		this.loadUserInfo();
	},
	methods: {
		loadUserInfo() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);

			this.user = tokenData.user;
			this.userType = tokenData.user_type;
		},
		exit() {
			this.$router.push({ name: 'Login' });
		},
	},
};
</script>

<style>

</style>
