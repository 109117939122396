<template>
  <page-container no-column>
    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <user-select
        v-model="vacation.user"
        :user="vacation.creator || vacation.user"
        label="Usuário"
        :isValidUser="validateUser()"
      />
    </v-col>
    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <substitute-select
        v-model="vacation.substitute"
        label="Substituto"
        :substitute="vacation.substitute"
        :rules="[rules.required]"
      />
    </v-col>

    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <date-picker
        :rules="[rules.required]"
        label="Data início"
        v-model="vacation.start_date"
        placeholder=""
        clearable
        @input="validate"
      />
    </v-col>
    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <date-picker
        :rules="[rules.required, rules.date]"
        label="Data fim"
        v-model="vacation.end_date"
        placeholder=""
        clearable
        @input="validate"
      />
    </v-col>

    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <period-select
        v-model="vacation.period"
        :rules="[rules.required]"
        :period="vacation.period"
        :fixedPeriod="validate()"
      ></period-select>
    </v-col>

    <v-col xl="5" lg="5" md="5" sm="12" xs="12">
      <v-text-field label="Motivo" v-model="vacation.reason" :rules="[rules.required]" />
    </v-col>

    <v-col xl="12" lg="12" md="12" sm="12" xs="12" class="mb-n4">
      <v-textarea label="Observação" v-model="vacation.observation" />
    </v-col>
  </page-container>
</template>
<script>
import Moment from 'moment';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import DatePicker from '@/components/defaults/DatePicker.vue';
import HolidaysService from '@/services/HolidaysService';
import UserSelect from './inputs/UserSelect.vue';
import SubstituteSelect from './inputs/SubstituteSelect.vue';
import PeriodSelect from './inputs/PeriodSelect.vue';

export default {
	name: 'VacationsSaveMainForm',

	components: {
		PageContainer,
		UserSelect,
		DatePicker,
		PeriodSelect,
		SubstituteSelect,
	},

	props: {
		user: { type: Object, required: true },
		oldVacation: Object,
		form: Boolean,
		period: String,
	},

	data() {
		return {
			vacation: {
				user: this.user.user,
				substitute: {
					id: null,
					name: null,
				},
				start_date: '',
				end_date: '',
				period: {},
				reason: '',
				observation: '',
				type: 'S',
				number_days: null,
				admin_observation: '',
			},
			items: [
				{ name: 'Manhã', id: 'morning' },
				{ name: 'Tarde', id: 'evening' },
				{ name: 'Integral', id: 'integral' },
			],

			holidays: [],

			rules: {
				required: (v) => (v !== 'undefined' && v !== null && v !== '') || 'Campo Necessário',
				date: () => {
					if (this.vacation.start_date > this.vacation.end_date) {
						return 'Data início deve ser menor que a final';
					}
					return true;
				},
			},
		};
	},
	watch: {
		form: {
			deep: true,
			handler(value) {
				if (value) {
					this.save();
				}
			},
		},
		period: {
			deep: true,
			handler(value) {
				this.vacation.period = this.items.filter((element) => element.id === value)[0];
			},
		},

		oldVacation(value) {
			this.$nextTick(() => {
				this.vacation = value;
			});
		},
	},
	created() {
		this.loadHolidays();
	},
	methods: {
		validate() {
			let diffDays = 0;
			let isHolyDay = false;

			const days = this.enumerateDaysBetweenDates(this.vacation.start_date, this.vacation.end_date);

			const saturday = 6;
			const sunday = 7;

			days.forEach((element) => {
				if (Moment(element).isoWeekday() === saturday || Moment(element).isoWeekday() === sunday) return;

				isHolyDay = this.holidays.some((holiday) => (
					Moment(holiday.date).isSame(element, 'day')
				));

				if (isHolyDay) return;

				diffDays += 1;
			});

			this.vacation.number_days = diffDays;

			return this.vacation.end_date > this.vacation.start_date;
		},

		async save() {
			this.$emit('save', this.vacation);
		},

		validateUser() {
			return this.user.user_type.name !== 'admin';
		},

		enumerateDaysBetweenDates(startDate, endDate) {
			const dates = [];

			while (Moment(startDate) <= Moment(endDate)) {
				dates.push(startDate);
				startDate = Moment(startDate)
					.add(1, 'days')
					.format('YYYY-MM-DD');
			}

			return dates;
		},

		async loadHolidays() {
			try {
				const holidayService = new HolidaysService();

				const response = await holidayService.index();

				this.holidays = response.data
					.filter((holiday) => holiday.global === true && holiday.types.includes('Public'));
			} catch (err) {
				this.$http.defaultCatchError(err);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
