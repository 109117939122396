<template>
	<v-btn
		@click="onClick"
		:disabled="!valid || disabled || loading"
		color="success"
		class="float-right"
		:class="buttonClass"
		:loading="loading"
		depressed
	>
		<v-icon class="mr-2" v-if="isToAddNewItem()">
			{{ buttonIcon() }}
		</v-icon>

		{{ buttonMessage() }}
	</v-btn>
</template>

<script>
export default {
	name: 'FormSaveButton',
	props: {
		valid: Boolean,
		loading: Boolean,
		icon: String,
		buttonClass: String,
		disabled: Boolean,
		create: Boolean,
	},
	methods: {
		onClick() {
			this.$emit('click');
		},
		isToAddNewItem() {
			if (this.create !== 'undefined') return false;

			return !this.$route.params.id || this.$route.params.id == 'new';
		},
		buttonMessage() {
			if (this.isToAddNewItem() || this.create) return 'Adicionar';

			return 'Salvar';
		},
		buttonIcon() {
			return this.icon || 'mdi-plus';
		},
	},
};
</script>

<style>

</style>
