var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100"},[(_vm.conciliated.length)?_c('v-data-table',{staticClass:"w-100 bg-light-3 mb-n3 mt-2",attrs:{"headers":_vm.headers,"items":_vm.conciliated,"disable-sort":"","item-key":"id","group-key":"status"},scopedSlots:_vm._u([{key:"item.net_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.net_value))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDateToBr")(item.due_date))+" ")]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDateToBr")(item.issue_date))+" ")]}},{key:"item.repurchased_value_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.sumAllRepurchasedValues(item)))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return _vm._l((item.status),function(status,index){return _c('v-chip',{key:index,staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.serializeStatusText(status.status))+" ")])})}},{key:"item.repurchased_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getRepurchasedValue(item)))+" ")]}},{key:"item.interest",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getInterest(item)))+" ")]}}],null,true)}):_c('disclaimer',{attrs:{"title":"Nenhum Registro Selecionado","subtitle":"Adicione registros relacionados à operação a ser enviada."}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }