	<template>
  <page-container no-column >
    <v-col xl="12" lg="12" md="12" sm="12" xs="12">
      <news-list />
    </v-col>
  </page-container>
</template>

<script>

import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import NewsList from './NewsList.vue';

export default {
	name: 'FullListNews',
	components: {
		NewsList,
		PageContainer,
	},

};

</script>
