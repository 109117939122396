import { render, staticRenderFns } from "./UserFunctionalitiesModal.vue?vue&type=template&id=44fecc4a&"
import script from "./UserFunctionalitiesModal.vue?vue&type=script&lang=js&"
export * from "./UserFunctionalitiesModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports