<template>
	<login-layout
		@submit="verify"
		v-model="valid"
		ref="authForm"
	>
		<template v-slot:form>

			<v-col cols="12" class="text-center">
				<span
					class="
						text-subtitle-1
						font-weight-normal
						"
				>
					Olá! Para criar uma senha nova, por favor, digite seu e-mail abaixo.
				</span>
			</v-col>

			<v-col cols="12">
				<v-text-field
					label="E-mail"
					v-model="email"
					:rules="[ rules.required ]"
					type="text"
					@input="revalidate"
					outlined
				/>
			</v-col>
		</template>

		<template v-slot:actions>
			<v-col class="pa-0 text-end ml-n1">
				<v-btn
					depressed
					color="primary"
					:loading="loggingIn"
					@click="verify"
					:disabled="!valid"
					block
				>
					Recuperar senha
				</v-btn>
			</v-col>
		</template>
	</login-layout>
</template>

<script>
import AuthService from '@/services/AuthService';
import LoginLayout from '../../components/auth/LoginLayout.vue';

export default {
	name: 'VerifyPassword',
	components: {
		LoginLayout,
	},
	data() {
		return {
			loggingIn: false,
			email: '',
			valid: false,
			validToken: false,

			rules: {
				required: (v) => (v !== 'undefined' && !!v) || 'Campo Necessário',
			},
		};
	},
	methods: {

		async verify() {
			try {
				this.loggingIn = true;

				const authService = new AuthService();
				await authService.lostPassword().store({ email: this.email });

				this.showSuccessDialog();
				this.$router.push({ name: 'Login' });
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loggingIn = false;
			}
		},

		showSuccessDialog() {
			this.$swal({
				title: 'Você deve receber um e-mail para troca de senha em breve!',
				icon: 'success',
				showConfirmButton: true,
			});
		},

		loadUserDataByToken() {
			if (!this.validToken) return;

			const { token } = this.$route.params;

			const decodedToken = this.$helpers.jwt.decode(token);

			const {
				id,
				email,
				name,
				created_by,
			} = decodedToken.user_id;

			const userData = {
				id,
				email,
				name,
				created_by,
				verify_token: token,
			};

			this.user = {
				...this.user,
				...userData,
			};
		},
		/**
		 * HELPERS
		 */
		revalidate() {
			this.$refs.authForm.revalidate();
		},
		saveLoginOnToken(loggedUserToken) {
			sessionStorage.logged_user = JSON.stringify(loggedUserToken);
		},
	},
};
</script>
