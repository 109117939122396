import jwt from 'jsonwebtoken';

const publicPaths = ['/login', '/auth/verify/', '/auth/verify', '/disapprove/', '/approve/'];

export default class RouterMiddleware {
	static like(compare, value) {
		const upperCasedCompare = `${compare}`.toLocaleUpperCase();
		const upperCasedValue = `${value}`.toLocaleUpperCase();

		const result = upperCasedCompare.indexOf(upperCasedValue) != -1;

		return result;
	}

	// eslint-disable-next-line
	static trait({ router, to, from, next }) {
		const { path } = to;

		const noRequired = publicPaths.some((publicPath) => RouterMiddleware.like(path, publicPath));
		if (noRequired) {
			next();
			return;
		}

		RouterMiddleware.verifyJwtAuth({ router, next });

		RouterMiddleware.verifyPermission({ router, to, next });
	}

	static verifyJwtAuth({ router, next }) {
		const instance = router.app;

		const authenticated = sessionStorage.getItem('logged_user');

		if (!authenticated) {
			RouterMiddleware.returnToLogin({ instance });
		}

		next();
	}

	static verifyPermission({ router, to, next }) {
		const user = RouterMiddleware.getLoggedUser();
		const instance = router.app;

		const decodedToken = RouterMiddleware.decode(user.access_token);

		let { accesses } = decodedToken;

		accesses = Object.values(accesses);

		if (!accesses.length) {
			RouterMiddleware.returnToLogin({ instance });
		}

		const hasPermission = RouterMiddleware.goThroughMenusToMatchRoute(accesses, to);

		if (!hasPermission) {
			RouterMiddleware.returnToLogin({ instance });
		}

		next();
	}

	/**
	 * decode
	 * copia da funcao decode de jwt.helpers
	 */
	static decode(token) {
		return jwt.decode(token);
	}

	/**
	 * getLoggedUser
	 * copia da funcao de getLoggedUser em jwt.helpers
	 */
	static getLoggedUser() {
		const jsonUser = sessionStorage.logged_user;

		const user = JSON.parse(jsonUser);

		return user;
	}

	static returnToLogin({ instance }) {
		instance.$router.push({ name: 'Login', params: { cleanCache: true } });
	}

	static goThroughMenusToMatchRoute(accesses, to) {
		if (!to || !to.path) return false;

		const result = accesses
			.some((access) => RouterMiddleware.matchRoute(access, to));

		return result;
	}

	static matchRoute(access, to) {
		if (access.children && access.children.length) {
			const result = RouterMiddleware.goThroughMenusToMatchRoute(access.children, to);

			return result;
		}

		const result = to.path.indexOf(access.path) != -1;

		return result;
	}
}
