export default class Base64 {
	static async encode(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onload = () => {
				resolve(reader.result);
			};

			reader.onerror = (error) => reject(error);
		});
	}
}
