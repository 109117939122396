<template>
	<v-btn
		icon
		@click="onClick"
		color="grey"
		:disabled="disabled || loading"
		:loading="loading"
	>
		<v-icon>mdi-account-convert</v-icon>
	</v-btn>
</template>

<script>
export default {
	name: 'ListReactivateButton',
	props: {
		valid: Boolean,
		loading: Boolean,
		icon: String,
		buttonClass: String,
		disabled: Boolean,
	},
	methods: {
		async onClick() {
			const result = await this.$swal({
				text: 'Você tem certeza que deseja reativar o usuário?',
				title: 'Reativar',
				icon: 'warning',
				showConfirmButton: true,
				showCancelButton: true,
			});

			const { value } = result;

			if (value) {
				this.$emit('reactivate');
				this.$emit('click');
			}
		},
	},
};
</script>

<style>

</style>
