import HelperMethods from './helper_methods';
import HttpMethods from './http_methods';

//  HELPER FUNCTIONS FOR VUE
const helpers = {};

// eslint-disable-next-line
helpers.install = function (Vue, options) {
	// GLOBAL VARIABLES
	Vue.prototype.$phoneMask = HelperMethods.$phoneMask;
	Vue.prototype.$cnpjMask = HelperMethods.$cnpjMask;
	Vue.prototype.$cepMask = HelperMethods.$cepMask;
	Vue.prototype.$cpfMask = HelperMethods.$cpfMask;

	Vue.prototype.$cropTypes = {
		noRestriction: 0,
		cropCanvas: 1,
		fitContainer: 2,
		fillContainer: 3,
	};

	/**
	 * HELPERS
	 * funcoes de formatacao e de auxilio para uso no sistema
	 */
	Vue.prototype.$helpers = HelperMethods;

	Vue.filter('mysqlDateToBr', Vue.prototype.$helpers.mysqlDateToBr);

	Vue.filter('mysqlTimestampToBr', Vue.prototype.$helpers.mysqlTimestampToBr);

	Vue.filter('textToCNPJ', Vue.prototype.$helpers.maskCNPJ);

	Vue.filter('getWithToken', Vue.prototype.$helpers.getUrlWithToken);

	Vue.filter('toCurrency', Vue.prototype.$helpers.toCurrency);

	/**
	 * HTTP
	 * funcoes relacionadas a manipulacao e tratativa de rotas nas requisicoes http
	 * utilizadas e testadas apenas para uso pelo axios
	 */
	HttpMethods.$vue = Vue;

	Vue.prototype.$http = HttpMethods;
};

export default helpers;
