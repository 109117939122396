<template>
  <v-col xl="4" lg="4" md="12" sm="12" xs="12">
    <v-col cols="12">
      <separator label="Incidentes Indiretos" />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <operation-expense-field
        label="Outras Tarifas"
        field="other_charges"
        v-model="operation.other_charges"
        :disabled="disabled"
        no-validation
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <operation-expense-field
        label="Juros de Mora"
        field="mora_cost"
        v-model="operation.mora_cost"
        :disabled="disabled"
        no-validation
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <conciliation-field
        label="Valor de Recompra"
        v-model="operation.calculated_expenses.repurchase_value"
        field="repurchase_value"
        status="repurchase_value"
        no-suffix
        readonly
        calculated
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <operation-expense-field
        label="Tarifa de Recompra"
        v-model="operation.repurchase_fine_value"
        field="repurchase_fine_value"
        status="title_repurchased"
        :disabled="disabled"
        calculated
        :conciliation="false"
        ref="repurchase_fine_value"
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <conciliation-field
        label="Valor de Envio p/ Cartório"
        v-model="operation.registry_send_movement_value"
        field="registry_send_movement_value"
        status="registry_sent"
        :disabled="disabled"
        calculated
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <conciliation-field
        label="Valor de Retirada p/ Cartório"
        v-model="operation.registry_repurchase_fine_value"
        field="registry_repurchase_fine_value"
        status="registry_repurchased"
        :disabled="disabled"
        calculated
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <conciliation-field
        label="Valor de Baixa de Titulo "
        v-model="operation.down_movement_value"
        field="down_movement_value"
        status="title_down"
        :disabled="disabled"
        calculated
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <conciliation-field
        label="Valor de Prorrogação de Titulos"
        v-model="operation.prorrogation_movement_value"
        field="prorrogation_movement_value"
        status="title_prorrogated"
        :disabled="disabled"
        calculated
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <operation-expense-field
        label="Custas de Abatimento"
        field="slaughter_cost"
        v-model="operation.slaughter_cost"
        :disabled="disabled"
        no-validation
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <operation-expense-field
        field="negotiation_retention_value"
        label="Retenção Negocial"
        v-model="operation.negotiation_retention_value"
        :disabled="disabled"
      />
    </v-col>

    <v-col cols="12" class="mb-n4">
      <v-form ref="net_received_value" class="d-flex w-100">
        <operation-expense-field
          field="net_received_value"
          label="Valor Liquido Recebido"
          v-model="operation.net_received_value"
          :disabled="disabled"
        />
      </v-form>
    </v-col>
  </v-col>
</template>

<script>
import { bus } from '@/main';
import Separator from '@/components/defaults/layout/Separator.vue';

import OperationHandler from '../../state/operation.handler';
import { actions } from '../../state/operation.state';
import ConciliationField from '../inputs/ConciliationField.vue';
import OperationExpenseField from '../inputs/OperationExpenseField.vue';

export default {
	name: 'OperationSaveExpensesIndirect',
	props: {
		disabled: Boolean,
	},
	mixins: [OperationHandler],
	components: {
		Separator,
		ConciliationField,
		OperationExpenseField,
	},
	data: () => ({
		repurchase_value: 0,
	}),
	created() {
		bus.$on('updateNetReceivedValue', this.revalidateNetReceivedValue);
		bus.$on('updateRepurchaseValue', this.recalculateValues);
	},

	mounted() {
		this.recalculateValues();
	},
	methods: {
		calculate() {
			actions.calculate.net_received_value();
		},
		revalidateNetReceivedValue() {
			this.$nextTick(() => {
				this.average_operation_time = this.operation.calculated_expenses.average_operation_time;
			});

			if (!this.$refs.net_received_value) return;

			this.$refs.net_received_value.validate();
		},

		recalculateValues() {
			this.operation.repurchase_value = this.operation.calculated_expenses.repurchase_value || 0;
			actions.calculate.repurchase_value();

			if (this.$refs.repurchase_fine_value) {
				this.$refs.repurchase_fine_value.getSuffixManually('repurchase_fine_value');
			}

			bus.$emit('updateIndirectField');
			bus.$emit('updateNetReceivedValue');
			this.$forceUpdate();
		},
	},
};
</script>

<style></style>
