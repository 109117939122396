<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:search="search"
		:loading="loadingData"
	>
    <template v-slot:item.status="{ item }">
      <v-btn depressed small :color="userStatusColor(item.status)">
        {{ item.status }}
      </v-btn>
    </template>

		<template v-slot:item.action="{ item }">
			<list-edit-button v-if="item.status === 'ATIVO'" @edit="edit(item)"/>
      <list-reactivate-button v-else-if="item.status === 'INATIVO'" @reactivate="reactivate(item)" />
		</template>

		<template v-slot:loading>
			<loading-data-circular/>
		</template>
	</v-data-table>
</template>

<script>
import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';
import ListReactivateButton from '@/components/defaults/buttons/ListReactivateButton.vue';
import UserService from '@/services/UserService';

export default {
	name: 'UserListTable',
	components: {
		LoadingDataCircular,
		ListEditButton,
		ListReactivateButton,

	},
	props: {
		search: String,
	},
	data() {
		return {
			loading: false,
			reactivating: false,
			items: [],
			loadingData: false,
			headers: [
				{ text: 'Status', value: 'status' },
				{ text: 'Usuário', value: 'name' },
				{ text: 'Email', value: 'email' },
				{ text: 'Tipo de Usuário', value: 'user_type.title' },
				{ text: '', value: 'action', sortable: false },
			],
		};
	},
	created() {
		this.loadUsers();
	},
	methods: {
		edit(user) {
			this.$emit('edit', user.id);
		},

		async reactivate(user) {
			try {
				this.loading = true;
				this.reactivating = true;
				const userService = new UserService();
				await userService.reactivate(user);
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadUsers();
				this.loading = false;
				this.reactivating = false;
			}
		},

		async loadUsers() {
			try {
				this.loadingData = true;

				const userService = new UserService();
				const response = await userService.index();

				this.items = response.data;

				this.items.forEach((user) => {
					user.status = user.deleted_at ? 'INATIVO' : 'ATIVO';
				});
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		userStatusColor(string) {
			switch (string) {
			case 'ATIVO':
				return '#C1E3C8';
			default:
				return '#F6B6B6';
			}
		},
	},
};
</script>

<style>

</style>
