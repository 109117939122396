<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:search="search"
		:loading="loadingData"
	>
		<template v-slot:item.actions="{ item }">
			<list-edit-button @edit="onEdit(item)"/>
		</template>

		<template v-slot:loading>
			<loading-data-circular/>
		</template>
	</v-data-table>
</template>

<script>

import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';
import ProjectService from '@/services/ProjectService';

export default {
	name: 'DefaultExpensesListTable',
	components: {
		LoadingDataCircular,
		ListEditButton,
	},
	props: {
		loading: Boolean,
		search: String,
	},
	data() {
		return {
			items: [],
			loadingData: false,
			headers: [
				{ text: '#', value: 'id' },
				{ text: 'Descrição', value: 'description' },
				{ text: 'Projeto', value: 'project.name' },
				{ text: 'Fundo', value: 'fund.name' },
				{ text: '', value: 'actions', align: 'end' },
			],
		};
	},
	created() {
		this.loadDefaultExpenses();
	},
	methods: {
		/**
		 * CRUDS
		 */
		async loadDefaultExpenses() {
			try {
				this.loadingData = true;

				const id = this.getProjectId();

				const projectService = new ProjectService();

				const response = await projectService.defaultExpenses(id).index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		onEdit(item) {
			this.$emit('edit', item);
		},
		getProjectId() {
			const projectId = this.$route.params.project;

			return projectId;
		},
	},
};
</script>

<style>

</style>
