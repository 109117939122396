<template>
	<v-tabs v-model="tab">
		<v-tab href="#cnab">Configurações</v-tab>

		<v-tab href="#segments">Segmentos</v-tab>

		<v-tab href="#positions">Posições</v-tab>

		<v-tab-item value="cnab">
			<cnab-form v-model="cnab"/>
		</v-tab-item>

		<v-tab-item value="segments">
			<segment-form
				v-model="cnab.segments"
				@view="viewPositionsOfSegment"
			/>
		</v-tab-item>

		<v-tab-item value="positions">
			<position-form
				v-model="cnab.segments"
				:changeSegmentToShow="segmentShown"
			/>
		</v-tab-item>
	</v-tabs>
</template>

<script>
import CnabForm from './CnabForm.vue';
import SegmentForm from './segment/SegmentForm.vue';
import PositionForm from './position/PositionForm.vue';

export default {
	name: 'CnabRegisterTabs',
	components: {
		CnabForm,
		SegmentForm,
		PositionForm,
	},
	data() {
		return {
			cnab: this.value,
			tab: 'cnab',
			segmentShown: null,
		};
	},
	props: {
		value: Object,
	},
	watch: {
		cnab() {
			this.$emit('input', this.cnab);
		},
		value() {
			if (this.cnab == this.value) return;

			this.cnab = this.value;
		},
	},
	methods: {
		viewPositionsOfSegment(segment) {
			this.changeTabToPosition();

			this.segmentShown = segment;
		},
		changeTabToPosition() {
			this.tab = 'positions';
		},
	},
};
</script>

<style>

</style>
