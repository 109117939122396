<template>
	<!-- ACCESS LIST -->
	<page-container no-column>
		<!-- FIELDSET -->
		<v-col cols="12">
			<div class="d-flex flex-row">
				<span class="flex-item text-caption mb-n3 text--secondary"> Acessos: </span>

				<div class="flex-item">
					<invert-selection-button
						right
						:items="accesses"
						@selectInverted="onSelectionInverted"
					/>
				</div>
			</div>
			<v-divider/>
		</v-col>

		<!-- ACCESS LIST GROUP -->
		<v-row v-show="!loading" class="pa-4">
			<v-col
				v-for="(access, index) in serializedAccesses"
				:key="index"
				:xl="isGrouper(access) ? '12' : '2'"
				:lg="isGrouper(access) ? '12' : '3'"
				:md="isGrouper(access) ? '12' : '4'"
				:sm="isGrouper(access) ? '12' : '4'"
				:xs="isGrouper(access) ? '12' : '6'"
			>
				<!-- ACCESS ITEMS -->

				<v-divider v-if="isGrouper(access) && index != 0" class="mb-1 mt-2"/>

				<checkbox
					@change="changeActiveAccesses(access)"
					:label="access.title"
					v-model="access.value"
					bind-model
					:class="isGrouper(access) ? 'font-weight-medium' : 'ml-4'"
				/>
			</v-col>
		</v-row>

		<loading-data-circular
			v-show="loading"
			color="grey"
		/>
	</page-container>
</template>

<script>
import InvertSelectionButton from '@/components/defaults/buttons/InvertSelectionButton.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import Checkbox from '@/components/defaults/Checkbox.vue';

import AccessesHandler from './accesses.handler';

export default {
	name: 'AccessesList',
	components: {
		PageContainer,
		InvertSelectionButton,
		LoadingDataCircular,
		Checkbox,
	},
	props: {
		items: Array,
		value: [Object, Array],
		loading: Boolean,
	},
	data() {
		return {
			accessesHandler: AccessesHandler,
			activeAccesses: this.value,
			accesses: [],
		};
	},
	computed: {
		serializedAccesses() {
			const toSerialize = this.accesses;

			const serialized = AccessesHandler.serializeAccesses(toSerialize);

			return serialized;
		},
	},
	watch: {
		items() {
			if (this.accesses == this.items) return;

			this.accesses = this.items;
		},
		value() {
			if (this.activeAccesses == this.value) return;

			this.activeAccesses = this.value;

			this.changeActiveAccessesByValue();
		},
	},
	methods: {
		isGrouper(access) {
			return AccessesHandler.isGrouper(access);
		},
		changeActiveAccessesByValue() {
			if (!this.accesses || !this.accesses.length || !this.value.length) return;

			const activeAlreadyRelatedAccesses = AccessesHandler
				.matchAccessesValue(this.accesses, this.activeAccesses);
			this.changeUserTypeAccess();

			this.accesses = activeAlreadyRelatedAccesses;
			this.$emit('changeItems', activeAlreadyRelatedAccesses);
		},
		changeUserTypeAccess() {
			this.activeAccesses = this.accesses.filter((access) => access.value);

			this.$emit('input', this.activeAccesses);
		},
		/**
		 * EVENTS
		 */
		changeActiveAccesses(value) {
			const access = value;

			access.value = !access.value;

			AccessesHandler.changeValueOfChildrenIfHaveAny(access, this.accesses);

			AccessesHandler.changeValueOfFatherIfHaveAny(access, this.accesses);

			this.changeUserTypeAccess();

			this.$forceUpdate();
		},
		onSelectionInverted(invertedSelection) {
			this.accesses = [...invertedSelection];

			this.changeUserTypeAccess();
		},
	},
};
</script>

<style>

</style>
