<template>
	<v-tabs v-model="tab" class="mt-n6" :disabled="loading">
		<v-tab href="#main"> Principais </v-tab>

		<v-tab-item value="main">
			<v-form
				ref="operationMainForm"
				v-model="valid.main"
			>
				<operation-save-main-form
					@revalidate="revalidate('operationMainForm')"
				/>
			</v-form>
		</v-tab-item>
	</v-tabs>
</template>

<script>
// import ConciliationListTable from './conciliation/ConciliationListTable.vue';
// import OperationSaveExpensesForm from './forms/ReceiptsSaveExpensesForm.vue';
import OperationSaveMainForm from './forms/ReceiptsSaveMainForm.vue';

export default {
	name: 'OperationSaveTabs',
	components: {

		OperationSaveMainForm,

	},
	props: {
		loading: Boolean,
	},
	data() {
		return {
			tab: 'main',
			valid: {
				main: false,
				expenses: false,
			},
			disabled: false,

		};
	},
	watch: {
		operation() {
			this.revalidate('operationMainForm');
		},
		'valid.main': {
			handler() {
				this.$emit('changeValidation', this.valid.main);
			},
		},
	},
	methods: {
		revalidate(form) {
			this.$refs[form].validate();

			if (form !== 'operationMainForm') return;

			if (!this.$refs[form].validate()) {
				this.disabled = true;
			} else {
				this.disabled = false;
			}
		},
		changeLoading(value) {
			this.disabled = value;
		},
	},
};
</script>

<style>

</style>
