<template>
	<page-container>
		<v-col cols="12" class="text-center py-12 d-flex flex-column">
			<v-icon class="text-h1 mt-12">
				mdi-airplane-takeoff
			</v-icon>

			<div
				v-if="!loading && !user.inntravel_token"
				class="d-flex flex-column mx-auto"
			>
				<span class="text-h4 text--secondary mt-3">
					Você não possui conexão com o Inntravel
				</span>

				<span class="text-h6 text--disabled mt-2">
					Vá até a sua configuração de usuário ou peça para um administrador configurar seu acesso.
				</span>
			</div>

			<span class="text-h4 text--secondary mt-3" v-else>
				Redirecionando você ao Inntravel
			</span>

			<v-col
				xl="4" lg="4" md="4" sm="8" xs="12"
				class="flex-item my-12 mx-auto"
				v-if="loading"
			>
				<v-progress-linear indeterminate/>
			</v-col>
		</v-col>
	</page-container>
</template>

<script>
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import UserService from '@/services/UserService';

export default {
	name: 'Inntravel',
	components: {
		PageContainer,
	},
	data() {
		return {
			loading: false,
			user: {},
		};
	},
	created() {
		this.accessInntravel();
	},
	methods: {
		/**
		 * @property {string} token - caught by function getInntravelToken
		 * @return void
		 */
		async accessInntravel() {
			const token = await this.getInntravelToken();

			if (!token) return;

			const base_url = `${process.env.VUE_APP_INNTRAVEL_URL}`;

			const inntravelUrl = `${base_url}/login/loginWithToken/${token}`;

			window.open(inntravelUrl);

			this.$router.replace({ name: 'Home' });
		},
		/**
		 * @return {String}
		 */
		async getInntravelToken() {
			try {
				const userid = this.getLoggedUserId();

				if (!userid) throw new Error('Usuário não autenticado');

				this.loading = true;

				const userService = new UserService();

				const response = await userService.show(userid);

				/** @type {User} */
				this.user = response.data;

				return this.user.inntravel_token;
			} catch (error) {
				this.$http.defaultCatchError(error);

				this.user = {};
				return '';
			} finally {
				this.loading = false;
			}
		},
		/**
		 * @return {Number} id
		 */
		getLoggedUserId() {
			/** @type {SessionStorage} */
			const loggedUser = this.$helpers.jwt.getLoggedUser();

			/** @type {User} */
			const { user } = this.$helpers.jwt.decode(loggedUser.access_token);

			return user.id;
		},
	},
};

/**
 * TYPE DEFINITIONS
 */

/**
 * @typedef {Object} SessionStorage
 *
 * @property {String} access_token
 * @property {Number} expires_in
 * @property {String} token_type
 */

/**
 * @typedef {Object} User
 *
 * @property {Number} id
 * @property {String} email
 * @property {Date} created_at
 * @property {Date} updated_at
 * @property {String} phone
 * @property {Number} roles_id
 * @property {String|null} verify_token
 * @property {Date|null} verified_at
 * @property {String} image_url
 * @property {String} inntravel_token
 * @property {String} connect_to_inntravel
 * @property {Date} created_by
 */

</script>

<style>

</style>
