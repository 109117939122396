import axios from 'axios';

export default class CurrencyService {
	constructor() {
		this.http = axios;
	}

	async request() {
		const links = [
			'https://economia.awesomeapi.com.br/json/last/USD,EUR',
			'https://api.bcb.gov.br/dados/serie/bcdata.sgs.189/dados/ultimos/1?formato=json',
			'https://api.bcb.gov.br/dados/serie/bcdata.sgs.433/dados/ultimos/1?formato=json',
			'https://api.bcb.gov.br/dados/serie/bcdata.sgs.432/dados/ultimos/1?formato=json',
			'https://api.bcb.gov.br/dados/serie/bcdata.sgs.433/dados/ultimos/12?formato=json',
		];

		const promises = [];

		links.map((link) => promises.push(this.http.get(link)));

		const responses = await Promise.all(promises);

		return responses.map((response) => response.data);
	}

	static calculateInflation(data) {
		return data.reduce((a, b) => Number(a) + (Number(b.valor) || 0), 0);
	}
}
