<template>
	<page-container>
		<breadcrumbs :page-title="getPageTitle()"/>

		<v-card
			flat
			:loading="loadingData"
		>
			<page-container no-column>
				<iframe v-if="project.power_bi_url || project.power_bi_url2"
					:src=" type === 'first' ? project.power_bi_url : project.power_bi_url2"
					name="Innova Connect Sistemas"
					height="700px"
					width="100%"
					frameborder="0"
					marginweight="10"
					marginwidth="10"
					scrolling="auto"
				/>
			</page-container>
		</v-card>
	</page-container>
</template>

<script>
// layout components
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import ProjectService from '@/services/ProjectService';

export default {
	name: 'PowerBIView',
	components: {
		PageContainer,
		Breadcrumbs,
	},
	data() {
		return {
			project: {},
			type: '',
		};
	},
	created() {
		this.loadProject();

		const { type } = this.$route.params;

		this.type = type;
	},
	methods: {
		getPageTitle() {
			// TO-DO: implementar busca da empresa para poder encontrar o nome certo para atividade
			return 'Dashboard - Power BI';
		},
		async loadProject() {
			try {
				const id = this.getIdOfRoute();

				if (this.isANewProject()) return;

				this.loadingData = true;

				const projectService = new ProjectService();

				const response = await projectService.show(id);

				this.project = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);

				this.project = {};
			} finally {
				this.loadingData = false;
			}
		},
		/**
		 * Helper functions
		 */
		idIsNewOrNotExists(id) {
			return (!id || id == 'new');
		},
		getIdOfRoute() {
			const { id } = this.$route.params;
			return id;
		},
		isANewProject() {
			if (this.project.id) return false;

			const id = this.getIdOfRoute();

			return (this.idIsNewOrNotExists(id));
		},
		returnToPreviousRoute() {
			this.$router.push({ name: 'ProjectList' });
		},
	},
};
</script>

<style>

</style>
