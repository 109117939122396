<template>
  <v-dialog width="60%" v-model="dialog">
    <v-card>
      <v-toolbar flat color="white">
				<v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title class="headline mb-2"> Marcar Títulos </v-card-title>

      <v-divider class="mb-4" />

      <v-card-text>
        <v-form ref="conciliationForm">
          <conciliation-dialog-filters v-model="filters" />

          <conciliation-dialog-list
            ref="conciliationDialogList"
            :project="project"
            :conciliated="conciliated"
            :filters="filters"
            v-model="selected"
            :status="status"
            @error="changeError"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex flex-row-reverse">
        <form-save-button :valid="!error" @click="save" />

        <span
          v-if="!!hasSelectedInAnotherOperation"
          class="d-flex error--text text-caption flex-item w-100"
        >
          Título:
          <b class="mx-2">
            {{
              hasSelectedInAnotherOperation
                ? hasSelectedInAnotherOperation.number
                : ""
            }}
          </b>
          Já conciliado em outra operação, com o mesmo motivo.
        </span>

        <!-- Another errors -->
        <span
          v-if="!!error"
          class="d-flex error--text text-caption flex-item w-100"
        >
          {{ error }}
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from '@/main';
import {
	getters,
	mutations,
} from '@/views/operations/save/state/operation.state';

import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import ConciliationDialogList from './ConciliationDialogList.vue';
import ConciliationDialogFilters from './ConciliationDialogFilters.vue';
import ReceiptsnHandler from '../state/receipts.handler';

export default {
	components: {
		FormSaveButton,
		ConciliationDialogList,
		ConciliationDialogFilters,
	},
	name: 'ConciliationDialog',
	props: {
		value: [Array, Object],
	},
	mixins: [ReceiptsnHandler],
	data() {
		return {
			filters: {},
			dialog: false,
			status: '',
			selected: [],
			conciliated: [],
			called_from: null,
			hasSelectedInAnotherOperation: '',
			error: '',
		};
	},
	computed: {
		conciliated_handled() {
			return getters.conciliated();
		},
	},
	watch: {
		dialog: {
			handler() {
				if (!this.dialog) {
					mutations.conciliated(this.conciliated);

					this.called_from.updateWhenConciliationField();
				}
			},
			deep: true,
		},
		conciliated_handled() {
			this.conciliated = this.conciliated_handled;
		},
	},
	mounted() {
		this.registerEvents();
	},
	methods: {
		changeError(error) {
			this.error = error;
		},
		registerEvents() {
			bus.$on('openConciliationDialog', this.toggleDialog);
		},
		toggleDialog(component) {
			this.dialog = !this.dialog;

			if (!this.dialog) return;

			this.called_from = component;
			this.status = component.status;
			this.filters = {};
			this.loadConciliations();

			if (!this.$refs.conciliationDialogList) return;

			this.$refs.conciliationDialogList.returnToFirstPage();
		},
		loadConciliations() {
			if (!this.conciliated || !this.conciliated.length) {
				this.selected = [];
			}

			const operationId = getters.operation().id;

			this.selected = this.conciliated.filter((conciliate) => this.filterConciliatedRegisterToGetOnlyOfThisStatus(
				conciliate,
				operationId,
			));
		},
		filterConciliatedRegisterToGetOnlyOfThisStatus(register, operationId) {
			if (!operationId) return false;

			const result = register.status.some((row) => {
				const statusMatch = row.status == this.status;
				const operationMatch = row.fund_return_id == operationId;

				const status = statusMatch && operationMatch;

				return status;
			});

			return result;
		},
		save() {
			if (this.error) {
				this.dialog = false;
				return;
			}

			this.removeOldConciliation();

			this.addConciliation();

			this.toggleDialog();
		},
		addConciliation() {
			this.selected.forEach((toConciliate) => {
				const conciliate = this.conciliated.find(
					(conciliated) => conciliated.id == toConciliate.id,
				);

				const operationId = getters.operation().id;

				if (conciliate) {
					if (!conciliate.status || !conciliate.status.length) {
						conciliate.status = [];
					}

					conciliate.status.push({
						status: this.status,
						fund_return_id: operationId,
					});
				} else {
					toConciliate.status = [
						{ status: this.status, fund_return_id: operationId },
					];

					this.conciliated.push(toConciliate);
				}
			});
		},
		removeOldConciliation() {
			this.conciliated = this.conciliated.map((conciliate) => {
				conciliate.status = conciliate.status.filter(
					(row) => !this.operationAndStatusMatch(row),
				);

				return conciliate;
			});
		},
		operationAndStatusMatch(row) {
			const operationId = getters.operation().id;

			return row.status == this.status && row.fund_return_id == operationId;
		},
	},
};
</script>

<style>
</style>
