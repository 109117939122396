<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:items-per-page="5"
		:loading="loading"
	>
		<template v-slot:item.operation="{ item }">
			{{ getOperationDescription(item.operation) }}
		</template>

		<template v-slot:item.type="{ item }">
			<v-chip
				class="white--text"
				small
				:color="getTypeColor(item.type)"
			>
				{{ getTypeDescription(item.type) }}
			</v-chip>
		</template>
		<template v-slot:item.duplicate="{ item } ">
			<cnab-duplicate-button
				v-model="item.id"
				@open="open"
			/>
		</template>

		<template v-slot:item.edit="{ item }">
			<list-edit-button @edit="save(item.id)"/>
		</template>
	</v-data-table>
</template>

<script>
import ListEditButton from '../../../components/defaults/buttons/ListEditButton.vue';
import CnabDuplicateButton from './CnabDuplicateButton.vue';

export default {
	name: 'CnabTable',
	components: {
		ListEditButton,
		CnabDuplicateButton,
	},
	props: {
		items: Array,
		loading: Boolean,
	},
	data() {
		return {
			headers: [
				{
					text: '#',
					value: 'id',
				},
				{
					text: 'Operação',
					value: 'operation',
				},
				{
					text: 'Tipo',
					value: 'type',
				},
				{
					text: 'Descrição',
					sortable: false,
					value: 'description',
				},
				{
					text: 'Tamanho',
					sortable: false,
					value: 'size',
				},
				{
					text: '',
					sortable: false,
					value: 'duplicate',
				},
				{
					text: '',
					sortable: false,
					value: 'edit',
				},
			],
		};
	},
	methods: {
		save(identifier) {
			this.$emit('save', identifier);
		},
		open(identifier) {
			this.$emit('open', identifier);
		},
		getOperationDescription(operation) {
			return operation ? 'Retorno' : 'Remessa';
		},
		getTypeDescription(type) {
			return type ? 'Receber' : 'Pagar';
		},
		getTypeColor(type) {
			return type ? 'success' : 'error';
		},
	},
};
</script>

<style>

</style>
