import Rest from './base/Rest';

export default class AccessService extends Rest {
	static resource = 'accesses';

	menus() {
		this.relationship = 'menus';

		return this;
	}

	functionalities() {
		this.relationship = 'functionalities';

		return this;
	}
}
