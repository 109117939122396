<template>
	<page-container no-column>
		<v-col xl="5" lg="6" md="6" sm="8" xs="12">
			<v-text-field
				label="Descrição"
				v-model="default_expenses.description"
				:rules="[ rules.required ]"
			/>
		</v-col>

		<v-col xl="3" lg="4" md="5" sm="4" xs="12">
			<fund-select
				v-model="default_expenses.fund_id"
				:rules="[ rules.required, rules.doesntHaveDefinedThisFund ]"
				:loading="loadingAlreadyDefinedFunds"
			/>
		</v-col>

		<v-col xl="4" lg="2" md="1" sm="0" xs="0" class="d-md-flex d-none">
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-text-field
				label="FLOAT"
				v-model="default_expenses.float"
				type="number"
				suffix="Dias"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<percent-field
				label="IOF"
				v-model="default_expenses.iof_rate"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="TED"
				v-model="default_expenses.ted_cost"
				prefix="R$"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="TAC"
				v-model="default_expenses.tac_cost"
				prefix="R$"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="Tarifa de Boleto"
				v-model="default_expenses.bank_slip_cost"
				prefix="R$"
			/>
		</v-col>

		<v-col xl="2" lg="3" md="4" sm="6" xs="12">
			<v-currency-field
				label="Tarifa Serasa"
				v-model="default_expenses.serasa_cost"
				prefix="R$"
			/>
		</v-col>
	</page-container>
</template>

<script>
import PercentField from '@/components/defaults/PercentField.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import FundSelect from '@/components/funds/FundSelect.vue';
import ProjectService from '@/services/ProjectService';

export default {
	name: 'DefaultDirectExpensesForm',
	components: {
		PercentField,
		PageContainer,
		FundSelect,
	},
	props: {
		value: Object,
		project: [String, Number, Object],
	},
	data() {
		return {
			rules: {
				required: (v) => (!!v && v !== 0) || 'Campo Necessário',
				doesntHaveDefinedThisFund: () => this.defaultExpensesAlreadyHaveFund(),
			},
			default_expenses: this.value || {},
			alreadyDefinedFunds: [],
			loadingAlreadyDefinedFunds: false,
		};
	},
	watch: {
		default_expenses() {
			this.$emit('input', this.default_expenses);
		},
		value() {
			if (this.default_expenses === this.value) return;

			this.default_expenses = this.value;
		},
	},
	created() {
		this.loadAlreadyDefinedFunds();
	},
	methods: {
		async loadAlreadyDefinedFunds() {
			if (this.alreadyDefinedFunds && this.alreadyDefinedFunds.length) {
				return this.alreadyDefinedFunds;
			}

			try {
				const projectService = new ProjectService();

				this.loadingAlreadyDefinedFunds = true;

				const response = await projectService
					.defaultExpenses(this.project)
					.index();

				this.alreadyDefinedFunds = this.$helpers.pluck('fund', response.data);

				return this.alreadyDefinedFunds;
			} catch (error) {
				this.$http.defaultCatchError(error);

				return [];
			} finally {
				this.loadingAlreadyDefinedFunds = false;
			}
		},
		defaultExpensesAlreadyHaveFund() {
			this.loadAlreadyDefinedFunds();

			const alreadyFund = this.alreadyDefinedFunds;

			if (!alreadyFund || !alreadyFund.length) {
				return true;
			}

			const alreadyDefined = this.$helpers
				.find('id', this.default_expenses.fund_id, alreadyFund);

			const isToSave = this.isToSaveDefaultExpenses();

			if (alreadyDefined && isToSave) {
				return 'Fundo já utilizado, por favor acesse a edição desse fundo';
			}

			return true;
		},
		isToSaveDefaultExpenses() {
			return !this.$route.params.expense
				|| this.$route.params.expense == 'new';
		},
	},
};
</script>

<style>

</style>
