<template>
  <page-container>
    <breadcrumbs page-title="Controle de férias" />
    <v-row style="margin-bottom: 50px">
      <v-col xs="8" lg="8" lx="8" sm="8" md="8">
        <v-text-field
          label="Pesquisar"
          v-model="search"
          prepend-icon="mdi-magnify"
          @input="setFilters"
          dense
          full-width
        />
      </v-col>
      <v-col xs="1" lg="1" lx="1" sm="1" md="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              style="margin-bottom: 10px"
              color="normal"
              v-on="on"
              @click="showFilters = !showFilters"
              ><v-icon>mdi-filter</v-icon></v-btn
            >
          </template>
          <span>Clique para selecionar os filtros</span>
        </v-tooltip>
      </v-col>
      <v-col xs="12" lg="12" lx="12" sm="12" md="12">
        <v-expand-transition>
          <v-card style="padding: 20px" v-show="showFilters">
            <filters-vacations-list ref="fields" />
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>

    <list-structure>
      <template v-slot:actions>
        <list-add-button @add="add" color="#E09A11" />
        <v-btn
          @click="credAdd"
          v-if="validatePermission()"
          class="float-right"
          color="#C1E3C8"
          style="margin-left: 16px"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>Crédito</v-btn>
        <v-btn
          @click="downAdd"
          v-if="validatePermission()"
          class="float-right"
          color="#FE5151"
          style="margin-left: 14px"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>Baixa</v-btn>
      </template>

      <template v-slot:table>
        <vacations-table-list
          :filters="filters"
          :fields="fields"
          @edit="edit"
          @calculate="calculate"
        />
        <v-row v-if="!validatePermission()">
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            xs="12"
            class="d-flex justify-center"
          >
            <h3>Saldo de férias</h3>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            xs="12"
            class="d-flex justify-space-around"
          >
            <h4>{{ `${calculated} dias` }}</h4>
          </v-col>
        </v-row>
      </template>
    </list-structure>
  </page-container>
</template>

<script>
import ListAddButton from '@/components/defaults/buttons/ListAddButton.vue';
import ListStructure from '@/components/defaults/layout/ListStructure.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';

// nested components
import VacationsTableList from './VacationsTableList.vue';
import FiltersVacationsList from './FiltersVacationsList.vue';

export default {
	name: 'VacationsList',

	data: () => ({
		user: {},
		filters: {},
		fields: [],
		searchTerm: '',
		search: '',

		calculated: 0,

		showFilters: false,
	}),

	created() {
		this.getLoggedUser();
	},

	components: {
		ListAddButton,
		ListStructure,
		PageContainer,
		Breadcrumbs,
		VacationsTableList,
		FiltersVacationsList,
	},

	methods: {
		add() {
			this.pushToOperationSendRoute({ id: 'new' });
		},
		edit(item) {
			const { type } = item;

			switch (type) {
			case 'C':
				this.pushToEditCreditVacationRoute({ id: item.vacations_id });
				break;
			case 'B':
				this.pushToEditPurchasedVacationRoute({ id: item.vacations_id });
				break;
			default:
				this.pushToOperationSendRoute({ id: item.vacations_id });
			}
		},
		pushToOperationSendRoute(params) {
			this.$router.push({ name: 'VacationsSave', params });
		},
		pushToEditPurchasedVacationRoute(params) {
			this.$router.push({ name: 'BuyVacationSave', params });
		},
		pushToEditCreditVacationRoute(params) {
			this.$router.push({ name: 'CreditVacationSave', params });
		},
		validatePermission() {
			return this.user.user_type.name === 'admin';
		},
		downAdd() {
			this.$router.push({
				name: 'BuyVacationSave',
				params: {
					id: 'new',
				},
			});
		},
		credAdd() {
			this.$router.push({
				name: 'CreditVacationSave',
				params: {
					id: 'new',
				},
			});
		},
		getLoggedUser() {
			const token = this.$helpers.jwt.getLoggedUser();

			const tokenData = this.$helpers.jwt.decode(token.access_token);
			this.user = tokenData;
		},
		calculate(itens) {
			let day = 0;
			itens.forEach((element) => {
				if (element.type === 'C' || element.type === 'CG') {
					day += element.number_days;
				}
				if (element.type === 'S' && element.status === 'approve') {
					day -= element.number_days;
				}

				if (element.type === 'B') {
					day -= element.number_days;
				}
			});

			this.calculated = day;
		},
		setFilters() {
			this.filters = this.search;
			this.fields = this.$refs.fields.row;
		},
	},
};
</script>
