export default {
	data() {
		return {
			primaryKey: 'index',
			model: {},
			list: [],
			toClean: {},
		};
	},
	methods: {
		/**
		 * FUNCOES QUE NECESSITAM SER SOBRESCRITAS
		 */
		resetForm() {
			throw 'Function resetForm needs to be overwrited by father';
		},
		validateForm() {
			throw 'Function validateForm needs to be overwrited by father';
		},
		/**
		 *
		 * FUNCOES DE CRUD PADRAO PARA RELACIONAMENTOS HASMANY
		 * manipula os dados para o filho desse relacionamento (aquele que pertence ao)
		 *
		 */
		edit(model) {
			this.model = { ...this.model, ...model };
		},
		save() {
			if (this.isToCreate()) {
				this.create();
			} else {
				this.update();
			}
		},
		create() {
			const valid = this.validateForm();

			if (!valid) return;

			if (!this.list || !this.list.length) {
				this.list = [];
			}

			this.model[this.primaryKey] = this.list.length + 1;

			this.list.push({ ...this.model });

			this.cleanHandleModel();

			this.updateList([...this.list]);
		},
		update() {
			const updatedList = this.updateAllListByReplacement();

			this.cleanHandleModel();

			this.updateList(updatedList);
		},
		updateAllListByReplacement() {
			const model = { ...this.model };

			const updatedList = this.list.map((item) => {
				if (item[this.primaryKey] == model[this.primaryKey]) {
					item = { ...item, ...model };
				}

				return item;
			});

			this.list = updatedList;

			return updatedList;
		},
		destroy(toDestroy) {
			const withoutDestroyed = this.list.filter((item) => item[this.primaryKey] != toDestroy[this.primaryKey]);

			this.list = [...withoutDestroyed];

			this.reorder(this.list);
		},
		/**
		 * FUNCOES AUXILIARES RELACIONADAS
		 */
		isToCreate() {
			const result = this.model && !this.model[this.primaryKey];

			return !!result;
		},
		isEditing() {
			return this.model && !this.isToCreate();
		},
		cleanHandleModel() {
			this.model = { ...this.model, ...this.toClean };
		},
		reorder(toReorder) {
			const reorderedList = this.reorderList([...toReorder]);

			this.updateList(reorderedList);
		},
		reorderList(toReorder) {
			const reorderedList = [];

			toReorder.forEach((item, index) => {
				item[this.primaryKey] = index + 1;

				reorderedList.push(item);
			});

			return reorderedList;
		},
		updateList(toUpdateList) {
			this.list = [];

			this.$nextTick(() => {
				this.list = toUpdateList;
			});
		},
	},
};
