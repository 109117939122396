import Rest from './base/Rest';

export default class FundTypesService extends Rest {
	static resource = 'funds/';

	cnab(fund_id) {
		this.relationship = `${fund_id}/cnab`;

		return this;
	}
}
