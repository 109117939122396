<template>
	<v-tabs v-model="tab" class="mt-n6" :disabled="loading">
		<v-tab href="#main"> Principais </v-tab>
		<v-tab href="#charges" :disabled="loading || disabled"> Cobranças </v-tab>
		<v-tab href="#conciliation" :disabled=" loading || disabled"> Pendências </v-tab>

		<v-tab-item value="main">
			<v-form
				ref="operationMainForm"
				v-model="valid.main"
			>
				<operation-save-main-form
					@revalidate="revalidate('operationMainForm')"
				/>
			</v-form>
		</v-tab-item>

		<v-tab-item value="charges">
			<v-form
				ref="operationExpensesForm"
				v-model="valid.expenses"
			>
				<operation-save-expenses-form
					@focus="revalidate('operationExpensesForm')"
					@click="revalidate('operationExpensesForm')"
				/>
			</v-form>
		</v-tab-item>

		<v-tab-item value="conciliation">
			<conciliation-list-table/>
		</v-tab-item>
	</v-tabs>
</template>

<script>
import ConciliationListTable from './conciliation/ConciliationListTable.vue';
import OperationSaveExpensesForm from './forms/OperationSaveExpensesForm.vue';
import OperationSaveMainForm from './forms/OperationSaveMainForm.vue';

export default {
	name: 'OperationSaveTabs',
	components: {
		ConciliationListTable,
		OperationSaveMainForm,
		OperationSaveExpensesForm,
	},
	props: {
		loading: Boolean,
	},
	data() {
		return {
			tab: 'main',
			valid: {
				main: false,
				expenses: false,
			},
			disabled: false,

		};
	},
	watch: {
		operation() {
			this.revalidate('operationMainForm');
		},
		'valid.main': {
			handler() {
				this.$emit('changeValidation', this.valid.main);
			},
		},
	},
	methods: {
		revalidate(form) {
			this.$refs[form].validate();

			if (form !== 'operationMainForm') return;

			if (!this.$refs[form].validate()) {
				this.disabled = true;
			} else {
				this.disabled = false;
			}
		},
		changeLoading(value) {
			this.disabled = value;
		},
	},
};
</script>

<style>

</style>
