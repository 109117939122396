<template>
	<page-container>
		<breadcrumbs :page-title="getRouteTitle()"/>

		<v-form class="w-100" v-model="valid" ref="form">
			<form-structure :loading="loadingData">
				<template v-slot:content>
					<div class="mt-n7 d-flex flex-column w-100">
						<v-tabs v-model="activeTab">
							<v-tab href="#direct" :disabled="isLoading()">
								Incidentes Diretos
							</v-tab>

							<v-tab href="#indirect" :disabled="isLoading()">
								Incidentes Indiretos
							</v-tab>

							<v-tab href="#goodwill" :disabled="isLoading()">
								Deságio
							</v-tab>

							<v-tabs-items v-model="activeTab">
								<v-tab-item value="direct">
									<default-direct-expenses-form
										v-model="default_expenses"
										:project="project"
									/>
								</v-tab-item>

								<v-tab-item value="indirect">
									<default-indirect-expenses-form
										v-model="default_expenses"
										:project="project"
									/>
								</v-tab-item>

								<v-tab-item value="goodwill">
									<default-expenses-goodwill v-model="default_expenses.goodwills"/>
								</v-tab-item>
							</v-tabs-items>
						</v-tabs>
					</div>
				</template>

				<template v-slot:actions>
					<form-cancel-button/>

					<form-save-button
						:valid="valid"
						@click="save"
						:create="isANewDefaultExpenses()"
						:loading="loadingSubmit"
						:disabled="loadingData"
					/>

					<form-destroy-button
						v-if="!isANewDefaultExpenses()"
						:loading="loadingRemove"
						:disabled="loadingData"
						@click="destroy"
					/>
				</template>
			</form-structure>
		</v-form>
	</page-container>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FormStructure from '@/components/defaults/layout/FormStructure.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';
import FormSaveButton from '@/components/defaults/buttons/FormSaveButton.vue';
import FormDestroyButton from '@/components/defaults/buttons/FormDestroyButton.vue';
import ProjectService from '@/services/ProjectService';
import FormCancelButton from '@/components/defaults/buttons/FormCancelButton.vue';

import DefaultDirectExpensesForm from './DefaultDirectExpensesForm.vue';
import DefaultIndirectExpensesForm from './DefaultIndirectExpensesForm.vue';
import DefaultExpensesGoodwill from './DefaultExpensesGoodwill.vue';

export default {
	name: 'DefaultExpensesSave',
	components: {
		PageContainer,
		FormStructure,
		DefaultDirectExpensesForm,
		DefaultIndirectExpensesForm,
		FormSaveButton,
		FormDestroyButton,
		Breadcrumbs,
		FormCancelButton,
		DefaultExpensesGoodwill,
	},
	data() {
		return {
			default_expenses: {
				gooodwills: [],
			},
			valid: false,
			loadingData: false,
			loadingSubmit: false,
			loadingRemove: false,
			activeTab: 'direct',
			project: this.$route.params.project,
		};
	},
	created() {
		this.show();
	},
	methods: {
		/**
		 * CRUD
		 */
		async show() {
			try {
				const defaultExpensesId = this.getIdByRoute();

				if (this.isANewDefaultExpenses()) return;

				this.loadingData = true;

				const projectService = new ProjectService();

				const response = await projectService
					.defaultExpenses(this.project)
					.show({ id: defaultExpensesId });

				this.default_expenses = response.data;

				if (!this.default_expenses.goodwills
					|| !this.default_expenses.goodwills.length) {
					return;
				}

				this.default_expenses.goodwills = this.default_expenses.goodwills
					.map((goodwill, index) => {
						goodwill.index = index;
						return goodwill;
					});
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		async save() {
			try {
				const canSave = this.revalidate();

				if (!canSave) return;

				this.loadingSubmit = true;

				const projectService = new ProjectService();

				if (this.isANewDefaultExpenses()) {
					await projectService
						.defaultExpenses(this.project)
						.store(this.default_expenses);
				} else {
					await projectService
						.defaultExpenses(this.project)
						.update(this.default_expenses);
				}

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingSubmit = false;
			}
		},
		async destroy() {
			try {
				if (this.isANewDefaultExpenses()) return;

				this.loadingRemove = true;

				const projectService = new ProjectService();

				await projectService
					.defaultExpenses(this.project)
					.destroy(this.default_expenses);

				this.default_expenses = {};

				this.returnToPreviousRoute();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingRemove = false;
			}
		},
		/**
		 * UX
		 */
		returnToPreviousRoute() {
			this.$router.push({ name: 'DefaultExpensesList' });
		},
		getRouteTitle() {
			if (this.isANewDefaultExpenses()) return 'Criação de Taxas Padrões de Fundos';

			return 'Edição de Taxas Padrões de Fundos';
		},
		isANewDefaultExpenses() {
			if (this.default_expenses && this.default_expenses.id) return false;

			const defaultExpensesId = this.getIdByRoute();

			const result = this.defaultExpensesIsNewOrNotExists(defaultExpensesId);

			return result;
		},
		getIdByRoute() {
			const defaultExpensesId = this.$route.params.expense;

			return defaultExpensesId;
		},
		defaultExpensesIsNewOrNotExists(id) {
			return (!id || id == 'new');
		},
		revalidate() {
			return this.$refs.form.validate();
		},
		isLoading() {
			return this.loadingData;
		},
	},
};
</script>

<style>

</style>
