<template>
	<page-container no-column>
		<v-col xl="12" lg="12" md="12" sm="12" xs="12">
			<file-dialog
				v-model="operation.file"
				@change="readFile"
			/>
			<span class="error--text text-caption" v-show="canRead() && !operation.registers.length">
				Ocorreu um erro ao ler o arquivo
			</span>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<company-select
				v-model="operation.company"
				:project="project"
				@change="readFile"
				return-object
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<date-picker
				label="Data de Contratação"
				v-model="operation.hiring_date"
				:rules="[ rules.required ]"
				@input="readFile"
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<fund-select
				v-model="operation.fund"
				@change="changeFund"
				:rules="[ rules.required, validDefaultExpenses ]"
				:loading="loading.default_expenses"
				return-object
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<operation-type-select
				v-model="operation.type"
				:rules="[ rules.required, validOperationTypeToCalculate ]"
				:specific-items="operationTypesByFund"
				@input="readFile"
				return-object
			/>
		</v-col>

		<v-col xl="4" lg="4" md="6" sm="12" xs="12">
			<cnab-config-select
				ref="cnabSelect"
				v-model="operation.cnab"
				:fund-id="operation.fund.id"
				:rules="[ rules.required, defaultExpensesIsNotDefined ]"
				@input="readFile"
				by-fund
				return-object
			/>
		</v-col>

		<v-col
			xl="12" lg="12" md="12" sm="12" xs="12"
			class="mb-n4"
		>
			<v-textarea
				label="Observação"
				v-model="operation.observation"
			/>
		</v-col>
	</page-container>
</template>
<script>
import { bus } from '@/main';

import DatePicker from '@/components/defaults/DatePicker.vue';
import FileDialog from '@/components/file_upload/FileDialog.vue';
import CnabConfigSelect from '@/components/funds/CnabConfigSelect.vue';
import FundSelect from '@/components/funds/FundSelect.vue';
import OperationTypeSelect from '@/components/operations/OperationTypeSelect.vue';
import CompanySelect from '@/components/project/CompanySelect.vue';
import PageContainer from '@/components/defaults/layout/PageContainer.vue';

import ProjectService from '@/services/ProjectService';
import ReaderService from '@/services/ReaderService';

import OperationHandler from '../state/operation.handler';
import { getters, actions } from '../state/operation.state';

export default {
	components: {
		FileDialog,
		CompanySelect,
		OperationTypeSelect,
		FundSelect,
		CnabConfigSelect,
		DatePicker,
		PageContainer,
	},
	name: 'OperationSaveMainForm',
	mixins: [OperationHandler],
	data() {
		return {
			loading: {
				file: false,
				default_expenses: false,
			},
			// validation rules
			rules: {
				required: (v) => (v !== 'undefined' && v !== null) || 'Campo Necessário',
			},
		};
	},
	computed: {
		operationTypesByFund() {
			if (!this.operation.fund
				|| !this.operation.default_expenses
				|| !this.operation.default_expenses.goodwills) {
				return [];
			}

			const data = [];

			this.operation.default_expenses.goodwills.forEach((goodwill) => {
				data.push(goodwill.operation);
			});

			return data;
		},
	},

	watch: {
		'operation.type': {
			handler() {
				this.updateGoodwill();
			},
		},
		loading: {
			deep: true,
			handler() {
				const result = Object.entries(this.loading).some((key, value) => value);

				this.$emit('changeLoading', result);
			},
		},
	},
	methods: {
		changeFund() {
			if (!this.operation.fund || !this.operation.fund.id) {
				return;
			}

			this.loadDefaultExpenses();

			this.callCnabLoading();

			this.updateGoodwill();

			this.readFile();
		},

		canRead() {
			if (!this.operation.file
				|| !this.operation.file.content) {
				return false;
			}

			if (!this.operation.company
				|| !this.operation.company.id) {
				return false;
			}

			if (!this.operation.fund
				|| !this.operation.fund.id) {
				return false;
			}

			if (!this.operation.default_expenses
				|| !this.operation.default_expenses.id) {
				return false;
			}

			if (!this.operation.cnab
				|| !this.operation.cnab.id) {
				return false;
			}

			return true;
		},

		updateGoodwill() {
			if (!this.operation.fund
				|| !this.operation.fund.id
				|| !this.operation.type
				|| !this.operation.type.id
				|| !this.operation.default_expenses
			) {
				return;
			}

			const operation_id = this.operation.type.id;

			if (this.operation.default_expenses.goodwills) {
				const matchGoodwill = this.operation.default_expenses.goodwills
					.find((goodwill) => goodwill.operation_id == operation_id);

				if (matchGoodwill === undefined) {
					this.operation.default_expenses.goodwill = 0;
				} else {
					this.operation.default_expenses.goodwill = matchGoodwill.goodwill;
				}
			}
		},

		callCnabLoading() {
			this.$refs.cnabSelect.loadCnab();
		},

		mustChangeDefaultExpenses() {
			return this.fundIsNotValid()
				&& (
					this.oldOperationNotDefined()
					|| this.oldOperationIsDifferent()
				);
		},

		oldOperationIsDifferent() {
			const fund = getters.old_operation_config('fund');
			const cnab = getters.old_operation_config('cnab');
			const file = getters.old_operation_config('file');

			if (fund.id != this.operation.fund.id) return true;

			if (cnab.id != this.operation.cnab.id) return true;

			if (file.content != this.operation.file.content) return true;

			return false;
		},
		/**
		 * VALIDATION METHODS
		 */
		validOperationTypeToCalculate() {
			if (!this.operation || !this.operation.default_expenses) {
				return 'Taxas Padrões não definidas';
			}

			const { goodwills } = this.operation.default_expenses;

			if (!goodwills || !goodwills.length) {
				return 'Nenhum valor de deságio definido para essa operação. Por favor, adicione antes de continuar';
			}

			const operation_id = this.operation.type.id;

			const goodwillMatch = goodwills.some((goodwill) => goodwill.operation_id == operation_id);

			if (!goodwillMatch) {
				return 'Nenhum valor de deságio definido para essa operação. Por favor, adicione antes de continuar';
			}

			return true;
		},

		validDefaultExpenses() {
			const default_expenses_id = getters.default_expense('id');

			if (!default_expenses_id
				&& !this.loading.default_expenses
			) {
				return 'Taxas padrões não definidas para esse fundo, por favor adicione-a';
			}

			return true;
		},

		defaultExpensesIsNotDefined() {
			const default_expenses_id = getters.default_expense('id');

			if (!default_expenses_id && this.loading.default_expenses) {
				return 'Taxas padrões não definidas para esse fundo, por favor adicione-a.';
			}

			return true;
		},

		async loadDefaultExpenses() {
			try {
				this.loading.default_expenses = true;

				bus.$emit('operationSaveLoading', true);

				let defaultExpenses = {};

				if (this.mustChangeDefaultExpenses()) {
					defaultExpenses = await this.loadNewDefaultExpenses();
				} else {
					defaultExpenses = await this.loadOldDefaultExpenses();
				}
				this.operation.default_expenses = defaultExpenses.data;

				this.loadGoodwill();
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading.default_expenses = false;
				bus.$emit('operationSaveLoading', false);

				this.$emit('revalidate');
			}
		},

		fundIsNotValid() {
			if (!this.operation.fund) return false;

			return true;
		},

		oldOperationNotDefined() {
			const fund = getters.old_operation_config('fund');
			const cnab = getters.old_operation_config('cnab');
			const file = getters.old_operation_config('file');

			if (!fund) return true;

			if (!cnab) return true;

			if (!file) return true;

			return false;
		},

		async loadNewDefaultExpenses() {
			const { operation, project } = this;
			const { fund } = operation;

			const projectService = new ProjectService();

			const defaultExpenses = await projectService
				.expense(project, fund.id);

			return defaultExpenses;
		},

		async loadOldDefaultExpenses() {
			const projectService = new ProjectService();
			const oldOperation = getters.old_operation();

			const defaultExpensesId = oldOperation.fund_default_expenses_id;

			const defaultExpenses = await projectService
				.defaultExpenses(this.project)
				.show({ id: defaultExpensesId });

			return defaultExpenses;
		},

		loadGoodwill() {
			if (this.goodwillNotDefined()) {
				return;
			}

			const operationId = this.operation.type.id;

			const foundGoodwill = this.operation.default_expenses.goodwills
				.find((row) => row.operation_id == operationId);

			if (!foundGoodwill) {
				this.operation.default_expenses.goodwill = null;
				return;
			}

			this.operation.default_expenses.goodwill = foundGoodwill.goodwill;
		},

		goodwillNotDefined() {
			return (!this.operation.default_expenses
				|| !this.operation.default_expenses.goodwills
				|| !this.operation.default_expenses.goodwills.length);
		},

		/**
		 * Ao definir alguns dos dados necessarios para leitura do arquivo
		 * a leitura ocorre para retornar os registros do arquivo
		 */
		async readFile() {
			const relationshipsDefined = this.requiredRelationshipsIsDefined();

			if (!relationshipsDefined) {
				return;
			}

			try {
				this.loading.file = true;
				bus.$emit('operationSaveLoading', true);

				const readerService = new ReaderService();

				const registers = await readerService.send(this.operation);

				this.operation.registers = registers.data;
			} catch (error) {
				this.operation.registers = [];
				this.$http.defaultCatchError(error);
			} finally {
				this.loading.file = false;
				actions.calculate.calculateExpenses();
				bus.$emit('updateAllCalculatedField');
				bus.$emit('operationSaveLoading', false);
				this.$emit('revalidate');
				this.deleteConciliation();
			}
		},

		requiredRelationshipsIsDefined() {
			if (!this.operation.hiring_date) return false;

			if (!this.operation.file || !this.operation.file.content) return false;

			if (!this.operation.fund || !this.operation.fund.id) return false;

			if (!this.operation.cnab || !this.operation.cnab.id) return false;

			if (!this.operation.default_expenses || !this.operation.default_expenses.id) return false;

			return true;
		},

		deleteConciliation() {
			this.operation.conciliated = [];

			bus.$emit('updateConciliationFields');
		},
	},
};
</script>

<style>

</style>
