import Rest from './base/Rest';

export default class VacationsService extends Rest {
	static resource = 'vacations'

	approve() {
		this.relationship = 'approve/';

		return this;
	}

	disapprove() {
		this.relationship = 'disapprove/';

		return this;
	}

	schedule() {
		this.relationship = 'schedule/';

		return this;
	}
}
