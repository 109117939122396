<template>
	<v-menu
		ref="dateMenu"
		v-model="menu"
		:close-on-content-click="false"
		:nudge-right="40"
		transition="scale-transition"
		offset-y
		min-width="290px"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="formattedDate"
				:label="label || 'Data'"
				:prepend-icon=" noPrepend ? 'mdi-calendar' : ''"
				readonly
				:clearable="clearable"
				@click:clear="date = undefined"
				placeholder=""
				v-bind="attrs"
				v-on="on"
				:rules="rules"
			/>
		</template>

		<v-date-picker
			v-model="date"
			@input="menu = false"
		/>
	</v-menu>
</template>

<script>
export default {
	name: 'DatePicker',
	data() {
		return {
			date: this.value,
			menu: false,
		};
	},
	props: {
		clearable: Boolean,
		label: String,
		value: String,
		noPrepend: Boolean,
		rules: Array,
	},
	computed: {
		formattedDate() {
			if (!this.date) return null;

			return this.$helpers.formatMysqlDate(this.date);
		},
	},
	watch: {
		date() {
			this.$emit('input', this.date);
		},
		value() {
			if (this.date == this.value) return;

			this.date = this.value;
		},
	},
};
</script>

<style>

</style>
