<template>
	<v-btn
		class="float-right mx-4 my-auto"
		:class="customClasses()"
		:color="customOrDefaultColor()"
		@click="add"
		depressed
	>
		<v-icon class="mr-2">mdi-plus</v-icon> Adicionar
	</v-btn>
</template>

<script>
export default {
	name: 'ListAddButton',
	components: {},
	props: {
		buttonClass: String,
		color: String,
	},
	methods: {
		add() {
			this.$emit('add');
		},
		customOrDefaultColor() {
			return this.color || 'success';
		},
		customClasses() {
			return this.buttonClass || '';
		},
	},
};
</script>

<style>

</style>
