<template>
  <v-btn
	color="grey"
	class="float-left"
	outlined
	@click="returnToPreviousRoute"
  >
	Cancelar
  </v-btn>
</template>

<script>
export default {
	name: 'FormCancelButton',
	methods: {
		returnToPreviousRoute() {
			this.$router.go(-1);
		},
	},
};
</script>

<style>

</style>
