import Rest from './base/Rest';

export default class UserService extends Rest {
	static resource = 'users';

	token(id) {
		this.relationship = `${id}/token`;

		return this;
	}
}
