<template>
	<v-select
		label="Empresa"
		v-model="model"
		:items="items"
		:loading="isLoading"
		item-text="name"
		item-value="id"
		:return-object="returnObject"
		:rules="rules"
	/>
</template>

<script>
import ProjectService from '@/services/ProjectService';

export default {
	name: 'CompanySelect',
	props: {
		value: [Object, Number],
		project: [Object, Number, String],
		returnObject: Boolean,
		rules: Array,
	},
	data() {
		return {
			model: this.value,
			items: [],
			isLoading: false,
		};
	},
	watch: {
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
		model() {
			this.$emit('input', this.model);
		},
	},
	mounted() {
		this.loadCompanies();
	},
	methods: {
		async loadCompanies() {
			try {
				this.isLoading = true;

				const projectId = typeof this.project === 'object'
					? this.project.id
					: this.project;

				const projectService = new ProjectService();

				const response = await projectService.company(projectId).index();

				this.items = response.data;

				if (this.items.length == 1) {
					const [uniqueCompany] = this.items;
					this.model = uniqueCompany;
				}
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style>

</style>
