<template>
	<v-navigation-drawer
		permanent
		expand-on-hover
		v-model="drawer"
		app
		color="grey lighten-3"
		clipped
		disable-resize-watcher
		class="drawer"
	>
		<v-list v-if="links.lenght != 0">
			<v-list-item-group v-model="route">
				<div
					v-for="(link) in serializedLinks"
					:key="link.title"
				>
					<drawer-item
						v-if="link.type != 'item-grouper'"
						item-class="primary--text py-1"
						:link="link"
					/>

					<drawer-submenu
						v-else
						item-class="primary--text py-1"
						:link="link"
					/>
				</div>
			</v-list-item-group>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import DrawerItem from '@/components/drawer/DrawerItem.vue';
import DrawerSubmenu from '@/components/drawer/DrawerSubmenu.vue';

export default {
	name: 'Drawer',
	components: {
		DrawerItem,
		DrawerSubmenu,
	},
	data() {
		return {
			drawer: null,
			route: 0,
			links: [
			],
		};
	},
	computed: {
		serializedLinks() {
			const filteredLinks = this.filterOnlyDrawerLinks(this.links);

			const sortedLinks = this.orderLinks(filteredLinks);

			return sortedLinks;
		},
	},
	mounted() {
		this.loadDrawerLinks();
	},
	methods: {
		orderLinks(links) {
			const toSort = [...links];

			const sortedLinks = toSort.sort((a, b) => a.order - b.order);

			return sortedLinks;
		},
		filterOnlyDrawerLinks(links) {
			const toFilter = [...links];

			const validTypes = ['item', 'item-grouper', 'system'];

			const filteredLinks = toFilter.filter((link) => validTypes.includes(link.type));

			return filteredLinks;
		},
		getLoggedUser() {
			const jsonUser = sessionStorage.logged_user;

			const user = JSON.parse(jsonUser);

			return user;
		},
		loadDrawerLinks() {
			const user = this.getLoggedUser();

			const decodedToken = this.$helpers.jwt.decode(user.access_token);

			this.links = decodedToken.accesses;
		},
	},
};
</script>

<style lang="sass">
.v-navigation-drawer__content

	&::-webkit-scrollbar
		width: 0.4em

	&::-webkit-scrollbar-track
		background-color: rgba(0, 0, 0, 0.1)

	&::-webkit-scrollbar-thumb
		background-color: rgba(0, 0, 0, 0.2)
		border-radius: 24px

</style>
