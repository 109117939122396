<template>
  <page-container icon="mdi-home-outline">
    <breadcrumbs page-title="Visualização do pedido de férias" />

      <v-card-text v-if="!loading && Object.keys(vacations).length > 0">
        <v-container>
          <v-card-text>Usuários envolvidos</v-card-text>
          <v-col cols="12" sm="12" class="pa-1">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="space">
                <h3>Criador: {{ vacations.creator.name }}</h3>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="space" v-if="vacations.substitute">
                <h3>Substituto: {{ vacations.substitute.name }}</h3>
              </v-col>
            </v-row>
          </v-col>
          <v-divider></v-divider>
          <v-card-text>Data da Solicitação</v-card-text>
          <v-col cols="12" sm="12" class="pa-1" >
            <v-row>
              <v-col cols="12" sm="6" class="space">
                <h3 v-if="vacations.start_date">Data inicial: {{ formatDate(vacations.start_date) }}</h3>
                <h3 v-else>>Data inicial: -</h3>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="space">
                <h3 v-if="vacations.end_date">Data final: {{ formatDate(vacations.end_date) }}</h3>
                <h3 v-else>Data final: -</h3>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="space">
                <h3>Quantidade de dias: {{ vacations.number_days }}</h3>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="space">
                <h3>Período: {{ period(vacations.period) }}</h3>
              </v-col>
            </v-row>
          </v-col>
          <v-divider></v-divider>
          <v-card-text>Informações adicionais</v-card-text>
          <v-col cols="12" sm="12" class="pa-1">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="space">
                <h3>Motivo: {{ vacations.reason }}</h3>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="vacations.observation" class="space">
                <h3>Observação: {{ vacations.observation }}</h3>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="vacations.admin_observation" class="space">
                <h3>Observação do administrador: {{ vacations.admin_observation }}</h3>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="space" >
                <h3>Situação: {{ status(vacations.status, vacations) }}</h3>
                <span v-if="status(vacations.status, vacations) !== 'Pendente de análise' || status(vacations.status, vacations) === ''">
                  <h3 v-if="vacations.admin">Responsável: {{ vacations.admin.name }}</h3>
                  <h3 v-else>Responsável: Sem Registro</h3>
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="space" >
                <h3>Tipo: {{ types(vacations.type) }}</h3>
              </v-col>
            </v-row>
          </v-col>
          <v-divider></v-divider>
        </v-container>
      </v-card-text>

  </page-container>
</template>
<script>
import Moment from 'moment';
import VacationsService from '@/services/VacationsService';
import breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';

import PageContainer from '@/components/defaults/layout/PageContainer.vue';

export default {
	name: 'VacationsShow',
	data: () => ({
		vacations: {},
		loading: false,
	}),
	components: {
		breadcrumbs,
		PageContainer,
	},
	mounted() {
		this.open();
	},
	methods: {
		async open() {
			try {
				this.loading = true;
				const vacationsService = new VacationsService();
				const { id } = this.$route.params;

				const response = await vacationsService.show({ id });

				this.vacations = response;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
		status(string, item) {
			if (string === 'approve') {
				return 'Pedido aprovado';
			}

			if (string === 'disapprove') {
				return 'Pedido reprovado';
			}

			if (string === '' && item.type === 'S') {
				return 'Pendente de análise';
			}

			return '';
		},
		formatDate(date) {
			return Moment(date).format('DD/MM/YYYY');
		},

		types(string) {
			switch (string) {
			case 'V':
				return 'Venda';
			case 'C':
				return 'Crédito';
			case 'CG':
				return 'Crédito Gerado';
			case 'B':
				return 'Baixa';
			default:
				return 'Solicitação';
			}
		},
		period(string) {
			switch (string) {
			case 'evening':
				return 'Tarde';
			case 'morning':
				return 'Manhã';
			case 'integral':
				return 'Integral';
			default:
				return '-';
			}
		},
	},
};
</script>
<style scoped>
.space {
  margin-bottom: 40px;
}
</style>
