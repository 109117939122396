<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:search="search"
		:loading="loadingData"
		:customFilter="filterByAllData"
		hide-default-footer
	>
		<template v-slot:item.fund_type.name="{ item }">
			<span class="text-capitalize"> {{ item.fund_type.name }} </span>
		</template>

		<template v-slot:item.actions="{ item }">
			<list-edit-button @edit="onEdit(item)"/>
		</template>

		<template v-slot:loading>
			<loading-data-circular/>
		</template>
	</v-data-table>
</template>

<script>
import FundService from '@/services/FundService';

import LoadingDataCircular from '@/components/defaults/layout/LoadingDataCircular.vue';
import ListEditButton from '@/components/defaults/buttons/ListEditButton.vue';

export default {
	name: 'FundListTable',
	components: {
		ListEditButton,
		LoadingDataCircular,
	},
	props: {
		loading: Boolean,
		search: String,
	},
	data() {
		return {
			items: [],
			loadingData: false,
			headers: [
				{ text: 'Fundo', value: 'name' },
				{ text: 'Tipo de Fundo', value: 'fund_type.name' },
				{ text: '', value: 'actions', align: 'end' },
			],
		};
	},
	created() {
		this.loadFunds();
	},
	methods: {
		/**
		 * CRUDS
		 */
		async loadFunds() {
			try {
				this.loadingData = true;

				const fundService = new FundService();

				const response = await fundService.index();

				this.items = response.data;
			} catch (error) {
				this.$http.defaultCatchError(error);
			} finally {
				this.loadingData = false;
			}
		},
		/**
		 * EVENTS
		 */
		onEdit(item) {
			this.$emit('edit', item);
		},
		/**
		 * FILTER METHODS
		 * usados em diversos codigos para filtragem especifica ou de relacionamentos
		 *
		 * filterFunction
		 * Filtra todas as linhas atraves do seus campos comumns,
		 * geralmente em String, por isso o switch case.
		 * alem dos valores associados que estarao no tipo objeco.
		 * no caso por exemplo de filterWithNestedAccesses (UserTypeTable.vue - 81)
		 * 	o objeto a ser filtrado (tipo de usuario) possui uma relacao de com muitos acessos
		 *
		 * !important: apesar de retornar um array com as relacoes, o tipo definido atraves
		 * 	da funcao typeof eh sempre um objeto.
		 *
		 * utiliza da chamada padrao de customFilter presente na documentacao do vuetify
		 *
		 * @reference https://vuetifyjs.com/en/api/v-data-table/
		 *
		 * @param {String|*} value - valor do campo percorrido pela funcao de
		 * 	filtro do vuetify, cada value refere a um valor do objeto ou item passado.
		 * @param {String|*} search - valor passado na propriedade :search para o v-data-table
		 * @return {Boolean} result
		 */
		filterByAllData(value, search) {
			const typeOfValue = typeof (value);

			switch (typeOfValue) {
			case 'string':
				return this.$helpers.like(value, search);

			case 'object':
				return this.filterRelationships(value, search);

			default:
				return false;
			}
		},
		/**
		 * filterRelationships
		 *
		 * divisao de conceito para filtro de dados relacionados a linha em busca.
		 * filtra uma relacao atraves de sua chave de busca (geralmente uma string).
		 * usados em diversos codigos para filtragem especifica ou de relacionamentos
		 *
		 * @param {String|*} value - valor do campo percorrido pela funcao de
		 * 	filtro do vuetify, cada value refere a um valor do objeto ou item passado.
		 * @param {String|*} search - valor passado na propriedade :search para o v-data-table
		 * @return {Boolean} result
		 */
		filterRelationships(value, search) {
			const result = value.some((item) => {
				const nameOfRelationField = item.name;

				return this.$helpers.like(nameOfRelationField, search);
			});

			return result;
		},
	},
};
</script>

<style>

</style>
