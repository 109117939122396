export default {
	isArchiveHeader(type) {
		return type == '0';
	},
	isArchiveTrailer(type) {
		return type == '9';
	},
	options: {
		payment_methods: [
			{ value: 1, text: 'Boleto' },
			{ value: 2, text: 'TED/Transf.' },
			{ value: 3, text: 'DOC' },
		],
		register_types: [
			{ value: 0, text: 'Header de arquivo' },
			{ value: 1, text: 'Header de lote' },
			{ value: 3, text: 'Detalhe' },
			{ value: 5, text: 'Trailer de lote' },
			{ value: 9, text: 'Trailer de arquivo' },
		],
		account_types: [
			{ value: 0, text: 'Poupança' },
			{ value: 1, text: 'Corrente' },
			{ value: 2, text: 'Ambos' },
		],
		is_tax: [
			{ value: 2, text: 'Ambos' },
			{ value: 1, text: 'Sim' },
			{ value: 0, text: 'Não' },
		],
		allow_duplicate_lines: [
			{ value: 0, text: 'Não' },
			{ value: 1, text: 'Sim' },
		],
		same_bank: [
			{ value: 0, text: 'Não' },
			{ value: 1, text: 'Sim' },
			{ value: 2, text: 'Ambos' },
		],
		same_owner: [
			{ value: 0, text: 'Não' },
			{ value: 1, text: 'Sim' },
			{ value: 2, text: 'Ambos' },
		],
		operations: [
			{ value: 0, text: 'Remessa' },
			{ value: 1, text: 'Retorno' },
		],
		operation_types: [
			{ value: 0, text: 'Pagar' },
			{ value: 1, text: 'Receber' },
		],
		situations: [
			{ value: 0, text: 'Carteira' },
			{ value: 1, text: 'Simples' },
			{ value: 2, text: 'Descontada' },
			{ value: 3, text: 'Caucionada' },
			{ value: 4, text: 'Vinculada' },
			{ value: 5, text: 'Com advogado' },
			{ value: 6, text: 'Judicial' },
			{ value: 7, text: 'Caução descontada' },
			{ value: 'F', text: 'Carteira protesto' },
			{ value: 'G', text: 'Carteira acordo' },
			{ value: 'H', text: 'Cartório' },
			{ value: 'I', text: 'Carteira caixa loja' },
			{ value: 'J', text: 'Carteira caixa geral' },
		],
	},
};
